<div class="example-form" style="padding-top: 2% !important; padding-bottom: 7% !important;" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile">
        <div class="col card-header">
            <div id="lastelement1" class="center texto">
                <h6><strong>{{idiomas.CertificadoBancario | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">

            <mat-divider></mat-divider>
            <div style="padding-top: 10px"></div>
            <br>
            <mat-label hidden="true">{{ idiomas.Cuenta | uppercase }}</mat-label>

            <div class="form-row" hidden="true">
                <mat-form-field class="col-12 token" appearance="outline">
                    <mat-label>{{ccuenta}}</mat-label>
                    <input matInput disabled>
                </mat-form-field>
            </div>


            <div class="form-row" style="text-align:center; padding-top: 15px;">
                <div class="col-6" style="min-width: 205px;">
                    <div>
                        <h6 class="posCon-header" style="text-align: center">
                            {{ idiomas.CertificadoCuentaActiva | uppercase }}
                        </h6>
                    </div>
                    <img src="../../assets/resources/mipmap-hdpi/certificados-cuenta-activa.png" style="margin: 5px;"
                        width="250px" height="220px" alt="Photo of certificate">
                    <div class="form-row">
                        <div class="col-6">
                            <button mat-raised-button [disabled]="deshabilitarBotones" color="primary"
                                class="buttonSubmit" style="margin-bottom: 1em;" (click)="previewReportPDF(0)">
                                <mat-icon>pageview</mat-icon>
                                {{idiomas.PrevisualizarCertificado}}
                            </button>
                        </div>
                        <div class="col-6">
                            <button mat-raised-button [disabled]="deshabilitarBotones" color="primary"
                                class="buttonSubmit" style="margin-bottom: 1em;" (click)="actionButtonGenerate('0')">
                                <mat-icon>download_done</mat-icon>
                                {{idiomas.GenerarCertificado}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-6" style="min-width: 205px;">
                    <div>
                        <h6 class="posCon-header" style="text-align: center">
                            {{ idiomas.CertificadoConsolidado | uppercase }}
                        </h6>
                    </div>
                    <img src="./../../assets/resources/mipmap-hdpi/certificados-consolidado.png" style="margin: 5px;"
                        width="250px" height="220px" alt="Photo of certificate">
                    <div class="form-row">
                        <div class="col-6">
                            <button mat-raised-button [disabled]="deshabilitarBotones" color="primary"
                                class="buttonSubmit" style="margin-bottom: 1em;" (click)="previewReportPDF(1)">
                                <mat-icon>pageview</mat-icon>
                                {{idiomas.PrevisualizarCertificado}}
                            </button>
                        </div>
                        <div class="col-6">
                            <button mat-raised-button [disabled]="deshabilitarBotones" color="primary"
                                class="buttonSubmit" style="margin-bottom: 1em;" (click)="actionButtonGenerate('1')">
                                <mat-icon>download_done</mat-icon>
                                {{idiomas.GenerarCertificado}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="enableFieldsRequestToken">
                <div autocomplete="off" class="form-row">
                    <mat-form-field class="col-2 token" *ngIf="tokenRequested" [class.col-12]="bandMobile"
                        appearance="outline">
                        <input id="soliToken" matInput type="text" autocomplete="off"
                            onkeydown="this.setAttribute('type','password')" [formControl]="tokenForm" maxlength="6"
                            inputmode="numeric" appBlockCopyPaste>
                        <mat-error *ngIf="tokenForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenForm.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenForm.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenForm.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenRequested" [class.col-12]="bandMobile"
                        appearance="outline">
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div *ngIf="internet.internet" class="left col-2 token-button">
                        <button [disabled]="(deshabilitarBotones) || (!ccuenta) || (enableCronoIn)" mat-raised-button
                            (click)="tokenRequest()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-6">
                        <button mat-raised-button *ngIf="((tokenForm.valid && tokenRequested))" (click)="sendResponse()"
                            title={{idiomas.Aceptar}} color="primary" class="buttonSubmit">
                            <mat-icon>download_done</mat-icon>
                            {{idiomas.Aceptar}}
                        </button>
                    </div>
                    <div align="end" style="text-align: end;" class="col-6">
                        <button mat-raised-button (click)="cleanForm()" title={{idiomas.Limpiar}} color="warn"
                            class="buttonClean">
                            <mat-icon> clear_all</mat-icon>
                            {{idiomas.Limpiar}}
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="generatePDF || intento">
                <br>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </mat-card>
    <div *ngIf="bandMobile">
        <div class="col card-header">
            <div id="lastelement1" class="center texto">
                <h6><strong>{{idiomas.CertificadoBancario | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">

            <mat-divider></mat-divider>
            <div style="padding-top: 10px"></div>
            <mat-label hidden="true">{{ idiomas.Cuenta | uppercase }}</mat-label>

            <div class="form-row" hidden="true">
                <mat-form-field class="col-12 token" appearance="outline">
                    <mat-label>{{ccuenta}}</mat-label>
                    <input matInput disabled>
                </mat-form-field>
            </div>

            <div *ngIf="generatePDF || intento">
                <br>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>

            <div class="form-row" style="text-align:center; padding-top: 15px;">
                <div class="col-12" style="min-width: 205px;">
                    <div>
                        <h6 class="posCon-header" style="text-align: center">
                            {{ idiomas.CertificadoCuentaActiva | uppercase }}
                        </h6>
                    </div>
                    <img src="../../assets/resources/mipmap-hdpi/certificados-cuenta-activa.png" style="margin: 5px;"
                        width="150px" height="120px" alt="Photo of certificate">
                    <div class="form-row">
                        <div class="col-6">
                            <button mat-raised-button [disabled]="deshabilitarBotones" color="primary"
                                class="buttonSubmit" style="margin-bottom: 1em;" (click)="previewReportPDF(0)">
                                <mat-icon>pageview</mat-icon>
                                {{idiomas.PrevisualizarCertificado}}
                            </button>
                        </div>
                        <div class="col-6">
                            <button mat-raised-button [disabled]="deshabilitarBotones" color="primary"
                                class="buttonSubmit" style="margin-bottom: 1em;" (click)="actionButtonGenerate('0')">
                                <mat-icon>download_done</mat-icon>
                                {{idiomas.GenerarCertificado}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="min-width: 205px;">
                    <div>
                        <h6 class="posCon-header" style="text-align: center">
                            {{ idiomas.CertificadoConsolidado | uppercase }}
                        </h6>
                    </div>
                    <img src="./../../assets/resources/mipmap-hdpi/certificados-consolidado.png" style="margin: 5px;"
                        width="150px" height="120px" alt="Photo of certificate">
                    <div class="form-row">
                        <div class="col-6">
                            <button mat-raised-button [disabled]="deshabilitarBotones" color="primary"
                                class="buttonSubmit" style="margin-bottom: 1em;" (click)="previewReportPDF(1)">
                                <mat-icon>pageview</mat-icon>
                                {{idiomas.PrevisualizarCertificado}}
                            </button>
                        </div>
                        <div class="col-6">
                            <button mat-raised-button [disabled]="deshabilitarBotones" color="primary"
                                class="buttonSubmit" style="margin-bottom: 1em;" (click)="actionButtonGenerate('1')">
                                <mat-icon>download_done</mat-icon>
                                {{idiomas.GenerarCertificado}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="enableFieldsRequestToken">
                <div autocomplete="off" class="form-row">
                    <div *ngIf="internet.internet" class="left col-6 token-button"
                        style="padding-bottom: 10px !important;">
                        <button style="margin-top: 0 !important;"
                            [disabled]="(deshabilitarBotones) || (!ccuenta) || (enableCronoIn)" mat-raised-button
                            (click)="tokenRequest()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <mat-form-field class="col-2 token" *ngIf="tokenRequested" [class.col-12]="bandMobile"
                        appearance="outline">
                        <input id="soliToken" matInput type="text" autocomplete="off"
                            onkeydown="this.setAttribute('type','password')" [formControl]="tokenForm" maxlength="6"
                            inputmode="numeric" appBlockCopyPaste>
                        <mat-error *ngIf="tokenForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenForm.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenForm.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenForm.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenRequested" [class.col-12]="bandMobile"
                        appearance="outline">
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                </div>
                <div class="form-row">
                    <div class="col-6">
                        <button mat-raised-button *ngIf="((tokenForm.valid && tokenRequested))" (click)="sendResponse()"
                            title={{idiomas.Aceptar}} color="primary" class="buttonSubmit">
                            <mat-icon>download_done</mat-icon>
                            {{idiomas.Aceptar}}
                        </button>
                    </div>
                    <div align="end" style="text-align: end;" class="col-6">
                        <button mat-raised-button (click)="cleanForm()" title={{idiomas.Limpiar}} color="warn"
                            class="buttonClean">
                            <mat-icon> clear_all</mat-icon>
                            {{idiomas.Limpiar}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>