<div class="example-form" style="padding-top: 2% !important; padding-bottom: 40px;" fxLayout="column"
    fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.BloqueoCuenta | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding: 16px;">
            <div *ngIf="!intentoReenviar">
                <strong>
                    <mat-label>{{idiomas.DatosBloqueoCuenta}}</mat-label>
                </strong>
                <div autocomplete="off" class="form-row">
                    <div class="col-12" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.Cuenta}}</mat-label>
                            <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()"
                                [compareWith]="compareJsonPerValue" (openedChange)="funcionRetornarCuentasOwn($event)">
                                <mat-option *ngIf="!intentoCuentasOwn" disabled>
                                    <div style="margin: 10px;">
                                        <mat-progress-bar mode="indeterminate">
                                        </mat-progress-bar>
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta"
                                    (onSelectionChange)="selectedOptionOrigen(cuenta)">
                                    {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                                    <mat-divider style="border-top-width: 2px;"></mat-divider>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
            
                </div>
           
                <div autocomplete="off" class="form-row">
                    <div class="col-12" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.MotivoBloqueoCuenta}}</mat-label>
                            <div>
                                <textarea matInput type="text"  autocomplete="off" style="text-transform: uppercase;"
                                    [formControl]="motivoDeBloqueoDeCuenta" name="motivoDeBloqueoDeCuenta" [rows]="4" minlength="1" maxlength="2000"></textarea>
                            </div>
                            <mat-error *ngIf="motivoDeBloqueoDeCuenta.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                            <mat-error *ngIf="motivoDeBloqueoDeCuenta.hasError('pattern')">
                                <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div 
                class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex align-items-start">
                <mat-checkbox 
                  name="check" 
                  color="primary"
                  [checked]="checkedAuthorizeAccountBlocking"
                  [disabled]="(!cuentaDesdeSelected.valid) || (!motivoDeBloqueoDeCuenta.valid) || (enableCronoIn)"
                  (change)="authorizeAccountBlocking($event)">
                </mat-checkbox>
                <div class="checkbox-text">
                  <label>{{idiomas.AutorizoBloqueoCuenta}}</label>
                  <span class="justified-text">{{idiomas.MensajeBloqueoCuenta}}</span>
                </div>
              </div>
              <br>
                <div *ngIf="!pinValidation && internet.internet" autocomplete="off" class="form-row"
                    (keyup.enter)="sendAccountBlocking('1')">
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="text" autocomplete="off"
                            onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                            inputmode="numeric" appBlockCopyPaste>
                        <mat-error *ngIf="token.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div *ngIf="internet.internet" class="left col-2 token-button">
                        <button
                            [disabled]="(!cuentaDesdeSelected.valid) || (!motivoDeBloqueoDeCuenta.valid) || (enableCronoIn) || (!checkedAuthorizeAccountBlocking)"
                            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                </div>
            </div>
            <div *ngIf=intento style="padding-top:  10px !important; padding-bottom: 10px !important;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="form-row" *ngIf="!intentoReenviar">
                <div id="floatCont" class="col-6">
                    <button mat-raised-button
                    *ngIf="(pinValid || !internet.internet) && cuentaDesdeSelected.valid &&  motivoDeBloqueoDeCuenta.valid  &&  (checkedAuthorizeAccountBlocking)"
                    (click)="sendAccountBlocking('1')" title={{idiomas.Aceptar}}
                    color="primary" class="buttonSubmit" [disabled]="disableButton">
                    <mat-icon>download_done</mat-icon>
                    {{idiomas.Aceptar}}
                </button>
                <button mat-raised-button
                    *ngIf="((token.valid && tokenSolicitado) || !internet.internet) && cuentaDesdeSelected.valid && motivoDeBloqueoDeCuenta.valid &&  (checkedAuthorizeAccountBlocking)"
                    (click)="sendAccountBlocking('0')" title={{idiomas.Aceptar}}
                    color="primary" class="buttonSubmit" [disabled]="disableButton">
                    <mat-icon>download_done</mat-icon>
                    {{idiomas.Aceptar}}
                </button>
                </div>
                <div id="floatCont" align="end" style="text-align: end;" class="col-6">
                    <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn"
                        class="buttonClean">
                        <mat-icon> clear_all</mat-icon>
                        {{idiomas.Limpiar}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card>
    <div *ngIf="bandMobile">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.BloqueoCuenta | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding: 12px;">
            <div *ngIf="!intentoReenviar">
                <div autocomplete="off" class="form-row">
                    <div class="col-10">
                        <strong>
                            <mat-label>{{idiomas.DatosBloqueoCuenta}}</mat-label>
                        </strong>
                    </div>
                    <div class="col-2" style="text-align: end;">
                        <mat-icon (click)="close()"> delete_sweep</mat-icon>
                    </div>
                    <div class="col-12" [class.col-12]="bandMobile" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.Cuenta}}</mat-label>
                            <mat-select class="fit-content"
                                style="min-height: 40px !important;font-size: 1.1em !important;"
                                [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()"
                                [compareWith]="compareJsonPerValue" (openedChange)="funcionRetornarCuentasOwn($event)">
                                <mat-option *ngIf="!intentoCuentasOwn" disabled>
                                    <div style="margin: 10px;">
                                        <mat-progress-bar mode="indeterminate">
                                        </mat-progress-bar>
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta"
                                    (onSelectionChange)="selectedOptionOrigen(cuenta)">
                                    {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                                    <mat-divider style="border-top-width: 2px;"></mat-divider>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
               
                  
                    <div class="col-12" [class.col-12]="bandMobile" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.MotivoBloqueoCuenta}}</mat-label>
                            <div>
                                <textarea matInput type="text" autocomplete="off" style="text-transform: uppercase;"
                                    [formControl]="motivoDeBloqueoDeCuenta" name="motivoDeBloqueoDeCuenta" [rows]="4" minlength="1" maxlength="2000"></textarea>
                            </div>
                            <mat-error *ngIf="motivoDeBloqueoDeCuenta.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                            <mat-error *ngIf="motivoDeBloqueoDeCuenta.hasError('pattern')">
                                <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
                            </mat-error>
                        </mat-form-field>

                       
                    </div>

                    <div 
                    class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex align-items-start">
                    <mat-checkbox 
                      name="check" 
                      color="primary"
                      [checked]="checkedAuthorizeAccountBlocking"
                      [disabled]="(!cuentaDesdeSelected.valid) || (!motivoDeBloqueoDeCuenta.valid) || (enableCronoIn)"
                      (change)="authorizeAccountBlocking($event)">
                    </mat-checkbox>
                    <div class="checkbox-text">
                      <label>{{idiomas.AutorizoBloqueoCuenta}}</label>
                      <span class="justified-text">{{idiomas.MensajeBloqueoCuenta}}</span>
                    </div>
                  </div>
                  
                  
                </div>
                <br>
                <div *ngIf="!pinValidation && internet.internet" autocomplete="off" class="form-row"
                    (keyup.enter)="sendAccountBlocking('1')">
                    <div *ngIf="internet.internet" class="left col-6 token-button"
                        style="padding-bottom: 10px !important;">
                        <button style="margin-top: 0 !important;"
                            [disabled]="(!cuentaDesdeSelected.valid) || (!motivoDeBloqueoDeCuenta.valid) || (enableCronoIn) || (!checkedAuthorizeAccountBlocking)"
                            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" [class.col-12]="bandMobile"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="text" autocomplete="off"
                            onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                            inputmode="numeric" appBlockCopyPaste>
                        <mat-error *ngIf="token.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" [class.col-12]="bandMobile"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                </div>
                
                <div *ngIf=intento style="padding-top:  10px !important; padding-bottom: 10px !important;">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div style="padding-top: 15px !important;">
                    <button *ngIf="token.valid" mat-raised-button (click)="sendAccountBlocking('0')" title={{idiomas.TransferirMobMen}}
                        color="primary" class="buttonSubmitTransfer"
                        [disabled]="((token.valid && !tokenSolicitado) || pinValid || !internet.internet) || disableButton || cuentaDesdeSelected.invalid || motivoDeBloqueoDeCuenta.invalid || (!checkedAuthorizeAccountBlocking)"
                        style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.Aceptar}}
                    </button>
                    <button *ngIf="pinValidation" mat-raised-button (click)="sendAccountBlocking('1')" title={{idiomas.TransferirMobMen}}
                        color="primary" class="buttonSubmitTransfer"
                        [disabled]="((token.valid && !tokenSolicitado) || pinValid || !internet.internet) || disableButton || cuentaDesdeSelected.invalid || motivoDeBloqueoDeCuenta.invalid || (!checkedAuthorizeAccountBlocking)"
                        style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.Aceptar}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>