import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { BaseDatos } from '../funciones/basededatos';
import { Servicios } from '../funciones/encryptar';
import { internetComponent } from '../funciones/internet';
import { FormControlValidators } from '../funciones/formcontrol';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AlertasLoginComponent } from '../alertas-login/alertas-login.component';

const AccountsListValueObjectName = 'CuentPropPagoServ';
const ServiceListValueObjectName = 'ServicPagoServ';

interface IPaymentValueObject {
  MONTO: number;
  COMISION: number;
  CUENTACREDITO: string;
  IDENTIFICACION_BENEFICIARIO: string;
}

interface IReportValueObject {
  reporte: string;
}

interface IError {
  error: {
    mensaje: string;
  };
  status: number;
}

const queryWSObject = {
  'numeroServicio': '',
  'usuario': ''
};

const reportWSObject = {
  'usuario': '',
  'nombre': '18_COMPROBANTE_PAGO',
  'subsistema': '18',
  'transaccion': '6000',
  'tipo': 'PDF',
  'rep^NUMEROMENSAJE': ''
};

const paymentWSObject = {
  'numeroServicio': '',
  'codigoServicio': '',
  'comentarioServicio': '',
  'usuario': '',
  'cuenta': '',
  'monto': '',
  'descripcion': '',
  'clave': '',
  'comision': '',
  'moneda': '',
  'tarPendType': 'pagoServ'
};

const rechargeWSObject = {
  'numeroServicio': '',
  'codigoServicio': '',
  'comentarioServicio': '',
  'usuario': '',
  'cuenta': '',
  'monto': '',
  'clave': '',
  'comision': '',
  'moneda': '',
  'tarPendType': 'recargas'
};

const accountsWSObject = {
  'transaccion': '186000-lv-cuentas-in.xml',
  'usuario': '',
  'alias': 'tc0',
  'cri^tc0^CESTATUSCUENTA^JOIN': '002',
  'cri^tc0^CSUBSISTEMA^JOIN': '04',
  'cri^tu0^CUSUARIO^JOIN': '',
  'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
  'cri^tctp0^CTRANSACCION^JOIN': '6000',
  'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
};

const servicesWSObject = {
  'transaccion': '186000-lv-servicios-in.xml',
  'usuario': '',
  'alias': 'tpsc0',
  'cri^tu0^CUSUARIO^JOIN': '$userCode',
  'cri^tpsc0^CPERSONA_COMPANIA^JOIN': '$company',

};

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatProgressBarModule, MatDividerModule, MatCardModule, MatButtonModule, MatInputModule, MatTableModule, MatExpansionModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, FlexLayoutModule, TemporizadorComponent, AlertasLoginComponent],
  selector: 'app-pago-servicios',
  templateUrl: './pago-servicios.component.html',
  styleUrls: ['./pago-servicios.component.css']
})
export class PagoServiciosComponent implements OnInit, AfterViewInit {

  //Inputs
  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;
  @Input() dataTarPend: any;
  @Input() bandServReg: boolean;
  @Input() nomServ: string;
  @Input() resendPay: any;

  //Outputs
  @Output() regServEv = new EventEmitter<any>();

  //Variables
  suministroForm = this.validators.textNumberForm();
  references: any[];
  lfiltradoservicios: any[] = [];
  accounts: any[];
  selectedReference: any;
  selectedAccount: any;
  amount: string;
  fee: string;
  queryExecuted = false;
  bandAdditionalDteail = false;
  tokenRequested = false;
  paymentExecuted = false;
  pdfBase64: string;
  accountforCredit: string;
  idBeneficiary: string;
  dowloadReporte: boolean;
  intento: boolean;
  servicioRegistrado: boolean;
  bandDisableForm: boolean;
  displayedColumns: string[] = ['Label', 'Value']
  paymentDetailedArray: any[];
  paymentsDetail: any[];
  paymentDetailAux: any[];
  rubros: any[];
  additionalDetailArray: any[];
  additionalDetail: any[];
  numMaxRow: number;
  flechaR0: boolean;
  flechaL0: boolean;
  flechaR1: boolean;
  flechaL1: boolean;
  repInvoiceData: string = "";
  repInvoiceAdditionalData: string = "";
  paymentValueZero: boolean = false;
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  rechargeExecuted = false;
  intentoCuentasOwn: boolean = false;
  comentarioSuministro: string = null;
  ejemploSuministro: string = "";
  intentoUsuarioSospechoso: boolean;
  bandMobile: boolean;
  usuarioSospechoso: boolean;
  fechaTransaccion: string = "";
  numeroMensaje: string = "";
  cuentaDesde: boolean;
  esServicio: boolean;
  amountValuesArray: any[] = null;
  minValueAmount: string = "";
  maxValueAmount: string = "";
  stepValueAmount: string = "";
  clientNameServicePayment: string = "";
  clientIdServicePayment: string = "";
  disableButton: boolean;
  isExpanded: boolean = false;

  //FormControls
  referenceFormControl = new UntypedFormControl();
  accountFormControl = this.validators.comboForm();
  rubroFormControl = this.validators.comboForm();
  descriptionFormControl = this.validators.textNumberForm();
  tokenFormControl = this.validators.otpForm();
  rechargeFormControl = this.validators.decimalsForm();

  constructor(private api: ApiService, public alert: Alerta, private dbHandler: BaseDatos,
    public service: Servicios, public internet: internetComponent, private validators: FormControlValidators,
    public mostrarPdf: MostrarPDF) {
    this.references = [];
    this.dowloadReporte = false;
    this.intento = true;
    this.servicioRegistrado = false;
    this.bandDisableForm = false;
    this.numMaxRow = 7;
    this.paymentDetailedArray = [];
    this.paymentsDetail = [];
    this.additionalDetailArray = [];
    this.additionalDetail = [];
    this.paymentDetailAux = [];
    this.rubros = [];
    this.flechaR0 = false;
    this.flechaL0 = true;
    this.flechaR1 = false;
    this.flechaL1 = true;
    this.enableCronoIn = false;
    this.bandMobile = false;
    this.disableButton = false;
  }

  ngOnInit() {
    this.bandMobile = false;
    this.usuarioSospechoso = true
    this.intentoUsuarioSospechoso = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngAfterViewInit() {
    this.getAccountList();
    if (this.bandServReg) {
      this.getServicesList();
    } else {
      this.retornarServicios();
    }
    if (this.dataTarPend) {
      Promise.resolve(null).then(() => this.intento = true);
      setTimeout(function () { Promise.resolve(null).then(() => this.intentoReenviar = true), 100 });
      Promise.resolve(null).then(() => this.setNewValuesFromPendingTask());
    }
  }

  consultarActividadesSospechosas() {
    let envioUsuario = {};
    this.api.postProvider2InternetCheck('/actividadSospechosa', this.dbHandler.id_token, envioUsuario).then((data: any) => {
      if (data[0]['SUSPICIUSACTIVITY'] == "true") {
        this.usuarioSospechoso = true;
      } else {
        this.usuarioSospechoso = false;
      }
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': data,
      };
      this.dbHandler.insertarConsulta('usuarioSospechoso', consulta) //Almacena en BDD los beneficiarios registrados
      this.intentoUsuarioSospechoso = true;
      //.
    }, (err) => {
      this.intentoUsuarioSospechoso = true;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else {
        this.usuarioSospechosoRegOffline();
      }
    });
  }

  usuarioSospechosoRegOffline() {
    let ObjConsulta = this.dbHandler.retornarConsulta('usuarioSospechoso');
    if (ObjConsulta.data[0]['SUSPICIUSACTIVITY'] == "true") {
      this.usuarioSospechoso = true;
    } else {
      this.usuarioSospechoso = false;
      this.intentoUsuarioSospechoso = true;
    }
  }

  getCurrentTimeAsTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = this.completeDigits(now.getMonth() + 1);
    const day = this.completeDigits(now.getDate());
    return `${year}-${month}-${day} 00:00:00.000`;
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString();
  }

  setNewValuesFromPendingTask() {
    this.selectedReference = this.references.find((suministro) => (suministro.NUMEROCONTRATO === this.dataTarPend.numeroServicio));
    this.referenceFormControl.setValue(this.dataTarPend.numeroServicio);
    this.selectedAccount = this.accounts.find((accountFromList) => (accountFromList.CCUENTA === this.dataTarPend.cuenta));
    this.accountFormControl.setValue(this.dataTarPend.cuenta);
    this.amount = this.validators.FormatNumber(this.dataTarPend.monto);
    this.fee = this.validators.FormatNumber(this.dataTarPend.comision);
    this.queryExecuted = true;
    this.descriptionFormControl.setValue(this.dataTarPend.descripcion);
  }

  getAccountList() {
    this.intentoCuentasOwn = false;
    accountsWSObject['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, accountsWSObject).then((data: any) => {
      if (data) {
        this.accounts = data;
        this.dbHandler.insertarConsulta(AccountsListValueObjectName, this.accounts);
        this.intentoCuentasOwn = true;
        this.accountFormControl.setValue(this.accounts[0].CCUENTA);
        this.onSelectedAccount(this.accounts[0].CCUENTA);
      }
    }, (err) => {
      this.intentoCuentasOwn = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alert.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.accounts = this.dbHandler.retornarConsulta(AccountsListValueObjectName);
        if (!this.accounts) {
          this.accounts = [];
        }
      }
    });
  }

  funcionRetornarCuentasOwn(e) {
    if (!this.intentoCuentasOwn) {
      this.getAccountList();
    }
  }

  getServicesList() {
    this.intento = true;
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, servicesWSObject).then((data: any) => {
      if (data) {
        data = data.filter(x => x.CSERVICIO != undefined);
        this.references = data;
        this.lfiltradoservicios = this.references;
        this.intento = false;
        this.dbHandler.insertarConsulta(ServiceListValueObjectName, this.references);
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alert.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.references = this.dbHandler.retornarConsulta(ServiceListValueObjectName);
        if (!this.references) {
          this.references = [];
        }
      }
      this.lfiltradoservicios = this.references;
    });
  }

  onSelectedReferenceRegServ(reference: any) {
    this.rubros = [];
    this.descriptionFormControl.reset();
    this.tokenFormControl.reset();
    this.paymentValueZero = false;
    this.queryExecuted = false;
    this.bandAdditionalDteail = false;
    this.selectedReference = this.references.find(
      (suministro: any) => (
        suministro.CSERVICIO === reference.CSERVICIO &&
        suministro.NUMEROCONTRATO === reference.NUMEROCONTRATO
      )
    );
    this.amountValuesArray = null;
    if (this.selectedReference['NOMBRESCONTRATO'] === 'RECARGAS') {
      this.esServicio = true;
      this.suministroForm.setValue(this.selectedReference['NUMEROCONTRATO']);
      if (this.selectedReference['COMENTARIOS'] && this.selectedReference['COMENTARIOS'] != "null") {
        //Si existen restricciones de montos en recargas
        let restriccionesMontosString = this.selectedReference['COMENTARIOS'].replace(/ /g, "");
        let restriccionesMontos = restriccionesMontosString.split("|");
        switch (restriccionesMontos[0]) {
          case "CB": //Valores Definidos de recarga Ej: 3,6,9
            this.amountValuesArray = [];
            restriccionesMontos[1].split(",").map(x => {
              let amountValue = { value: x, viewValue: new CantidadMonetaria().transform(x) };
              this.amountValuesArray.push(amountValue);
              return true;
            })
            if (this.amountValuesArray.length == 0) {
              this.amountValuesArray = null;
            }
            break;
          case "MMI": //Control con valores Minimo, Maximo e Intervalo Ej: 3,100,1 (min,max,interv)
            let valuesMMI = restriccionesMontos[1].split(",");
            this.minValueAmount = new CantidadMonetaria().transform(valuesMMI[0]);
            this.maxValueAmount = new CantidadMonetaria().transform(valuesMMI[1]);
            this.stepValueAmount = new CantidadMonetaria().transform(valuesMMI[2]);
            this.rechargeFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(valuesMMI[0]), maxAmountValidationNumber(valuesMMI[1]), noDecimalAmountValidation, nMultipleAmountValidationNumber(valuesMMI[0], valuesMMI[2])]);
            break;
          case "MI": //Control con valores Minimo e Intervalo Ej: 3,1 (min,interv)
            let valuesMI = restriccionesMontos[1].split(",");
            this.minValueAmount = new CantidadMonetaria().transform(valuesMI[0]);
            this.stepValueAmount = new CantidadMonetaria().transform(valuesMI[1]);
            this.rechargeFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(valuesMI[0]), noDecimalAmountValidation, nMultipleAmountValidationNumber(valuesMI[0], valuesMI[1])]);
            break;
          default:
            break;
        }
      }
    } else {
      this.esServicio = false;
    }
    if (!this.internet.internet) {
      this.alert.presentarAlerta(this.idiomas.ConsultaOffLinePagoServicio);
    }
  }

  onSelectedReference(cservicio: string) {
    this.rubros = [];
    this.descriptionFormControl.reset();
    this.tokenFormControl.reset();
    this.suministroForm.reset();
    this.suministroForm.setValue('');
    this.bandAdditionalDteail = false;
    this.paymentValueZero = false;
    this.queryExecuted = false;
    this.selectedReference = this.references.find((suministro) => (suministro.CSERVICIO === cservicio));
    if (this.selectedReference.COMENTARIOS && this.selectedReference.COMENTARIOS != "null") {
      this.selectedReference.COMENTARIOS.split("|").map(x => {
        if (x.slice(0, 2).toUpperCase() == this.idiomas.IdiomaSeleccionado.toUpperCase()) {
          this.comentarioSuministro = x.slice(3, x.lenght);
        }
        if (x.slice(0, 3).toUpperCase() == "EJM") {
          this.comentarioSuministro = this.comentarioSuministro + " - " + x.slice(4, x.lenght);
        }
        return true;
      })
    }
    else {
      this.comentarioSuministro = null;
    }
    if (!this.internet.internet) {
      this.alert.presentarAlerta(this.idiomas.ConsultaOffLinePagoServicio);
    }
  }

  onSelectedRubro(rubro: any) {
    this.paymentsDetail = [];
    this.paymentDetailedArray = [];
    for (let paymentDetail of this.paymentDetailAux) {
      if (paymentDetail.RUBRO == rubro.value) {
        this.paymentsDetail = [paymentDetail];
      }
    }
    this.paymentsDetail.filter((reg, index) => { //Se llena arreglo de tabla pago servicio
      Object.keys(reg).filter(regKey => {
        this.paymentDetailedArray.push({ label: regKey, value: reg[regKey] });
      })
    })
    this.amount = this.validators.FormatNumber(this.paymentsDetail[0]['VALOR PAGADO']);
  }

  makePayment() {
    if (!this.esServicio) {
      this.intento = true;
      this.disableButton = true;
      this.numeroMensaje = "";
      this.fechaTransaccion = "";
      if (this.bandServReg) {
        paymentWSObject['numeroServicio'] = this.selectedReference.NUMEROCONTRATO;
        paymentWSObject['codigoServicio'] = "";
        paymentWSObject['comentarioServicio'] = this.selectedReference.COMENTARIOS;
      } else {
        paymentWSObject['numeroServicio'] = this.suministroForm.value;
        paymentWSObject['codigoServicio'] = this.selectedReference.CSERVICIO;
        paymentWSObject['comentarioServicio'] = this.selectedReference.DESCRIPCION;
      }
      paymentWSObject['recarga'] = '0';
      paymentWSObject['cuenta'] = this.selectedAccount.CCUENTA;
      paymentWSObject['moneda'] = this.selectedAccount.CMONEDA;
      paymentWSObject['monto'] = this.validators.FormatNumber(this.amount).replace(/,/g, '');
      paymentWSObject['descripcion'] = this.descriptionFormControl.value.toUpperCase();
      paymentWSObject['clave'] =
        (this.internet.internet && this.tokenFormControl.value)
          ? this.service.toAES(this.tokenFormControl.value)
          : '';
      paymentWSObject['desencriptar'] = '1';
      paymentWSObject['comision'] = this.validators.FormatNumber(this.fee).replace(/,/g, '');
      paymentWSObject['idBeneficiario'] = this.idBeneficiary;
      paymentWSObject['cuentaCredito'] = this.accountforCredit;
      paymentWSObject['payment'] = this.paymentsDetail;
      paymentWSObject['additional'] = this.additionalDetail;
      //Se agregan parametros IDENTIFICACION CLI y NOMBRES CLI para tareas pendientes
      paymentWSObject["IDENTIFICACION_CLI"] = this.clientIdServicePayment;
      paymentWSObject["NOMBRES_CLI"] = this.clientNameServicePayment;
      this.alert.generarDialogoConfirmacionTransferencias(this.idiomas.pagServ, 'pagServ', this.idiomas, paymentWSObject).then((data) => {
        if (data) {
          this.api.postProvider2InternetCheck('/pagoservicios', this.dbHandler.id_token, paymentWSObject).then((dataTr: any) => {
            this.disableFormControls();
            this.intento = false;
            this.paymentExecuted = true;
            this.repInvoiceData = dataTr.invoiceData;
            this.repInvoiceAdditionalData = dataTr.invoiceAdditionalData;
            this.fechaTransaccion = dataTr.fechaTransaccion;
            this.numeroMensaje = dataTr.numeroMensaje;
            this.alert.generarDialogoSegurotransacciones(this.idiomas, this.numeroMensaje, false, 5);
            this.mostrarCron = false;
            this.enableCronoIn = false;
          }, (err) => {
            this.intento = false;
            this.mostrarCron = false;
            this.enableCronoIn = false;
            this.dowloadReporte = false;
            this.disableButton = false;
            if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
              this.paymentExecuted = false;
              if (err.error) {
                if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
                else {
                  this.alert.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario)
                }
              } else {
                this.alert.generarDialogoSeguroErrortransacciones(this.idiomas, this.idiomas.ServidorError)
              }
            } else { // Sin Internet
              this.disableFormControls();
              this.paymentExecuted = false;
              let tareaPendienteJson = JSON.parse(JSON.stringify(paymentWSObject));
              this.dbHandler.insertarTarea(tareaPendienteJson);
              this.alert.presentarAlerta(this.idiomas.NoConectado);
            }
          });
        } else {
          this.intento = false;
          this.paymentExecuted = false;
          this.disableButton = false;
        }
      });
    } else {
      this.makeRecharge();
    }
  }

  makeRecharge() {
    const tmpFee = '0.00';
    this.fechaTransaccion = "";
    this.intento = true;
    this.disableButton = true;
    rechargeWSObject['numeroServicio'] = this.suministroForm.value;
    rechargeWSObject['codigoServicio'] = this.selectedReference['CSERVICIO'];
    rechargeWSObject['comentarioServicio'] = this.selectedReference['DESCRIPCION'];
    rechargeWSObject['usuario'] = "$userCode";
    rechargeWSObject['recarga'] = '1';
    rechargeWSObject['cuenta'] = this.selectedAccount.CCUENTA;
    rechargeWSObject['moneda'] = this.selectedAccount.CMONEDA;
    rechargeWSObject['monto'] = this.rechargeFormControl.value.toString().replace(/,/g, '');
    rechargeWSObject['descripcion'] = this.descriptionFormControl.value.toUpperCase();
    rechargeWSObject['clave'] =
      (this.internet.internet && this.tokenFormControl.value)
        ? this.service.toAES(this.tokenFormControl.value)
        : '';
    rechargeWSObject['desencriptar'] = '1';
    rechargeWSObject['comision'] = tmpFee;
    //Se agregan parametros IDENTIFICACION CLI y NOMBRES CLI para tareas pendientes
    rechargeWSObject["IDENTIFICACION_CLI"] = this.clientIdServicePayment;
    rechargeWSObject["NOMBRES_CLI"] = this.clientNameServicePayment;
    this.alert.generarDialogoConfirmacionTransferencias(this.idiomas.Recargas, 'Recargas', this.idiomas, rechargeWSObject).then((data) => {
      if (data) {
        this.api.postProvider2InternetCheck('/pagoservicios', this.dbHandler.id_token, rechargeWSObject).then((dataTr: any) => {
          this.disableFormControls();
          this.intento = false;
          this.rechargeExecuted = true;
          this.repInvoiceData = dataTr.invoiceData;
          this.repInvoiceAdditionalData = dataTr.invoiceAdditionalData;
          this.fechaTransaccion = dataTr.fechaTransaccion;
          this.numeroMensaje = dataTr.numeroMensaje;
          this.alert.presentarAlerta(dataTr.mensajeUsuario);
          this.mostrarCron = false;
          this.enableCronoIn = false;
        }, (err) => {
          this.intento = false;
          this.disableButton = false;
          this.dowloadReporte = false;
          this.mostrarCron = false;
          this.enableCronoIn = false;
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            this.rechargeExecuted = false;
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
              else {
                this.alert.presentarAlertaX(err.error.mensajeUsuario);
              }
            } else {
              this.alert.presentarAlerta(this.idiomas.ServidorError);
            }
          } else { // Sin Internet
            this.disableFormControls();
            this.rechargeExecuted = false;
            let tareaPendienteJson = JSON.parse(JSON.stringify(rechargeWSObject));
            this.dbHandler.insertarTarea(tareaPendienteJson);
            this.alert.presentarAlerta(this.idiomas.NoConectado);
          }
        });
      } else {
        this.intento = false;
        this.rechargeExecuted = false;
        this.disableButton = false;
      }
    });
  }

  disableFormControls() {
    this.bandDisableForm = true;
    this.referenceFormControl.disable();
    this.rubroFormControl.disable();
    this.suministroForm.disable();
    this.accountFormControl.disable();
    this.descriptionFormControl.disable();
    this.tokenFormControl.disable();
    this.rechargeFormControl.disable();
  }

  onSelectedAccount(account: string) {
    this.selectedAccount = this.accounts.find((accountFromList) => (accountFromList.CCUENTA === account));
  }

  registrarServicio() {
    let regServJson = { cservicio: this.selectedReference.CSERVICIO, suministro: this.suministroForm.value, dservicio: this.selectedReference.DESCRIPCION, descripcion: '', tarPendType: 'regServ' };
    this.regServEv.emit(regServJson);
    setTimeout(function () { document.getElementById("soliToken").blur(); }, 700);
  }

  makeQuery() {
    this.intento = true;
    this.paymentValueZero = false;
    if (this.bandServReg) {
      queryWSObject['numeroServicio'] = this.selectedReference.NUMEROCONTRATO;
      queryWSObject['cServicio'] = this.selectedReference.CSERVICIO;
    } else {
      queryWSObject['numeroServicio'] = this.suministroForm.value;
      queryWSObject['cServicio'] = this.selectedReference.CSERVICIO;
    }
    this.api.postProvider2InternetCheck("/valorpagoservicios", this.dbHandler.id_token, queryWSObject).then((data: any) => {
      if (data) {
        this.amount = this.validators.FormatNumber(data.MONTO);
        this.fee = this.validators.FormatNumber(data.COMISION);
        if (this.amount === this.validators.FormatNumber("0")) {
          this.paymentValueZero = true;
          this.alert.presentarAlertaX(this.idiomas.paymentValueZero);
        }
        this.queryExecuted = true;
        this.accountforCredit = data.CUENTACREDITO;
        this.idBeneficiary = data.IDENTIFICACION_BENEFICIARIO;
        this.servicioRegistrado = data.SERVICIOREGISTRADO;
        this.intento = false;
        this.rubros = [];
        this.paymentDetailAux = [];
        this.paymentDetailedArray = [];
        this.additionalDetailArray = [];
        this.paymentsDetail = [];
        this.additionalDetail = [];
        this.paymentDetailAux = data.PAYMENTS;
        this.paymentsDetail = [this.paymentDetailAux[0]];
        this.paymentsDetail.filter((reg, index) => { //Se llena arreglo de tabla pago servicio
          Object.keys(reg).filter(regKey => {
            this.paymentDetailedArray.push({ label: regKey, value: reg[regKey] })
            //Se obtienen valores de parametros IDENTIFICACION CLI y NOMBRES CLI de rubro 0
            if (regKey == "IDENTIFICACION CLI") {
              this.clientIdServicePayment = reg[regKey];
            }
            if (regKey == "NOMBRES CLI") {
              this.clientNameServicePayment = reg[regKey];
            }
            //.
          })
        })
        this.paymentDetailAux.filter((reg, index) => { //Se llena arreglo de tabla pago servicio
          Object.keys(reg).filter(regKey => {
            if (regKey == "RUBRO") {
              this.rubros.push({ label: regKey, value: reg[regKey] });
            }
          })
        })
        if (data.ADDITIONAL_DATA) {
          this.additionalDetail = data.ADDITIONAL_DATA;
          this.additionalDetail.filter((reg, index) => { //Se llena arreglo de tabla pago servicio
            this.additionalDetailArray.push({ payment: true, label: index + 1, value: "" });
            Object.keys(reg).filter(regKey => {
              this.additionalDetailArray.push({ label: regKey, value: reg[regKey] });
            })
          })
          this.bandAdditionalDteail = true;
        }
        this.amount = this.validators.FormatNumber(this.paymentsDetail[0]['VALOR PAGADO']);
        this.rubroFormControl.setValue(this.rubros[0]);
      } else {
        this.paymentDetailedArray = [];
        this.additionalDetailArray = [];
        this.paymentsDetail = [];
        this.additionalDetail = [];
        this.queryExecuted = false;
        this.bandAdditionalDteail = false;
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false;
        this.queryExecuted = false;
        this.bandAdditionalDteail = false;
        this.alert.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
        this.intento = false;
        this.queryExecuted = false;
        this.bandAdditionalDteail = false;
      }
    });
  }

  solicitarToken() {
    const envio = { transaccion: '186000' };
    this.intento = true;
    this.api.postProvider2InternetCheck('/clavetemporal', this.dbHandler.id_token, envio).then((data: any) => {
      this.tokenRequested = true;
      this.intento = false;
      this.tokenFormControl.reset();
      this.alert.presentarAlerta(this.idiomas.EnvioToken.toUpperCase())
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  createPDFReport() {
    var fecha = new Date();
    var fd = fecha.getFullYear() + "-" + this.anadirCero(fecha.getMonth() + 1) + "-" + this.anadirCero(fecha.getDate()) + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
    this.intento = true;
    reportWSObject['rep^NUMEROMENSAJE'] = this.numeroMensaje;
    this.api.postProvider2InternetCheck("/reporte", this.dbHandler.id_token, reportWSObject).then((data: any) => {
      if (data) {
        this.intento = false;
        this.pdfBase64 = data.reporte;
        this.mostrarPdf.mostrarReportePDF(this.pdfBase64);
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
  }

  createPDFReportRecargas() {
    this.intento = true;
    reportWSObject["rep^NUMEROMENSAJE"] = this.numeroMensaje;
    this.api.postProvider2InternetCheck("/reporte", this.dbHandler.id_token, reportWSObject).then((data: any) => {
      if (data) {
        this.intento = false;
        this.pdfBase64 = data.reporte;
        this.mostrarPdf.mostrarReportePDF(this.pdfBase64);
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }

  reset() {
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.paymentValueZero = false;
    this.bandDisableForm = false;
    this.disableButton = false;
    this.selectedReference = null;
    this.amount = '';
    this.fee = '';
    this.queryExecuted = false;
    this.tokenRequested = false;
    this.intento = false;
    this.paymentExecuted = false;
    this.bandAdditionalDteail = false;
    this.pdfBase64 = '';
    this.accountforCredit = '';
    this.idBeneficiary = '';
    this.comentarioSuministro = null;
    this.rubroFormControl.enable();
    this.referenceFormControl.enable();
    this.suministroForm.enable();
    this.accountFormControl.enable();
    this.descriptionFormControl.enable();
    this.tokenFormControl.enable();
    this.rechargeFormControl.enable();
    this.referenceFormControl.reset();
    this.suministroForm.reset();
    this.rubroFormControl.reset();
    this.referenceFormControl.setErrors(null);
    this.suministroForm.setErrors(null);
    this.descriptionFormControl.reset();
    this.rechargeFormControl.reset();
    this.tokenFormControl.reset();
    document.querySelector('.mat-sidenav-content').scrollTop = 0;;
  }

  clearScreenWithDialog() {
    this.alert.generarDialogoSeguro(this.idiomas).then((data) => {
      if (data) {
        this.reset();
      }
    });
  }

  applyFilter(filterValue: any) {
    this.references = (this.bandServReg) ?
      this.lfiltradoservicios.filter((servicio) => (servicio.NUMEROCONTRATO + servicio.DESCRIPCION + servicio.COMENTARIOS).indexOf(filterValue) > -1) :
      this.lfiltradoservicios.filter((servicio) => (servicio.CSERVICIO + servicio.DESCRIPCION).indexOf(filterValue) > -1);
  }

  retornarServicios() { //Servicios Disponibles para Registrar
    this.intento = true;
    let envio = {
      transaccion: "010003-lv-servicios-por-tipo-in.xml",
      alias: "tpsc0",
      "cri^tpsc0^NOMBRESCONTRATO^NORMAL": this.nomServ
    }
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, envio).then((data: any) => {
      if (data) {
        data = data.filter(x => x.CSERVICIO != undefined);
        this.references = data;
        this.lfiltradoservicios = this.references;
        this.intento = false;
        if (this.resendPay) {
          Promise.resolve(null).then(() => this.setValuesOfForm());
        }
        this.dbHandler.insertarConsulta('Servicios' + this.nomServ + 'Disponibles', this.references); //Inserta Lista de Servicios Disponibles
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError)
        }
      }
      else { //Sin Internet    
        this.references = this.dbHandler.retornarConsulta('Servicios' + this.nomServ + 'Disponibles');
        if (!this.references) {
          this.references = [];
        }
      }
      this.lfiltradoservicios = this.references;
    });
  }

  setValuesOfForm() {
    this.selectedReference = this.references.find((suministro) => (suministro.CSERVICIO === this.resendPay.numeroServicio));
    this.referenceFormControl.setValue(this.resendPay.numeroServicio);
    this.suministroForm.setValue(this.resendPay.suministro);
    if (this.selectedReference.COMENTARIOS && this.selectedReference.COMENTARIOS != "null") {
      this.selectedReference.COMENTARIOS.split("|").map(x => {
        if (x.slice(0, 2).toUpperCase() == this.idiomas.IdiomaSeleccionado.toUpperCase()) {
          this.comentarioSuministro = x.slice(3, x.lenght);
        }
        if (x.slice(0, 3).toUpperCase() == "EJM") {
          this.comentarioSuministro = this.comentarioSuministro + " - " + x.slice(4, x.lenght);
        }
        return true;
      })
    }
    else {
      this.comentarioSuministro = null;
    }
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace(/,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0];
          let decimalPart = amountArrayDotSplit[1];
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  caretInputAmount(event) {
    event.target.value = event.target.value.replace(/,/g, '');
    let amountArrayDotSplit = event.target.value.split(".");
    if (amountArrayDotSplit.length > 1) {
      event.target.selectionStart = amountArrayDotSplit[0].length;
      event.target.selectionEnd = amountArrayDotSplit[0].length;
    }
    else {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
    }
  }

  showHideRows(last) {
    if (last) {
      try {
        let classContainer1Width = document.getElementsByClassName('container2AuxServicios')[0].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('paymentDetailed')[0].setAttribute('style', 'width: 460px');
          if (460 - classContainer1Width > 0) {
            document.getElementById('flechaR0').setAttribute('style', 'visibility: visible;');
            if (!this.flechaL0) {
              document.getElementById('flechaL0').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR0').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL0').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('paymentDetailed')[0].setAttribute('style', 'width: 100%');
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('paymentDetailed')[0].setAttribute('style', 'width: 385px');
          if (385 - classContainer1Width > 0) {
            document.getElementById('flechaR0').setAttribute('style', 'visibility: visible;');
            if (!this.flechaL0) {
              document.getElementById('flechaL0').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR0').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL0').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('paymentDetailed')[0].setAttribute('style', 'width: 100%');
          }
        }
        if (this.normal) {
          document.getElementsByClassName('paymentDetailed')[0].setAttribute('style', 'width: 298px; font-size: 14px');
          if (298 - classContainer1Width > 0) {
            document.getElementById('flechaR0').setAttribute('style', 'visibility: visible;');
            if (!this.flechaL0) {
              document.getElementById('flechaL0').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR0').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL0').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('paymentDetailed')[0].setAttribute('style', 'width: 100%; font-size: 14px');
          }
        }
      } catch (e) {
      }
    }
  }

  showHideRows1(last) {
    if (last) {
      try {
        let classContainer1Width = document.getElementsByClassName('container2AuxServicios')[1].clientWidth;
        if (this.grande) {
          document.getElementsByClassName('additionalDetail')[0].setAttribute('style', 'width: 562px');
          if (562 - classContainer1Width > 0) {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;');
            if (!this.flechaL1) {
              document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('additionalDetail')[0].setAttribute('style', 'width: 100%');
          }
        }
        if (this.mediano) {
          document.getElementsByClassName('additionalDetail')[0].setAttribute('style', 'width: 487px');
          if (487 - classContainer1Width > 0) {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;');
            if (!this.flechaL1) {
              document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('additionalDetail')[0].setAttribute('style', 'width: 100%');
          }
        }
        if (this.normal) {
          document.getElementsByClassName('additionalDetail')[0].setAttribute('style', 'width: 400px; font-size: 14px');
          if (400 - classContainer1Width > 0) {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: visible;');
            if (!this.flechaL1) {
              document.getElementById('flechaL1').setAttribute('style', 'visibility: visible;');
            }
          } else {
            document.getElementById('flechaR1').setAttribute('style', 'visibility: hidden;');
            document.getElementById('flechaL1').setAttribute('style', 'visibility: hidden;');
            document.getElementsByClassName('additionalDetail')[0].setAttribute('style', 'width: 100%; font-size: 14px');
          }
        }
      } catch (e) {
      }
    }
  }

  scroll(id) {
    if (id == 'paymentDetailed') {
      var container1 = document.getElementsByClassName('container2AuxServicios');
    }
    var tabla = document.getElementsByClassName(id);
    if (container1.item(0).scrollLeft != 0) {
      if (container1[0].scrollLeft + 2 >= (tabla[0].clientWidth - container1[0].clientWidth)) {
        this.flechaR0 = true;
        this.flechaL0 = false;
      } else {
        this.flechaR0 = false;
        this.flechaL0 = true;
      }
    } else {
      this.flechaR0 = false;
      this.flechaL0 = true;
    }
  }

  scroll1(id) {
    if (id == 'additionalDetail') {
      var container1 = document.getElementsByClassName('container2AuxServicios');
    }
    var tabla = document.getElementsByClassName(id);
    if (container1.item(1).scrollLeft != 0) {
      if (container1[1].scrollLeft + 2 >= (tabla[0].clientWidth - container1[1].clientWidth)) {
        this.flechaR1 = true;
        this.flechaL1 = false;
      } else {
        this.flechaR1 = false;
        this.flechaL1 = true;
      }
    } else {
      this.flechaR1 = false;
      this.flechaL1 = true;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase);
    if (clase == 'paymentDetailed') {
      let container1 = document.getElementsByClassName('container2AuxServicios');
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth;
      } else {
        container1.item(0).scrollLeft = 0;
        this.flechaL0 = true;
        this.flechaR0 = false;
      }
    }
  }

  scrollTo1(clase, direccion) {
    let tabla = document.getElementsByClassName(clase);
    if (clase == 'additionalDetail') {
      let container1 = document.getElementsByClassName('container2AuxServicios');
      if (direccion == 'right') {
        container1.item(1).scrollLeft = tabla[0].clientWidth - container1[1].clientWidth;
      } else {
        container1.item(1).scrollLeft = 0;
        this.flechaL1 = true;
        this.flechaR1 = false;
      }
    }
  }

  logout() {
    this.alert.clearAlerta();
    this.alert.generarOfflineDialogo({ 'salir': true }, this.idiomas);
  }
}

//Validacion de montos minimos de recarga
interface minAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null;
}

function minAmountValidationNumber(minValue): minAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value;
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto >= minValue) {
      return null;
    } else {
      return {
        minAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos maximos de recarga
interface maxAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null;
}

function maxAmountValidationNumber(maxValue): maxAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value;
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto <= maxValue) {
      return null;
    } else {
      return {
        maxAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos de recarga por intervalos
interface nMultipleAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null;
}
function nMultipleAmountValidationNumber(minValue, stepValue): nMultipleAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    if (stepValue == 1) {
      return null;
    }
    var monto = control.value;
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    let montoOperation = monto;
    let errValidation = true;
    while (montoOperation >= minValue) {
      if (montoOperation == minValue) {
        errValidation = false;
        break;
      }
      montoOperation = montoOperation - stepValue;
    }
    if (!errValidation) {
      return null;
    } else {
      return {
        nMultipleAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de numero enteros como montos de recarga
function noDecimalAmountValidation(control: UntypedFormControl) {
  var monto = control.value;
  monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
  if (Number.isInteger(monto)) {
    return null;
  }
  else {
    return {
      noDecimalAmount: {
        parsedDomain: monto
      }
    }
  }
}


