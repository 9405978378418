<div fxLayout="column" style="padding-top: 0px !important; padding-bottom: 5px" fxLayoutAlign="start stretch">
  <mat-card class="cards-transations" *ngIf="!bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.pagServ | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding-left: 16px; padding-right: 16px; padding-top: 16px;" class="form-row">
      <div *ngIf="bandServReg" class="col-md-12">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Servicio}}</mat-label>
          <mat-select [formControl]="referenceFormControl" (selectionChange)="onSelectedReferenceRegServ($event.value)">
            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value.toUpperCase())"
              (keydown)="$event.stopPropagation()" placeholder=" {{idiomas.FiltrarResultados | uppercase }}"
              style="height: 35px;border-bottom-style: groove;">
            <mat-option *ngFor="let reference of references" [value]="reference">
              {{reference.NUMEROCONTRATO}} - {{reference.DESCRIPCION}} - {{reference.COMENTARIOS | uppercase}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="referenceFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="referenceFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="!bandServReg" class="col-md-12">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Referencia}}</mat-label>
          <mat-select [formControl]="referenceFormControl" (selectionChange)="onSelectedReference($event.value)">
            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value.toUpperCase())"
              (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}"
              style="height: 35px;border-bottom-style: groove;">
            <mat-option *ngFor="let reference of references" [value]="reference.CSERVICIO">
              {{reference.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="referenceFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="referenceFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="selectedReference && bandServReg">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.CodigoServicio}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" value={{selectedReference.DESCRIPCION}}>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="selectedReference && bandServReg">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.DescripcionServicio}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" value={{selectedReference.COMENTARIOS}}>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="!bandServReg">
        <div *ngIf="comentarioSuministro"
          style="width: 100%; margin: auto; max-width: 700px !important; padding-bottom: 5px; margin-top: -12px;">
          <app-alertas-login [alertalogin]="true" [mensaje]="comentarioSuministro">
          </app-alertas-login>
        </div>
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Suministro}}</mat-label>
          <input matInput type="text" autocomplete="off" (keyup.enter)="makeQuery()"
            oninput="this.value = this.value.toUpperCase();" [formControl]="suministroForm">
          <mat-error *ngIf="suministroForm.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="!esServicio; else esRecarga">
        <div *ngIf="referenceFormControl.valid && selectedReference && bandServReg" class="col-md-2"
          style="padding-left: 5px !important;">
          <button mat-raised-button [disabled]="(!internet.internet && bandDisableForm) || paymentValueZero"
            (click)="makeQuery()" color="primary" class="buttonSubmit">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
        </div>
        <div
          *ngIf="referenceFormControl.valid && !bandServReg && suministroForm.valid && selectedReference && suministroForm.value"
          class="col-md-2" style="padding-left: 5px !important;">
          <button mat-raised-button [disabled]="(!internet.internet && bandDisableForm) || paymentValueZero"
            (click)="makeQuery()" color="primary" class="buttonSubmit">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
        </div>
      </div>
      <ng-template #esRecarga>
        <div class="col-md-12" *ngIf="selectedReference">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>{{idiomas.NumCelular}}</mat-label>
            <input matInput readonly type="text" autocomplete="off" [formControl]="suministroForm">
            <mat-error *ngIf="suministroForm.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="selectedReference && (amountValuesArray == null)">
          <mat-form-field appearance="outline" floatLabel="always" fxFlexFill>
            <mat-label>{{idiomas.MontoRecarga}}</mat-label>
            <input matInput (focus)="caretInputAmount($event)"
              (blur)="rechargeFormControl.setValue(validators.FormatNumberZeroValidation($event.target.value))"
              (keypress)="amountKeyInputControl($event)" type="text" autocomplete="off"
              [formControl]="rechargeFormControl">
            <span matPrefix>$&nbsp;</span>
            <mat-error *ngIf="rechargeFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('pattern')
                && !rechargeFormControl.hasError('required')">
              <strong>{{idiomas.msjSoloNumeros}} </strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('minAmount')
                  && !rechargeFormControl.hasError('required')
                  && !rechargeFormControl.hasError('pattern')">
              <strong>{{idiomas.montoMinimoRecargas}}{{minValueAmount}}</strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('maxAmount')
                && !rechargeFormControl.hasError('required')
                && !rechargeFormControl.hasError('pattern')
                && !rechargeFormControl.hasError('minAmount')">
              <strong>{{idiomas.montoMaximoRecargas}}{{maxValueAmount}}</strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('noDecimalAmount')
                && !rechargeFormControl.hasError('required')
                && !rechargeFormControl.hasError('pattern')
                && !rechargeFormControl.hasError('minAmount')
                && !rechargeFormControl.hasError('maxAmount')">
              <strong>{{idiomas.montoEnteroRecargas}}</strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('nMultipleAmount')
                && !rechargeFormControl.hasError('required')
                && !rechargeFormControl.hasError('pattern')
                && !rechargeFormControl.hasError('minAmount')
                && !rechargeFormControl.hasError('maxAmount')
                && !rechargeFormControl.hasError('noDecimalAmount')">
              <strong>{{idiomas.montoMinimoRecargas}}{{minValueAmount}}
                {{idiomas.montoIntervaloRecargas}}{{stepValueAmount}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="selectedReference && (amountValuesArray != null)">
          <mat-form-field appearance="outline" fxFlexFill>
            <mat-label>{{idiomas.MontoRecarga}}</mat-label>
            <mat-select [formControl]="rechargeFormControl">
              <mat-option *ngFor="let amountValue of amountValuesArray" [value]="amountValue.value">
                {{amountValue.viewValue}}
                <mat-divider style="border-top-width: 1px;"></mat-divider>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="accountFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="selectedReference">
          <mat-form-field appearance="outline" fxFlexFill>
            <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
            <mat-select [formControl]="accountFormControl" (selectionChange)="onSelectedAccount($event.value)"
              (openedChange)="funcionRetornarCuentasOwn($event)">
              <mat-option *ngIf="!intentoCuentasOwn" disabled>
                <div style="margin: 10px;">
                  <mat-progress-bar mode="indeterminate">
                  </mat-progress-bar>
                </div>
              </mat-option>
              <mat-option *ngFor="let account of accounts" [value]="account.CCUENTA">
                {{account.CCUENTA | ocultarCuentaPosTrans}} - {{account.DESCRIPCION}}
                <mat-divider style="border-top-width: 2px;"></mat-divider>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="accountFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="selectedReference">
          <mat-form-field appearance="outline" fxFlexFill>
            <mat-label>{{idiomas.Descripcion}}</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="descriptionFormControl"
              oninput="this.value = this.value.toUpperCase();">
            <mat-error *ngIf="descriptionFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="descriptionFormControl.hasError('pattern')">
              <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row" *ngIf="selectedReference" (keyup.enter)="makeRecharge()">
          <mat-form-field class="col-2 token" appearance="outline" *ngIf="internet.internet && tokenRequested">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input id="soliToken" matInput type="password" autocomplete="off" [formControl]="tokenFormControl"
              maxlength="6" name="token" appBlockCopyPaste>
            <mat-error *ngIf="tokenFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('pattern')">
              <strong>{{idiomas.msjSoloNumeros}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('minlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2 token" appearance="outline" *ngIf="internet.internet && !tokenRequested">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
          </mat-form-field>
          <div class="left col-2 token-button" *ngIf="internet.internet">
            <button
              [disabled]="(!suministroForm.valid || !rechargeFormControl.valid || !selectedAccount || !descriptionFormControl.valid || enableCronoIn)"
              mat-raised-button (click)="solicitarToken()" color="primary" style="float: left;" class="buttonToken">
              <mat-icon>security</mat-icon>
              {{idiomas.SolicitarToken}}
            </button>
          </div>
          <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
            <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
              [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
          </div>
          <div class="col-1" *ngIf="internet.internet && rechargeExecuted"
            style="cursor:pointer;padding-top: 1em;max-width: 30px !important">
            <a (click)="createPDFReportRecargas()" style="margin-top: 10px; padding-left: 30px"><mat-icon
                color="primary" style="font-size: 30px; height: 30px; width: 30px;"> picture_as_pdf</mat-icon></a>
          </div>
        </div>
      </ng-template>
    </div>
    <div style="padding-left: 16px; padding-right: 16px; padding-top: 16px;" class="form-row">
      <div class="col-6" *ngIf="queryExecuted">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.Monto}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" value={{amount}}>
        </mat-form-field>
      </div>
      <div class="col-6" *ngIf="queryExecuted && !paymentValueZero">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.Comision}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" value={{fee}}>
        </mat-form-field>
      </div>
      <div *ngIf="queryExecuted && !paymentValueZero" class="col-12">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{Rubro}}</mat-label>
          <mat-select [formControl]="rubroFormControl" (selectionChange)="onSelectedRubro($event.value)">
            <mat-option *ngFor="let rubro of rubros" [value]="rubro">
              {{rubro.label}} - {{rubro.value}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="rubroFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Tabla de Detalle -->
      <div *ngIf="queryExecuted && !paymentValueZero && !bandMobile" class="col-12">
        <div>
          <div class="center col texto" style="margin-top:-3px">
            <h6>
              <strong>{{idiomas.DetalleP | uppercase}}</strong>
            </h6>
          </div>
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaL0 id="flechaL0" (click)="scrollTo('paymentDetailed','left')">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container2AuxServicios" [class.heightconteiner2]="!(paymentDetailedArray.length>numMaxRow)"
              (scroll)="scroll('paymentDetailed')">
              <table mat-table [dataSource]="paymentDetailedArray" class="paymentDetailed">
                <ng-container matColumnDef="Label">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Detalles}}</th>
                  <td mat-cell *matCellDef="let dato" [class.tittlePayment]="dato.payment"><a
                      *ngIf="dato.payment">{{idiomas.Pago}} {{dato.label}}</a><a
                      *ngIf="!dato.payment">{{dato.label}}</a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Value">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let dato" style="padding-left: 10px; max-width: 200px;">{{dato.value}}
                  </td>
                </ng-container>
                <tr mat-header-row class="mayt-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; let even = even; let last = last; columns: displayedColumns;"
                  [ngClass]="{gray: even}">{{showHideRows(last)}}</tr>
              </table>
            </div>
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaR0 id="flechaR0" (click)="scrollTo('paymentDetailed','right')">
                keyboard_arrow_right
              </mat-icon>
            </div>
          </div>
        </div>
        <br>
        <div *ngIf="bandAdditionalDteail">
          <div class="center col texto" style="margin-top:-3px">
            <h6>
              <strong>{{idiomas.DetalleAdicional | uppercase}}</strong>
            </h6>
          </div>
          <div class="row" style="margin-left: -24px !important;">
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaL1 id="flechaL1" (click)="scrollTo1('additionalDetail','left')">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container2AuxServicios" [class.heightconteiner2]="!(additionalDetailArray.length>numMaxRow)"
              (scroll)="scroll1('additionalDetail')">
              <table mat-table [dataSource]="additionalDetailArray" class="additionalDetail">
                <ng-container matColumnDef="Label">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Detalles}}</th>
                  <td mat-cell *matCellDef="let dato" [class.tittlePayment]="dato.payment"><a
                      *ngIf="dato.payment">{{idiomas.Pago}} {{dato.label}}</a><a
                      *ngIf="!dato.payment">{{dato.label}}</a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Value">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let dato" style="padding-left: 10px; max-width: 200px;">{{dato.value}}
                  </td>
                </ng-container>
                <tr mat-header-row class="mayt-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; let even = even; let last = last; columns: displayedColumns;"
                  [ngClass]="{gray: even}">{{showHideRows1(last)}}</tr>
              </table>
            </div>
            <div class="col flecha">
              <mat-icon [class.desaparecer]=flechaR1 id="flechaR1" (click)="scrollTo1('additionalDetail','right')">
                keyboard_arrow_right
              </mat-icon>
            </div>
          </div>
        </div>
        <br>
      </div>

      <div class="col-md-12" *ngIf="queryExecuted && !paymentValueZero">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
          <mat-select [formControl]="accountFormControl" (selectionChange)="onSelectedAccount($event.value)"
            (openedChange)="funcionRetornarCuentasOwn($event)">
            <mat-option *ngIf="!intentoCuentasOwn" disabled>
              <div style="margin: 10px;">
                <mat-progress-bar mode="indeterminate">
                </mat-progress-bar>
              </div>
            </mat-option>
            <mat-option *ngFor="let account of accounts" [value]="account.CCUENTA">
              {{account.CCUENTA | ocultarCuentaPosTrans}} - {{account.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="accountFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="queryExecuted && !paymentValueZero">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Descripcion}}</mat-label>
          <input matInput type="text" autocomplete="off" [formControl]="descriptionFormControl"
            oninput="this.value = this.value.toUpperCase();">
          <mat-error *ngIf="descriptionFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="descriptionFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="internet.internet" autocomplete="off" class="form-row" (keyup.enter)="makePayment()"
        style="padding-left: 5px !important;">
        <mat-form-field class="col-2 token" appearance="outline"
          *ngIf="queryExecuted && !paymentValueZero && tokenRequested">
          <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
          <input id="soliToken" matInput type="password" autocomplete="off" [formControl]="tokenFormControl"
            maxlength="6" inputmode="numeric" name="token" appBlockCopyPaste>
          <mat-error *ngIf="tokenFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('minlength')">
            <strong>{{idiomas.msjOtpminmax}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
            <strong>{{idiomas.msjOtpminmax}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2 token" appearance="outline"
          *ngIf="queryExecuted && !paymentValueZero && !tokenRequested">
          <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
          <input id="soliToken" matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
        </mat-form-field>
        <div class="left col-2 token-button" *ngIf="queryExecuted && !paymentValueZero && internet.internet">
          <button
            [disabled]="(!queryExecuted || !selectedAccount || !selectedReference || !descriptionFormControl.valid) || (enableCronoIn)"
            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
            <mat-icon>security</mat-icon>
            {{idiomas.SolicitarToken}}
          </button>
        </div>
        <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
          <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
        </div>
      </div>
    </div>
    <div *ngIf="intento" style="padding-left: 16px; padding-right: 16px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div id="floatCont" class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
      <div class="col-6" *ngIf="
      (!internet.internet && referenceFormControl.valid && accountFormControl.valid && descriptionFormControl.valid)
      ||
      (internet.internet && referenceFormControl.valid && accountFormControl.valid && descriptionFormControl.valid && tokenRequested && tokenFormControl.valid)
      ">
        <button mat-raised-button (click)="makePayment()" color="primary" title={{idiomas.Aceptar}} class="buttonSubmit"
          [disabled]="disableButton">
          <mat-icon>download_done</mat-icon>
          {{idiomas.Aceptar}}
        </button>
      </div>
      <div class="col" *ngIf="selectedAccount || selectedReference || descriptionFormControl.value" align="end"
        style="text-align: end;">
        <button mat-raised-button (click)="clearScreenWithDialog()" title={{idiomas.Limpiar}} color="warn"
          class="buttonClean">
          <mat-icon> clear_all</mat-icon>
          {{idiomas.Limpiar}}
        </button>
      </div>
    </div>
  </mat-card>
  <div *ngIf="bandMobile">
    <div style="padding-left: 16px; padding-right: 16px; padding-top: 16px;" class="form-row">
      <div *ngIf="bandServReg" class="col-md-12">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Servicio}}</mat-label>
          <mat-select [formControl]="referenceFormControl" (selectionChange)="onSelectedReferenceRegServ($event.value)">
            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value.toUpperCase())"
              (keydown)="$event.stopPropagation()" placeholder=" {{idiomas.FiltrarResultados | uppercase }}"
              style="height: 35px;border-bottom-style: groove;">
            <mat-option *ngFor="let reference of references" [value]="reference">
              {{reference.NUMEROCONTRATO}} - {{reference.DESCRIPCION}} - {{reference.COMENTARIOS | uppercase}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="referenceFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="referenceFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="!bandServReg" class="col-md-12">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Referencia}}</mat-label>
          <mat-select [formControl]="referenceFormControl" (selectionChange)="onSelectedReference($event.value)">
            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value.toUpperCase())"
              (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}"
              style="height: 35px;border-bottom-style: groove;">
            <mat-option *ngFor="let reference of references" [value]="reference.CSERVICIO">
              {{reference.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="referenceFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="referenceFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="selectedReference && bandServReg">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.CodigoServicio}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" value={{selectedReference.DESCRIPCION}}>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="selectedReference && bandServReg">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.DescripcionServicio}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" value={{selectedReference.COMENTARIOS}}>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="!bandServReg">
        <div *ngIf="comentarioSuministro"
          style="width: 100%; margin: auto; max-width: 700px !important; padding-bottom: 5px; margin-top: -12px;">
          <app-alertas-login [alertalogin]="true" [mensaje]="comentarioSuministro">
          </app-alertas-login>
        </div>
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Suministro}}</mat-label>
          <input matInput type="text" autocomplete="off" (keyup.enter)="makeQuery()"
            oninput="this.value = this.value.toUpperCase();" [formControl]="suministroForm">
          <mat-error *ngIf="suministroForm.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="!esServicio; else esRecarga">
        <div *ngIf="referenceFormControl.valid && selectedReference && bandServReg" class="col-md-2"
          style="padding-left: 5px !important;">
          <button mat-raised-button style="margin-top: 0 !important;"
            [disabled]="(!internet.internet && bandDisableForm) || paymentValueZero" (click)="makeQuery()"
            color="primary" class="buttonSubmit">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
        </div>
        <div
          *ngIf="referenceFormControl.valid && !bandServReg && suministroForm.valid && selectedReference && suministroForm.value"
          class="col-md-2" style="padding-left: 5px !important;">
          <button mat-raised-button style="margin-top: 0 !important;"
            [disabled]="(!internet.internet && bandDisableForm) || paymentValueZero" (click)="makeQuery()"
            color="primary" class="buttonSubmit">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
        </div>
      </div>
      <ng-template #esRecarga>
        <div class="col-md-12" *ngIf="selectedReference">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>{{idiomas.NumCelular}}</mat-label>
            <input matInput readonly type="text" autocomplete="off" [formControl]="suministroForm">
            <mat-error *ngIf="suministroForm.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="selectedReference && (amountValuesArray == null)">
          <mat-form-field appearance="outline" floatLabel="always" fxFlexFill>
            <mat-label>{{idiomas.MontoRecarga}}</mat-label>
            <input matInput (focus)="caretInputAmount($event)"
              (blur)="rechargeFormControl.setValue(validators.FormatNumberZeroValidation($event.target.value))"
              (keypress)="amountKeyInputControl($event)" type="text" autocomplete="off"
              [formControl]="rechargeFormControl">
            <span matPrefix>$&nbsp;</span>
            <mat-error *ngIf="rechargeFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('pattern')
                && !rechargeFormControl.hasError('required')">
              <strong>{{idiomas.msjSoloNumeros}} </strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('minAmount')
                  && !rechargeFormControl.hasError('required')
                  && !rechargeFormControl.hasError('pattern')">
              <strong>{{idiomas.montoMinimoRecargas}}{{minValueAmount}}</strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('maxAmount')
                && !rechargeFormControl.hasError('required')
                && !rechargeFormControl.hasError('pattern')
                && !rechargeFormControl.hasError('minAmount')">
              <strong>{{idiomas.montoMaximoRecargas}}{{maxValueAmount}}</strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('noDecimalAmount')
                && !rechargeFormControl.hasError('required')
                && !rechargeFormControl.hasError('pattern')
                && !rechargeFormControl.hasError('minAmount')
                && !rechargeFormControl.hasError('maxAmount')">
              <strong>{{idiomas.montoEnteroRecargas}}</strong>
            </mat-error>
            <mat-error *ngIf="rechargeFormControl.hasError('nMultipleAmount')
                && !rechargeFormControl.hasError('required')
                && !rechargeFormControl.hasError('pattern')
                && !rechargeFormControl.hasError('minAmount')
                && !rechargeFormControl.hasError('maxAmount')
                && !rechargeFormControl.hasError('noDecimalAmount')">
              <strong>{{idiomas.montoMinimoRecargas}}{{minValueAmount}}
                {{idiomas.montoIntervaloRecargas}}{{stepValueAmount}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="selectedReference && (amountValuesArray != null)">
          <mat-form-field appearance="outline" fxFlexFill>
            <mat-label>{{idiomas.MontoRecarga}}</mat-label>
            <mat-select [formControl]="rechargeFormControl">
              <mat-option *ngFor="let amountValue of amountValuesArray" [value]="amountValue.value">
                {{amountValue.viewValue}}
                <mat-divider style="border-top-width: 1px;"></mat-divider>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="accountFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="selectedReference">
          <mat-form-field appearance="outline" fxFlexFill>
            <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
            <mat-select [formControl]="accountFormControl" (selectionChange)="onSelectedAccount($event.value)"
              (openedChange)="funcionRetornarCuentasOwn($event)">
              <mat-option *ngIf="!intentoCuentasOwn" disabled>
                <div style="margin: 10px;">
                  <mat-progress-bar mode="indeterminate">
                  </mat-progress-bar>
                </div>
              </mat-option>
              <mat-option *ngFor="let account of accounts" [value]="account.CCUENTA">
                {{account.CCUENTA | ocultarCuentaPosTrans}} - {{account.DESCRIPCION}}
                <mat-divider style="border-top-width: 2px;"></mat-divider>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="accountFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="selectedReference">
          <mat-form-field appearance="outline" fxFlexFill>
            <mat-label>{{idiomas.Descripcion}}</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="descriptionFormControl"
              oninput="this.value = this.value.toUpperCase();">
            <mat-error *ngIf="descriptionFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="descriptionFormControl.hasError('pattern')">
              <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row" *ngIf="selectedReference" (keyup.enter)="makeRecharge()">
          <div class="left col-6 token-button" *ngIf="internet.internet" style="padding-bottom: 10px !important;">
            <button style="margin-top: 0 !important;"
              [disabled]="(!suministroForm.valid || !rechargeFormControl.valid || !selectedAccount || !descriptionFormControl.valid || enableCronoIn)"
              mat-raised-button (click)="solicitarToken()" color="primary" style="float: left;" class="buttonToken">
              <mat-icon>security</mat-icon>
              {{idiomas.SolicitarToken}}
            </button>
          </div>
          <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
            <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
              [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
          </div>
          <mat-form-field class="col-2 token" appearance="outline" *ngIf="internet.internet && tokenRequested"
            [class.col-12]="bandMobile">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input id="soliToken" matInput type="password" autocomplete="off" [formControl]="tokenFormControl"
              maxlength="6" name="token" appBlockCopyPaste>
            <mat-error *ngIf="tokenFormControl.hasError('required')">
              <strong>{{idiomas.ValidarCampo}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('pattern')">
              <strong>{{idiomas.msjSoloNumeros}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('minlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
              <strong>{{idiomas.msjOtpminmax}}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2 token" appearance="outline" [class.col-12]="bandMobile" *ngIf="internet.internet && !tokenRequested">
            <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
            <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
          </mat-form-field>
          <div class="col-1" *ngIf="internet.internet && rechargeExecuted"
            style="cursor:pointer;padding-top: 1em;max-width: 30px !important">
            <a (click)="createPDFReportRecargas()" style="margin-top: 10px; padding-left: 30px"><mat-icon
                color="primary" style="font-size: 30px; height: 30px; width: 30px;"> picture_as_pdf</mat-icon></a>
          </div>
        </div>
      </ng-template>
    </div>
    <div style="padding-left: 16px; padding-right: 16px; padding-top: 16px;" class="form-row">
      <div class="col-6" *ngIf="queryExecuted">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.Monto}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" value={{amount}}>
        </mat-form-field>
      </div>
      <div class="col-6" *ngIf="queryExecuted && !paymentValueZero">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.Comision}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" value={{fee}}>
        </mat-form-field>
      </div>
      <div *ngIf="queryExecuted && !paymentValueZero" class="col-12">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{Rubro}}</mat-label>
          <mat-select [formControl]="rubroFormControl" (selectionChange)="onSelectedRubro($event.value)">
            <mat-option *ngFor="let rubro of rubros" [value]="rubro">
              {{rubro.label}} - {{rubro.value}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="rubroFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Tabla de Detalle -->
      <div *ngIf="queryExecuted && !paymentValueZero && bandMobile" class="col-12">
        <div class="form-row">
          <div class="col-10">
            <strong>
              <mat-label>{{idiomas.DetalleP}}</mat-label>
            </strong>
          </div>
          <div class="col-2">
            <mat-icon *ngIf="!isExpanded" color="primary" (click)="isExpanded = true">library_books</mat-icon>
            <mat-icon *ngIf="isExpanded" color="primary" (click)="isExpanded = false">cancel</mat-icon>
          </div>
        </div>
        <div *ngIf="isExpanded" class="cards-mobile-accounts"
          [class.heightconteiner2]="!(paymentDetailedArray.length>numMaxRow)">
          <mat-expansion-panel [expanded]="isExpanded" hideToggle="true" appearance="outline"
            (opened)="isExpanded = true" (closed)="isExpanded = false">
            <div class="col-12">
              <mat-list>
                <mat-list-item *ngFor="let dato of paymentDetailedArray; let even = even;">
                  <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                    <div class="col-8">
                      <div style="font-weight: bold; font-size: .9em;">{{dato.label}} - {{dato.value}}</div>
                    </div>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-expansion-panel>
        </div>
        <div *ngIf="bandAdditionalDteail" class="cards-mobile-accounts">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong class="colorCartas">{{idiomas.DetalleAdicional}}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="col-12 container-card-mobile-ser"
              [class.heightconteiner2]="!(paymentDetailedArray.length>numMaxRow)">
              <mat-list>
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let dato of paymentDetailedArray; let even = even;">
                  <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                    <div class="col-12">
                      <div style="font-weight: bold; font-size: .9em;">{{dato.label}} - {{dato.value}}</div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-expansion-panel>
        </div>
        <br>
      </div>
      <div class="col-md-12" *ngIf="queryExecuted && !paymentValueZero">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
          <mat-select [formControl]="accountFormControl" (selectionChange)="onSelectedAccount($event.value)"
            (openedChange)="funcionRetornarCuentasOwn($event)">
            <mat-option *ngIf="!intentoCuentasOwn" disabled>
              <div style="margin: 10px;">
                <mat-progress-bar mode="indeterminate">
                </mat-progress-bar>
              </div>
            </mat-option>
            <mat-option *ngFor="let account of accounts" [value]="account.CCUENTA">
              {{account.CCUENTA | ocultarCuentaPosTrans}} - {{account.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="accountFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="queryExecuted && !paymentValueZero">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Descripcion}}</mat-label>
          <input matInput type="text" autocomplete="off" [formControl]="descriptionFormControl"
            oninput="this.value = this.value.toUpperCase();">
          <mat-error *ngIf="descriptionFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="descriptionFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="internet.internet" autocomplete="off" class="form-row" (keyup.enter)="makePayment()"
        style="padding-left: 5px !important;">
        <div class="left col-6 token-button" *ngIf="queryExecuted && !paymentValueZero && internet.internet"
          style="padding-bottom: 10px !important;">
          <button style="margin-top: 0 !important;"
            [disabled]="(!queryExecuted || !selectedAccount || !selectedReference || !descriptionFormControl.valid) || (enableCronoIn)"
            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
            <mat-icon>security</mat-icon>
            {{idiomas.SolicitarToken}}
          </button>
        </div>
        <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
          <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
        </div>
        <mat-form-field class="col-2 token" appearance="outline" [class.col-12]="bandMobile"
          *ngIf="queryExecuted && !paymentValueZero && tokenRequested">
          <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
          <input id="soliToken" matInput type="password" autocomplete="off" [formControl]="tokenFormControl"
            maxlength="6" inputmode="numeric" name="token" appBlockCopyPaste>
          <mat-error *ngIf="tokenFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('minlength')">
            <strong>{{idiomas.msjOtpminmax}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
            <strong>{{idiomas.msjOtpminmax}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2 token" appearance="outline" [class.col-12]="bandMobile"
          *ngIf="queryExecuted && !paymentValueZero && !tokenRequested">
          <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
          <input id="soliToken" matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="intento" style="padding-left: 16px; padding-right: 16px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div id="floatCont" class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
      <div class="col-6" *ngIf="
      (!internet.internet && referenceFormControl.valid && accountFormControl.valid && descriptionFormControl.valid)
      ||
      (internet.internet && referenceFormControl.valid && accountFormControl.valid && descriptionFormControl.valid && tokenRequested && tokenFormControl.valid)
      ">
        <button mat-raised-button (click)="makePayment()" color="primary" title={{idiomas.Aceptar}} class="buttonSubmit"
          [disabled]="disableButton">
          <mat-icon>download_done</mat-icon>
          {{idiomas.Aceptar}}
        </button>
      </div>
      <div class="col" *ngIf="selectedAccount || selectedReference || descriptionFormControl.value" align="end"
        style="text-align: end;">
        <button mat-raised-button (click)="clearScreenWithDialog()" title={{idiomas.Limpiar}} color="warn"
          class="buttonClean">
          <mat-icon> clear_all</mat-icon>
          {{idiomas.Limpiar}}
        </button>
      </div>
    </div>
  </div>
</div>