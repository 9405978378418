<div fxLayout="column" fxLayoutAlign="start stretch" class="example-form" style="padding-bottom: 35px">
  <mat-card class="cards-transations" *ngIf="bandsFuncion.beneficiarios && !bandMobile">
    <div>
      <div id="tableDiv" autocomplete="off" class="form-row" *ngIf="!showNuevoBeneficiario">
        <div class="col">
          <div class="card-header">
            <div id="lastelement" class="center texto">
              <h6>
                <strong *ngIf="bandTarjetaCredito == 2">{{idiomas.TarjetasCredito | uppercase}}</strong>
                <strong *ngIf="bandTarjetaCredito != 2">{{idiomas.Beneficiarios | uppercase}}</strong>
              </h6>
            </div>
          </div>
          <div *ngIf=intentoBene style="padding-top: 16px; padding-left: 16px; padding-right: 16px;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <div *ngIf=intento tyle="padding-top: 16px; padding-left: 16px; padding-right: 16px;">
            <br>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <div class="row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
            <div *ngIf="!showNuevoBeneficiario && !intento" id="floatCont" class="col-6">
              <button mat-raised-button (click)="nuevoBeneficiario()" title={{idiomas.Nuevo}} class="buttonSubmit"
                color="primary">
                <mat-icon>add_circle_outline</mat-icon>
                {{idiomas.Nuevo}}
              </button>
            </div>
            <div *ngIf="tieneBeneficiarios && !showNuevoBeneficiario  && internet.internet && !intento" class="col-6"
              align="end" style="text-align: end">
              <button mat-raised-button [disabled]="!bandBtnCaducar" (click)="caducarBeneficiarios()" color="warn"
                id=btnCaducar class="buttonSubmitDelete">
                <mat-icon>delete</mat-icon>
                {{idiomas.Caducar}}
              </button>
            </div>
          </div>

          <div *ngIf="tieneBeneficiarios && !intento && !bandMobile && bandTarjetaCredito != 2" class="container"
            style="margin-top: 1%;text-align: center;">
            <mat-radio-group (change)="radioChange($event)" [formControl]="orden" aria-label="Select an option">
              <mat-radio-button *ngIf="datosMism.length>0" color="primary" style="padding-left: 5px" [value]="1"
                [checked]=true>
                {{idiomas.Internas| uppercase}}
              </mat-radio-button>
              <mat-radio-button *ngIf="datosOtros.length>0" color="primary" style="padding-left: 15px" [value]="2">
                {{idiomas.Otros| uppercase}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="form-row container" *ngIf="tieneBeneficiarios && !intento && !bandMobile && mostarMisma">
            <div class="col-12">
             
              <mat-form-field *ngIf="linternos" appearance="outline" fxFlexFill>
                <input matInput autocomplete="off" (keyup)="applyFilterInternos($event.target.value.toUpperCase())"
                  oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                  (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}">
              </mat-form-field>
            </div>
          </div>
          <div class="form-row container" *ngIf="tieneBeneficiarios && !intento && !bandMobile && mostrarOtros">
            <div class="col-12">
              <mat-form-field *ngIf="lexternos" appearance="outline" fxFlexFill>
                <input matInput autocomplete="off" (keyup)="applyFilterExternos($event.target.value.toUpperCase())"
                  oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                  (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}">
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="tieneBeneficiarios && !intento && !bandMobile" style="text-align: center;">
            <mat-label *ngIf="bandTarjetaCredito != 2"
              style="font-size: .7em; opacity: 0.9;">{{idiomas.InforTransBene}}</mat-label>
            <mat-label *ngIf="bandTarjetaCredito == 2"
              style="font-size: .7em; opacity: 0.9;">{{idiomas.InforTransBeneTar}}</mat-label>
          </div>
          <div *ngIf="tieneBeneficiarios && !intento && !bandMobile && mostarMisma" class="row"
            style="margin-left: -24px !important;" style="padding: 16px">
            <div class="col flecha" style="margin-right: 4px;">
              <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('tablaBeneficiarios','left')">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container2Aux" [class.heightconteiner2]="!(datosMism.length>numMaxRow)"
              (scroll)="scroll3('tablaBeneficiarios')">
              <table mat-table [dataSource]="datosMism" class="tablaBeneficiarios"
                (keyup.enter)="caducarBeneficiarios()">
                <ng-container matColumnDef="Identificación">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Identificacion1}}</th>
                  <td mat-cell *matCellDef="let dato"> {{dato.identificacionBeneficiario}} </td>
                </ng-container>
                <ng-container matColumnDef="NombreBeneficiario">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.NombreBeneficiario}}</th>
                  <td mat-cell *matCellDef="let dato" class="texton" style="max-width: 210px">
                    {{dato.nombreBeneficiario}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Cuenta">
                  <div *ngIf="bandTarjetaCredito != 2">
                    <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Cuenta}}</th>
                  </div>
                  <div *ngIf="bandTarjetaCredito == 2">
                    <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Tarjeta}}</th>
                  </div>
                  <td mat-cell *matCellDef="let dato" class="left" style="width: 12%;">
                    <a *ngIf="bandTarjetaCredito != 2"
                      (click)="RealizarTransferenciaDirecta(dato.id,dato.cpersona_banco,dato.cmoneda,0)" style="text-decoration:
                      underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                    <a *ngIf="bandTarjetaCredito == 2"
                      (click)="RealizarTransferenciaDirecta(dato.id,dato.cpersona_banco,dato.cmoneda,1)" style="text-decoration:
                                              underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Banco">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.InstFinan}}</th>
                  <td mat-cell *matCellDef="let dato" class="left">
                    <div *ngIf="dato.cbanco">{{dato.cbanco}}</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Editar">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Editar}}</th>
                  <td mat-cell *matCellDef="let dato" class="left">
                    <mat-icon title={{idiomas.Editar}}
                      *ngIf="dato.cpersona_banco != 2 && dato.identificacionBeneficiario"
                      (click)="editarBenfeiciario(dato)" style="cursor: pointer !important;">edit
                    </mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Caducar">
                  <th mat-header-cell *matHeaderCellDef class="left" [hidden]="!internet.internet">{{idiomas.Caducar}}
                  </th>
                  <td mat-cell *matCellDef="let dato" class="left" [hidden]="!internet.internet">
                    <mat-checkbox *ngIf="dato.identificacionBeneficiario" [checked]='dato.caducar'
                      [value]="dato.caducar" (change)="dato.caducar=!dato.caducar; selectCheck()" color="primary">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsInt; sticky: true"></tr>
                <tr id="celda" mat-row
                  *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsInt;"
                  [ngClass]="{gray: even}">
                  {{showHideRows3(last)}}</tr>
              </table>
            </div>
            <div class="col flecha" style="margin-left: 4px;">
              <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tablaBeneficiarios','right')">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>

          <div *ngIf="tieneBeneficiarios && !intento && !bandMobile && mostrarOtros" class="row"
            style="margin-left: -24px !important;" style="padding: 16px">
            <div class="col flecha" style="margin-right: 4px;">
              <mat-icon [class.desaparecer]=flechaL id="flechaL" (click)="scrollTo('tablaBeneficiariosExt','left')">
                keyboard_arrow_left
              </mat-icon>
            </div>
            <div class="col container2Aux" [class.heightconteiner2]="!(datosOtros.length>numMaxRow)"
              (scroll)="scroll3('tablaBeneficiariosExt')">
              <table mat-table [dataSource]="datosOtros" class="tablaBeneficiariosExt"
                (keyup.enter)="caducarBeneficiarios()">
                <ng-container matColumnDef="Identificación">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.Identificacion1}}</th>
                  <td mat-cell *matCellDef="let dato"> {{dato.identificacionBeneficiario}} </td>
                </ng-container>
                <ng-container matColumnDef="NombreBeneficiario">
                  <th mat-header-cell *matHeaderCellDef>{{idiomas.NombreBeneficiario}}</th>
                  <td mat-cell *matCellDef="let dato" class="texton" style="max-width: 210px">
                    {{dato.nombreBeneficiario}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Cuenta">
                  <div *ngIf="bandTarjetaCredito != 2">
                    <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Cuenta}}</th>
                  </div>
                  <div *ngIf="bandTarjetaCredito == 2">
                    <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Tarjeta}}</th>
                  </div>
                  <td mat-cell *matCellDef="let dato" class="left" style="width: 12%;">
                    <a *ngIf="bandTarjetaCredito != 2"
                      (click)="RealizarTransferenciaDirecta(dato.id,dato.cpersona_banco,dato.cmoneda,0)" style="text-decoration:
                      underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                    <a *ngIf="bandTarjetaCredito == 2"
                      (click)="RealizarTransferenciaDirecta(dato.id,dato.cpersona_banco,dato.cmoneda,1)" style="text-decoration:
                                              underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Banco">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.InstFinan}}</th>
                  <td mat-cell *matCellDef="let dato" class="left">
                    <div *ngIf="dato.cbanco">{{dato.cbanco}}</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Editar">
                  <th mat-header-cell *matHeaderCellDef class="left">{{idiomas.Editar}}</th>
                  <td mat-cell *matCellDef="let dato" class="left">
                    <mat-icon title={{idiomas.Editar}}
                      *ngIf="dato.cpersona_banco != 2 && dato.identificacionBeneficiario"
                      (click)="editarBenfeiciario(dato)" style="cursor: pointer !important;">edit
                    </mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Caducar">
                  <th mat-header-cell *matHeaderCellDef class="left" [hidden]="!internet.internet">{{idiomas.Caducar}}
                  </th>
                  <td mat-cell *matCellDef="let dato" class="left" [hidden]="!internet.internet">
                    <mat-checkbox *ngIf="dato.identificacionBeneficiario" [checked]='dato.caducar'
                      [value]="dato.caducar" (change)="dato.caducar=!dato.caducar; selectCheck()" color="primary">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsExt; sticky: true"></tr>
                <tr id="celda" mat-row
                  *matRowDef="let row; let even = even; let last = last; columns: displayedColumnsExt;"
                  [ngClass]="{gray: even}">
                  {{showHideRows3(last)}}</tr>
              </table>
            </div>
            <div class="col flecha" style="margin-left: 4px;">
              <mat-icon [class.desaparecer]=flechaR id="flechaR" (click)="scrollTo('tablaBeneficiariosExt','right')">
                keyboard_arrow_right</mat-icon>
            </div>
          </div>

          <div *ngIf="!tieneBeneficiarios && !intento" class="center col texto" style="padding: 16px">
            <mat-label>
              {{idiomas.NoTieneBeneficiarios }}
            </mat-label>
          </div>
          <div *ngIf="!tieneBeneficiariosExterno && tieneBeneficiarios && mostrarOtros && !intento" class="center col texto" style="padding: 16px">
            <mat-label>
              {{idiomas.NoTieneBeneficiarios }}
            </mat-label>
          </div>
          <div *ngIf="!tieneBeneficiariosPJL && tieneBeneficiarios && mostarMisma && !intento" class="center col texto" style="padding: 16px">
            <mat-label>
              {{idiomas.NoTieneBeneficiarios }}
            </mat-label>
          </div>

          <div class="row" *ngIf="expirar" style="padding-top: 16px; padding-left: 16px; padding-right: 16px;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </div>
      <div autocomplete="off" *ngIf="showNuevoBeneficiario">
        <div class="card-header">
          <div id="lastelement" class="center texto">
            <h6>
              <strong *ngIf="bandTarjetaCredito == 2">{{idiomas.IngresoTarjetasCredito | uppercase}}</strong>
              <strong *ngIf="bandTarjetaCredito != 2">{{idiomas.IngresoBeneficiarios | uppercase}}</strong>
            </h6>
          </div>
        </div>
        <div class="form-row" style="padding: 16px; padding-bottom: 0px;">
          <div class="col-12">
            <strong>
              <mat-label *ngIf="bandTarjetaCredito != 2">
                {{idiomas.DatosCuenta | capitalize }}
              </mat-label>
            </strong>
            <strong>
              <mat-label *ngIf="bandTarjetaCredito == 2">
                {{idiomas.DatosTarjeta | capitalize }}
              </mat-label>
            </strong>
          </div>
          <mat-progress-bar *ngIf="intentoEditarlBancos && !lbancos.length > 0" mode="indeterminate"></mat-progress-bar>
          <br>
          <div class="col-6" *ngIf="!intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.InstFinan}}</mat-label>
              <input matInput *ngIf="beneficiarioingreso.id_banco == '2' && (infoLocalBenefQuery || autoSelectedEFI)"
                [(value)]="beneficiarioingreso.nombre_banco" [readonly]="true">
              <mat-select
                *ngIf="!(beneficiarioingreso.id_banco == '2' && !intentoCodInstOrdenante && (infoLocalBenefQuery || autoSelectedEFI))"
                (openedChange)="funcionRetornarBanco($event)" (selectionChange)="tomarValorBancos($event.value)"
                [formControl]="controlBancos">
                <input *ngIf="intentoBancos" matInput autocomplete="off"
                  (keyup)="applyFilter($event.target.value.toUpperCase())"
                  oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                  (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                  style="height: 35px;border-bottom-style: groove;">
                <mat-option *ngIf="!intentoBancos" disabled>
                  <div style="margin: 10px;">
                    <mat-progress-bar mode="indeterminate">
                    </mat-progress-bar>
                  </div>
                </mat-option>
                <mat-option *ngFor="let banco of lbancos" [(value)]="banco.id">
                  {{banco.nombre}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.InstFinan}}</mat-label>
              <input matInput *ngIf="intentoCodInstOrdenante && infoLocalBenefQuery"
                [(value)]="beneficiarioingreso.nombre_banco" [readonly]="true">
              <mat-select *ngIf="!(intentoCodInstOrdenante && (infoLocalBenefQuery || autoSelectedEFI))"
                (openedChange)="funcionRetornarBanco($event)" (selectionChange)="tomarValorBancos($event.value)"
                [formControl]="controlBancos">
                <input *ngIf="intentoBancos" matInput autocomplete="off"
                  (keyup)="applyFilter($event.target.value.toUpperCase())"
                  oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                  (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                  style="height: 35px;border-bottom-style: groove;">
                <mat-option *ngIf="!intentoBancos" disabled>
                  <div style="margin: 10px;">
                    <mat-progress-bar mode="indeterminate">
                    </mat-progress-bar>
                  </div>
                </mat-option>
                <mat-option *ngFor="let banco of lbancos" [(value)]="banco.id">
                  {{banco.nombre}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="bandTarjetaCredito!=2 && !intentoCodInstOrdenante">
            <mat-form-field *ngIf="bandTarjetaCredito!=2" appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.TipoCuenta}}</mat-label>
              <input matInput *ngIf="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery"
                [(value)]="beneficiarioingreso.tipocuenta" [readonly]="true">
              <mat-select *ngIf="!(beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery)"
                (selectionChange)="tomarValorCuentas($event.value)" [formControl]="controlTCuenta">
                <mat-option *ngFor="let cuenta of listadocuentas" [(value)]="cuenta.id">
                  {{cuenta.nombre}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="bandTarjetaCredito!=2 && intentoCodInstOrdenante">
            <mat-form-field *ngIf="bandTarjetaCredito!=2" appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.TipoCuenta}}</mat-label>
              <input matInput *ngIf="infoLocalBenefQuery" [(value)]="beneficiarioingreso.tipocuenta" [readonly]="true">
              <mat-select *ngIf="!(intentoCodInstOrdenante && infoLocalBenefQuery)"
                (selectionChange)="tomarValorCuentas($event.value)" [formControl]="controlTCuenta">
                <mat-option *ngFor="let cuenta of listadocuentas" [(value)]="cuenta.id">
                  {{cuenta.nombre}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="!intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label *ngIf="bandTarjetaCredito != 2">{{idiomas.NumeroCuenta}}</mat-label>
              <mat-label *ngIf="bandTarjetaCredito == 2">{{idiomas.NumeroTarjeta}}</mat-label>
              <input autocomplete="off" matInput [formControl]="numcuenta" inputmode="tel"
                [readonly]="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery">
              <mat-error *ngIf="numcuenta.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="numcuenta.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
              <mat-error *ngIf="numcuenta.hasError('minlength')">
                <strong>{{idiomas.minAccountNumberLength}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label *ngIf="bandTarjetaCredito != 2">{{idiomas.NumeroCuenta}}</mat-label>
              <mat-label *ngIf="bandTarjetaCredito == 2">{{idiomas.NumeroTarjeta}}</mat-label>
              <input autocomplete="off" matInput [formControl]="numcuenta" inputmode="tel"
                [readonly]="infoLocalBenefQuery">
              <mat-error *ngIf="numcuenta.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="numcuenta.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
              <mat-error *ngIf="numcuenta.hasError('minlength')">
                <strong>{{idiomas.minAccountNumberLength}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.Moneda}}</mat-label>
              <mat-select (selectionChange)="tomarValorMonedas($event.value)" [formControl]="controlMoneda">
                <mat-option *ngFor="let moneda of lmonedas" [(value)]="moneda.cmoneda">
                  {{moneda.cmoneda}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <strong>
              <mat-label>{{idiomas.DatosBeneficiario | uppercase}}</mat-label>
            </strong>
          </div>
          <br>
          <div class="col-6" *ngIf="!intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.idTipo}}</mat-label>
              <input matInput *ngIf="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery"
                [(value)]="beneficiarioingreso.ctipoidentificacion" [readonly]="true">
              <mat-select *ngIf="!(beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery)"
                (selectionChange)="tomarValorTipoId($event.value)" [formControl]="controlIdTipo">
                <mat-option *ngFor="let tidentificacion of lidentificaciones" [(value)]="tidentificacion.id">
                  {{tidentificacion.descripcion}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.idTipo}}</mat-label>
              <input matInput *ngIf="infoLocalBenefQuery" [(value)]="beneficiarioingreso.ctipoidentificacion"
                [readonly]="true">
              <mat-select *ngIf="!(intentoCodInstOrdenante && infoLocalBenefQuery)"
                (selectionChange)="tomarValorTipoId($event.value)" [formControl]="controlIdTipo">
                <mat-option *ngFor="let tidentificacion of lidentificaciones" [(value)]="tidentificacion.id">
                  {{tidentificacion.descripcion}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="!intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.Identificacion1}}</mat-label>
              <input matInput autocomplete="off" [formControl]="identificacion" inputmode="tel"
                [readonly]="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery">
              <mat-error *ngIf="identificacion.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('minlength')">
                <strong>{{idiomas.msjMin}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('maxlength')">
                <strong>{{idiomas.msjExedeTamano}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('digitoVerificador')">
                <strong>{{idiomas.CedulaInv}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.Identificacion1}}</mat-label>
              <input matInput autocomplete="off" [formControl]="identificacion" inputmode="tel"
                [readonly]="infoLocalBenefQuery">
              <mat-error *ngIf="identificacion.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('minlength')">
                <strong>{{idiomas.msjMin}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('maxlength')">
                <strong>{{idiomas.msjExedeTamano}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('digitoVerificador')">
                <strong>{{idiomas.CedulaInv}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div
          *ngIf="beneficiarioingreso.id_banco != '2' && continueProcess && !intentoCodInstOrdenante  || (beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery) || !internet.internet"
          style="width: 100%; padding-left: 16px; padding-right: 16px; padding-bottom: 16px;" class="form-row">
          <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.Nombres}}</mat-label>
              <input matInput autocomplete="off" [formControl]="nombre" #input style="text-transform: uppercase"
                [readonly]="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery">
              <mat-error *ngIf="nombre.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="nombre.hasError('pattern')">
                <strong>{{idiomas.msjSoloTexto}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.Telefono}}</mat-label>
              <input matInput autocomplete="off" [formControl]="telefono" inputmode="tel">
              <mat-error *ngIf="telefono.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.DireccionEMail}}</mat-label>
              <input matInput autocomplete="off" [formControl]="email" inputmode="email">
              <mat-error *ngIf="email.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="email.hasError('pattern')">
                <strong>{{idiomas.msjSoloEmail}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="internet.internet && !pinValidation" class="col-12">
            <div autocomplete="off" class="form-row" (keyup.enter)="ProcesarIngreso(1, 0)">
              <mat-form-field *ngIf="ingtoken" class="col-2 token" [class.col-12]="bandMobile" appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input id="soliToken" matInput type="text" name="soliToken" autocomplete="off"
                  onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                  inputmode="numeric" appBlockCopyPaste>
                <mat-error *ngIf="token.hasError('required')">
                  <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('pattern')">
                  <strong>{{idiomas.msjSoloNumeros}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('minlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('maxlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="!ingtoken" class="col-2 token" [class.col-12]="bandMobile" appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input matInput autocomplete="off" type="text" disabled appBlockCopyPaste>
              </mat-form-field>
              <div *ngIf="internet.internet" class="left col-2 token-button">
                <button
                  [disabled]="numcuenta.invalid || identificacion.invalid || (nombre.invalid && nombreBanred.invalid) || telefono.invalid || email.invalid || enableCronoIn"
                  mat-raised-button color="primary" class="buttonToken" (click)="SolicitarToken()">
                  <mat-icon>security</mat-icon>
                  {{idiomas.SolicitarToken}}
                </button>
              </div>
              <div *ngIf="mostrarCron" class="col">
                <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                  [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="beneficiarioingreso.id_banco != '2' && continueProcess && intentoCodInstOrdenante  && infoLocalBenefQuery || !internet.internet"
          style="width: 100%; padding-left: 16px; padding-right: 16px; padding-bottom: 16px;" class="form-row">
          <div class="col-6">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.Nombres}}</mat-label>
              <input matInput autocomplete="off" [formControl]="nombreBanred" #input
                (input)="input.value=$event.target.value.toUpperCase()" [readonly]="infoLocalBenefQuery">
              <mat-error *ngIf="nombre.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="nombre.hasError('pattern')">
                <strong>{{idiomas.msjSoloTexto}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.Telefono}}</mat-label>
              <input matInput autocomplete="off" [formControl]="telefono" inputmode="tel">
              <mat-error *ngIf="telefono.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.DireccionEMail}}</mat-label>
              <input matInput autocomplete="off" [formControl]="email">
              <mat-error *ngIf="email.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="email.hasError('pattern')">
                <strong>{{idiomas.msjSoloEmail}}</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="internet.internet && !pinValidation" class="col-12">
            <div autocomplete="off" class="form-row" (keyup.enter)="ProcesarIngreso(1, 0)">
              <mat-form-field *ngIf="ingtoken" class="col-2 token" [class.col-12]="bandMobile" appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input id="soliToken" matInput type="text" name="soliToken" autocomplete="off"
                  onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                  inputmode="numeric" appBlockCopyPaste>
                <mat-error *ngIf="token.hasError('required')">
                  <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('pattern')">
                  <strong>{{idiomas.msjSoloNumeros}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('minlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('maxlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="!ingtoken" class="col-2 token" [class.col-12]="bandMobile" appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input matInput autocomplete="off" type="text" disabled appBlockCopyPaste>
              </mat-form-field>
              <div *ngIf="internet.internet" class="left col-2 token-button">
                <button
                  [disabled]="numcuenta.invalid || identificacion.invalid || (nombre.invalid && nombreBanred.invalid)|| telefono.invalid || email.invalid || enableCronoIn"
                  mat-raised-button color="primary" class="buttonToken" (click)="SolicitarToken()">
                  <mat-icon>security</mat-icon>
                  {{idiomas.SolicitarToken}}
                </button>
              </div>
              <div *ngIf="mostrarCron" class="col">
                <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                  [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="intentop" style="padding-top: 16px; padding-left: 16px; padding-right: 16px;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
        <div id="floatCont" class="col-6">
          <button mat-raised-button (click)="solicitarAutorizacion()" title={{idiomas.SolicitarAutorizacion}}
            class="buttonSubmit"
            *ngIf="pinValidation && !pinValid && continueProcess &&  (beneficiarioingreso.id_banco != '2' || infoLocalBenefQuery) && internet.internet && showNuevoBeneficiario"
            color="primary"
            [disabled]="numcuenta.invalid || identificacion.invalid || (nombre.invalid && nombreBanred.invalid) || telefono.invalid || email.invalid || disableButton">
            <mat-icon>security</mat-icon>
            {{idiomas.SolicitarAutorizacion}}
          </button>
          <button mat-raised-button (click)="ProcesarIngreso(1, 0)" title={{idiomas.Aceptar}} class="buttonSubmit"
            *ngIf="(pinValid || token.valid) && internet.internet && continueProcess && showNuevoBeneficiario && !intentoEditar && !bandTransfer"
            color="primary" [disabled]="disableButton">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button (click)="editarRegistro(0)" title={{idiomas.Aceptar}} class="buttonSubmit"
            *ngIf="(pinValid || token.valid) && internet.internet && continueProcess  && showNuevoBeneficiario && intentoEditar && !bandTransfer"
            color="primary" [disabled]="disableButton">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button (click)="ProcesarIngreso(1, 0)" title={{idiomas.Aceptar}} class="buttonSubmit"
            *ngIf="!internet.internet && showNuevoBeneficiario && continueProcess && numcuenta.valid && identificacion.valid
           && (nombre.valid ||  nombreBanred.valid) && telefono.valid && email.valid && !intentoEditar" color="primary">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button (click)="editarRegistro(0)" title={{idiomas.Aceptar}} class="buttonSubmit" *ngIf="!internet.internet && showNuevoBeneficiario && continueProcess && numcuenta.valid && identificacion.valid
            && (nombre.valid || nombreBanred.valid) && telefono.valid && email.valid && intentoEditar" color="primary">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Aceptar}}
          </button>
          <button mat-raised-button color="primary" (click)="consultarUserInformation()" title={{idiomas.Consultar}}
            class="buttonSubmit"
            *ngIf="beneficiarioingreso.id_banco == '2' && !continueProcess && !infoLocalBenefQuery && showNuevoBeneficiario"
            [disabled]="numcuenta.invalid || identificacion.invalid">
            <mat-icon>search</mat-icon>{{idiomas.Consultar}}
          </button>
          <button mat-raised-button color="primary" (click)="consultarUserInformationPagoDirecto()" class="buttonSubmit"
            *ngIf="bandTarjetaCredito != 2 && intentoCodInstOrdenante && !continueProcess && !infoLocalBenefQuery"
            [disabled]="!controlTCuenta.valid || !numcuenta.valid || !controlIdTipo.valid || !identificacion.valid">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
          <button mat-raised-button color="primary" (click)="consultarUserInformationPagoDirecto()" class="buttonSubmit"
            *ngIf="bandTarjetaCredito ==2 && intentoCodInstOrdenante && !continueProcess && !infoLocalBenefQuery"
            [disabled]="!numcuenta.valid || !controlIdTipo.valid || !identificacion.valid">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
          <button mat-raised-button color="primary" (click)="continueRegister()" class="buttonSubmit"
            *ngIf="intentoCodInstOrdenante && continueRegisterProcess && !infoLocalBenefQuery">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Continuar}}
          </button>
          <button *ngIf="internet.internet && bandTransfer && cuenta !='' && bandTarjetaCredito !=2" mat-raised-button
            class="buttonSubmit" (click)="RealizarTransferencia()" title={{idiomas.RealizarTransferencia}}
            color="primary">
            <mat-icon style="padding-right: 30px">compare_arrows</mat-icon>
            {{idiomas.RealizarTransferencia}}
          </button>
          <button *ngIf="internet.internet && bandTransfer && cuenta !='' && bandTarjetaCredito ==2" mat-raised-button
            class="buttonSubmit" (click)="RealizarTransferencia()" title={{idiomas.RealizarPagoTarjeta}}
            color="primary">
            <mat-icon style="padding-right: 30px">credit_card</mat-icon>{{idiomas.RealizarPagoTarjeta}}
          </button>
        </div>
      </div>
    </div>
  </mat-card>

  <div style="padding-left: 10px; padding-right: 10px;"
    *ngIf="bandMobile && (!showNuevoBeneficiario && bandsFuncion.transCtsProp || bandsFuncion.transMismaInst || bandsFuncion.transOtraInst || bandsFuncion.transTarCred || bandsFuncion.transTarCred)">
    <button mat-raised-button (click)="habilitarFunciones('beneficiarios',true)" title={{idiomas.RegresarBene}}
      style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;" color="primary"
      class="buttonBene">
      <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
      <strong *ngIf="bandTarjetaCredito != 2">{{idiomas.RegresarBene}}</strong>
      <strong *ngIf="bandTarjetaCredito == 2">{{idiomas.RegresarTarj}}</strong>
    </button>
  </div>
  <div style="padding-left: 10px; padding-right: 10px;" *ngIf="bandMobile && showNuevoBeneficiario && bandsFuncion.beneficiarios">
    <button mat-raised-button (click)="return(false)" title={{idiomas.Beneficiarios}}
      style="width: 100%;  display: flex; align-items: initial; justify-content: initial; margin:auto;" color="primary"
      class="buttonBene">
      <mat-icon style="text-align: initial;"> arrow_back</mat-icon>
      <strong *ngIf="bandTarjetaCredito != 2">{{idiomas.RegresarBene}}</strong>
      <strong *ngIf="bandTarjetaCredito == 2">{{idiomas.RegresarTarj}}</strong>
    </button>
  </div>
  <div *ngIf="bandsFuncion.beneficiarios && bandMobile">
    <div>
      <div id="tableDiv" autocomplete="off" class="form-row" *ngIf="!showNuevoBeneficiario">
        <div class="col">
          <div class="card-header">
            <div id="lastelement" class="center texto">
              <h6>
                <strong *ngIf="bandTarjetaCredito == 2">{{idiomas.TarjetasCredito | uppercase}}</strong>
                <strong *ngIf="bandTarjetaCredito != 2">{{idiomas.Beneficiarios | uppercase}}</strong>
              </h6>
            </div>
          </div>
          <div *ngIf=intentoBene style="padding-top: 16px; padding-left: 16px; padding-right: 16px;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <div *ngIf=intento tyle="padding-top: 16px; padding-left: 16px; padding-right: 16px;">
            <br>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <div *ngIf="bandTarjetaCredito != 2" style="padding-top: 10px !important; ">
            <div *ngIf="!intento && bandMobile"
              class="container" style="margin-top: 1%;text-align: center;padding-bottom: 4%;">
              <mat-radio-group (change)="radioChange($event)" [formControl]="orden" aria-label="Select an option">
                <mat-radio-button color="primary" style="padding-left: 5px" [value]="1" [checked]=true>
                  {{idiomas.Internas| uppercase}}
                </mat-radio-button>
                <mat-radio-button color="primary" style="padding-left: 15px" [value]="2">
                  {{idiomas.Otros| uppercase}}
                </mat-radio-button>
                <div *ngIf="tieneBeneficiariosPropio && !intento && bandMobile"
                 class="container" style="margin-top: 1%;padding-bottom: 4%;text-align: center;">
                  <mat-radio-button color="primary" style="padding-left: 25px" [value]="3">
                    {{idiomas.TransferenciasCuentasPropias | uppercase}}
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>



            <div class="form-row container" *ngIf="!intento && bandMobile">
              <div class="col-12" *ngIf="tieneBeneficiariosPJL && mostarMisma">
                <mat-form-field *ngIf="linternos" appearance="outline" fxFlexFill>
                  <input matInput autocomplete="off" (keyup)="applyFilterInternos($event.target.value.toUpperCase())"
                    oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                    (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}">
                </mat-form-field>
              </div>
              <div class="col-12" *ngIf="tieneBeneficiariosExterno && mostrarOtros">
                <mat-form-field *ngIf="lexternos" appearance="outline" fxFlexFill>
                  <input matInput autocomplete="off" (keyup)="applyFilterExternos($event.target.value.toUpperCase())"
                    oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                    (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}">
                </mat-form-field>
              </div>
              
              <div class="col-6" *ngIf="mostrarOtros"
                style="text-align: center; padding-bottom: 10px; margin-top: -5px;">
                <button mat-raised-button
                  style="margin: auto !important; font-size: .8em; width: fit-content; padding-top: 0; padding-bottom: 0;"
                  (click)="nuevoBeneficiario()" title={{idiomas.Nuevo}} class="buttonSubmit" color="primary">
                  <mat-icon>add_circle_outline</mat-icon>
                  {{idiomas.Nuevo}}
                </button>
              </div>
              <div class="col-6" *ngIf="mostrarOtros"
                style="text-align: center; padding-bottom: 10px; margin-top: -5px;">
                <button mat-raised-button
                  style="margin: auto !important; font-size: .8em; width: fit-content; padding-top: 0; padding-bottom: 0;"
                  (click)="scannQrExterno()" color="primary" class="buttonSubmit">
                  <img src="assets/resources/mipmap-hdpi/icon-cash-azul.svg" alt="Pay QR" style="height: 24px; width: 24px;">
                  CASH CASH
                </button>
              </div>
              <div class="col-6" *ngIf="!mostrarOtros"
                style="text-align: center; padding-bottom: 10px; margin-top: -5px;">
                <button mat-raised-button
                  style="margin: auto !important; font-size: .8em; width: fit-content; padding-top: 0; padding-bottom: 0;"
                  (click)="nuevoBeneficiario()" title={{idiomas.Nuevo}} class="buttonSubmit" color="primary">
                  <mat-icon>add_circle_outline</mat-icon>
                  {{idiomas.Nuevo}}
                </button>
              </div>
              <div class="col-6" *ngIf="!mostrarOtros"
                style="text-align: center; padding-bottom: 10px; margin-top: -5px;">
                <button mat-raised-button
                  style="margin: auto !important; font-size: .8em; width: fit-content; padding-top: 0; padding-bottom: 0;"
                  (click)="scannQrInterna()" color="primary" class="buttonSubmit">
                  <img src="assets/resources/mipmap-hdpi/icon-cash-azul.svg" alt="Pay QR" style="height: 24px; width: 24px;">
                  CASH CASH
                </button>
              </div>
            </div>
            <div class="col-12"
              *ngIf="!intento && bandMobile && mostrarPropias">
              <app-transferencias-cuentas-propias #transCtsProp [mediano]="mediano" [grande]="grande" [normal]="normal"
                [idiomas]="idiomas" [dataTarPend]="dataTarPend"></app-transferencias-cuentas-propias>
            </div>
            <div class="col-12 container-card-mobile-ben"
              *ngIf="tieneBeneficiarios && tieneBeneficiariosPJL &&!intento && bandMobile && mostarMisma">
              <div [class.heightconteiner2]="!(datosMism.length>=numMaxRow)">
                <mat-list>
                  <mat-divider></mat-divider>
                  <mat-list-item *ngFor="let dato of datosMism; let even = even;">
                    <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                      <div *ngIf="bandTarjetaCredito != 2"
                        (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)" class=" col-6">
                        <div style="font-weight: bold; font-size: .8em;">{{dato.nombreBeneficiario}}</div>
                      </div>
                      <div *ngIf="bandTarjetaCredito == 2"
                        (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,false)" class=" col-6">
                        <div style="font-weight: bold; font-size: .8em;">{{dato.nombreBeneficiario}}</div>
                      </div>
                      <div class="col-5" style="text-align: end;">
                        <div class="cuentas-beneficiarios">
                          <a *ngIf="bandTarjetaCredito != 2"
                            (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0, false)" style="text-decoration:
                      underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                          <a *ngIf="bandTarjetaCredito == 2"
                            (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1, false)" style="text-decoration:
                                              underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                        </div>
                      </div>
                      <div class="col-1">
                        <button mat-icon-button class="boton2" id="btnCerrarSesion" (click)="doBlur()"
                          style="margin-top: -20px;" [matMenuTriggerFor]="CerrarSesion">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #CerrarSesion="matMenu">
                          <a mat-menu-item *ngIf="dato.cpersona_banco != 2 && dato.identificacionBeneficiario"
                            title={{idiomas.Editar}} (click)="editarBenfeiciario(dato)"
                            style="cursor: pointer !important; vertical-align: text-bottom;">
                            <mat-icon>edit</mat-icon>{{idiomas.Editar}}</a>
                          <a mat-menu-item title={{idiomas.Caducar}} (click)="caducarBeneficiario(dato)"
                            style="cursor: pointer !important; vertical-align: text-bottom;">
                            <mat-icon>delete</mat-icon>{{idiomas.Caducar}}</a>
                          <a *ngIf="bandTarjetaCredito != 2" mat-menu-item title={{idiomas.transfererir}}
                            (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,true)"
                            style="cursor: pointer !important; vertical-align: text-bottom;">
                            <mat-icon>sync</mat-icon>{{idiomas.transfererir}}</a>
                          <a *ngIf="bandTarjetaCredito == 2" mat-menu-item title={{idiomas.transfererir}}
                            (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,true)"
                            style="cursor: pointer !important; vertical-align: text-bottom;">
                            <mat-icon>sync</mat-icon>{{idiomas.transfererir}}</a>
                        </mat-menu>
                      </div>
                      <div *ngIf="bandTarjetaCredito != 2"
                        (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)" class="col-8"
                        style="padding-top: 5px;">
                        <div style="font-size: .8em; white-space: initial;">{{dato.cbanco}}</div>
                      </div>
                      <div *ngIf="bandTarjetaCredito != 2"
                        (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)" class="col-6">
                        <div style="font-size: .7em; white-space: initial;">{{dato.email}}</div>
                      </div>

                      <div *ngIf="bandTarjetaCredito == 2"
                        (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,false)" class="col-8"
                        style="padding-top: 5px;">
                        <div style="font-size: .8em; white-space: initial;">{{dato.cbanco}}</div>
                      </div>
                      <div *ngIf="bandTarjetaCredito == 2"
                        (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,false)" class="col-6">
                        <div style="font-size: .7em; white-space: initial;">{{dato.email}}</div>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
            <div class="col-12 container-card-mobile-ben"
              *ngIf="tieneBeneficiarios && tieneBeneficiariosExterno &&!intento && bandMobile && mostrarOtros"
              [class.heightconteiner2]="!(datosOtros.length>=numMaxRow)">
              <mat-list>
                <mat-divider class="col-12"></mat-divider>
                <mat-list-item *ngFor="let dato of datosOtros; let even = even;">
                  <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                    <div (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0, false)"
                      class=" col-6">
                      <div style="font-weight: bold; font-size: .8em;">{{dato.nombreBeneficiario}}</div>
                    </div>
                    <div *ngIf="bandTarjetaCredito == 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1, false)" class=" col-6">
                      <div style="font-weight: bold; font-size: .8em;">{{dato.nombreBeneficiario}}</div>
                    </div>
                    <div class="col-5" style="text-align: end;">
                      <div class="cuentas-beneficiarios">
                        <a *ngIf="bandTarjetaCredito != 2"
                          (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0, false)" style="text-decoration:
                      underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                        <a *ngIf="bandTarjetaCredito == 2"
                          (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1, false)" style="text-decoration:
                                              underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                      </div>
                    </div>
                    <div class="col-1">
                      <button mat-icon-button class="boton2" id="btnCerrarSesion" (click)="doBlur()"
                        style="margin-top: -20px;" [matMenuTriggerFor]="CerrarSesion">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #CerrarSesion="matMenu">
                        <a mat-menu-item *ngIf="dato.cpersona_banco != 2 && dato.identificacionBeneficiario"
                          title={{idiomas.Editar}} (click)="editarBenfeiciario(dato)"
                          style="cursor: pointer !important; vertical-align: text-bottom;">
                          <mat-icon>edit</mat-icon>{{idiomas.Editar}}</a>
                        <a mat-menu-item title={{idiomas.Caducar}} (click)="caducarBeneficiario(dato)"
                          style="cursor: pointer !important; vertical-align: text-bottom;">
                          <mat-icon>delete</mat-icon>{{idiomas.Caducar}}</a>
                        <a *ngIf="bandTarjetaCredito != 2" mat-menu-item title={{idiomas.transfererir}}
                          (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0, true)"
                          style="cursor: pointer !important; vertical-align: text-bottom;">
                          <mat-icon>sync</mat-icon>{{idiomas.transfererir}}</a>
                        <a *ngIf="bandTarjetaCredito == 2" mat-menu-item title={{idiomas.transfererir}}
                          (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1, true)"
                          style="cursor: pointer !important; vertical-align: text-bottom;">
                          <mat-icon>sync</mat-icon>{{idiomas.transfererir}}</a>
                      </mat-menu>
                    </div>
                    <div *ngIf="bandTarjetaCredito != 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)" class="col-8"
                      style="padding-top: 5px;">
                      <div style="font-size: .8em; white-space: initial;">{{dato.cbanco}}</div>
                    </div>
                    <div *ngIf="bandTarjetaCredito != 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)" class="col-6">
                      <div style="font-size: .7em; white-space: initial;">{{dato.email}}</div>
                    </div>

                    <div *ngIf="bandTarjetaCredito == 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,false)" class="col-8"
                      style="padding-top: 5px;">
                      <div style="font-size: .8em; white-space: initial;">{{dato.cbanco}}</div>
                    </div>
                    <div *ngIf="bandTarjetaCredito == 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,false)" class="col-6">
                      <div style="font-size: .7em; white-space: initial;">{{dato.email}}</div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </mat-list>
            </div>
          </div>

          <div *ngIf="bandTarjetaCredito == 2" style="padding-top: 10px !important;">
            <div class="form-row container" *ngIf="!intento && bandMobile">
              <div class="col-8" *ngIf="tieneBeneficiarios && tieneBeneficiariosExterno && mostrarOtros">
                <mat-form-field *ngIf="lexternos" appearance="outline" fxFlexFill>
                  <input matInput autocomplete="off" (keyup)="applyFilterExternos($event.target.value.toUpperCase())"
                    oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                    (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}">
                </mat-form-field>
              </div>
              <div *ngIf="!showNuevoBeneficiario && !intento" class="col-4" style="text-align: center;">
                <button mat-raised-button (click)="nuevoBeneficiario()" title={{idiomas.Nuevo}} class="buttonSubmit"
                  color="primary">
                  <mat-icon>add_circle_outline</mat-icon>
                  {{idiomas.Nuevo}}
                </button>
              </div>
            </div>
            <div class="col-12 container-card-mobile-ben"
              *ngIf="tieneBeneficiarios && tieneBeneficiariosExterno && !intento && bandMobile && mostrarOtros"
              [class.heightconteiner2]="!(datosOtros.length>=numMaxRow)">
              <mat-list>
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let dato of datosOtros; let even = even;">
                  <div class="form-row" style="width: 100%; padding: 10px 0 10px 0 !important;">
                    <div (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)"
                      class=" col-6">
                      <div style="font-weight: bold; font-size: .8em;">{{dato.nombreBeneficiario}}</div>
                    </div>
                    <div class="col-5" style="text-align: end;">
                      <div class="cuentas-beneficiarios">
                        <a *ngIf="bandTarjetaCredito != 2"
                          (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)" style="text-decoration:
                        underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                        <a *ngIf="bandTarjetaCredito == 2"
                          (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,false)" style="text-decoration:
                                                underline !important;cursor: pointer;">{{dato.id | ocultarCuentaPosTrans}}</a>
                      </div>
                    </div>
                    <div class="col-1">
                      <button mat-icon-button class="boton2" id="btnCerrarSesion" (click)="doBlur()"
                        style="margin-top: -20px;" [matMenuTriggerFor]="CerrarSesion">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #CerrarSesion="matMenu">
                        <a mat-menu-item *ngIf="dato.cpersona_banco != 2 && dato.identificacionBeneficiario"
                          title={{idiomas.Editar}} (click)="editarBenfeiciario(dato)"
                          style="cursor: pointer !important; vertical-align: text-bottom;">
                          <mat-icon>edit</mat-icon>{{idiomas.Editar}}</a>
                        <a mat-menu-item title={{idiomas.Caducar}} (click)="caducarBeneficiario(dato)"
                          style="cursor: pointer !important; vertical-align: text-bottom;">
                          <mat-icon>delete</mat-icon>{{idiomas.Caducar}}</a>
                        <a *ngIf="bandTarjetaCredito != 2" mat-menu-item title={{idiomas.transfererir}}
                          (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,true)"
                          style="cursor: pointer !important; vertical-align: text-bottom;">
                          <mat-icon>sync</mat-icon>{{idiomas.transfererir}}</a>
                        <a *ngIf="bandTarjetaCredito == 2" mat-menu-item title={{idiomas.transfererir}}
                          (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,true)"
                          style="cursor: pointer !important; vertical-align: text-bottom;">
                          <mat-icon>sync</mat-icon>{{idiomas.transfererir}}</a>
                      </mat-menu>
                    </div>
                    <div *ngIf="bandTarjetaCredito != 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)" class="col-8"
                      style="padding-top: 5px;">
                      <div style="font-size: .8em; white-space: initial;">{{dato.cbanco}}</div>
                    </div>
                    <div *ngIf="bandTarjetaCredito != 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,0,false)" class="col-6">
                      <div style="font-size: .7em; white-space: initial;">{{dato.email}}</div>
                    </div>

                    <div *ngIf="bandTarjetaCredito == 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,false)" class="col-8"
                      style="padding-top: 5px;">
                      <div style="font-size: .8em; white-space: initial;">{{dato.cbanco}}</div>
                    </div>
                    <div *ngIf="bandTarjetaCredito == 2"
                      (click)="realizarTransferMenu(dato.id,dato.cpersona_banco,dato.cmoneda,1,false)" class="col-6">
                      <div style="font-size: .7em; white-space: initial;">{{dato.email}}</div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
          <!--Control para mostrar menssaje beneficiarios no existentes -->
          <div *ngIf="!tieneBeneficiarios && !intento" class="center col texto" style="padding: 16px">
            <mat-label>
              {{idiomas.NoTieneBeneficiarios }}
            </mat-label>
          </div>
          <div *ngIf="!tieneBeneficiariosExterno && tieneBeneficiarios && mostrarOtros && !intento" class="center col texto" style="padding: 16px">
            <mat-label>
              {{idiomas.NoTieneBeneficiarios }}
            </mat-label>
          </div>
          <div *ngIf="!tieneBeneficiariosPJL && tieneBeneficiarios && mostarMisma && !intento" class="center col texto" style="padding: 16px">
            <mat-label>
              {{idiomas.NoTieneBeneficiarios }}
            </mat-label>
          </div>



          <div class="row" *ngIf="expirar" style="padding-top: 16px; padding-left: 16px; padding-right: 16px;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </div>
      <div autocomplete="off" *ngIf="showNuevoBeneficiario">
        <div class="form-row" style="padding: 16px; padding-bottom: 0px;">
          <div class="col-12">
            <strong>
              <mat-label *ngIf="bandTarjetaCredito != 2">
                {{idiomas.DatosCuenta | uppercase}}
              </mat-label>
            </strong>
            <strong>
              <mat-label *ngIf="bandTarjetaCredito == 2">
                {{idiomas.DatosTarjeta | uppercase}}
              </mat-label>
            </strong>
          </div>
          <div *ngIf="intentoEditarlBancos && !lbancos.length > 0">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <br>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="!intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.InstFinan}}</mat-label>
              <input matInput *ngIf="beneficiarioingreso.id_banco == '2' && (infoLocalBenefQuery || autoSelectedEFI)"
                [(value)]="beneficiarioingreso.nombre_banco" [readonly]="true">
              <mat-select
                *ngIf="!(beneficiarioingreso.id_banco == '2' && !intentoCodInstOrdenante && (infoLocalBenefQuery || autoSelectedEFI))"
                (openedChange)="funcionRetornarBanco($event)" (selectionChange)="tomarValorBancos($event.value)"
                [formControl]="controlBancos">
                <input *ngIf="intentoBancos" matInput autocomplete="off"
                  (keyup)="applyFilter($event.target.value.toUpperCase())"
                  oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                  (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                  style="height: 35px;border-bottom-style: groove;">
                <mat-option *ngIf="!intentoBancos" disabled>
                  <div style="margin: 10px;">
                    <mat-progress-bar mode="indeterminate">
                    </mat-progress-bar>
                  </div>
                </mat-option>
                <mat-option *ngFor="let banco of lbancos" [(value)]="banco.id">
                  {{banco.nombre}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.InstFinan}}</mat-label>
              <input matInput *ngIf="intentoCodInstOrdenante && infoLocalBenefQuery"
                [(value)]="beneficiarioingreso.nombre_banco" [readonly]="true">
              <mat-select *ngIf="!(intentoCodInstOrdenante && (infoLocalBenefQuery || autoSelectedEFI))"
                (openedChange)="funcionRetornarBanco($event)" (selectionChange)="tomarValorBancos($event.value)"
                [formControl]="controlBancos">
                <input *ngIf="intentoBancos" matInput autocomplete="off"
                  (keyup)="applyFilter($event.target.value.toUpperCase())"
                  oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                  (keydown)="$event.stopPropagation()" placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                  style="height: 35px;border-bottom-style: groove;">
                <mat-option *ngIf="!intentoBancos" disabled>
                  <div style="margin: 10px;">
                    <mat-progress-bar mode="indeterminate">
                    </mat-progress-bar>
                  </div>
                </mat-option>
                <mat-option *ngFor="let banco of lbancos" [(value)]="banco.id">
                  {{banco.nombre}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="bandTarjetaCredito!=2 && !intentoCodInstOrdenante">
            <mat-form-field *ngIf="bandTarjetaCredito!=2" appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.TipoCuenta}}</mat-label>
              <input matInput *ngIf="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery"
                [(value)]="beneficiarioingreso.tipocuenta" [readonly]="true">
              <mat-select *ngIf="!(beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery)"
                (selectionChange)="tomarValorCuentas($event.value)" [formControl]="controlTCuenta">
                <mat-option *ngFor="let cuenta of listadocuentas" [(value)]="cuenta.id">
                  {{cuenta.nombre}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="bandTarjetaCredito!=2 && intentoCodInstOrdenante">
            <mat-form-field *ngIf="bandTarjetaCredito!=2" appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.TipoCuenta}}</mat-label>
              <input matInput *ngIf="infoLocalBenefQuery" [(value)]="beneficiarioingreso.tipocuenta" [readonly]="true">
              <mat-select *ngIf="!(intentoCodInstOrdenante && infoLocalBenefQuery)"
                (selectionChange)="tomarValorCuentas($event.value)" [formControl]="controlTCuenta">
                <mat-option *ngFor="let cuenta of listadocuentas" [(value)]="cuenta.id">
                  {{cuenta.nombre}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="!intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label *ngIf="bandTarjetaCredito != 2">{{idiomas.NumeroCuenta}}</mat-label>
              <mat-label *ngIf="bandTarjetaCredito == 2">{{idiomas.NumeroTarjeta}}</mat-label>
              <input autocomplete="off" matInput [formControl]="numcuenta" inputmode="tel"
                [readonly]="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery">
              <mat-error *ngIf="numcuenta.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="numcuenta.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
              <mat-error *ngIf="numcuenta.hasError('minlength')">
                <strong>{{idiomas.minAccountNumberLength}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label *ngIf="bandTarjetaCredito != 2">{{idiomas.NumeroCuenta}}</mat-label>
              <mat-label *ngIf="bandTarjetaCredito == 2">{{idiomas.NumeroTarjeta}}</mat-label>
              <input autocomplete="off" matInput [formControl]="numcuenta" inputmode="tel"
                [readonly]="infoLocalBenefQuery">
              <mat-error *ngIf="numcuenta.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="numcuenta.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
              <mat-error *ngIf="numcuenta.hasError('minlength')">
                <strong>{{idiomas.minAccountNumberLength}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.Moneda}}</mat-label>
              <mat-select (selectionChange)="tomarValorMonedas($event.value)" [formControl]="controlMoneda">
                <mat-option *ngFor="let moneda of lmonedas" [(value)]="moneda.cmoneda">
                  {{moneda.cmoneda}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <strong>
              <mat-label>{{idiomas.DatosBeneficiario | uppercase}}</mat-label>
            </strong>
          </div>
          <br>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="!intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.idTipo}}</mat-label>
              <input matInput *ngIf="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery"
                [(value)]="beneficiarioingreso.ctipoidentificacion" [readonly]="true">
              <mat-select *ngIf="!(beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery)"
                (selectionChange)="tomarValorTipoId($event.value)" [formControl]="controlIdTipo">
                <mat-option *ngFor="let tidentificacion of lidentificaciones" [(value)]="tidentificacion.id">
                  {{tidentificacion.descripcion}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.idTipo}}</mat-label>
              <input matInput *ngIf="infoLocalBenefQuery" [(value)]="beneficiarioingreso.ctipoidentificacion"
                [readonly]="true">
              <mat-select *ngIf="!(intentoCodInstOrdenante && infoLocalBenefQuery)"
                (selectionChange)="tomarValorTipoId($event.value)" [formControl]="controlIdTipo">
                <mat-option *ngFor="let tidentificacion of lidentificaciones" [(value)]="tidentificacion.id">
                  {{tidentificacion.descripcion}}
                  <mat-divider style="border-top-width: 2px;"></mat-divider>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="!intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.Identificacion1}}</mat-label>
              <input matInput autocomplete="off" [formControl]="identificacion" inputmode="tel"
                [readonly]="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery">
              <mat-error *ngIf="identificacion.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('minlength')">
                <strong>{{idiomas.msjMin}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('maxlength')">
                <strong>{{idiomas.msjExedeTamano}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('digitoVerificador')">
                <strong>{{idiomas.CedulaInv}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" *ngIf="intentoCodInstOrdenante">
            <mat-form-field appearance="outline" fxFlexFill>
              <mat-label>{{idiomas.Identificacion1}}</mat-label>
              <input matInput autocomplete="off" [formControl]="identificacion" inputmode="tel"
                [readonly]="infoLocalBenefQuery">
              <mat-error *ngIf="identificacion.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('minlength')">
                <strong>{{idiomas.msjMin}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('maxlength')">
                <strong>{{idiomas.msjExedeTamano}}</strong>
              </mat-error>
              <mat-error *ngIf="identificacion.hasError('digitoVerificador')">
                <strong>{{idiomas.CedulaInv}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div
          *ngIf="beneficiarioingreso.id_banco != '2' && continueProcess && !intentoCodInstOrdenante  || (beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery) || !internet.internet"
          style="width: 100%; padding-left: 16px; padding-right: 16px; padding-bottom: 16px;" class="form-row">
          <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.Nombres}}</mat-label>
              <input matInput autocomplete="off" [formControl]="nombre" #input style="text-transform: uppercase"
                [readonly]="beneficiarioingreso.id_banco == '2' && infoLocalBenefQuery">
              <mat-error *ngIf="nombre.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="nombre.hasError('pattern')">
                <strong>{{idiomas.msjSoloTexto}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.Telefono}}</mat-label>
              <input matInput autocomplete="off" [formControl]="telefono" inputmode="tel">
              <mat-error *ngIf="telefono.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.DireccionEMail}}</mat-label>
              <input matInput autocomplete="off" [formControl]="email" inputmode="email">
              <mat-error *ngIf="email.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="email.hasError('pattern')">
                <strong>{{idiomas.msjSoloEmail}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="!pinValidation && internet.internet" lass="col-12">
            <div autocomplete="off" class="form-row" (keyup.enter)="ProcesarIngreso(1, 0)">
              <div *ngIf="internet.internet" class="left col-6 token-button" style="padding-bottom: 10px !important;">
                <button style="margin-top: 0 !important;"
                  [disabled]="(!numcuenta.valid) || (!identificacion.valid) || (!nombre.valid && !nombreBanred.valid)|| (!telefono.valid) || (!email.valid) || (enableCronoIn)"
                  mat-raised-button color="primary" class="buttonToken" (click)="SolicitarToken()">
                  <mat-icon>security</mat-icon>
                  {{idiomas.SolicitarToken}}
                </button>
              </div>
              <div *ngIf="mostrarCron" class="col-6">
                <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                  [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
              </div>
              <mat-form-field *ngIf="ingtoken" class="col-2 token" [class.col-12]="bandMobile" appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input id="soliToken" matInput type="text" name="soliToken" autocomplete="off"
                  onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                  inputmode="numeric" appBlockCopyPaste>
                <mat-error *ngIf="token.hasError('required')">
                  <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('pattern')">
                  <strong>{{idiomas.msjSoloNumeros}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('minlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('maxlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="!ingtoken" class="col-2 token" [class.col-12]="bandMobile" appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input matInput autocomplete="off" type="text" disabled appBlockCopyPaste>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div
          *ngIf="beneficiarioingreso.id_banco != '2' && continueProcess && intentoCodInstOrdenante && infoLocalBenefQuery || !internet.internet"
          style="width: 100%; padding-left: 16px; padding-right: 16px; padding-bottom: 16px;" class="form-row">
          <div class="col-6" [class.col-12]="bandMobile">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.Nombres}}</mat-label>
              <input matInput autocomplete="off" [formControl]="nombreBanred" #input
                (input)="input.value=$event.target.value.toUpperCase()" [readonly]="infoLocalBenefQuery">
              <mat-error *ngIf="nombre.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="nombre.hasError('pattern')">
                <strong>{{idiomas.msjSoloTexto}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.Telefono}}</mat-label>
              <input matInput autocomplete="off" [formControl]="telefono" inputmode="tel">
              <mat-error *ngIf="telefono.hasError('pattern')">
                <strong>{{idiomas.msjSoloNumeros}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" [class.col-12]="bandMobile">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{idiomas.DireccionEMail}}</mat-label>
              <input matInput autocomplete="off" [formControl]="email">
              <mat-error *ngIf="email.hasError('required')">
                <strong>{{idiomas.ValidarCampo}}</strong>
              </mat-error>
              <mat-error *ngIf="email.hasError('pattern')">
                <strong>{{idiomas.msjSoloEmail}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="!pinValidation && internet.internet" lass="col-12">
            <div autocomplete="off" class="form-row" (keyup.enter)="ProcesarIngreso(1, 0)">
              <div *ngIf="internet.internet" class="left col-6 token-button" style="padding-bottom: 10px !important;">
                <button style="margin-top: 0 !important;"
                  [disabled]="(!numcuenta.valid) || (!identificacion.valid) || (!nombre.valid && !nombreBanred.valid) || (!telefono.valid) || (!email.valid) || (enableCronoIn)"
                  mat-raised-button color="primary" class="buttonToken" (click)="SolicitarToken()">
                  <mat-icon>security</mat-icon>
                  {{idiomas.SolicitarToken}}
                </button>
              </div>
              <div *ngIf="mostrarCron" class="col-6">
                <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                  [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
              </div>
              <mat-form-field *ngIf="ingtoken" class="col-2 token" [class.col-12]="bandMobile" appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input id="soliToken" matInput type="text" name="soliToken" autocomplete="off"
                  onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                  inputmode="numeric" appBlockCopyPaste>
                <mat-error *ngIf="token.hasError('required')">
                  <strong>{{idiomas.ValidarCampo}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('pattern')">
                  <strong>{{idiomas.msjSoloNumeros}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('minlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
                <mat-error *ngIf="token.hasError('maxlength')">
                  <strong>{{idiomas.msjOtpminmax}}</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="!ingtoken" class="col-2 token" [class.col-12]="bandMobile" appearance="outline">
                <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                <input matInput autocomplete="off" type="text" disabled appBlockCopyPaste>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="intentop" style="padding-top: 16px; padding-bottom: 16px;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
        <div id="floatCont" class="col-6">
          <button
            *ngIf="pinValidation && !pinValid && continueProcess && (beneficiarioingreso.id_banco != '2' || infoLocalBenefQuery) && internet.internet && showNuevoBeneficiario"
            [disabled]="disableButton || numcuenta.invalid || identificacion.invalid || (nombre.invalid && nombreBanred.invalid) || telefono.invalid || email.invalid"
            mat-raised-button color="primary" class="buttonSubmitTransfer" (click)="solicitarAutorizacion()"
            style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
            {{idiomas.IngresarPinLog | uppercase}}
          </button>
          <button mat-raised-button (click)="ProcesarIngreso(1, 0)" title={{idiomas.Guardar}}
            class="buttonSubmitTransfer"
            *ngIf="(pinValid || token.valid)  && internet.internet && continueProcess && showNuevoBeneficiario && !intentoEditar && !bandTransfer"
            color="primary" [disabled]="disableButton"
            style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
            {{idiomas.Guardar | uppercase}}
          </button>
          <button mat-raised-button (click)="editarRegistro(0)" title={{idiomas.Editar}} class="buttonSubmitTransfer"
            *ngIf="(pinValid || token.valid)  && internet.internet && continueProcess && showNuevoBeneficiario && intentoEditar && !bandTransfer"
            color="primary" [disabled]="disableButton"
            style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
            {{idiomas.Guardar | uppercase}}
          </button>
          <button mat-raised-button (click)="ProcesarIngreso(1, 0)" title={{idiomas.Guardar}}
            class="buttonSubmitTransfer" *ngIf="!internet.internet && showNuevoBeneficiario && continueProcess && numcuenta.valid && identificacion.valid
           && (nombre.valid || nombreBanred.valid) && telefono.valid && email.valid && !intentoEditar" color="primary"
            style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
            {{idiomas.Guardar | uppercase}}
          </button>
          <button mat-raised-button (click)="editarRegistro(0)" title={{idiomas.Editar}} class="buttonSubmitTransfer"
            *ngIf="!internet.internet && showNuevoBeneficiario && continueProcess && numcuenta.valid && identificacion.valid
            && (nombre.valid || nombreBanred.valid) && telefono.valid && email.valid && intentoEditar" color="primary"
            style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
            {{idiomas.Guardar | uppercase}}
          </button>
          <button mat-raised-button color="primary" (click)="consultarUserInformation()" title={{idiomas.Consultar}}
            class="buttonSubmitTransfer"
            *ngIf="beneficiarioingreso.id_banco == '2' && !continueProcess && !infoLocalBenefQuery && showNuevoBeneficiario"
            [disabled]="numcuenta.invalid || identificacion.invalid"
            style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
            {{idiomas.Consultar | uppercase}}
          </button>
          <button mat-raised-button color="primary" (click)="consultarUserInformationPagoDirecto()"
            class="buttonSubmitTransfer"
            *ngIf=" bandTarjetaCredito != 2 && intentoCodInstOrdenante && !continueProcess && !infoLocalBenefQuery"
            [disabled]="!controlTCuenta.valid || !numcuenta.valid || !controlIdTipo.valid || !identificacion.valid">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
          <button mat-raised-button color="primary" (click)="consultarUserInformationPagoDirecto()"
            class="buttonSubmitTransfer"
            *ngIf="bandTarjetaCredito == 2 && intentoCodInstOrdenante && !continueProcess && !infoLocalBenefQuery"
            [disabled]="!numcuenta.valid || !controlIdTipo.valid || !identificacion.valid">
            <mat-icon>search</mat-icon>
            {{idiomas.Consultar}}
          </button>
          <button mat-raised-button color="primary" (click)="continueRegister()" class="buttonSubmit"
            *ngIf="intentoCodInstOrdenante && continueRegisterProcess && !infoLocalBenefQuery">
            <mat-icon>download_done</mat-icon>
            {{idiomas.Continuar}}
          </button>
          <button *ngIf="internet.internet && bandTransfer && cuenta !='' && bandTarjetaCredito !=2" mat-raised-button
            class="buttonSubmitTransfer" (click)="RealizarTransferencia()" title={{idiomas.RealizarTransferencia}}
            color="primary" style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
            <mat-icon style="padding-right: 30px">compare_arrows</mat-icon>
            {{idiomas.TransferirMobMen | uppercase}}
          </button>
          <button *ngIf="internet.internet && bandTransfer && cuenta !='' && bandTarjetaCredito ==2" mat-raised-button
            class="buttonSubmitTransfer" (click)="RealizarTransferencia()" title={{idiomas.RealizarPagoTarjeta}}
            color="primary" style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
            <mat-icon style="padding-right: 30px">credit_card</mat-icon>{{idiomas.TransferirMobMen | uppercase}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="bandsFuncion.transCtsProp">
    <app-transferencias-cuentas-propias #transCtsProp [mediano]="mediano" [grande]="grande" [normal]="normal"
      [idiomas]="idiomas" [dataTarPend]="dataTarPend"></app-transferencias-cuentas-propias>
  </div>
  <div *ngIf="bandsFuncion.transMismaInst">
    <app-transferencias-misma-institutcion #transMismaInst (evtNuevoBenef)="nuevoBeneficiario($event)"
      [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend">
    </app-transferencias-misma-institutcion>
  </div>
  <div *ngIf="bandsFuncion.transOtraInst">
    <app-transferencias #transOtraInst (evtNuevoBenef)="nuevoBeneficiario($event)" [mediano]="mediano" [grande]="grande"
      [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend"></app-transferencias>
  </div>
  <div *ngIf="bandsFuncion.transTarCred">
    <app-transferencia-tarjetas-credito #transTarCred (evtNuevoBenef)="nuevoBeneficiario($event)" [mediano]="mediano"
      [grande]="grande" [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend">
    </app-transferencia-tarjetas-credito>
  </div>
  <div
    *ngIf="!bandMobile && (!showNuevoBeneficiario && bandsFuncion.transCtsProp || bandsFuncion.transMismaInst || bandsFuncion.transOtraInst || bandsFuncion.transTarCred || bandsFuncion.transTarCred)"
    class="float-transacciones">
    <button mat-fab (click)="habilitarFunciones('beneficiarios',true)" title={{idiomas.Regresar}} class="float-right"
      color="primary">
      <mat-icon class="white">arrow_back</mat-icon>
    </button>
  </div>
  <div class="float-transacciones" *ngIf="showNuevoBeneficiario && !bandMobile">
    <button mat-fab class=" btn btnGrafico" title={{idiomas.Regresar}} color="primary" class="float-right"
      (click)="return(false)">
      <mat-icon class="white">arrow_back</mat-icon>
    </button>
  </div>
</div>