<div class="textura">
    <div style="text-align: right;">
        <button class="btn btnGraficoDialogo" color="primary" (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <h2 *ngIf="intentoConfirmacion" mat-dialog-title style="text-align: center;">{{idiomas.DialogoParaEnviarNotificacionAlCorreo}}</h2>
    <h2 *ngIf="intentoFelicitacion" mat-dialog-title style="text-align: center;">{{idiomas.Felicitaciones}}</h2>
    <a *ngIf="intentoFelicitacion" style="font-size: 12px !important;">{{idiomas.MensajeConfirmacionRat}}</a>
    <mat-dialog-content>
        <div *ngIf="intentoFelicitacion" style="text-align: center;">
            <img src="assets/resources/mipmap-hdpi/felicitacion.png" class="imagen3" />
        </div>
        <div *ngIf="intentoConfirmacion" style="text-align: center;">
            <img src="assets/resources/mipmap-hdpi/felicitacion.png" class="imagen3" />
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="intentoConfirmacion">
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{aceptar}}</button>
        </div>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="intentoFelicitacion">
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="accept()">
                <mat-icon>download_done</mat-icon>{{aceptar}}</button>
        </div>
    </mat-dialog-actions>
</div>