import { Component, OnInit, DoCheck, EventEmitter, Output, Renderer2, HostListener } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { Servicios } from '../funciones/encryptar';
import { Alerta } from '../funciones/alerta';
import { Observable } from 'rxjs';
import { Idioma } from '../funciones/idioma';
import { BaseDatos } from '../funciones/basededatos';
import { Consulta } from '../funciones/consulta';
import { internetComponent } from '../funciones/internet'
import { FormControlValidators } from '../funciones/formcontrol';
import { DomSanitizer } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';

//Soporte para FIDO
import { User } from '../interfaces/user';
import { ServerMockService } from '../services/server-mock.service';
import { WebAuthnService } from '../services/web-authn.service';
import { NotificationService } from '../services/notification.service';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AlertasLoginComponent } from '../alertas-login/alertas-login.component';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HomeComponent } from '../home/home.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AgenciasMapaComponent } from '../agencias-mapa/agencias-mapa.component';
import { CajerosMapaComponent } from '../cajeros-mapa/cajeros-mapa.component';

//Amazon Liveness__
import awsconfig from './../../aws-exports';
import { Amplify } from 'aws-amplify';
import { SignInInput, signIn, signOut } from 'aws-amplify/auth';
import { FaceLivenessComponent } from '../face-liveness/face-liveness.component';

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, RouterModule, MatInputModule, CarouselModule, MatToolbarModule, MatButtonModule, MatProgressBarModule, MatDividerModule, MatTableModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule, AlertasLoginComponent, TemporizadorComponent, HomeComponent, MatProgressSpinnerModule, ZXingScannerModule, CajerosMapaComponent, AgenciasMapaComponent, FaceLivenessComponent],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements DoCheck, OnInit {
  @Output() evtRegresoDatos = new EventEmitter<any>();

  online$: Observable<boolean>;
  usuario: string;
  usuarioCredntialBiometric: string;
  activado: boolean;
  contrasena: string;
  contrasenaNewPassQue: string;
  intento: boolean;
  offline: number;
  idiomas: any;
  desnewUser: boolean;
  bandsFuncion: any;
  bandsFuncionLogin: any;
  newUsernameMigratedUser: boolean;
  reminderUser: boolean;
  desnewPassword: boolean;
  desLogin: boolean;
  desnext: boolean;
  uservalid: boolean;
  user: string;
  passwordvalid: boolean;
  grupo: any[];
  numImages: number;
  randomsArrayNums: number[];
  firstTimeLogin: boolean;
  cont: number;
  aux: number[];
  selectImageForm: boolean;
  userImage: number;
  error: boolean;
  alertalogin: boolean;
  verifyMesgInfo: boolean;
  msjerror: string;
  msjalert: string;
  borderImg: boolean[];
  userExist: boolean;
  information: boolean;
  msjinformation: string;
  otpEnterForm: boolean;
  bodyForOTP: any;
  imgQButton: boolean;
  QButton: boolean;
  imagenes: any[];
  preguntas: any[];
  preguntas1: any[];
  preguntas2: any[];
  imgSelected: number;
  newPassForm: boolean;
  newPassFormFirst: boolean;
  errorOffline: boolean;
  preguntasaux: any[];
  nImgAndQ: boolean;
  mensajeContra: string = null;
  bandusuario: boolean;
  loginInfo: any;
  bandUnlockUser: any;
  unlockUserForm: boolean;
  msjerrorOffline: any;
  logoTemaOscuro: boolean;
  haveStore: boolean;
  pinValidation: boolean;
  verifyContractIdType: string;
  verifyContractId: string;
  verifyContractCel: string;
  verifyContractEmail: string;
  verifyContract: boolean;
  saveUser: string;
  userAccept: string;
  isPasswordExpired: boolean;
  mensajePasswordExpirado: string = "";
  isUserValid: boolean;
  enableCronoIn: boolean = true;
  hide: boolean[];
  bandMobile: boolean;
  users: User[];
  useFingerprint = true;
  isAndroidDevice: boolean;
  showAlert: boolean;
  hasAcceptDialogBiometric: any = "1";
  hasBiometricRegister: boolean = false;
  hasBiometricRegisterForDialog: boolean = false;
  hasBiometricPhone: boolean = false;
  hasBiometricCredentials: boolean = false;
  intentoLoginWBiometricCredentials: boolean = false;
  disableButton: boolean = false;
  disablePin: boolean = false;
  hasDeviceLinked: boolean = false;
  bandAuth: boolean = false;
  isUserEnt: boolean = false;
  intentoInicio: boolean = false;
  hasSoftToken: boolean = false;
  hasLivenessRecognition: boolean = false;
  spinnerDevice: boolean = false;
  deviceUnLinked: boolean = false;
  activeSofttoken: boolean = false;
  bodyForPIN: any = {};
  isLegalPerson: boolean = false;
  scannQr: boolean = false;
  unlinkDevice: boolean = false;
  pinEnterForm: boolean = false;
  solicitudForm: boolean = false;
  spinner: boolean = false;
  deviceLinkedForm: boolean = false;
  deviceLinked: boolean = false;
  valid = false;
  invalid = false;
  readText: string = "";
  deviceName: string = "";
  phoneNumber: string = "";
  dateRegister: string = "";
  cuser: string = "";
  validPin: boolean = false;
  digits: any[] = [
    { id: 1, field: this.validators.numberForm() },
    { id: 2, field: this.validators.numberForm() },
    { id: 3, field: this.validators.numberForm() },
    { id: 4, field: this.validators.numberForm() },
    { id: 5, field: this.validators.numberForm() },
    { id: 6, field: this.validators.numberForm() },
  ];
  bandStandlone: boolean = false;
  nombreDispositivo: any;
  numeroTelefono: any;
  datosAdicionales: any;
  fechaRegistro: any;
  fechaVinculacion: any;
  desvincularForm: boolean = false;

  //QR Code funcionalidad
  showButtonQR: boolean = false;
  openQR: boolean = false;
  data: string;

  //Se usa entre login y next step para indicar que debo pedir huella o no
  requestFingerprint = false;

  //Indicar si deseamos precargar imagen y solicitar fingerprint automaticamente en los futuros login
  isDetectFingerprintAtLogin = false;

  //Indica si esta disponible la autenticacion FIDO desde el navegador que estemos
  webAuthnAvailable = !!navigator.credentials && !!navigator.credentials.create;

  //Indica si el usuario ha escogido autenticarse por password (aun teniendo una huella registrada)
  isPasswordAuthRequested = false;

  //Indica si debemos mostrar las huellas registradas en el dispositivo, para poder borrarlas (para TEST unicamente)
  showFidoCredentialsAtLogin = false;

  //Marketing Images Carousel
  marketingSlides: any[] = [];

  isClientPrest: boolean = false;
  bandIos: boolean;
  bandAndroidIosInstalled: boolean;
  deferredPrompt: any;

  //ngx-carousel options
  customCarouselOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    autoplayTimeout: 6500,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false,
  }

  //ngx-carousel options
  customCarouselMenu: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 9500,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      640: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false,
  }

  //Face liveness
  faceLivenessForm: boolean = false;
  instructionsForm: boolean = false;
  isSignedIn: boolean = false;
  intentoSpinner: boolean = false;

  //Evento que verificar si ha sido intalado el app en android
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(event: any) {
    event.preventDefault();
    this.deferredPrompt = event
    if (localStorage.getItem('AppInstalled') != "1") {
      localStorage.setItem("AppInstalled", "0");
    }
  }

  constructor(
    private _DomSanitizer: DomSanitizer,
    public base: BaseDatos,
    public apiService: ApiService,
    private overlay: OverlayContainer,
    public servicios: Servicios,
    public internetC: internetComponent,
    private router: Router,
    private routeParams: ActivatedRoute,
    private alerta: Alerta,
    private idioma: Idioma,
    private consulta: Consulta,
    private validators: FormControlValidators,
    private serverMockService: ServerMockService,
    private webAuthnService: WebAuthnService,
    private notificationService: NotificationService,
    private renderer: Renderer2) {
    this.bandMobile = false;
    this.intento = false;
    this.activado = false;
    this.idioma.getIdioma();
    this.idiomas = JSON.parse(localStorage.getItem('idioma'));
    this.desnewUser = false;
    this.newUsernameMigratedUser = false;
    this.reminderUser = false;
    this.desnewPassword = false;
    this.desnext = false;
    this.desLogin = true;
    this.uservalid = false;
    this.passwordvalid = false;
    this.grupo = [];
    this.aux = [];
    this.randomsArrayNums = [];
    this.numImages = 8; //Numero de Imagenes a mostrar
    this.firstTimeLogin = false;
    this.cont = 0;
    this.selectImageForm = false;
    this.userImage = 0;
    this.error = false;
    this.alertalogin = false;
    this.verifyMesgInfo = false;
    this.msjalert = '';
    this.msjerror = '';
    this.msjerrorOffline = '';
    this.borderImg = [];
    this.information = false;
    this.userExist = false;
    this.msjinformation = '';
    this.otpEnterForm = false;
    this.imgQButton = false;
    this.QButton = false;
    this.imagenes = [];
    this.preguntas = [];
    this.preguntas1 = [];
    this.preguntas2 = [];
    this.preguntasaux = [];
    this.hide = [];
    this.errorOffline = false;
    this.nImgAndQ = false;
    this.bandusuario = false;
    this.logoTemaOscuro = true;
    this.bandUnlockUser = false;
    this.haveStore = false;
    this.pinValidation = false;
    this.verifyContractIdType = "";
    this.verifyContractId = "";
    this.verifyContractCel = "";
    this.verifyContractEmail = "";
    this.verifyContract = false;
    this.saveUser = '';
    this.userAccept = '';
    this.isPasswordExpired = false;
    this.faceLivenessForm = false;
    this.instructionsForm = false;
    this.intentoSpinner = false;
    this.isUserValid = false;
    this.showAlert = false;
    this.loginInfo = { nombreUsuario: "", lastLogin: "", nombrePreferido: "", email: "", cuenta: "" }
    for (var i = 0; i < 5; i++) {
      this.preguntasForm[i] = this.validators.comboForm();
    }
    for (var i = 0; i < 3; i++) {
      this.preguntasForm1[i] = this.validators.comboForm();
    }
    for (var i = 0; i < 3; i++) {
      this.preguntasForm2[i] = this.validators.comboForm();
    }
    for (var i = 0; i < 5; i++) {
      this.hide[i] = true
    }
    this.imgSelected = 0;
    this.newPassForm = false;
    this.newPassFormFirst = false;
    //Control de temas
    let temasDisponibles = ["night-theme", "light-theme", "light-theme-verde", "light-theme-azul"]
    let temaSeleccionado = localStorage.getItem('themeDisplayed');
    if (!temaSeleccionado) //Cuando no se encuentre tema en localStorage
    {
      for (let temeIndex = 0; temeIndex < temasDisponibles.length; temeIndex++) {
        if (this.overlay.getContainerElement().classList.contains(temasDisponibles[temeIndex])) {
          for (let indexClassList = 0; indexClassList < this.overlay.getContainerElement().classList.length; indexClassList++) {
            if (temasDisponibles[temeIndex] == this.overlay.getContainerElement().classList.item(indexClassList)) {
              temaSeleccionado = this.overlay.getContainerElement().classList.item(indexClassList)
              break
            }
          }
          localStorage.setItem('themeDisplayed', temaSeleccionado)
          break;
        }
      }
    }
    if (temaSeleccionado != "night-theme") {
      this.logoTemaOscuro = false;
    }
    //.
    this.marketingSlides = [];
    this.enableCronoIn = true;

    //Soporte FIDO
    this.users = serverMockService.getUsers();
    this.isAndroidDevice = this.serverMockService.isAndroid();
    this.hasBiometricCredentials = localStorage.getItem('users') != null ? true : false;

    this.bandsFuncionLogin = {
      '0': true,
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
      '6': false,
      '7': false
    }

    this.bandsFuncion = {
      'login': true,
      'home': false,
    };
  }

  ngOnInit() {
    setTimeout(function () { let element = document.getElementById("userID"); element && element.focus(); }, 1);
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }

    //Verifica si el dispositivo que esta siendo utilizado es de apple
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /ipad|iphone|ipod/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    this.bandStandlone = this.isStandaloneApp()
    //this.bandStandlone = true
    this.bandIos = isIos();
    this.bandMobile = isMobile();
    this.intentoInicio = isMobile();
    this.spinnerDevice = isMobile();

    //Prototipo, indicar si queremos que FIDO autocomplete el usuario para futuros login
    if (this.users.length > 0 && this.isDetectFingerprintAtLogin) {
      //Establecer el codigo de usuario
      this.usernameFormControl.setValue(this.users[0].username);

      //Informar que luego de obtener la imagen, debe pedir la autenticacion biometrica automaticamente
      this.requestFingerprint = true;

      //Lanzar siguiente paso para mostrar imagen
      this.nextStep(false);
    }

    // if (this.hasBiometricCredentials) {
    //   this.webAuthVerifyUserPassw();
    // }

    this.hasDeviceLinked = localStorage.getItem('deviceLinked') !== null ? true : false;
    this.hasAcceptDialogBiometric = localStorage.getItem('hasAcceptDialogBiometric') ? this.servicios.desencriptar(localStorage.getItem('hasAcceptDialogBiometric')) : this.hasAcceptDialogBiometric;
    this.hasBiometricCredentials = localStorage.getItem('users') != null ? true : false;

    if (this.hasDeviceLinked && this.bandMobile) {
      this.getSolicitudesMob()
    } else {
      this.spinnerDevice = false
      this.deviceUnLinked = false
    }

    this.routeParams.paramMap.subscribe(params => {
      this.verifyContractIdType = params.get('idType');
      this.verifyContractId = params.get('id');
      if (this.verifyContractIdType != null && this.verifyContractId != null) {
        this.verifyContract = true;
      }
      else {
        this.verifyContractIdType = "";
        this.verifyContractId = "";
      }
    });
    this.saveUser = localStorage.getItem('userSave') ? this.servicios.desencriptar(localStorage.getItem('userSave')) : this.saveUser;
    this.userAccept = localStorage.getItem('userAccept') ? this.servicios.desencriptar(localStorage.getItem('userAccept')) : this.userAccept;
    if (this.userAccept && this.saveUser && this.saveUser != '' && this.userAccept == '1' && !this.bandMobile) {
      this.usernameFormControl.setValue(this.saveUser);
    }
    this.bandsFuncion = {
      'login': true,
      'home': false,
    };
    this.hasSoftToken = true
    this.hasLivenessRecognition = true;
  }

  isStandaloneApp() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  ngAfterViewInit() {
    if (this.verifyContract) {
      let userverifyContractData = { verifyContract: true, tipoDocUsrMig: this.verifyContractIdType, idUsrMig: this.verifyContractId, UsrCel: this.verifyContractCel, UsrEmail: this.verifyContractEmail }
      Promise.resolve(null).then(() => this.newUser(userverifyContractData));
    }
    this.bandsFuncion = {
      'login': true,
      'home': false,
    };
    //Verifica si esta utilizando el app despues de intalarlo como un app
    this.bandAndroidIosInstalled = this.getPWADisplayMode();

    //Si se abre la aplicacion instalada se guardar una variable en 1 para saber q esta instalado
    if (this.bandMobile && this.bandAndroidIosInstalled) {
      localStorage.setItem("AppInstalled", "1");
    }

    if (localStorage.getItem("clienteQRDataJSON")) {
      Promise.resolve(null).then(() => this.showButtonQR = true);
    }
  }

  getPWADisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return true;
    } else if ((window as any).navigator.standalone || isStandalone) {
      return true;
    }
    return false;
  }

  ngDoCheck() {
    this.usuario = !this.usernameFormControl.value ? this.usuario : this.usernameFormControl.value;
    this.contrasena = !this.passwordFormControl.value ? this.contrasena : this.passwordFormControl.value;
    if (this.usuario && this.contrasena) {
      this.activado = true;
    }
  }

  idForm = this.validators.idForm();
  idTipo = this.validators.comboForm();
  userForm = this.validators.userForm();
  userForm1 = this.validators.newUserForm();
  usernameFormControl = this.validators.userMigratedForm();
  passwordFormControl = this.validators.passwordForm();
  usernameFormControl1 = this.validators.userMigratedForm();
  passwordFormControl1 = this.validators.passwordForm();
  otpForm = this.validators.otpForm();
  preguntasForm: UntypedFormControl[] = [];
  preguntasForm1: UntypedFormControl[] = [];
  preguntasForm2: UntypedFormControl[] = [];
  passForm = this.validators.passwordForm();
  passConfirmForm = this.validators.passwordForm();

  login() {
    this.enableCronoIn = true;
    this.nImgAndQ = false
    this.borderImg = []
    this.imagenes = []
    this.preguntas2 = []

    //Si ya se pre-asigno el usuario en otro proceso, respetar ese valor
    if (!this.usuario) {
      this.usuario = this.usernameFormControl.value;
    }

    //Si ya se pre-asigno el password en otro proceso, respetar ese valor
    if (!this.contrasena) {
      this.contrasena = this.passwordFormControl.value;
    }

    this.intento = true;
    this.disableButton = true;
    let reqJSON = {
      usuario: this.usuario,
      contrasena: this.servicios.toAES(this.contrasena),
      desencriptar: "1",
      isMobile: this.bandMobile ? "1" : "0"
    }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/oauth').then((data: any) => {
      this.error = false;
      if (data) {
        localStorage.setItem('accountingDate', this.servicios.encriptar(data.accountingDate));
        localStorage.setItem('sessionId', this.servicios.encriptar(data.sessionId));
        localStorage.setItem('usuarioLogin', this.servicios.encriptar(data.usuarioLogin));
        this.idioma.getIdioma();
        if (data.case == 0) {
          for (let i in this.preguntasForm) {
            this.preguntasForm[i].reset()
          }
          for (let i in this.preguntasForm1) {
            this.preguntasForm1[i].reset()
          }
          for (let i in this.preguntasForm2) {
            this.preguntasForm2[i].reset()
          }
          this.nImgAndQ = true
          this.selectImageForm = true
          this.firstTimeLogin = false
          this.alertalogin = false
          this.desLogin = false
          this.showAlert = data.showalert
          this.imagenes = data.imagesArray
          this.preguntas = data.pregArray
        } else if (data.case == 1) {
          this.selectImageForm = false
          this.otpEnterForm = true
          this.pinEnterForm = false
          this.solicitudForm = false
          this.desnext = false
          this.desLogin = false
          this.loginInfo.lastLogin = data.previousLogin
          this.loginInfo.nombrePreferido = data.nombrePreferido
          this.loginInfo.email = data.email
          this.loginInfo.cuenta = data.cuenta
          this.loginInfo.usuario = this.usuario
          this.bodyForOTP = data
          this.haveStore = data.tienda
          this.pinValidation = data.pinValidation
          this.isUserValid = data.isUserValid
          this.isLegalPerson = data.isLegalPerson
          this.base.setInfoUser(data.CIO, data.SUC, data.OFC); //Almacena Compañia - Sucursal y Oficina de Usuario
          setTimeout(function () { document.getElementById("userOTP").focus(); }, 1);
        } else if (data.case == 10) {
          this.newPassForm = true
          this.alertalogin = false
          this.desLogin = false
          this.preguntas1 = data.pregArray
        } else if (data.case == 11) {
          this.bandUnlockUser = true
          this.error = true
          this.msjerror = data.message
          this.preguntas2 = data.pregArray
        } else if (data.case == 12) {
          this.intentoInicio = false
          this.selectImageForm = false
          this.otpEnterForm = false
          this.desnext = false
          this.desLogin = false
          this.loginInfo.lastLogin = data.previousLogin
          this.loginInfo.nombrePreferido = data.nombrePreferido
          this.loginInfo.email = data.email
          this.loginInfo.cuenta = data.cuenta
          this.loginInfo.usuario = this.usuario
          this.loginInfo.personId = data.personId
          this.bodyForPIN = data
          this.haveStore = data.tienda
          this.pinValidation = data.pinValidation
          this.isLegalPerson = data.isLegalPerson
          this.isUserValid = data.isUserValid
          this.base.setInfoUser(data.CIO, data.SUC, data.OFC); //Almacena Compañia - Sucursal y Oficina de Usuario
          if (this.bandMobile) {
            this.pinEnterForm = this.hasDeviceLinked ? true : false
            if (this.pinEnterForm) {
              setTimeout(() => { this.renderer.selectRootElement('#myInput0').focus() }, 1)
            } else if (!this.pinEnterForm) {
              this.consultarTieneDispositivoSoftoken()
            }
          } else {
            this.solicitudForm = true
            this.getSolicitudes()
          }
          this.isUserEnt = true
        } else {
          this.newPassFormFirst = true
          this.alertalogin = false
          this.desLogin = false
          this.isPasswordExpired = data.isPasswordExpired
          this.obtenerValidacionesPassword()
        }
      }
      this.intento = false
      this.disableButton = false
      this.spinnerDevice = false
      if (this.newPassForm) {
        this.obtenerValidacionesPassword()
      }
    }, (err) => {
      this.spinnerDevice = false
      this.nImgAndQ = false
      this.disableButton = false
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet , -1 cuando timeOut
        this.intento = false
        this.otpEnterForm = false
        this.msjerror = err.error.mensajeUsuario;
        this.error = true
      }
      else {
        this.offline = this.base.UsuarioOffline(this.usuario, this.contrasena);
        this.intento = true;
        if (this.offline == 1) { //Ingreso en Modo Offline si es que usuario ha ingresado previamente sesion y su informacion se encuentra almacenada 
          this.alerta.presentarAlerta(this.idiomas.LoginOff);
          localStorage.setItem('ingresado', this.servicios.encriptar('ingresado'))
          localStorage.setItem("offline", this.servicios.encriptar("true"))
          this.base.insertarConsulta('showAlert', this.showAlert)
          this.idioma.getIdioma();
          this.errorOffline = false;
          this.router.navigate(['/home']);
        } else {
          if (this.offline == 0) { //Usuario Offline OK! - Pwws Mal!
            this.intento = false
            this.errorOffline = true
            this.msjerrorOffline = this.idiomas.PassMal
          } else {
            if (this.offline == -2 || this.offline == -3) { //Modo Offline Bloqueado
              this.intento = false
              this.errorOffline = true
              this.msjerrorOffline = this.idiomas.PassMal
            } else {  //Ingreso restringido a usuarios que no han iniciado sesion previamente y su informacion no se encuentra almacenada
              this.intento = false
              this.errorOffline = true
              this.msjerrorOffline = this.idiomas.PassMal
            }
          }
        }
      }
    })
  }

  consultarTieneDispositivoSoftoken() {
    let envio = {
      usuario: this.usuario
    };
    this.apiService.noTokenProviderInternetCheck(envio, '/consultaSoftokenactivo').then((data: any) => {
      if (data) {
        this.desvincularForm = true
        this.nombreDispositivo = data[0]['nombreDispositivo'];
        this.numeroTelefono = data[0]['numeroTelefono'];
        this.fechaRegistro = data[0]['fregistro'];
        this.fechaVinculacion = data[0]['fvinculacion'];
      }
    },
      err => {
        this.intento = false;
        if (err.status != 0 && err.status != 504 && err.status != -1) {
          //Con Internet
          this.intento = false;
          if (err.error) {
            if (
              err.error.mensaje == "Error de autenticación via token JWT."
            ) {
              //this.logout();
            }
          } else {
            this.alerta.presentarAlerta(this.idiomas.ServidorError);
          }
        }
      }
    );
  }

  hideInterval() {
    setInterval(() => {
      for (var i = 0; i < 5; i++) {
        this.hide[i] = true
      }
    }, 5000);
  }

  newUser(migratedUserData) {
    this.desnewUser = true
    this.desLogin = false
    this.alertalogin = false
    this.error = false
    this.information = false
    if (migratedUserData != null) {
      if (("reminder" in migratedUserData) && migratedUserData['reminder'] == true) {
        this.reminderUser = true;
      }
      else {
        this.newUsernameMigratedUser = ("verifyContract" in migratedUserData) && migratedUserData['verifyContract'] == true ? false : true;
        Promise.resolve(null).then(() => this.setValuesOfNewUserForm(migratedUserData));
      }
    }
  }

  setValuesOfNewUserForm(migratedUserData) {
    this.idTipo.setValue(migratedUserData.tipoDocUsrMig)
    this.idForm.setValue(migratedUserData.idUsrMig);
    this.sendIdentification();
  }

  changePassword() {
    this.error = false
    this.information = false
    this.alertalogin = false
    this.desnewPassword = true
    this.bandusuario = false
    this.desLogin = false
    setTimeout(function () { document.getElementById("userID").focus(); }, 1);
  }

  return() {
    if (this.verifyContract) { //Si se envio identificacion despues de /verifyContract al regresar se vulve a /
      this.router.navigate(['']);
    }
    if (this.deviceLinked) {
      this.activeSofttoken = true;
      this.getSolicitudesMob();
    }
    if (this.hasDeviceLinked && this.bandMobile && this.desnewPassword) {
      this.getSolicitudesMob();
    }
    this.deviceUnLinked = false;
    this.spinner = false;
    this.scannQr = false;
    this.openQR = false;
    this.unlinkDevice = false;
    this.pinEnterForm = false;
    this.solicitudForm = false;
    this.desvincularForm = false;
    this.faceLivenessForm = false;
    this.instructionsForm = false;
    this.bandAuth = false;
    this.deviceLinkedForm = false;
    this.deviceLinked = false;
    this.deviceUnLinked = false;
    this.readText = "";
    this.valid = false;
    this.cuser = "";
    this.deviceName = "";
    this.phoneNumber = "";
    this.dateRegister = "";
    this.isUserEnt = false;
    this.intentoInicio = true;
    this.hasDeviceLinked = localStorage.getItem('deviceLinked') !== null ? true : false;
    this.verifyContractIdType = "";
    this.verifyContractId = "";
    this.verifyContract = false;
    this.desnewUser = false
    this.newUsernameMigratedUser = false;
    this.reminderUser = false;
    this.desnewPassword = false
    this.desLogin = true
    this.desnext = false
    this.firstTimeLogin = false
    this.selectImageForm = false
    this.information = false
    this.alertalogin = false
    this.error = false
    this.userExist = false
    this.uservalid = false
    this.imgQButton = false
    this.QButton = false
    this.otpEnterForm = false
    this.activado = false
    this.intento = false
    this.newPassForm = false
    this.newPassFormFirst = false
    this.errorOffline = false
    this.bandUnlockUser = false
    this.unlockUserForm = false
    this.mensajeContra = null
    this.otpForm.reset()
    this.idForm.reset()
    this.idTipo.reset()
    this.userForm.reset()
    this.userForm1.reset()
    this.usernameFormControl.reset()
    this.usernameFormControl.enable()
    this.passwordFormControl.reset()
    this.passConfirmForm.reset()
    this.passForm.reset()
    this.borderImg = []
    this.imgSelected = 0
    for (let i in this.preguntasForm) {
      this.preguntasForm[i].reset()
    }
    for (let i in this.preguntasForm1) {
      this.preguntasForm1[i].reset()
    }
    for (let i in this.preguntasForm2) {
      this.preguntasForm2[i].reset()
    }
    for (var i = 0; i < 5; i++) {
      this.hide[i] = true
    }
    for (let digit of this.digits) {
      digit.field.reset()
    }
    //setTimeout(function () { document.getElementById("userID").focus(); }, 1);
  }

  nextStep(forceExpireSessions: boolean) {
    this.user = this.usernameFormControl.value
    this.verifyMesgInfo = false;
    this.error = false;
    this.intento = true
    let reqJSON = { usuario: this.user, forceExpireSessions: forceExpireSessions.toString(), tipoUsuario: 'HBA' }
    this.preguntas2 = []
    this.disableButton = true
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/imagenes').then((data: any) => {
      if (data) {
        this.alertalogin = true
        this.error = false
        if (data.cod == 'HBR3-0') {
          let dataCreateMigrateUser = { id: data.idUsrMig, tipoDoc: data.tipoDocUsrMig, tipoUsuario: data.tipoUsrMig }
          this.alerta.generarDialogoSeguroContrato(this.idiomas, 'assets/resources/mipmap-hdpi/contratoTest.png', dataCreateMigrateUser).then((data) => {
            if (data) {
              this.nextStep(true);
            }
          })
        }
        else if (data.cod == 'HBR-0') {
          this.firstTimeLogin = true
          this.desnext = true
          this.msjalert = data.mensaje
        }
        else if (data.cod == 'HBR1-0') { //Para usuarios migrados que requieren actualizacion de nombre de usuario
          this.newUser(data);
        }
        else if (/^HBR\d[-]\d$/.test(data.cod)) {
          this.alertalogin = false
          this.error = true
          this.msjerror = data.mensaje
          if (data.cod != 'HBR4-0' && data.cod != 'HBR6-0') {
            this.msjerror = this.idiomas.NoUsrMigCompleteData.replace('$newUserText', this.idiomas.NuevoUsuario)
          }
          else {
            this.verifyMesgInfo = true;
          }
        }
        else {
          if (data.inSession == 0 || forceExpireSessions) {
            if (data.case == 1) {
              if (!data.image) { //Usuario existe - primer ingreso
                this.firstTimeLogin = true
                this.desnext = true
                this.msjalert = data.message
              } else {
                if (((this.saveUser == '' && this.userAccept == '') || this.saveUser != this.usernameFormControl.value) && data['isUserSaveLogin'] == 'true') {
                  this.alerta.generarDialogoGuardarUsuario("saveUser", this.idiomas).then((data) => {
                    if (data == true) {
                      localStorage.setItem('userSave', this.servicios.encriptar(this.usernameFormControl.value))
                      localStorage.setItem("userAccept", this.servicios.encriptar("1"))
                    } else if (data == false && this.userAccept != '1') {
                      localStorage.setItem('userSave', this.servicios.encriptar(this.usernameFormControl.value))
                      localStorage.setItem("userAccept", this.servicios.encriptar("0"))
                    }
                    this.desnext = true
                    setTimeout(function () { document.getElementById("userPwdID").focus(); }, 1);
                  })
                } else {
                  this.desnext = true
                  setTimeout(function () { document.getElementById("userPwdID").focus(); }, 1);
                }
                this.firstTimeLogin = false
                this.userImage = data.image
                this.msjalert = data.message
                if (this.serverMockService.isMobile() && this.hasAcceptDialogBiometric != '0') {
                  this.obtainHasBiometric()
                }
              }
            } else if (data.case == 2) {
              this.error = true
              this.msjerror = data.message
              this.bandUnlockUser = true
              this.preguntas2 = data.pregArray
            }
            else if (data.case == 3) {
              this.error = true
              this.intento = false
              this.msjerror = data.message
            }
          } else {
            this.alerta.generarDialogoSeguroSesion(this.idiomas).then((data) => {
              if (data) {
                this.nextStep(true)
              }
            });
          }
        }
      }
      this.intento = false
      this.disableButton = false
    }, (err) => {
      this.error = true
      this.intento = false
      this.msjerror = err.error.mensajeUsuario
      this.disableButton = false
    })
  }

  obtainHasBiometric() {
    const envio = {
      usuario: this.user
    }
    this.apiService.noTokenProviderInternetCheck(envio, '/consultaTieneBiometria').then((data: any) => {
      if (data) {
        if (data[0]['hasBiometricUser'] == "true") {
          this.hasBiometricRegisterForDialog = true;
        }
      }
    }, (err) => {
      this.hasBiometricRegister = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet  
      }
    });
  }

  obtainHasBiometricPassLog() {
    this.spinnerDevice = true
    const envio = {
      usuario: this.user
    }
    this.apiService.noTokenProviderInternetCheck(envio, '/consultaTieneBiometria').then((data: any) => {
      if (data) {
        if (this.serverMockService.isMobile()) {
          this.hasBiometricPhone = data[0]['hasBiometricUser'] == "true" ? true : false
          localStorage.setItem('hasBiometricPhone', this.servicios.encriptar(data[0]['hasBiometricUser'] == "true" ? '1' : '0'))
          if (data[0]['hasBiometricUser'] == "true") {
            if (this.hasBiometricCredentials) {
              if (this.servicios.decryptAEStoString(this.users[0]['username']) == this.user) {
                this.hasBiometricRegister = true;
                //Si nos solicitaron una autenticacion biometrica automatica, hacerlo!
                this.webAuthSigninWUserPasswd(this.user);
              }
            }
            this.hasBiometricRegisterForDialog = true;
          } else {
            this.spinnerDevice = false
            this.hasBiometricRegister = false;
            this.hasBiometricRegisterForDialog = false;
            if (this.hasBiometricCredentials) {
              if (this.servicios.decryptAEStoString(this.users[0]['username']) == this.user) {
                this.removeUser(this.user)
                this.alerta.presentarAlertaX(this.idiomas.ErrorHuellaPassword.toUpperCase())
                this.hasBiometricCredentials = false
                this.intentoLoginWBiometricCredentials = false
                this.usernameFormControl.enable()
                this.usernameFormControl.reset()
              }
            }
          }
        }
      }
    }, (err) => {
      this.spinnerDevice = false
      this.hasBiometricRegister = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { }
        }
        else {
          //this.alerta.presentarAlerta(this.idioma.ServidorError)
        }
      }
      else { //Sin Internet  
      }
    });
  }


  selectImage(aux: number) {
    if (this.internetC.internet) {
      for (var i = 0; i < this.imagenes.length; i++) {
        if (aux == this.imagenes[i].cimagen) {
          this.borderImg[i] = true
        } else {
          this.borderImg[i] = false
        }
      }
      this.imgSelected = aux
    } else {
      for (var i = 0; i < this.imagenes.length; i++) {
        if (this.imgSelected == this.imagenes[i].cimagen) {
          this.borderImg[i] = true
        } else {
          this.borderImg[i] = false
        }
      }
    }
  }

  sendImageAndQuestions() {
    this.intento = true
    var cont = 0
    for (var i = 0; i < this.preguntas.length; i++) {
      if (this.imgSelected != 0 && this.preguntasForm[i].valid) {
        cont = cont + 1
      }
    }
    if (cont == this.preguntas.length) {
      this.information = false
      for (var i = 0; i < this.preguntas.length; i++) {
        this.preguntasaux[i] = { cpregunta: this.preguntas[i].cpregunta, respuesta: this.preguntasForm[i].value.toUpperCase() }
      }
      let reqJSON = {
        usuario: this.usuario,
        contrasena: this.servicios.toAES(this.contrasena),
        desencriptar: "1",
        cimagen: this.imgSelected,
        preguntas: this.preguntasaux
      }
      this.apiService.noTokenProviderInternetCheck(reqJSON, '/guardarimgpregs').then((data: any) => {
        if (data) {
          this.imgQButton = true
          this.information = true
          this.msjinformation = data.message
          this.error = false
        }
        this.intento = false
      }, (err) => {
        this.information = false
        this.intento = false
        this.imgQButton = false
        this.error = true
        this.msjerror = err.error.mensajeUsuario
      })
    } else {
      this.intento = false
      this.alertalogin = true
    }
  }

  sendIdentification() {
    this.intento = true
    this.error = false
    let reqJSON = { id: this.idForm.value, tipoDoc: this.idTipo.value, tipoUsuario: 'HBA' }
    if (this.reminderUser) {
      reqJSON['reminderUser'] = true
    }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/crearnuevousuario').then((data: any) => {
      if (data) {
        this.intento = false
        if (data.cod == 'HBR1-0') {
          if (this.hasLivenessRecognition) {
            this.instructionsForm = true
          } else {
            this.userExist = true
            this.information = true
          }
          this.msjinformation = data.mensaje + 'El usuario debe cumplir las siguiente características:' +
            '*Mínimo 6 caracteres, combinación de números y letras.'
        } else if (data.cod == 'HBR3-0') {
          this.alerta.generarDialogoSeguroContrato(this.idiomas, true, reqJSON).then((data) => {
            if (data) {
              this.sendIdentification()
            }
          })
        }
        else if (data.cod == 'HBR5-0') {//No existe persona con Id ingresada
          this.userExist = false
          this.error = true
          this.msjerror = data.mensaje
        }
        else {
          this.userExist = false
          this.information = true
          this.msjinformation = data.mensaje
          if (data.cod == 'HBR4-0') {
            this.verifyContract = true;
          }
        }
      }
    }, (err) => {
      this.intento = false
      this.userExist = false
      this.error = true
      this.msjerror = err.error.mensajeUsuario
    })
  }

  async handleSignIn({ username, password }: SignInInput) {
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      this.isSignedIn = isSignedIn
      if (isSignedIn) {
        this.spinner = false
        this.instructionsForm = false
        this.faceLivenessForm = true
      }
    } catch (error) {
      if (error) {
        this.isSignedIn = true
        this.spinner = false
        this.instructionsForm = false
        this.faceLivenessForm = true
      }
    }
  }

  async handleSignOut() {
    try {
      await signOut();
      this.isSignedIn = false;
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  rekognitionStep() {
    this.spinner = true
    Amplify.configure(awsconfig)
    var credentials: SignInInput = {
      username: "danydelcas@gmail.com",
      password: "1234abcd"
    }
    this.handleSignIn(credentials)
  }


  verifyImage(image: any) {
    this.spinner = true
    this.intentoSpinner = true
    this.error = false
    this.msjerror = ""
    this.disableButton = true
    setTimeout(() => {
      let request = {
        transaccion: "010198-sig-in.xml",
        userCode: 'HBREGISTRO',
        "ctl^IDENTIFICACION": this.idForm.value,
        "ctl^IMAGE": image
      }
      this.apiService.noTokenProviderInternetCheck(request, '/seguridades').then(() => {
        this.spinner = false
        this.intentoSpinner = false
        this.userExist = true
        this.information = true
        this.faceLivenessForm = false
      }, (err) => {
        this.error = true
        this.spinner = false
        this.intentoSpinner = false
        if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
          if (err.error) {
            this.msjerror = err.error.mensaje
          } else {
            this.msjerror = this.idiomas.ServidorError
          }
        } else { }
      });
    }, 1000)
  }


  sendUser() {
    this.intento = true
    let reqJSON = { id: this.idForm.value, tipoDoc: this.idTipo.value, usuarioHB: this.userForm1.value, tipoUsuario: 'HBA' }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/enviarnuevousuario').then((data: any) => {
      if (data) {
        this.intento = false
        if (data.cod == 'HBR2-0') {
          this.information = false
          this.alertalogin = true
          this.userForm1.setValue('')
          this.msjalert = data.mensaje
          this.uservalid = false
        }
      }
    }, (err) => {
      this.alertalogin = false
      this.intento = false
      this.information = true;
      this.msjinformation = err.error.mensajeUsuario
      this.uservalid = true
    })
  }

  sendOtp() {
    this.bodyForOTP.OTP = this.servicios.toAES(this.otpForm.value)
    this.bodyForOTP.desencriptar = "1"
    this.bodyForOTP["transaccion"] = "010000"
    this.intento = true
    this.disableButton = true
    this.apiService.noTokenProviderInternetCheck(this.bodyForOTP, '/verificarotp').then((data: any) => {
      this.loginInfo.nombreUsuario = data.nombreusuario
      this.base.buscarUsuario(this.usuario, this.contrasena, data.token, data.cpersona, data.user, data.nombreusuario, this.idiomas).then(
        (data => {
          if (data) {
            this.base.insertarConsulta("loginInfo", this.loginInfo)
            localStorage.setItem('ingresado', this.servicios.encriptar('ingresado'))
            localStorage.setItem("offline", this.servicios.encriptar("true"))
            this.base.insertarConsulta('usuarioValido', this.isUserValid)
            this.base.insertarConsulta('tienda', this.haveStore)
            this.base.insertarConsulta('pinValidation', this.pinValidation)
            this.base.insertarConsulta('isLegalPerson', this.isLegalPerson)
            this.idioma.getIdioma();
            this.router.navigate(['/home']);
            //Buscar si ya hay una huella registrada para este usuario
            const user = this.users.find(u => u.username === this.usuario);
            if (this.hasAcceptDialogBiometric && this.hasAcceptDialogBiometric != '' && this.hasAcceptDialogBiometric == '1' && !this.hasBiometricPhone && !this.hasBiometricRegisterForDialog) {
              //El solicitar un registro biometro SOLO debe permitirse en Dispositivos Moviles
              if (!user && this.serverMockService.isMobile() && this.bandMobile) {
                //En caso de un password exitoso, solicitar si desea registrar la huella
                this.alerta.generarDialogoSeguroRegistrarHuella(this.idiomas).then((data) => {
                  if (data) {
                    //Este componente resuelve la suscripcion de la promesa INMEDIATAMENTE luego de abrirse
                    this.alerta.generarDialogoEsperando(this.idiomas, this.idiomas.RegistrandoHuella).then((data) => {
                      //En este caso, no nos interesa si data viene con algun evento/valor... llamar directamente el signup
                      //Al abrir el dialogo, ya nos debe solicitar el registro de huella
                      this.signup();
                    });
                  }
                });
                localStorage.setItem('hasAcceptDialogBiometric', this.servicios.encriptar('0'))
              }
            }
          }
          this.error = false
          this.intento = false
          this.disableButton = false
        })
      )
    }, (err) => {
      this.msjerror = err.error.mensajeUsuario;
      this.error = true
      this.intento = false
      this.disableButton = false
    })
  }

  sendUserNewPass() {
    this.intento = true
    let reqJSON = { usuarioHB: this.userForm.value }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/claveolvidada').then((data: any) => {
      if (data) {
        this.information = true
        this.msjinformation = data.message
      }
      this.intento = false
      this.error = false
      this.bandusuario = true
    }, (err) => {
      this.bandusuario = false
      this.intento = false
      this.error = true
      this.msjerror = err.error.mensajeUsuario
    })
  }

  setMensajes() {
    this.information = false
    this.error = false
    this.alertalogin = false
    this.msjinformation = ''
    this.msjalert = ''
    this.msjerror = ''
  }

  nextStepNewPass() {
    this.intento = true
    this.setMensajes()
    this.usuario = this.usernameFormControl.value;
    this.contrasena = this.passwordFormControl.value;
    let reqJSON = { usuario: this.usuario, contrasena: this.servicios.toAES(this.contrasena), desencriptar: "1", isMobile: this.bandMobile ? "1" : "0" }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/oauth').then((data: any) => {
      if (data) {
        this.intento = false
        if (data.case == 10) {
          this.selectImageForm = false
          this.newPassFormFirst = true
          this.preguntas1 = data.pregArray
        }
        else if (data.case == 1) { //Si no se solicito actualizacion de password pasa a ingreso de OTP - login
          this.selectImageForm = false
          this.otpEnterForm = true
          this.desnext = false
          this.desLogin = false
          this.loginInfo.lastLogin = data.previousLogin
          this.bodyForOTP = data
          this.base.setInfoUser(data.CIO, data.SUC, data.OFC); //Almacena Compañia - Sucursal y Oficina de Usuario
          setTimeout(function () { document.getElementById("userOTP").focus(); }, 1);
        }
        if (this.newPassFormFirst) {
          this.obtenerValidacionesPassword()
        }
      }
    }, (err) => {
      this.intento = false
      this.error = true
      this.msjerror = err.error.mensajeUsuario
    })
  }

  sendNewPass() {
    this.intento = true
    let preguntas1aux: any[]
    preguntas1aux = []
    for (var i = 0; i < this.preguntasaux.length; i++) {
      for (var j = 0; j < this.preguntas1.length; j++) {
        if (this.preguntas1[j].cpregunta == this.preguntasaux[i].cpregunta)
          preguntas1aux[j] = { cpregunta: this.preguntas1[j].cpregunta, respuesta: this.preguntasaux[i].respuesta.toUpperCase() }
      }
    }
    let reqJSON = {
      usuario: this.usuario,
      contrasena: this.servicios.toAES(this.contrasena),
      contrasenaNva: this.servicios.toAES(this.passConfirmForm.value),
      desencriptar: "1",
      desencriptarNva: "1",
      passExpirado: "0",
      preguntas: preguntas1aux
    }
    if (this.isPasswordExpired) {
      reqJSON['passExpirado'] = "1"
    }
    this.apiService.noTokenProviderInternetCheck(reqJSON, '/nuevaclave').then((data: any) => {
      if (data) {
        this.QButton = true
        this.information = true
        this.msjinformation = data.message
        this.error = false
        if (!this.isPasswordExpired) {
          //Dialogo informando llamada de callcenter para activar usuario por creacion
          let showUserValidationDialog = data.validacionRegistroInicial
          if (showUserValidationDialog != undefined && showUserValidationDialog == "true") {
            this.alerta.generarDialogo(this.idiomas, "LoginUserCreatedCallCenterValidation").then((data) => {
              if (data) { }
            });
          }
          this.msjinformation = data.message
        } else {
          this.msjinformation = this.idiomas.PasswordCambiado
        }
      }
      this.intento = false
    }, (err) => {
      this.information = false
      this.intento = false
      this.QButton = false
      this.error = true
      this.msjerror = err.error.mensajeUsuario
    })
  }

  sendNewPassAndQ() {
    this.intento = true
    let preguntas1aux: any[]
    var cont = 0
    preguntas1aux = []
    for (var i = 0; i < this.preguntas1.length; i++) {
      if (this.preguntasForm1[i].valid && this.passForm.valid && this.passConfirmForm.valid
        && (this.passForm.value == this.passConfirmForm.value)) {
        cont++
      }
    }
    if (cont == this.preguntas1.length) {
      this.alertalogin = false
      for (var i = 0; i < this.preguntas1.length; i++) {
        preguntas1aux[i] = { cpregunta: this.preguntas1[i].cpregunta, respuesta: this.preguntasForm1[i].value.toUpperCase() }
      }
      let reqJSON = {
        usuario: this.usuario,
        contrasena: this.hasUserFingerPrint(this.usuario) && !this.passwordFormControl.value ? this.contrasenaNewPassQue : this.servicios.toAES(this.contrasena),
        contrasenaNva: this.servicios.toAES(this.passConfirmForm.value),
        desencriptar: this.hasUserFingerPrint(this.usuario) && !this.passwordFormControl.value ? "0" : "1",
        desencriptarNva: "1",
        passExpirado: "0",
        preguntas: preguntas1aux
      }
      this.apiService.noTokenProviderInternetCheck(reqJSON, '/nuevaclave').then((data: any) => {
        if (data) {
          this.QButton = true
          this.information = true
          this.msjinformation = data.message
          this.error = false
          this.disablePin = false
        }
        this.intento = false
      }, (err) => {
        this.information = false
        this.intento = false
        this.QButton = false
        this.error = true
        this.msjerror = err.error.mensajeUsuario
        if (err.error.errorCode == "SEC098") {
          //Dialogo informando llamada de callcenter para activar usuario por bloqueo total
          this.alerta.generarDialogo(this.idiomas, "UserTotallyBloquedCallCenterContact").then((data) => {
            if (data) { }
          });
        }
      })
    } else {
      this.intento = false
      this.alertalogin = true
    }
  }

  deshabilitar() {
    this.passForm.disable()
    this.passConfirmForm.disable()
    for (let i in this.preguntasForm1) {
      this.preguntasForm1[i].disable()
    }
  }

  nextStepUnlockUser() {
    this.desLogin = false
    this.information = false
    this.error = false
    this.alertalogin = false
    this.unlockUserForm = true
  }

  sendQuestions() {
    this.error = false
    this.intento = true
    let preguntas2aux: any[]
    var cont = 0
    preguntas2aux = []
    this.usuario = this.usernameFormControl.value;
    for (var i = 0; i < this.preguntas2.length; i++) {
      if (this.preguntasForm2[i].valid) {
        cont++
      }
    }
    if (cont == this.preguntas2.length) {
      this.alertalogin = false
      for (var i = 0; i < this.preguntas2.length; i++) {
        preguntas2aux[i] = { cpregunta: this.preguntas2[i].cpregunta, respuesta: this.preguntasForm2[i].value.toUpperCase() }
      }
      let reqJSON = {
        usuario: this.usuario,
        preguntas: preguntas2aux
      }
      this.apiService.noTokenProviderInternetCheck(reqJSON, '/desbloquearusuario').then((data: any) => {
        if (data) {
          this.QButton = true
          this.information = true
          this.msjinformation = data.message
          this.error = false
        }
        this.intento = false
      }, (err) => {
        this.information = false
        this.intento = false
        if (err.error.errorCode != "IB-5005") {
          this.QButton = true
        }
        this.error = true
        this.msjerror = err.error.mensajeUsuario
        if (err.error.errorCode == "SEC098") {
          //Dialogo informando llamada de callcenter para activar usuario por bloqueo total
          this.alerta.generarDialogo(this.idiomas, "UserTotallyBloquedCallCenterContact").then((data) => {
            if (data) { }
          });
        }
      })
    } else {
      this.intento = false
      this.alertalogin = true
    }
  }

  changeTipoIdentification(valor) {
    if (valor == 'CED') {
      this.idForm = this.validators.idForm()
    } else if (valor == 'RUC') {
      this.idForm = this.validators.rucForm()
    } else {
      this.idForm = this.validators.pasForm()
    }
  }

  obtenerValidacionesPassword() {
    this.intento = true
    this.mensajeContra = null
    this.apiService.noTokenProviderInternetCheck({}, '/validacionesClave').then((data: any) => {
      this.mensajeContra = this.idiomas.mensajeContra
      let passwordValidations = Object.keys(data)
      for (let validation of passwordValidations) {
        if (data[validation] == true) {
          if (validation == 'VALIDATE_MINPASS') {
            this.mensajeContra = this.mensajeContra + "<br>*" + this.idiomas[validation].replace('$MINPASSWORDHB', data['MINPASSWORDHB']);
          }
          else if (validation == 'VALIDATE_REPEATED') {
            this.mensajeContra = this.mensajeContra + "<br>*" + this.idiomas[validation].replace('$ITERPASSWORD', data['ITERPASSWORD']);
          }
          else {
            this.mensajeContra = this.mensajeContra + "<br>*" + this.idiomas[validation]
          }
        } else {
          if (validation == 'ONLYNUMPASSWORDHB') {
            this.mensajeContra = this.mensajeContra + "<br>*" + this.idiomas['ALPHANUMERICPASSWORDHB']
          }
        }
      }
      if (this.isPasswordExpired) {
        this.mensajePasswordExpirado = this.idiomas['PASSWORDNOVIGENTE']
      }
      this.intento = false
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.alerta.presentarAlerta(this.idiomas.ServidorError)
      }
      else { //Sin Internet        
        this.mensajeContra = null
      }
      this.intento = false;
    });
  }

  //Funcinamiento con tecla enter
  usrEnter() {
    if (this.usernameFormControl.valid) {
      document.getElementById("userID").blur();
      this.nextStep(false);
    }
  }

  enterNewPassword() {
    if (this.userForm.valid) {
      document.getElementById("userID").blur();
      this.sendUserNewPass();
    }
  }

  psswEnter() {
    if (this.passwordFormControl.valid) {
      this.login();
    }
  }

  otpEnter() {
    if (this.otpForm.valid) {
      this.sendOtp();
    }
  }
  //.
  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  //Soporte FIDO
  removeUser(username: string) {
    this.serverMockService.removeUser(username);
    this.users = this.serverMockService.getUsers();
    localStorage.setItem('hasAcceptDialogBiometric', this.servicios.encriptar('1'))
    this.hasAcceptDialogBiometric = this.servicios.desencriptar(localStorage.getItem('hasAcceptDialogBiometric'))
  }

  signup() {
    // Guardar el usuario en la 'DB' local
    const prevUser = this.serverMockService.getUser(this.usuario);
    if (prevUser) {
      this.alerta.presentarAlertaX(this.idiomas.HuellaRegistradaTelUsu.toUpperCase())
      return;
    }
    const user: User = this.serverMockService.addUser({ username: this.servicios.toAES(this.usuario), credentials: [] });
    this.users = this.serverMockService.getUsers();

    // Preguntar si existe el metodo de autenticacion usando WebAuthn
    if (this.webAuthnAvailable && this.useFingerprint) {
      this.webAuthnService.webAuthnSignup(user)
        .then((credential: PublicKeyCredential) => {
          const valid = this.serverMockService.registerCredential(user, credential);
          this.users = this.serverMockService.getUsers();
          this.insertIdBiometric(this.users[0]['id'])
        }).catch((error) => {
          //Quizas haya una mejor manera de cerrar un dialogo ?
          var element = document.getElementById("btnCloseWaitingDialog");
          element && element.click();
          localStorage.setItem('hasAcceptDialogBiometric', this.servicios.encriptar('0'))
          this.alerta.presentarAlertaX(this.idiomas.ErrorRegistrarHuellaExitosa.toUpperCase())
        });
    }
  }

  insertIdBiometric(idBiometric: any) {
    this.intento = false
    let envio = {
      idBiometric: idBiometric
    }

    this.apiService.postProvider2InternetCheck('/registrarIdBiometrico', this.base.id_token, envio).then((data: any) => {
      this.intento = true
      //Mostrar un dialogo indicando que la huella se registró OK
      this.alerta.generarDialogo(this.idiomas, "RegistrarHuellaExitosa").then((data) => {
        if (data) {
          //Quizas haya una mejor manera de cerrar un dialogo ?
          var element = document.getElementById("btnCloseWaitingDialog");
          element && element.click();
        }
      });
      localStorage.setItem('hasBiometricPhone', this.servicios.encriptar('1'))
      this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase())
    }, (err) => {
      this.intento = true
      this.removeUser(this.usuario)

      //Con esto cerramos el dialogo si existe un error automaticamente
      var element = document.getElementById("btnCloseWaitingDialog");
      this.alerta.presentarAlertaX(this.idiomas.ErrorRegistrarHuellaExitosa.toUpperCase())
      element && element.click();

      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlertaX(this.idiomas.AlertaService)
        }
      }
    });
  }

  logout() {
    let obj = {
      'salir': true
    }
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }


  signin() {
    const user = this.serverMockService.getUsers().find(u => u.username === this.usuario);
    if (user) {
      //alert('Excelente! autenticación exitosa sin biometría!');
    } else {
      this.alerta.presentarAlertaX(this.idiomas.ErrorHuella.toUpperCase())
    }
  }

  webAuthVerifyUserPassw() {
    this.intentoLoginWBiometricCredentials = true
    this.users = this.serverMockService.getUsers();
    if (this.hasBiometricCredentials) {
      this.user = this.servicios.decryptAEStoString(this.users[0]['username'])
      this.obtainHasBiometricPassLog()
    }
  }

  webAuthSigninWUserPasswd(usuario) {
    const user = this.serverMockService.getUser(usuario);
    this.usuarioCredntialBiometric = usuario
    this.webAuthnService.webAuthnSignin(user).then((response) => {
      // TODO: validar attestion (esto debe ser algo del REST)
      this.usuario = this.servicios.decryptAEStoString(user.username);
      this.loginBiometria(this.usuario);
    }).catch((error) => {
      this.spinnerDevice = false
      this.alerta.presentarAlertaX(this.idiomas.ErrorHuella.toUpperCase())
    });
  }

  hasUserFingerPrint(username: any) {
    const user = this.serverMockService.getUser(username);
    //Detectar si hay una huella registrada, SOLO si no ha solicitado autenticacion por password
    return !this.isPasswordAuthRequested
      && user && user.credentials && user.credentials.length != 0;
  }

  requestPasswordAuth() {
    this.isPasswordAuthRequested = true;
    //Truco para forzar un refresh de variables disparadoras de ngIf
    setTimeout(function () { document.getElementById("userPwdID").focus(); }, 125);
  }


  getSolicitudesMob() {
    this.spinnerDevice = true
    var token = localStorage.getItem('deviceLinked')
    var usersBase = localStorage.getItem('users')
    var userHB = token.split(';')[0]
    if (usersBase != null) {
      this.user = this.servicios.decryptAEStoString(this.users[0]['username'])
    }
    let request = {
      "userHB": userHB
    }
    this.apiService.noTokenProviderInternetCheck(request, '/consultaSolicitudMob').then((data: any) => {
      if (data) {
        if (data.softToken == "1") {
          this.hasDeviceLinked = true
          this.activeSofttoken = true
          var datev = new Date(data.fvigencia).getTime()
          var date = new Date(data.factual).getTime()
          if (data.status == "SOL" && (datev >= date)) {
            this.alerta.generarDialogoSolicitarAutorizacionData(this.idiomas, false, false, data.info, token).then((response) => {
              if (response) {
                this.alerta.presentarAlerta(this.idiomas.SolicitudAutorizada.toUpperCase())
              }
            });
          }
        } else {
          this.hasDeviceLinked = false
          this.activeSofttoken = false
          localStorage.removeItem('deviceLinked')
          localStorage.removeItem('pushNotifications')
        }
        if (this.serverMockService.isMobile()) {
          this.hasBiometricPhone = data.hasNombreDispositivoMovil == "true" ? true : false
          localStorage.setItem('hasBiometricPhone', this.servicios.encriptar(data.hasNombreDispositivoMovil == "true" ? '1' : '0'))
          if (data.hasBiometricUser == "true") {
            if (usersBase != null) {
              if (this.servicios.decryptAEStoString(this.users[0]['username']) == this.user && this.hasBiometricPhone) {
                this.hasBiometricRegister = true;
              } else if (!this.hasBiometricPhone) {
                this.removeUser(this.user)
                this.spinnerDevice = false
              }
            } else {
              this.hasBiometricPhone = false
              this.hasBiometricRegisterForDialog = false
            }
          } else {
            this.spinnerDevice = false
            this.hasBiometricRegister = false;
            this.hasBiometricRegisterForDialog = false;
            this.users = this.serverMockService.getUsers();
            if (usersBase != null) {
              if (this.servicios.decryptAEStoString(this.users[0]['username']) == this.user) {
                this.removeUser(this.user)
                this.hasBiometricCredentials = false
                this.intentoLoginWBiometricCredentials = false
                this.usernameFormControl.enable()
                this.usernameFormControl.reset()
              }
            }
          }
        }
        if (data.isTemporal == "1" || data.isTemporal == "null") {
          this.disablePin = true
        }
        this.spinnerDevice = false
      }
    }, (err) => {
      this.spinnerDevice = false
    });
  }

  getSolicitudes() {
    this.spinner = true
    var userHB = this.bodyForPIN.USR;
    let request = {
      "userHB": userHB,
      "isMobile": this.bandMobile,
      "sessionId": this.bodyForPIN.sessionId,
      "nombreUsuario": this.bodyForPIN.name,
      "cpersona": this.bodyForPIN.cperson
    }
    var interval = setInterval(() => {
      this.apiService.noTokenProviderInternetCheck(request, '/consultaSolicitud').then((data: any) => {
        if (data) {
          var datev = new Date(data.fvigencia).getTime()
          var date = new Date(data.factual).getTime()
          if (data.status == 'AUT' && (datev >= date)) {
            clearInterval(interval)
            this.spinner = false
            this.loginInfo.nombreUsuario = data.nombreusuario
            this.base.buscarUsuario(this.usuario, this.contrasena, data.token, data.cpersona, data.user, data.nombreusuario, this.idiomas).then(
              (data => {
                if (data) {
                  this.base.insertarConsulta("loginInfo", this.loginInfo)
                  localStorage.setItem('ingresado', this.servicios.encriptar('ingresado'))
                  localStorage.setItem("offline", this.servicios.encriptar("true"))
                  this.base.insertarConsulta('usuarioValido', this.isUserValid)
                  this.base.insertarConsulta('tienda', this.haveStore)
                  this.base.insertarConsulta('pinValidation', this.pinValidation)
                  this.base.insertarConsulta('isLegalPerson', this.isLegalPerson)
                  this.idioma.getIdioma();
                  this.router.navigate(['/home']);

                  //Buscar si ya hay una huella registrada para este usuario
                  const user = this.users.find(u => u.username === this.usuario);
                  if (this.hasAcceptDialogBiometric && this.hasAcceptDialogBiometric != '' && this.hasAcceptDialogBiometric == '1' && !this.hasBiometricPhone && !this.hasBiometricRegisterForDialog) {
                    //El solicitar un registro biometro SOLO debe permitirse en Dispositivos Moviles
                    if (!user && this.serverMockService.isMobile() && this.bandMobile) {
                      //En caso de un password exitoso, solicitar si desea registrar la huella
                      this.alerta.generarDialogoSeguroRegistrarHuella(this.idiomas).then((data) => {
                        if (data) {
                          //Este componente resuelve la suscripcion de la promesa INMEDIATAMENTE luego de abrirse
                          this.alerta.generarDialogoEsperando(this.idiomas, this.idiomas.RegistrandoHuella).then((data) => {
                            //En este caso, no nos interesa si data viene con algun evento/valor... llamar directamente el signup
                            //Al abrir el dialogo, ya nos debe solicitar el registro de huella
                            this.signup();
                          });
                        }
                      });
                      localStorage.setItem('hasAcceptDialogBiometric', this.servicios.encriptar('0'))
                    }
                  }
                }
                this.error = false
                this.intento = false
                this.disableButton = false
              })
            )
          }
          if (data.status == '') {
            clearInterval(interval)
            this.spinner = false
            this.error = true
          }
        }
      }, (err) => {
        clearInterval(interval)
        this.spinner = false
      });
    }, 3000);
  }

  activeEntUser() {
    this.isUserEnt = true;
    this.desLogin = true;
    this.intentoInicio = false;
    if (!this.verifyContract) {
      setTimeout(function () { document.getElementById("userID").focus(); }, 1);
    }
  }

  openScanner() {
    this.isUserEnt = true
    this.intentoInicio = false
    this.desLogin = false
    this.scannQr = true
  }

  opendeviceUnRegister() {
    this.isUserEnt = true
    this.intentoInicio = false
    this.desLogin = false
    this.unlinkDevice = true
  }

  scanSuccessHandler($event: any) {
    this.readText = this.servicios.desencriptar($event)
    var data = this.readText.split(";")
    if (data.length == 4 && data[0].includes("HBA")) {
      this.cuser = data[0]
      this.deviceName = data[1]
      this.phoneNumber = data[2]
      this.dateRegister = data[3]
      this.valid = true
      this.pinEnterForm = true
      setTimeout(() => { this.renderer.selectRootElement('#myInput0').focus() }, 1)
    }
  }

  unregisterDevice() {
    if (this.usernameFormControl1.valid && this.passwordFormControl1.valid) {
      let usersBase = localStorage.getItem('users');
      this.intento = true
      let reqJSON = {
        usuario: this.usernameFormControl1.value,
        contrasena: this.servicios.toAES(this.passwordFormControl1.value),
        desencriptar: "1"
      }
      this.apiService.noTokenProviderInternetCheck(reqJSON, '/desDispositivo').then((data: any) => {
        if (data) {
          this.deviceUnLinked = true;
          this.hasDeviceLinked = false;
          this.activeSofttoken = false;
          this.valid = false;
          localStorage.removeItem('deviceLinked');
          localStorage.removeItem('pushNotifications');
          this.spinnerDevice = false
          this.hasBiometricRegister = false;
          this.hasBiometricRegisterForDialog = false;
          this.users = this.serverMockService.getUsers();
          if (usersBase != null) {
            if (this.servicios.decryptAEStoString(this.users[0]['username']) == this.user) {
              this.removeUser(this.user)
              this.hasBiometricCredentials = false
              this.intentoLoginWBiometricCredentials = false
              this.usernameFormControl.enable()
              this.usernameFormControl.reset()
            }
          }
        }
        this.intento = false
      }, (err) => {
        this.error = true
        this.intento = false
        this.msjerror = err.error.mensajeUsuario
        this.disableButton = false
      });
    }
  }

  enableButton() {
    var i = 0
    for (let digit of this.digits) {
      if (digit.field.valid && (digit.field.value != '' || digit.field.value)) {
        i = i + 1
      }
    }
    if (i == this.digits.length) {
      this.validPin = true
    } else {
      this.validPin = false
    }
  }

  blockText(event) {
    if (isNaN(event.key) && event.key !== 'Tab' && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  focusNext(i, event) {
    if (i < this.digits.length - 1 && event.key !== 'Tab' && event.key !== 'Backspace' && (!isNaN(event.key) || event.keyCode === 229) && !isNaN(this.digits[i].field.value)) {
      this.renderer.selectRootElement('#myInput' + (i + 1)).focus();
    }

    if (i == 5 && event.key === 'Tab') {
      event.preventDefault();
    }

    if (i > 0 && event.key === 'Backspace') {
      this.digits[i].field.reset();
      this.renderer.selectRootElement('#myInput' + (i - 1)).focus();
    }
  }

  changeValue(i, event) {
    if (!isNaN(event.key)) {
      this.digits[i].field.setValue(event.key);
    }
  }

  sendPin() {
    var service = ""
    let request = {}
    var pin = this.digits[0].field.value + this.digits[1].field.value + this.digits[2].field.value + this.digits[3].field.value + this.digits[4].field.value + this.digits[5].field.value
    var softToken = localStorage.getItem('deviceLinked') !== null ? localStorage.getItem('deviceLinked') : ""
    if (!this.hasDeviceLinked) {
      service = "/seguridades"
      request = {
        transaccion: "010181-sig-in.xml",
        userCode: this.cuser,
        "ctl^CUSUARIO": this.cuser,
        "ctl^NOMBREDISPOSITIVO": this.deviceName,
        "ctl^NUMEROTELEFONO": this.phoneNumber,
        "ctl^BIOMETRICVALIDATION": '0',
        "ctl^PIN": pin
      }
    } else {
      service = "/verificarpin"
      this.bodyForPIN['transaccion'] = '010181-sig-in.xml'
      this.bodyForPIN['userCode'] = softToken.split(';')[0]
      this.bodyForPIN['desencriptar'] = '0'
      this.bodyForPIN['PIN'] = pin
      this.bodyForPIN['nombreDispositivo'] = softToken.split(';')[1]
      this.bodyForPIN['numeroTelefono'] = softToken.split(';')[2]
      this.bodyForPIN['biometricValidation'] = "0"
    }
    this.intento = true
    this.error = false
    this.msjerror = ""
    this.apiService.noTokenProviderInternetCheck(!this.hasDeviceLinked ? request : this.bodyForPIN, service).then((data: any) => {
      this.intento = false
      if (this.readText && this.valid) {
        this.pinEnterForm = false
        this.deviceLinkedForm = true
      } else {
        this.loginInfo.nombreUsuario = data.nombreusuario
        this.base.buscarUsuario(this.usuario, this.contrasena, data.token, data.cpersona, data.user, data.nombreusuario, this.idiomas).then(
          ((data: any) => {
            if (data) {
              this.base.insertarConsulta("loginInfo", this.loginInfo)
              localStorage.setItem('ingresado', this.servicios.encriptar('ingresado'))
              localStorage.setItem("offline", this.servicios.encriptar("true"))
              this.base.insertarConsulta('usuarioValido', this.isUserValid)
              this.base.insertarConsulta('tienda', this.haveStore)
              this.base.insertarConsulta('pinValidation', this.pinValidation)
              this.base.insertarConsulta('isLegalPerson', this.isLegalPerson)
              this.idioma.getIdioma();
              this.router.navigate(['/home']);
              //Buscar si ya hay una huella registrada para este usuario
              const user = this.users.find(u => u.username === this.usuario);
              if (this.hasAcceptDialogBiometric && this.hasAcceptDialogBiometric != '' && this.hasAcceptDialogBiometric == '1' && !this.hasBiometricPhone && !this.hasBiometricRegisterForDialog) {
                //El solicitar un registro biometro SOLO debe permitirse en Dispositivos Moviles
                if (!user && this.serverMockService.isMobile() && this.bandMobile) {
                  //En caso de un password exitoso, solicitar si desea registrar la huella
                  this.alerta.generarDialogoSeguroRegistrarHuella(this.idiomas).then((data) => {
                    if (data) {
                      //Este componente resuelve la suscripcion de la promesa INMEDIATAMENTE luego de abrirse
                      this.alerta.generarDialogoEsperando(this.idiomas, this.idiomas.RegistrandoHuella).then((data) => {
                        //En este caso, no nos interesa si data viene con algun evento/valor... llamar directamente el signup
                        //Al abrir el dialogo, ya nos debe solicitar el registro de huella
                        this.signup();
                      });
                    }
                  });
                  localStorage.setItem('hasAcceptDialogBiometric', this.servicios.encriptar('0'))
                }
              }
            }
            this.error = false
            this.intento = false
            this.disableButton = false
          })
        )
      }
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          this.error = true
          this.msjerror = err.error.mensaje
        } else {
          this.error = true
          this.msjerror = this.idiomas.ServidorError
        }
      }
    });
  }

  sendPinLogin() {
    this.intento = true
    this.error = false
    this.msjerror = ""
    var pin = this.digits[0].field.value + this.digits[1].field.value + this.digits[2].field.value + this.digits[3].field.value + this.digits[4].field.value + this.digits[5].field.value
    let request = {
      transaccion: "010181-sig-in.xml",
      userCode: this.cuser,
      "ctl^CUSUARIO": this.cuser,
      "ctl^NOMBREDISPOSITIVO": this.deviceName,
      "ctl^BIOMETRICVALIDATION": '0',
      "ctl^PIN": pin
    }
    this.apiService.noTokenProviderInternetCheck(request, '/verificarpin').then((data: any) => {
      this.intento = false
      this.base.insertarConsulta("loginInfo", this.loginInfo)
      localStorage.setItem('ingresado', this.servicios.encriptar('ingresado'))
      localStorage.setItem("offline", this.servicios.encriptar("true"))
      this.base.insertarConsulta('usuarioValido', this.isUserValid)
      this.base.insertarConsulta('tienda', this.haveStore)
      this.base.insertarConsulta('pinValidation', this.pinValidation)
      this.base.insertarConsulta('isLegalPerson', this.isLegalPerson)
      this.idioma.getIdioma();
      this.router.navigate(['/home']);
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          this.error = true
          this.msjerror = err.error.mensaje
        } else {
          this.error = true
          this.msjerror = this.idiomas.ServidorError
        }
      }
    });
  }

  sendAuth() {
    this.intento = true
    setInterval(() => {
      this.intento = false
      this.bandAuth = true
    }, 1000);
  }

  linkDevice() {
    this.intento = true
    var pin = this.digits[0].field.value + this.digits[1].field.value + this.digits[2].field.value + this.digits[3].field.value + this.digits[4].field.value + this.digits[5].field.value
    let request = {
      transaccion: "013046-sig-in.xml",
      userCode: this.cuser,
      "ctl^CUSUARIO": this.cuser,
      "ctl^NOMBREDISPOSITIVO": this.deviceName,
      "ctl^NUMEROTELEFONO": this.phoneNumber,
      "ctl^BIOMETRICVALIDATION": '0',
      "ctl^PIN": pin
    }
    this.apiService.noTokenProviderInternetCheck(request, '/seguridades').then((data: any) => {
      this.intento = false
      this.deviceLinked = true
      localStorage.setItem('deviceLinked', this.cuser + ";" + this.deviceName + ";" + this.phoneNumber)
    }, (err) => {
      this.intento = false
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          this.error = true
          this.msjerror = err.error.mensaje
        } else {
          this.error = true
          this.msjerror = this.idiomas.ServidorError
        }
      }
    });
  }

  loginPIN() {
    this.spinnerDevice = true
    var token = localStorage.getItem('deviceLinked')
    var userHB = token ? token.split(';')[0] : ""
    let request = {
      usuario: userHB,
      isMobile: "1"
    }
    this.apiService.noTokenProviderInternetCheck(request, '/oauthPin').then((data: any) => {
      this.error = false;
      if (data) {
        localStorage.setItem('accountingDate', this.servicios.encriptar(data.accountingDate));
        localStorage.setItem('sessionId', this.servicios.encriptar(data.sessionId));
        localStorage.setItem('usuarioLogin', this.servicios.encriptar(data.usuarioLogin));
        this.idioma.getIdioma();
        if (data.case == 12) {
          this.usuario = data.userWEB;
          this.intentoInicio = false
          this.isUserEnt = true
          this.selectImageForm = false
          this.otpEnterForm = false
          this.desnext = false
          this.desLogin = false
          this.loginInfo.lastLogin = data.previousLogin
          this.loginInfo.nombrePreferido = data.nombrePreferido
          this.loginInfo.email = data.email
          this.loginInfo.cuenta = data.cuenta
          this.loginInfo.usuario = this.usuario
          this.bodyForPIN = data
          this.haveStore = data.tienda
          this.pinValidation = data.pinValidation
          this.isLegalPerson = data.isLegalPerson
          this.isUserValid = data.isUserValid
          this.base.setInfoUser(data.CIO, data.SUC, data.OFC); //Almacena Compañia - Sucursal y Oficina de Usuario
          this.pinEnterForm = true
          setTimeout(() => { this.renderer.selectRootElement('#myInput0').focus() }, 1)
        }
      }
      this.spinnerDevice = false
      this.disableButton = false
    }, (err) => {
      this.spinnerDevice = false
      this.disableButton = false
      this.disablePin = true
      this.alerta.presentarAlertaX(err.error.mensaje)
    });
  }

  loginBiometria(userHB) {
    let request = {
      usuario: userHB,
      isMobile: "1"
    }
    this.apiService.noTokenProviderInternetCheck(request, '/oauthBiometrico').then((data: any) => {
      this.error = false;
      if (data) {
        localStorage.setItem('accountingDate', this.servicios.encriptar(data.accountingDate));
        localStorage.setItem('sessionId', this.servicios.encriptar(data.sessionId));
        localStorage.setItem('usuarioLogin', this.servicios.encriptar(data.usuarioLogin));
        this.idioma.getIdioma();
        this.usuario = data.userWEB;
        this.intentoInicio = false
        this.isUserEnt = false
        this.selectImageForm = false
        this.otpEnterForm = false
        this.desnext = false
        this.desLogin = false
        this.loginInfo.lastLogin = data.previousLogin
        this.loginInfo.nombrePreferido = data.nombrePreferido
        this.loginInfo.email = data.email
        this.loginInfo.cuenta = data.cuenta
        this.loginInfo.usuario = this.usuario
        this.haveStore = data.tienda
        this.pinValidation = data.pinValidation
        this.isLegalPerson = data.isLegalPerson
        this.isUserValid = data.isUserValid
        this.base.setInfoUser(data.CIO, data.SUC, data.OFC); //Almacena Compañia - Sucursal y Oficina de Usuario
        this.loginInfo.nombreUsuario = data.nombreusuario
        this.base.buscarUsuario(this.usuario, this.contrasena, data.token, data.cpersona, data.user, data.nombreusuario, this.idiomas).then(
          (data => {
            if (data) {
              this.base.insertarConsulta("loginInfo", this.loginInfo)
              localStorage.setItem('ingresado', this.servicios.encriptar('ingresado'))
              localStorage.setItem("offline", this.servicios.encriptar("true"))
              this.base.insertarConsulta('usuarioValido', this.isUserValid)
              this.base.insertarConsulta('tienda', this.haveStore)
              this.base.insertarConsulta('pinValidation', this.pinValidation)
              this.base.insertarConsulta('isLegalPerson', this.isLegalPerson)
              this.idioma.getIdioma();
              this.router.navigate(['/home']);
            }
          })
        )
      }
      this.spinnerDevice = false
      this.disableButton = false
    }, (err) => {
      this.spinnerDevice = false
      this.disableButton = false
      this.alerta.presentarAlertaX(err.error.mensaje)
    });
  }

  returnMenu() {
    this.isUserEnt = false;
    this.faceLivenessForm = false;
    this.instructionsForm = false;
    this.intentoInicio = true;
    this.scannQr = false;
    this.pinEnterForm = false;
    this.usernameFormControl.reset();
    this.usernameFormControl.enable();
  }

  returnInicio() {
    this.evtRegresoDatos.emit({
      intentoInicio: true,
      intentoLogin: false
    });
    this.validPin = false;
    this.usernameFormControl.reset()
    this.usernameFormControl.enable()
  }

  buttonSelected(index) {
    this.bandsFuncionLogin = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
      '6': false,
      '7': false
    }

    this.bandsFuncionLogin[index] = true;

    //Si se abre la aplicacion instalada se guardar una variable en 1 para saber q esta instalado
    if (this.bandAndroidIosInstalled && localStorage.getItem('AppInstalled') != "1") {
      localStorage.setItem("AppInstalled", "1");
    }

    if (index == 1) {
      // Se verifica que este intalado el app y si esta todo ok le deja hacer login sin problema
      if (this.bandMobile) {
        if (!this.bandAndroidIosInstalled) {
          this.generarDialogoInstalacion();
        }
      } else if (this.bandAndroidIosInstalled && localStorage.getItem('AppInstalled') == "1") {
        this.isClientPrest = false;
      } else {
        this.isClientPrest = false;
      }
    }

    window.scroll(0, 0);
  }

  buttonSelectedPres(index) {
    this.bandsFuncionLogin = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
      '6': false,
      '7': false
    }

    this.bandsFuncionLogin[index] = true;

    //Si se abre la aplicacion instalada se guardar una variable en 1 para saber q esta instalado
    if (this.bandAndroidIosInstalled && localStorage.getItem('AppInstalled') != "1") {
      localStorage.setItem("AppInstalled", "1");
    }

    if (index == 1) {
      // Se verifica que este intalado el app y si esta todo ok le deja hacer login sin problema
      if (this.bandMobile) {
        if (!this.bandAndroidIosInstalled) {
          this.generarDialogoInstalacion();
          this.isClientPrest = true;
        }
      } else if (this.bandAndroidIosInstalled && localStorage.getItem('AppInstalled') == "1") {
        this.isClientPrest = true;
      } else {
        this.isClientPrest = true;
      }
    }

    window.scroll(0, 0);
  }

  returnInicioI() {
    this.bandsFuncionLogin = {
      '0': false,
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
      '6': false,
      '7': false
    }
    this.bandsFuncionLogin['0'] = true
  }

  generarDialogoInstalacion() {
    //Funcionalidad para android detectar si esta instlado o no el app en el dispositivo mobile
    if (this.bandMobile && !this.bandIos && localStorage.getItem('AppInstalled') == "0") {
      this.alerta.generarDialogoAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'), this.deferredPrompt);
    }

    //Se verificar si esta instalado, si es mobile, si no se abrio desde la app y si tiene la varibale de appinstalado en 1 
    //y con esto no dejar que pueda usar el app en el safari del celular en iOS
    if (this.bandMobile && this.bandIos && !this.bandAndroidIosInstalled) {
      this.alerta.generarDialogoAppleAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'));
    }

    //Se verificar si esta instalado, si es mobile, si no se abrio desde la app y si tiene la varibale de appinstalado en 1 
    //y con esto no dejar que pueda usar el app en el chrome del celular en android
    if (this.bandMobile && !this.bandAndroidIosInstalled && !this.bandIos && localStorage.getItem('AppInstalled') == "1") {
      this.alerta.generarDialogoAppleAndroidInstall(this.idiomas, this.bandIos, localStorage.getItem('AppInstalled'));
    }
  }

  loginApp() {
    this.disableButton = false;
    this.isUserEnt = true;
    this.intentoInicio = false;
    this.scannQr = false;
    this.pinEnterForm = false;
    this.deviceLinkedForm = false;
    this.unlinkDevice = false;
    this.deviceUnLinked = false;
    this.usernameFormControl.reset();
    this.usernameFormControl.enable();
  }

  loginPC() {
    this.isUserEnt = true;
    this.intentoInicio = false;
    this.scannQr = false;
    this.pinEnterForm = false;
    this.deviceLinkedForm = false;
    this.unlinkDevice = false;
    this.deviceUnLinked = false;
    this.usernameFormControl.reset();
    this.usernameFormControl.enable();
  }

  openSimulators() {
    window.open("https://simuladorespjl.web.app/#/simulador", "_blank")
  }
}