<div class="example-form" style="padding-top: 2% !important; padding-bottom: 60px;" fxLayout="column"
    fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.AhorroProgramado | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div autocomplete="off" class="form-row">
                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.MontoMensual}}</mat-label>
                        <input class="numeros" matInput (focus)="caretInputAmount($event)"
                            (keyup)="amountKeyInputControl($event)" type="text" autocomplete="off"
                            [formControl]="montoForm" name="montoForm" maxlength="10" inputmode="decimal">
                        <mat-error *ngIf="montoForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoForm.hasError('min')">
                            <strong>{{idiomas.montoMinimoRecargas}} - {{montominimo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoForm.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.Tasa}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="tasaAhorro" name="tasaAhorro">
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.DiaMes}}</mat-label>
                        <input matInput autocomplete="off" (focus)="FechaCalculada()" (keyup)="FechaCalculada()"
                            (change)="FechaCalculada()" type="number" [formControl]="diasForm" min="1" max="31" #input inputmode="numeric">
                        <mat-error *ngIf="diasForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="diasForm.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="diasForm.hasError('min')">
                            <strong>{{idiomas.NMinimo}} 1</strong>
                        </mat-error>
                        <mat-error *ngIf="diasForm.hasError('max')">
                            <strong>{{idiomas.NMaximo}} 31</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.NumCuotasA}}</mat-label>
                        <mat-select [formControl]="numeroCuotasForm" (selectionChange)="FechaCalculada()">
                            <mat-option *ngFor="let cuota of lvCutoas" [value]="cuota.CODIGO">
                                {{cuota.CODIGO}}
                                <mat-divider style="border-top-width: 3px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="numeroCuotasForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.FDesde}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="finicio" name="fhasta">
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.FHasta}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="fvencimiento" name="fhasta">
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.NetoGanado}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="interesGanado" name="interesGanado">
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 160px;"
                    *ngIf="diasForm.valid && montoForm.valid && numeroCuotasForm.valid">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                        <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()">
                            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta"
                                (onSelectionChange)="selectedOptionOrigen(cuenta)">
                                {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                                <mat-divider style="border-top-width: 3px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 160px;"
                    *ngIf="diasForm.valid && montoForm.valid && numeroCuotasForm.valid && mostrarSucursal">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.AgenciasC}}</mat-label>
                        <mat-select [formControl]="agencyFormControl" (selectionChange)="onSelectAgency($event.value)">
                            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value.toUpperCase())"
                                oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                                (keydown)="$event.stopPropagation()"
                                placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                                style="height: 35px;border-bottom-style: groove;">
                            <mat-option *ngFor="let agency of agencies" [value]="agency.CSUCURSAL"
                                style="padding-right: 50px!important">
                                {{agency.NOMBRE}}
                                <mat-divider style="border-top-width: 1px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div *ngIf="mostrarTerminos">
                <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedC" (change)="terminosC($event)"></mat-checkbox>
                    <label style="padding-left: 5px; padding-bottom: 15px">{{idiomas.AceptarT1}}<u
                            style="cursor:pointer" (click)="reporteTerminos()">{{idiomas.AceptarT2}}</u></label>
                </div>
                <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px;">
                    <mat-checkbox name="check" color="primary"
                    [checked]="checkedI" [disabled]="deshabilitarI" (change)="informacionC($event)"></mat-checkbox>
                    <label style="padding-left: 5px; padding-bottom: 15px"><u style="cursor:pointer"
                            (click)="reporteInformacion()">{{idiomas.AceptarT3}}</u></label>
                </div>
                <div class="form-row" *ngIf="internet.internet" autocomplete="off">
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="password" autocomplete="off"
                            [formControl]="tokenFormControl" maxlength="6" appBlockCopyPaste>
                        <mat-error *ngIf="tokenFormControl.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div *ngIf="internet.internet" class="left col-2 token-button">
                        <button [disabled]="(!checkedC || !checkedI) || (enableCronoIn)" mat-raised-button
                            (click)="solicitarToken()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col"
                        style="padding-left: 25px !important; margin-top: 5px !important">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <div *ngIf="internet.internet && generaDocumento"
                        style="cursor:pointer;max-width: 30px !important; padding-top: 1em; padding-left: 50px;">
                        <a (click)="reportePDFAhorro()">
                            <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                                picture_as_pdf</mat-icon></a>
                    </div>
                </div>
            </div>
            <div *ngIf=intento style="padding-top: 10px; padding-bottom: 10px;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="form-row">
                <div id="floatCont" class="col-6">
                    <button mat-raised-button
                        *ngIf="diasForm.valid && cuentaDesdeSelected.valid && montoForm.valid && numeroCuotasForm.valid && tokenFormControl.valid && mostrarTerminos"
                        (click)="guardarAhorro()" title={{idiomas.Nuevo}} color="primary" class="buttonSubmit"
                        [disabled]="disableButton">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.Aceptar}}
                    </button>
                </div>
                <div id="floatCont" align="end" style="text-align: end;" class="col-6">
                    <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn"
                        class="buttonClean">
                        <mat-icon> clear_all</mat-icon>
                        {{idiomas.Limpiar}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card>
    <div *ngIf="bandMobile">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.AhorroProgramado | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding:16px">
            <div autocomplete="off" class="form-row">

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.MontoMensual}}</mat-label>
                        <input class="numeros" matInput (focus)="caretInputAmount($event)"
                            (keyup)="amountKeyInputControl($event)" type="text" autocomplete="off"
                            [formControl]="montoForm" name="montoForm" maxlength="10" inputmode="decimal">
                        <mat-error *ngIf="montoForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoForm.hasError('min')">
                            <strong>{{idiomas.montoMinimoRecargas}} - {{montominimo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="montoForm.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.Tasa}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="tasaAhorro" name="tasaAhorro">
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.DiaMes}}</mat-label>
                        <input matInput autocomplete="off" (focus)="FechaCalculada()" (keyup)="FechaCalculada()"
                            (change)="FechaCalculada()" type="number" [formControl]="diasForm" min="1" max="31" #input inputmode="numeric">
                        <mat-error *ngIf="diasForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="diasForm.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="diasForm.hasError('min')">
                            <strong>{{idiomas.NMinimo}} 1</strong>
                        </mat-error>
                        <mat-error *ngIf="diasForm.hasError('max')">
                            <strong>{{idiomas.NMaximo}} 31</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.NumCuotasA}}</mat-label>
                        <mat-select [formControl]="numeroCuotasForm" (selectionChange)="FechaCalculada()">
                            <mat-option *ngFor="let cuota of lvCutoas" [value]="cuota.CODIGO">
                                {{cuota.CODIGO}}
                                <mat-divider style="border-top-width: 3px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="numeroCuotasForm.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.FDesde}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="finicio" name="fhasta">
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.FHasta}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="fvencimiento" name="fhasta">
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 145px;">
                    <mat-form-field appearance="fill" fxFlexFill>
                        <mat-label>{{idiomas.NetoGanado}}</mat-label>
                        <input matInput type="text" readonly [(ngModel)]="interesGanado" name="interesGanado">
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 160px;"
                    *ngIf="diasForm.valid && montoForm.valid && numeroCuotasForm.valid">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                        <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()"
                        (openedChange)="funcionRetornarCuentasOwn($event)">
                            <mat-option *ngIf="!intentoCuentasOwn" disabled>
                                <div style="margin: 10px;">
                                    <mat-progress-bar mode="indeterminate">
                                    </mat-progress-bar>
                                </div>
                            </mat-option>
                            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta"
                                (onSelectionChange)="selectedOptionOrigen(cuenta)">
                                {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                                <mat-divider style="border-top-width: 3px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6" [class.col-12]="bandMobile" style="min-width: 160px;"
                    *ngIf="diasForm.valid && montoForm.valid && numeroCuotasForm.valid && mostrarSucursal">
                    <mat-form-field appearance="outline" fxFlexFill>
                        <mat-label>{{idiomas.AgenciasC}}</mat-label>
                        <mat-select [formControl]="agencyFormControl" (selectionChange)="onSelectAgency($event.value)">
                            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value.toUpperCase())"
                                oninput="this.value = this.value.toUpperCase();" autocomplete="off"
                                (keydown)="$event.stopPropagation()"
                                placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                                style="height: 35px;border-bottom-style: groove;">
                            <mat-option *ngFor="let agency of agencies" [value]="agency.CSUCURSAL"
                                style="padding-right: 50px!important">
                                {{agency.NOMBRE}}
                                <mat-divider style="border-top-width: 1px;"></mat-divider>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div *ngIf="mostrarTerminos">
                <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedC" (change)="terminosC($event)"
                        [disabled]="deshabilitar"></mat-checkbox>
                    <label style="padding-left: 5px; padding-bottom: 15px">{{idiomas.AceptarT1}}<u
                            style="cursor:pointer" (click)="reporteTerminos()">{{idiomas.AceptarT2}}</u></label>
                </div>
                <div class="rowCardTopSpace col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="text-align: left; padding-left: 0px;">
                    <mat-checkbox name="check" color="primary" [checked]="checkedI" (change)="informacionC($event)"
                        [disabled]="deshabilitarI"></mat-checkbox>
                    <label style="padding-left: 5px; padding-bottom: 15px"><u style="cursor:pointer"
                            (click)="reporteInformacion()">{{idiomas.AceptarT3}}</u></label>
                </div>
                <div class="form-row" *ngIf="internet.internet" autocomplete="off">
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="password" autocomplete="off"
                            [formControl]="tokenFormControl" maxlength="6" appBlockCopyPaste>
                        <mat-error *ngIf="tokenFormControl.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div *ngIf="internet.internet" class="left col-2 token-button">
                        <button [disabled]="(!checkedC || !checkedI) || (enableCronoIn)" mat-raised-button
                            (click)="solicitarToken()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col"
                        style="padding-left: 25px !important; margin-top: 5px !important">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <div *ngIf="internet.internet && generaDocumento"
                        style="cursor:pointer;max-width: 30px !important; padding-top: 1em; padding-left: 50px;">
                        <a (click)="reportePDFAhorro()">
                            <mat-icon color="primary" style="font-size: 30px; height: 30px; width: 30px;">
                                picture_as_pdf</mat-icon></a>
                    </div>
                </div>
            </div>
            <div *ngIf=intento style="padding-top: 10px; padding-bottom: 10px;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="form-row">
                <div id="floatCont" class="col-6">
                    <button mat-raised-button
                        *ngIf="diasForm.valid && cuentaDesdeSelected.valid && montoForm.valid && numeroCuotasForm.valid && tokenFormControl.valid && mostrarTerminos"
                        (click)="guardarAhorro()" title={{idiomas.Nuevo}} color="primary" class="buttonSubmit"
                        [disabled]="disableButton">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.Aceptar}}
                    </button>
                </div>
                <div id="floatCont" align="end" style="text-align: end;" class="col-6">
                    <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn"
                        class="buttonClean">
                        <mat-icon> clear_all</mat-icon>
                        {{idiomas.Limpiar}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>