import { Component, OnInit, Input, SimpleChange, SimpleChanges, HostListener } from '@angular/core';
import { FormControlValidators } from '../funciones/formcontrol';
import { internetComponent } from '../funciones/internet';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { Servicios } from '../funciones/encryptar';
import { Alerta } from '../funciones/alerta';
import { FuncionesTabla } from '../funciones/funcionesTabla';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans,} from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { TransferenciasCuentasPropiasComponent } from '../transferencias-cuentas-propias/transferencias-cuentas-propias.component';
import { TransferenciasMismaInstitutcionComponent } from '../transferencias-misma-institutcion/transferencias-misma-institutcion.component';
import { TransferenciasComponent } from '../transferencias/transferencias.component';
import { TransferenciaTarjetasCreditoComponent } from '../transferencia-tarjetas-credito/transferencia-tarjetas-credito.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { ConsultaTransferenciasComponent } from '../consulta-transferencias/consulta-transferencias.component';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { CurrencyInputDirective } from '../currency-input.directive';

@Component({
  standalone: true,
  imports: [OcultarCuentaPosTrans, MatIconModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTabsModule, MatCardModule, MatMenuModule, MatButtonModule, MatDividerModule, MatRadioModule, MatProgressBarModule, MatTableModule, MatCheckboxModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, CurrencyInputDirective, FormatoFechaCalendar, FlexLayoutModule, TransferenciasCuentasPropiasComponent, TransferenciasMismaInstitutcionComponent, TransferenciasComponent, TransferenciaTarjetasCreditoComponent, ConsultaTransferenciasComponent, TemporizadorComponent],
  selector: 'app-beneficiarios',
  templateUrl: './beneficiarios.component.html',
  styleUrls: ['./beneficiarios.component.css']
})
export class BeneficiariosComponent implements OnInit {

  //Inputs
  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;
  @Input() bandTarjetaCredito: any;
  @Input() pendingTaskData: any;
  @Input() account: string;

  //Variables
  continueRegisterProcess: boolean = false;
  continueProcess: boolean = false;
  bandCheck: boolean;
  flechaR: boolean;
  flechaL: boolean;
  paraScroll: any;
  topOfTable: any;
  container: boolean;
  band: boolean = true;
  numMaxRow: number;
  intento: boolean;
  intentoBene: boolean;
  intentop: boolean;
  expirar: boolean;
  bandsFuncion: any;
  dataTarPend: any;
  displayedColumns: string[] = ['NombreBeneficiario', 'Cuenta', 'Banco', 'Editar', 'Caducar'];
  displayedColumnsInt: string[] = ['NombreBeneficiario', 'Cuenta', 'Banco', 'Caducar'];
  displayedColumnsExt: string[] = ['NombreBeneficiario', 'Cuenta', 'Banco', 'Editar', 'Caducar'];
  datos2: IBeneficiarioC[] = [];
  datosOtros: IBeneficiarioC[] = [];
  datosMism: IBeneficiarioC[] = [];
  datos: IBeneficiarioC = {
    id: '',
    tipo: '',
    etiqueta: '',
    nombreBeneficiario: '',
    identificacionBeneficiario: '',
    cpersona_banco: '',
    cbanco: '',
    efiInstFinan: '',
    fregistro: '',
    tipodesc: '',
    cmoneda: '',
    telefono: '',
    email: '',
    montoMaximo: '',
    ctipoIdentificacion: '',
    versionControl: '',
    caducar: false
  };
  tieneBeneficiarios: boolean = true;
  tieneBeneficiariosExterno: boolean =false;
  tieneBeneficiariosPJL: boolean =false;
  tieneBeneficiariosPropio: boolean =false;
  bandId: boolean = false;
  autoSelectedEFI: boolean = false;
  bandTransfer: boolean = false;
  showNuevoBeneficiario: boolean = false;
  intentoCodInstOrdenante: boolean = false;
  id: any;
  tipo: any;
  etiqueta: any;
  nombreBeneficiario: any;
  identificacionBeneficiario: any;
  cpersona_banco: any;
  cbanco: any;
  efiInstFinan: any;
  fregistro: any;
  tipodesc: any;
  cmoneda: any;
  telefonoC: any;
  mail: any;
  cmonedaBeneficiario: any;
  ccuentaBeneficiario: any;
  cpersonaEmpresaBancariaBeneficiario: any;
  ctipoIdentificacion: any;
  versionControl: any;
  lbancos: any[] = [];
  linternos: any[] = [];
  lexternos: any[] = [];
  intentoBancos: boolean;
  lfiltradobancos: any[] = [];
  lbancosInicial: any[] = [];
  controlBancos = this.validators.comboForm();
  listadocuentas: any[] = [];
  controlTCuenta = this.validators.comboForm();
  lmonedas: IMonedas[] = [];
  controlMoneda = this.validators.comboForm();
  lidentificaciones: any[] = [];
  controlIdTipo = this.validators.comboForm();
  idusuario: any;
  idiomaactual: string;
  beneficiarioingreso: any = {
    tipocuenta: '',
    cuentabeneficiario: '',
    cmoneda: '',
    cpersona_empresabancaria: '',
    cpersona: this.base.cpersona,    //para cpersona y cpersona_usuario
    identificacionbeneficiario: '',
    nombre: '',   //nombre del beneficiario
    telefono: '',
    email: '',
    montoMaximo: '',
    zonarutatransito: 'LOCAL',
    nombre_banco: '',
    id_banco: '',            //
    ctipoidentificacion: '',
    codigoInstitucionon2Ordenante: '',
  };
  respuestaToken: any = '';
  ingtoken: boolean;
  bandBtnCaducar: boolean;
  intentoEditar: boolean = false;
  intentoEditarlBancos: boolean = false;
  bandMobile: boolean = false;
  cuenta: string = '';
  infoLocalBenefQuery: boolean = false;
  infoLocalBenef: any = {};
  localBenefRegisterBand: boolean = false;
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  disableButton: boolean = false;
  mostrarOtros: boolean = false;
  mostarMisma: boolean = false;
  mostrarPropias: boolean = false;
  pinValid: boolean = false;
  pinValidation: boolean;
  beneficiaryExist: any = '0';

  //Valores disponibles para lista Tipos Cuentas
  tcuentasEs: any[] = [
    { id: 'AH', nombre: 'AHORROS' },
    { id: 'CO', nombre: 'CORRIENTE' }
  ];
  tcuentasEn: any[] = [
    { id: 'AH', nombre: 'SAVINGS' },
    { id: 'CO', nombre: 'CHECKING' }
  ];

  //Valores disponibles para lista Tipo Identificación
  tidentificacionEs: any[] = [
    { id: 'CED', descripcion: 'CÉDULA DE IDENTIDAD' },
    { id: 'RUC', descripcion: 'RUC' },
    { id: 'PAS', descripcion: 'PASAPORTE' }
  ];
  tidentificacionEn: any[] = [
    { id: 'CED', descripcion: 'ID CARD' },
    { id: 'RUC', descripcion: 'RUC' },
    { id: 'PAS', descripcion: 'PASSPORT' }
  ];

  //FormControls
  numcuenta = this.validators.accountNumberForm();
  identificacion = this.validators.idForm();
  nombre = this.validators.textForm();
  nombreBanred = this.validators.texNumberSpecialFeaturetForm();
  telefono = this.validators.numberForm();
  email = this.validators.emailForm();
  token = this.validators.otpForm();
  orden = this.validators.numberForm();
  amountFormControl = this.validators.decimalsDolarForm();;

  constructor(private api: ApiService, private base: BaseDatos, private validators: FormControlValidators,
    public internet: internetComponent, public servicios: Servicios, public alerta: Alerta, private funcionesTabla: FuncionesTabla) {
    this.pinValidation = this.base.retornarConsulta("pinValidation")
    this.bandCheck = true;
    this.intento = false;
    this.intentop = false;
    this.ingtoken = false;
    this.datos2 = [];
    this.flechaR = false;
    this.flechaL = true;
    this.showNuevoBeneficiario = false;
    this.paraScroll = 0;
    this.topOfTable = 0;
    this.container = false;
    this.numMaxRow = 6;
    this.idiomaactual = localStorage.getItem('locale');
    this.bandBtnCaducar = false;
    this.expirar = false;
    this.infoLocalBenefQuery = false;
    this.infoLocalBenef = {};
    this.intentoEditar = false;
    this.intentoEditarlBancos = false;
    this.bandMobile = false;
    this.intentoBancos = false;
    this.bandsFuncion = {
      'transCtsProp': false,
      'transMismaInst': false,
      'transOtraInst': false,
      'beneficiarios': true,
    };
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.intentoCodInstOrdenante = false;
  }

  ngOnInit() {
    this.bandMobile = false;
    this.consultarBeneficiarios();
    this.token.setValue('');
    this.intento = true;
    this.bandsFuncion = {
      'transCtsProp': false,
      'transMismaInst': false,
      'transOtraInst': false,
      'beneficiarios': true
    }
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  async ngAfterViewInit() {
    Promise.resolve(null).then(async () => {
      if (this.pendingTaskData) {
        if (this.pendingTaskData.tarPendType != null) {
          this.beneficiarioPendingTask(this.pendingTaskData);
        }
        else {
          if (this.pendingTaskData.mismaInstitucion) {
            this.localBenefRegisterBand = true;
            this.beneficiarioMismaInstitucion();
          }
          else {
            this.nuevoBeneficiario();
          }
        }
      }
    });
  }

  scroll3(id) {
    if (id == 'tablaBeneficiarios' || id == 'tablaBeneficiariosExt') {
      var container1 = document.getElementsByClassName('container2Aux');
    }
    var tabla = document.getElementsByClassName(id);
    if (container1.item(0).scrollLeft != 0) {
      if (container1[0].scrollLeft + 2 >= (tabla[0].clientWidth - container1[0].clientWidth)) {
        this.flechaR = true;
        this.flechaL = false;
      } else {
        this.flechaR = false;
        this.flechaL = true;
      }
    } else {
      this.flechaR = false;
      this.flechaL = true;
    }
  }

  showHideRows3(last) {
    if (last) {
      try {
        if (this.mostarMisma) {
          this.funcionesTabla.DivHeight(this.datosMism);
          let classContainer1Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
          if (this.grande) {
            document.getElementsByClassName('tablaBeneficiarios')[0].setAttribute('style', 'width: 800px');
            if (800 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
              document.getElementsByClassName('tablaBeneficiarios')[0].setAttribute('style', 'width: 100%');
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('tablaBeneficiarios')[0].setAttribute('style', 'width: 700px');
            if (700 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
              document.getElementsByClassName('tablaBeneficiarios')[0].setAttribute('style', 'width: 100%');
            }
          }
          if (this.normal) {
            document.getElementsByClassName('tablaBeneficiarios')[0].setAttribute('style', 'width: 600px; font-size: 14px');
            if (600 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
              document.getElementsByClassName('tablaBeneficiarios')[0].setAttribute('style', 'width: 100%; font-size: 14px');
            }
          }
        } else {
          this.funcionesTabla.DivHeight(this.datosOtros);
          let classContainer1Width = document.getElementsByClassName('container2Aux')[0].clientWidth;
          if (this.grande) {
            document.getElementsByClassName('tablaBeneficiariosExt')[0].setAttribute('style', 'width: 800px');
            if (800 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
              document.getElementsByClassName('tablaBeneficiariosExt')[0].setAttribute('style', 'width: 100%');
            }
          }
          if (this.mediano) {
            document.getElementsByClassName('tablaBeneficiariosExt')[0].setAttribute('style', 'width: 700px');
            if (700 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
              document.getElementsByClassName('tablaBeneficiariosExt')[0].setAttribute('style', 'width: 100%');
            }
          }
          if (this.normal) {
            document.getElementsByClassName('tablaBeneficiariosExt')[0].setAttribute('style', 'width: 600px; font-size: 14px');
            if (600 - classContainer1Width > 0) {
              document.getElementById('flechaR').setAttribute('style', 'visibility: visible;');
              if (!this.flechaL) {
                document.getElementById('flechaL').setAttribute('style', 'visibility: visible;');
              }
            } else {
              document.getElementById('flechaR').setAttribute('style', 'visibility: hidden;');
              document.getElementById('flechaL').setAttribute('style', 'visibility: hidden;');
              document.getElementsByClassName('tablaBeneficiariosExt')[0].setAttribute('style', 'width: 100%; font-size: 14px');
            }
          }
        }

      } catch (e) {
      }
    }
  }

  scrollToTab(band) {
    if (band && this.paraScroll < 1) {
      scroll(0, this.topOfTable - document.getElementById("tool-bar").getBoundingClientRect().height);
      this.paraScroll = this.paraScroll + 1;
    }
  }

  scrollTo(clase, direccion) {
    let tabla = document.getElementsByClassName(clase);
    if (clase == 'tablaBeneficiarios' || clase == 'tablaBeneficiariosExt') {
      let container1 = document.getElementsByClassName('container2Aux');
      if (direccion == 'right') {
        container1.item(0).scrollLeft = tabla[0].clientWidth - container1[0].clientWidth;
      } else {
        container1.item(0).scrollLeft = 0;
        this.flechaL = true;
        this.flechaR = false;
      }
    }
  }

  beneficiarioMismaInstitucion() {
    this.showNuevoBeneficiario = true;
    this.nombre.setValue('COOPERATIVA DE AHORRO Y CRÉDITO JULIAN LORENTE')
    this.beneficiarioingreso.nombre_banco = 'COOPERATIVA DE AHORRO Y CRÉDITO JULIAN LORENTE'
    this.beneficiarioingreso.cpersona_empresabancaria = '2';
    this.controlBancos.setValue('2');
    this.beneficiarioingreso.id_banco = '2';
    this.autoSelectedEFI = true;
  }

  beneficiarioPendingTask(beneficiarioPendiente) {
    this.showNuevoBeneficiario = true;
    this.beneficiarioingreso.cpersona_empresabancaria = beneficiarioPendiente.CPersonaEmpresaBancaria;
    this.controlBancos.setValue(beneficiarioPendiente.idBanco);
    this.beneficiarioingreso.nombre_banco = beneficiarioPendiente.nombreBanco;
    this.beneficiarioingreso.id_banco = beneficiarioPendiente.idBanco;
    this.numcuenta.setValue(beneficiarioPendiente.cuentabeneficiario);
    this.controlIdTipo.setValue(beneficiarioPendiente.CTipoIdentificacion);
    this.beneficiarioingreso.ctipoidentificacion = beneficiarioPendiente.CTipoIdentificacion;
    this.identificacion.setValue(beneficiarioPendiente.identificacionBeneficiario);
    this.nombre.setValue(beneficiarioPendiente.nombre.toUpperCase());
    this.controlTCuenta.setValue(beneficiarioPendiente.tipoCuenta);
    this.beneficiarioingreso.tipocuenta = beneficiarioPendiente.tipoCuenta;
    this.controlMoneda.setValue(beneficiarioPendiente.CMoneda);
    this.beneficiarioingreso.cmoneda = beneficiarioPendiente.CMoneda;
    this.telefono.setValue(beneficiarioPendiente.telefono);
    this.email.setValue(beneficiarioPendiente.mail);
    this.amountFormControl.setValue(beneficiarioPendiente.montoMaximo)
  }

  ngOnChanges(changes: SimpleChanges) {
    const cambioIdiomas: SimpleChange = changes.idiomas;
    if (cambioIdiomas) {
      this.idiomaactual = localStorage.getItem('locale')
      if (this.idiomaactual == 'en') {
        this.listadocuentas = this.tcuentasEn;
        this.lidentificaciones = this.tidentificacionEn;
      } else {
        this.listadocuentas = this.tcuentasEs;
        this.lidentificaciones = this.tidentificacionEs;
      }
    }
  }

  nuevoBeneficiario() {
    this.obtainOwnAccount();
    document.querySelector('.mat-sidenav-content').scrollTop = 0;
    this.lfiltradobancos = this.lbancos;
    if (this.showNuevoBeneficiario == true) {
      this.showNuevoBeneficiario = false;
    } else {
      this.showNuevoBeneficiario = true;
    }
  }

  editarBenfeiciario(dato) {
    this.intentoEditar = true;
    //Datos para Editar
    this.id = dato.id;
    this.tipo = dato.tipo;
    this.nombreBeneficiario = dato.nombreBeneficiario;
    this.identificacionBeneficiario = dato.identificacionBeneficiario;
    this.cpersona_banco = dato.cpersona_banco;
    this.cbanco = dato.cbanco;

    if (dato.ctipoIdentificacion == 'CED') {
      this.identificacion = this.validators.idForm();
    } else {
      if (dato.ctipoIdentificacion == 'PAS') {
        this.identificacion = this.validators.pasForm();
      }
      else {
        this.identificacion = this.validators.rucForm();
      }
    }

    //Datos actuales en el formulario 
    this.controlIdTipo.setValue(dato.ctipoIdentificacion);
    this.beneficiarioingreso.cpersona_empresabancaria = dato.cpersona_banco;
    this.controlBancos.setValue(dato.cpersona_banco);
    this.beneficiarioingreso.nombre_banco = dato.cbanco;
    this.beneficiarioingreso.id_banco = dato.efiInstFinan;
    this.numcuenta.setValue(dato.id);
    this.controlIdTipo.setValue(dato.ctipoIdentificacion);
    this.beneficiarioingreso.ctipoidentificacion = dato.ctipoIdentificacion;
    this.identificacion.setValue(dato.identificacionBeneficiario);
    this.nombre.setValue(dato.nombreBeneficiario.toUpperCase());
    this.controlTCuenta.setValue(dato.tipo);
    this.beneficiarioingreso.tipocuenta = dato.tipo;
    this.controlMoneda.setValue(dato.cmoneda);
    this.beneficiarioingreso.cmoneda = dato.cmoneda;
    this.telefono.setValue(dato.telefono);
    this.email.setValue(dato.email);
    this.amountFormControl.setValue(dato.montoMaximo)
    if (this.lbancosInicial.length == 0) {
      this.retornarBancosEditar()
    } else {
      this.showNuevoBeneficiario = true;
    }
    this.continueProcess = true;
  }

  consultarBeneficiarios() {
    this.intento = true;
    let bandTarjeta = this.bandTarjetaCredito == 2 ? "1" : "0";
    var cont = 0;
    const envio = { tarCredito: bandTarjeta };
    this.api.postProvider2InternetCheck('/beneficiarios', this.base.id_token, envio).then((data: any) => {
      this.datos2 = [];
      this.datosMism = [];
      this.datosOtros = [];
      
      
      if (data.length == 0) {
        this.tieneBeneficiarios = false;
      } else {
        this.cargarListaBeneficiarios(data,cont);
        let consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.datos2,
        };
        if (this.bandTarjetaCredito != 2) {
          this.base.insertarConsulta('BeneficiariosRegistrados', consulta); //Almacena en BDD los beneficiarios registrados
        } else {
          this.base.insertarConsulta('BeneficiariosRegistradosTarjetasCredito', consulta); //Almacena en BDD los beneficiarios de trajeta de credito registrados
        }
      }
      this.intento = false;
      this.retornarMonedas();
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
        this.bandCheck = true;
      }
      else {
        this.intento = false;
        this.band = true;
        this.beneficiariosRegOffline();
      }
    });
  }

  cargarListaBeneficiarios(data: any, cont: number) {

    this.tieneBeneficiarios = true;
    for (const aux of data) {
      if (aux.origenBeneficiario != 'QR') {
        this.datos.id = aux.id;
        this.datos.tipo = aux.tipo;
        this.datos.etiqueta = aux.etiqueta;
        this.datos.nombreBeneficiario = aux.nombreBeneficiario;
        this.datos.identificacionBeneficiario = aux.identificacionBeneficiario;
        this.datos.cpersona_banco = aux.cpersona_banco;
        this.datos.cbanco = aux.cbanco;
        this.datos.efiInstFinan = aux.efiInstFinan;
        this.datos.fregistro = aux.fregistro;
        this.datos.cmoneda = aux.moneda;
        this.datos.telefono = aux.telefono;
        this.datos.email = aux.email;
        this.datos.montoMaximo = aux.montomaximo;
        this.datos.ctipoIdentificacion = aux.ctipoIdentificacion;
        this.datos.versionControl = aux.versionControl;
        this.datos.caducar = false;
        if (this.datos.tipo == this.idiomas.TiposCuentaBeneficiario[0].value) {
          this.datos.tipodesc = this.idiomas.TiposCuentaBeneficiario[0].viewValue;
        } else if (this.datos.tipo == this.idiomas.TiposCuentaBeneficiario[1].value) {
          this.datos.tipodesc = this.idiomas.TiposCuentaBeneficiario[1].viewValue;
        } else {
          this.datos.tipodesc = this.idiomas.TiposCuentaBeneficiario[2].viewValue;
        }
        this.datos2.push(this.datos);
        this.orden.setValue('1');
        if (this.datos.cpersona_banco == '2') {
          this.tieneBeneficiariosPJL = true;
          this.datosMism.push(this.datos)
          this.linternos = this.datosMism
        } else {
          this.tieneBeneficiariosExterno = true;
          this.datosOtros.push(this.datos)
          this.lexternos = this.datosOtros
        }
        if (this.datosMism.length > 0) {
          this.tieneBeneficiariosPropio = true;
        }
        if (this.datosMism.length >= 0) {
          this.mostarMisma = true;
          this.mostrarOtros = false;
        } else {
          this.mostarMisma = false;
          this.mostrarOtros = true;
        }
        this.datos = {
          id: '',
          tipo: '',
          etiqueta: '',
          nombreBeneficiario: '',
          identificacionBeneficiario: '',
          cpersona_banco: '',
          cbanco: '',
          efiInstFinan: '',
          fregistro: '',
          tipodesc: '',
          cmoneda: '',
          telefono: '',
          email: '',
          montoMaximo: '',
          ctipoIdentificacion: '',
          versionControl: '',
          caducar: false
        };
        cont = cont + 1;

      }
    }
  }

  beneficiariosRegOffline() {
    let ObjConsulta = this.bandTarjetaCredito != 2 ? this.base.retornarConsulta('BeneficiariosRegistrados') : this.base.retornarConsulta('BeneficiariosRegistradosTarjetasCredito');
    if (!ObjConsulta || ObjConsulta.data.length == 0) {
      this.band = false;  //Oculta tabla y muestra carta de no servicios registrados
      this.tieneBeneficiarios = false;
    }
    else {
      this.datos2 = [];
      this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas);
      this.datos2 = ObjConsulta.data;
      this.tieneBeneficiarios = true;
    }
  }

  applyFilterInternos(filterValue: any) {
    this.datosMism = this.linternos.filter((internos) => (internos.id + internos.nombreBeneficiario).indexOf(filterValue) > -1);
    if (this.datosMism.length == 0) {
      this.alerta.presentarAlerta(this.idiomas.MensBene);
    }
  }

  applyFilterExternos(filterValue: any) {
    this.datosOtros = this.lexternos.filter((externos) => (externos.id + externos.nombreBeneficiario).indexOf(filterValue) > -1);
    if (this.datosOtros.length == 0) {
      this.alerta.presentarAlerta(this.idiomas.MensBene);
    }
  }

  radioChange(e) {
    if (this.orden.value == '1') {
      this.mostarMisma = true
      this.mostrarOtros = false
      this.mostrarPropias = false
      this.limpiarBusquedaBeneficiario();
    } else if (this.orden.value == '2') {
      this.mostarMisma = false
      this.mostrarOtros = true
      this.mostrarPropias = false
      this.limpiarBusquedaBeneficiario();
    } else {
      this.mostarMisma = false
      this.mostrarOtros = false
      this.mostrarPropias = true
      this.limpiarBusquedaBeneficiario();
    }
  }
  limpiarBusquedaBeneficiario() {
    this.datosMism = this.linternos;
    this.datosOtros = this.lexternos;
  }
  caducarBeneficiarios() {
    this.expirar = true;
    this.bandBtnCaducar = false;
    this.idusuario = this.base.usuario;
    let algosecaduca = false;
    var envio = {
      transaccion: '184024-man-in.xml',
      usuario: this.base.usuario
    };
    var cont = 0;
    for (const bene of this.datos2) {
      if (bene.caducar) {
        envio['rec^tprb0^' + cont + '^IDENTIFICACIONBENEFICIARIO'] = bene.identificacionBeneficiario;
        envio['rec^tprb0^' + cont + '^NOMBRE'] = bene.nombreBeneficiario;
        envio['rec^tprb0^' + cont + '^CUENTABENEFICIARIO'] = bene.id;
        envio['rec^tprb0^' + cont + '^TIPOCUENTA'] = bene.tipo;
        envio['rec^tprb0^' + cont + '^CPERSONA_EMPRESABANCARIA'] = bene.cpersona_banco;
        envio['rec^tprb0^' + cont + '^CPERSONA'] = "$personCode";
        envio['ctl^CUSUARIOREAL'] = this.idusuario;
        envio['ctl^_MAILEXPIREDBENEFICIARY'] = "1";
        envio['ctl^_ClientName'] = bene.nombreBeneficiario;
        envio['ctl^USR'] = this.idusuario;
        envio['ctl^CPERSONA_USUARIO'] = "$personCode";
        cont = cont + 1;
        algosecaduca = true;
      }
    }
    this.api.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
      this.expirar = false;
      this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase());
      this.consultarBeneficiarios();
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        this.expirar = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario);
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
        this.expirar = false;
      }
    });
    if (!algosecaduca) {
      this.expirar = false;
      this.alerta.presentarAlertaX(this.idiomas.NadaCaducar.toUpperCase());
      this.intento = false;
    }
  }

  caducarBeneficiario(dato) {
    this.alerta.generarDialogoSeguroCaducar(this.idiomas).then((data) => {
      if (data) {
        this.expirar = true;
        this.bandBtnCaducar = false;
        this.idusuario = this.base.usuario;
        let algosecaduca = false;
        var envio = {
          transaccion: '184024-man-in.xml',
          usuario: this.base.usuario
        };
        var cont = 0;
        envio['rec^tprb0^' + cont + '^IDENTIFICACIONBENEFICIARIO'] = dato.identificacionBeneficiario;
        envio['rec^tprb0^' + cont + '^NOMBRE'] = dato.nombreBeneficiario;
        envio['rec^tprb0^' + cont + '^CUENTABENEFICIARIO'] = dato.id;
        envio['rec^tprb0^' + cont + '^TIPOCUENTA'] = dato.tipo;
        envio['rec^tprb0^' + cont + '^CPERSONA_EMPRESABANCARIA'] = dato.cpersona_banco;
        envio['rec^tprb0^' + cont + '^CPERSONA'] = "$personCode";
        envio['ctl^CUSUARIOREAL'] = this.idusuario;
        envio['ctl^_MAILEXPIREDBENEFICIARY'] = "1";
        envio['ctl^_ClientName'] = dato.nombreBeneficiario;
        envio['ctl^USR'] = this.idusuario;
        envio['ctl^CPERSONA_USUARIO'] = "$personCode";

        algosecaduca = true;
        this.api.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
          this.expirar = false;
          this.alerta.presentarAlerta(this.idiomas.TransaccionE.toUpperCase());
          this.consultarBeneficiarios();
        }, (err) => {
          if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
            this.expirar = false;
            if (err.error) {
              if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
              else {
                this.alerta.presentarAlertaX(err.error.mensajeUsuario);
              }
            }
            else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError);
            }
          }
          else { //Sin Internet
            this.expirar = false;
          }
        });
        if (!algosecaduca) {
          this.expirar = false;
          this.alerta.presentarAlertaX(this.idiomas.NadaCaducar.toUpperCase());
          this.intento = false;
        }
      }
    });
  }

  //Método para poblar formulario cuando beneficiario local
  consultarUserInformation() {
    this.alerta.clearAlerta();
    this.infoLocalBenefQuery = false;
    this.infoLocalBenef = {};
    this.intentop = true;
    let consultaUsuarios = {
      tipoIdentificacionBeneficiario: this.beneficiarioingreso.ctipoidentificacion,
      identificacionBeneficiario: this.identificacion.value,
      tipoCuentaBeneficiario: this.beneficiarioingreso.tipocuenta,
      cuentaBeneficiario: this.numcuenta.value,
      cmoneda: this.beneficiarioingreso.cmoneda
    };
    this.api.postProvider2InternetCheck('/infoClientePorCuenta', this.base.id_token, consultaUsuarios).then((data: any) => {
      this.infoLocalBenefQuery = true;
      this.infoLocalBenef = data;
      this.beneficiarioLocalAutoInfo();
      this.continueProcess = !this.continueProcess;
      this.intentop = false;
    }, (err) => {
      this.intentop = false;
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        this.intento = false;
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario);
          }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
  }

  beneficiarioLocalAutoInfo() {
    this.controlIdTipo.setValue(this.infoLocalBenef.TipoDocumentoTitular);
    this.beneficiarioingreso.ctipoidentificacion = this.infoLocalBenef.TipoDocumentoTitular;
    this.identificacion.setValue(this.infoLocalBenef.DocumentoTitular);
    this.nombre.setValue(this.infoLocalBenef.NombreTitular.toUpperCase());
    this.telefono.setValue(this.infoLocalBenef.TelefonoTitular);
    this.email.setValue(this.infoLocalBenef.EmailTitular);
  }


  //Método para poblar formulario cuando beneficiario local
  consultarUserInformationPagoDirecto() {
    this.alerta.clearAlerta()
    this.infoLocalBenefQuery = false;
    this.infoLocalBenef = {};
    this.intentop = true;
    let tipoCuenta = this.bandTarjetaCredito == 2 ? "02" : this.beneficiarioingreso.tipocuenta == 'CO' ? '04' : '05'
    let envio = {
      "transaccion": "046179-con-Beneficiario-Banred.xml",
      "ctl^CCUENTA_DEBITO": this.cuenta,
      "ctl^CPERSONA": "$personCode",
      "ctl^CPERSONA_IFI_CREDITO": this.beneficiarioingreso.id_banco,
      "ctl^NOMBRE_IFI_CREDITO": this.beneficiarioingreso.nombre_banco,
      "ctl^CUENTA_CREDITO": this.numcuenta.value,
      "ctl^TIPOCUENTA_CREDITO": tipoCuenta,
      "ctl^MONEDA_CREDITO": this.beneficiarioingreso.cmoneda,
      "ctl^IDENTIFICACION_CREDITO": this.identificacion.value,
      "ctl^IDENTIFICACIONBENEFICIARIO": this.identificacion.value,
      "ctl^NUMEROPAPELETA": tipoCuenta + "|" + this.numcuenta.value + "|" + this.beneficiarioingreso.cmoneda,
      "ctl^CODIGOPERSONA": this.beneficiarioingreso.id_banco,
    }
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.infoLocalBenefQuery = true;
        this.infoLocalBenef = data
        let nombre = data.ctl.NOMBRELEGAL_CREDITO != null || data.ctl.NOMBRELEGAL_CREDITO != '' ? data.ctl.NOMBRELEGAL_CREDITO : ""
        let telefono = data.ctl.NUMEROTELEFONO_CREDITO != null || data.ctl.NUMEROTELEFONO_CREDITO != '' ? data.ctl.NUMEROTELEFONO_CREDITO : ""
        this.beneficiarioLocalAutoInfoPagoDirecto(nombre, telefono);
        this.intentop = false;
        this.continueProcess = !this.continueProcess;
      }
    }, (err) => {
      this.intentop = false;
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        this.intento = false;
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
          else {
            if (err.error.errorCode.startsWith('PD')) {
              this.alerta.generarContinuarProcesoBeneficiario(this.idiomas, err.error.mensajeUsuario).then((data) => {
                if (data) {
                  this.continueRegisterProcess = !this.continueRegisterProcess;
                  this.intentoCodInstOrdenante = false;
                  this.continueProcess = !this.continueProcess;
                }
              });
            } else if (!err.error.errorCode.startsWith('PD')) {
              this.alerta.presentarAlertaX(err.error.mensajeUsuario);
            }
          }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      } else { // Sin Internet       
      }
    });
  }

  continueRegister() {
    this.intentoCodInstOrdenante = false;
    this.continueProcess = !this.continueProcess;
    this.alerta.cancelar();
  }

  beneficiarioLocalAutoInfoPagoDirecto(nombre: any, telefono: any) {
    this.controlIdTipo.setValue(this.beneficiarioingreso.ctipoidentificacion,)
    this.beneficiarioingreso.ctipoidentificacion = this.beneficiarioingreso.ctipoidentificacion,
      this.identificacion.setValue(this.identificacion.value)
    this.nombreBanred.setValue(nombre.toUpperCase())
    this.telefono.setValue(telefono)
  }

  solicitarAutorizacion() {
    if (!this.intentoEditar) {
      this.ProcesarIngreso(1, 1)
    } else {
      this.editarRegistro(1)
    }
  }

  SolicitarToken() {
    this.intentop = true;
    this.ingtoken = false;
    const envio = { transaccion: '183211' };
    this.api.postProvider2InternetCheck('/clavetemporal', this.base.id_token, envio).then((data: any) => {
      this.intentop = false;
      this.ingtoken = true;
      this.token.reset();
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase());
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intentop = false;
      this.ingtoken = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
        this.bandCheck = true;
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  logout() {
    let obj = {
      'salir': true
    };
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  ProcesarIngreso(emailBeneficiary, isValidation) {
    this.bandId = false;
    this.intentop = true;
    this.disableButton = true;
    this.cmonedaBeneficiario = this.beneficiarioingreso.cmoneda;
    this.ccuentaBeneficiario = this.numcuenta.value;
    this.cpersonaEmpresaBancariaBeneficiario = this.beneficiarioingreso.cpersona_empresabancaria;
    let envio: any = {};
    envio.otp = this.servicios.toAES(!this.pinValidation ? this.token.value : "000000");
    envio.desencriptar = '1';
    envio.CPersonaEmpresaBancaria = this.beneficiarioingreso.cpersona_empresabancaria;
    envio.nombreBanco = this.beneficiarioingreso.nombre_banco;
    envio.idBanco = this.beneficiarioingreso.id_banco;
    envio.cuentabeneficiario = this.numcuenta.value;
    envio.CTipoIdentificacion = this.beneficiarioingreso.ctipoidentificacion;
    envio.identificacionBeneficiario = this.identificacion.value;
    envio.nombre = this.intentoCodInstOrdenante ? this.nombreBanred.value :this.nombre.value.toUpperCase();
    envio.emailBeneficiary = emailBeneficiary;
    envio.tipoCuenta = this.bandTarjetaCredito == 2 ? "TC" : this.beneficiarioingreso.tipocuenta;
    envio.CMoneda = this.beneficiarioingreso.cmoneda;
    envio.telefono = this.telefono.value ? this.telefono.value : "SIN DATOS";
    envio.mail = this.email.value ? this.email.value : "SIN DATOS";
    envio.montoMaximo = this.amountFormControl.value ? this.amountFormControl.value.replace("$", '').replace(/,/g, '') : "0";
    envio.tarPendType = this.bandTarjetaCredito == 2 ? "regBenTc" : "regBen";
    envio.direccion = "SIN DATOS";
    envio.isMobile = this.bandMobile ? "1" : "0";
    envio.isValidation = isValidation
    envio.origenBeneficiarios = this.bandMobile ? "MBK" : "WEB";
    for (let dato of this.datos2) {
      if (dato.cpersona_banco == this.beneficiarioingreso.cpersona_empresabancaria && dato.id == this.numcuenta.value && dato.identificacionBeneficiario != this.identificacion.value && !this.intentoEditar) {
        this.bandId = true;
        this.intentop = false;
        this.alerta.presentarAlertaX((this.bandTarjetaCredito == 2 ? this.idiomas.TarjetaReg : this.idiomas.BeneficiarioReg) + " " + dato.identificacionBeneficiario);
        break;
      }
    }
    if (!this.bandId) {
      this.api.postProvider2InternetCheck('/ingresobeneficiario', this.base.id_token, envio).then((data: any) => {
        this.alerta.presentarAlerta(this.idiomas.TransaccionExitosa.toUpperCase());
        this.disableForms();
        this.intentop = false;
        this.bandTransfer = true;
        this.mostrarCron = false;
        this.enableCronoIn = false;
        this.disableButton = false;
        this.intentoCodInstOrdenante = false;
        this.continueProcess = false;
      }, (err) => {
        this.intentop = false;
        this.mostrarCron = false;
        this.enableCronoIn = false;
        if (this.bandMobile && this.pinValidation) {
          this.disableButton = true;
        } else {
          this.enableCronoIn = false;
          this.disableButton = false;
        }
        if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
          if (err.error) {
            if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
            else if (err.error.mensaje == "MENSAJE PENDIENTE DE AUTORIZACION POR SOFT TOKEN ") {
              if (isValidation == 1 && this.pinValidation) {
                let user = {
                  userCode: this.base.usuarioHB
                }
                this.alerta.generarDialogoSolicitarAutorizacionData(this.idiomas, this.grande, this.mediano, user, "").then((data: any) => {
                  if (data) {
                    this.pinValid = true
                    this.disableButton = false
                  } else {
                    this.pinValid = false
                    this.disableButton = false
                  }
                }, (err) => {
                  this.intentop = false
                });
              }
            } else {
              this.alerta.presentarAlertaX(err.error.mensajeUsuario);
            }
          }
          else {
            this.alerta.presentarAlerta(this.idiomas.ServidorError);
          }
          this.bandCheck = true;
        }
        else {
          this.base.insertarTarea(envio);
          this.alerta.presentarAlerta(this.idiomas.NoConectado);
        }
      });
    }
  }

  consultarBeneficairioQR(ccuenta: any, cmoneda: any, cpersona_empresabancaria: any, identificacion: any, ctipoidentificacion: any, nombrelegal: any, email: any, telefono: any, codigobancoinsitucion: any, cuentaDesde: any, interna: boolean) {
    let envio = {
      transaccion: '010003-con-datosbeneQR.xml',
      fullNames: true,
      'cri^tct0^IDENTIFICACIONBENEFICIARIO^NORMAL^tct0^=': identificacion,
      'cri^tct0^CUENTABENEFICIARIO^NORMAL^tct0^=': ccuenta,
      'cri^tct0^NOMBRE^NORMAL^tct0^=': nombrelegal,
      'cri^tct0^EMAIL^NORMAL^tct0^=': email,
      'cri^tct0^CPERSONA^NORMAL^tct0^=': "$personCode",
    }
    let sendTransferJSON = {
      cuentaOrigen: cuentaDesde,
      cuentaDestino: ccuenta,
      monto: '0',
      referencia: '',
      moneda: cmoneda,
      cpersonaBanco: cpersona_empresabancaria,
      isBeneQR: '1',
      beneficiaryExist: this.beneficiaryExist,
      tarPendType: 'transferCtasTer',
      resender: true
    };
    this.api.postProvider2InternetCheck('/consulta', this.base.id_token, envio).then((data: any) => {
      if (data.tct0.length < 1) {
        if (interna) {
          this.ProcesarIngresoQR(ccuenta, cmoneda, cpersona_empresabancaria, identificacion, ctipoidentificacion, nombrelegal, email, telefono, cuentaDesde);
        } else {
          this.ProcesarIngresoExternoQR(ccuenta, cmoneda, interna ? cpersona_empresabancaria : codigobancoinsitucion, identificacion, ctipoidentificacion, nombrelegal, email, telefono, cuentaDesde, codigobancoinsitucion);
        }
      } else if (data.tct0.length > 0 && data.tct0[0]['tct0.ORIGENBENEFICIARIO'] != 'QR') {
        this.beneficiaryExist = '1';
        sendTransferJSON['beneficiaryExist'] = '1';
        this.habilitarFunciones('transMismaInst', sendTransferJSON);
        this.intentoBene = false
      } else if (data.tct0.length > 0 && data.tct0[0]['tct0.ORIGENBENEFICIARIO'] == 'QR') {
        this.beneficiaryExist = '0';
        sendTransferJSON['beneficiaryExist'] = '0';
        this.habilitarFunciones('transMismaInst', sendTransferJSON);
        this.intentoBene = false
      } else {
        this.intentoBene = false
      }
    }, (err) => {
      this.intentoBene = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError)
        }
        this.intento = false
      }
      else { //Sin Internet
        this.intento = false
      }
    });
  }

  ProcesarIngresoQR(ccuenta: any, cmoneda: any, cpersona_empresabancaria: any, identificacion: any, ctipoidentificacion: any, nombrelegal: any, email: any, telefono: any, cuentaDesde: any) {
    let envio: any = {
      transaccion: '183211-man-in-QR.xml',
    };
    envio['rec^tprb0^0^TIPOCUENTA'] = "AH";
    envio['rec^tprb0^0^CUENTABENEFICIARIO'] = ccuenta;
    envio['rec^tprb0^0^CMONEDA'] = cmoneda;
    envio['rec^tprb0^0^IDENTIFICACIONBENEFICIARIO'] = identificacion;
    envio['rec^tprb0^0^CPERSONA_EMPRESABANCARIA'] = cpersona_empresabancaria;
    envio['rec^tprb0^0^CPERSONA'] = "$personCode";
    envio['rec^tprb0^0^CODIGOBANCOBENEFICIARIO'] = ctipoidentificacion;
    envio['rec^tprb0^0^NOMBRE'] = nombrelegal;
    envio['rec^tprb0^0^TELEFONO'] = telefono;
    envio['rec^tprb0^0^EMAIL'] = email;
    envio['rec^tprb0^0^ORIGENBENEFICIARIO'] = "QR"
    envio['ctl^IS_MOBILE'] = this.bandMobile ? "1" : "0";
    envio['ctl^NOMBRE_BANCO'] = "COOPERATIVA DE AHORRO Y CRÉDITO JULIAN LORENTE";
    envio['ctl^CPERSONA_USUARIO'] = "$userCode";
    envio['ctl^CUSUARIOREAL'] = "$userCode";
    envio['ctl^_ClientName'] = "$personName";
    envio['ctl^USR'] = "$userCode";
    envio['ctl^CPERSONAEMPRESABANCARIA'] = cpersona_empresabancaria;
    envio['ctl^CTIPOIDBENEF'] = ctipoidentificacion;
    envio['ctl^IDBENEF'] = identificacion;
    envio['ctl^CTIPOCUENTABENEF'] = "1";
    envio['ctl^CUENTABENEF'] = ccuenta;
    envio['ctl^_MAILNEWBENEFICIARY'] = "0";
    envio['ctl^IS_VALIDATION_PIN'] = "1";

    this.api.postProvider2InternetCheck('/mantenimientosimple', this.base.id_token, envio).then((data: any) => {
      if (data) {
        let sendTransferJSON = {
          cuentaOrigen: cuentaDesde,
          cuentaDestino: ccuenta,
          monto: '0',
          referencia: '',
          moneda: cmoneda,
          cpersonaBanco: cpersona_empresabancaria,
          isBeneQR: '1',
          beneficiaryExist: this.beneficiaryExist,
          tarPendType: 'transferCtasTer',
          resender: true
        };
        this.habilitarFunciones('transMismaInst', sendTransferJSON);
      }
      this.intentoBene = false
    }, (err) => {
      this.intentoBene = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario);
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
        this.bandCheck = true;
      }
      else {
        this.base.insertarTarea(envio);
        this.alerta.presentarAlerta(this.idiomas.NoConectado);
      }
    });
  }

  ProcesarIngresoExternoQR(ccuenta: any, cmoneda: any, cpersona_empresabancaria: any, identificacion: any, ctipoidentificacion: any, nombrelegal: any, email: any, telefono: any, cuentaDesde: any, codigobancoinsitucion: any) {
    let envio: any = {};
    envio.otp = this.servicios.toAES("000000");
    envio.desencriptar = '1';
    envio.CPersonaEmpresaBancaria = cpersona_empresabancaria;
    envio.codigobancoinsitucion = codigobancoinsitucion
    envio.cuentabeneficiario = ccuenta;
    envio.CTipoIdentificacion = ctipoidentificacion;
    envio.identificacionBeneficiario = identificacion;
    envio.nombre = nombrelegal.toUpperCase();
    envio.emailBeneficiary = '1';
    envio.tipoCuenta = "AH";
    envio.CMoneda = cmoneda;
    envio.telefono = telefono;
    envio.mail = email;
    envio.montoMaximo = "0";
    envio.tarPendType = "regBen";
    envio.direccion = "SIN DATOS";
    envio.isMobile = "1";
    envio.isValidation = '0'
    envio.origenBeneficiarios = "QR";
    this.api.postProvider2InternetCheck('/ingresoBeneficarioQR', this.base.id_token, envio).then((data: any) => {
      if (data) {
        let sendTransferJSON = {
          cuentaOrigen: cuentaDesde,
          cuentaDestino: ccuenta,
          monto: '0',
          referencia: '',
          moneda: cmoneda,
          cpersonaBanco: data.cpersonaInstitucion,
          isBeneQR: '1',
          beneficiaryExist: this.beneficiaryExist,
          tarPendType: 'transferCtasOtr',
          resender: true
        };
        this.habilitarFunciones('transOtraInst', sendTransferJSON);
      }
      this.intentoBene = false
    }, (err) => {
      this.intentoBene = false
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
          else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario);
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
        this.bandCheck = true;
      }
      else {
        this.base.insertarTarea(envio);
        this.alerta.presentarAlerta(this.idiomas.NoConectado);
      }
    });
  }

  disableForms() {
    this.numcuenta.disable();
    this.identificacion.disable();
    this.nombre.disable();
    this.telefono.disable();
    this.email.disable();
    this.token.disable();
    this.controlBancos.disable();
    this.controlIdTipo.disable();
    this.controlMoneda.disable();
    this.controlTCuenta.disable();
    this.intentoCodInstOrdenante = false;
  }

  editarRegistro(isValidation) {
    this.intentop = true;
    this.disableButton = true;
    this.idusuario = this.base.usuario;
    var envio = {
      otp: this.servicios.toAES(!this.pinValidation ? this.token.value : "000000"),
      desencriptar: '1',
      cpersonaEmpresaBancaria: this.cpersona_banco,
      cuentaBeneficiario: this.id,
      identificacionBeneficiario: this.identificacionBeneficiario,
      nombre: this.nombreBeneficiario.toUpperCase(),
      tipoCuenta: this.tipo,
      nombreBeneficiario: this.nombre.value.toUpperCase(),
      idBeneficiario: this.identificacion.value,
      cuentaBen: this.numcuenta.value,
      moneda: this.beneficiarioingreso.cmoneda,
      nombreBanco: this.beneficiarioingreso.nombre_banco,
      email: this.email.value ? this.email.value : "SIN DATOS",
      telefono: this.telefono.value ? this.telefono.value : "SIN DATOS",
      montoMaximo: this.amountFormControl.value ? this.amountFormControl.value.replace("$", '').replace(/,/g, '') : "0",
      isMobile: this.bandMobile ? "1" : "0",
      isValidation: isValidation
    };
    this.api.postProvider2InternetCheck('/caducarbeneficiario', this.base.id_token, envio).then((data: any) => {
      this.ProcesarIngreso(0, isValidation);
    }, (err) => {
      this.intentop = false;
      this.disableButton = this.pinValidation ? true : false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
          else if (err.error.mensaje == "MENSAJE PENDIENTE DE AUTORIZACION POR SOFT TOKEN ") {
            if (isValidation == 1 && this.pinValidation) {
              let user = {
                userCode: this.base.usuarioHB
              }
              this.alerta.generarDialogoSolicitarAutorizacionData(this.idiomas, this.grande, this.mediano, user, "").then((data: any) => {
                if (data) {
                  this.pinValid = true
                  this.disableButton = false
                } else {
                  this.pinValid = false
                  this.disableButton = false
                }
                this.intento = false
              }, (err) => {
                this.intento = false
              });
            }
          } else {
            this.alerta.presentarAlertaX(err.error.mensajeUsuario);
          }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
  }

  close(afterRegisterBenefOk) {
    this.nuevoBeneficiario();
    this.limpiarIngreso(afterRegisterBenefOk);
    this.ingtoken = false;
    this.intentoEditar = false;
    this.intentoEditarlBancos = false;
    this.token.setValue('');
    this.token.reset();
    this.token.enable();
  }

  return(afterRegisterBenefOk) {
    this.limpiarIngreso(afterRegisterBenefOk);
    this.obtainOwnAccount();
    this.consultarBeneficiarios();
    this.bandsFuncion = {
      'transCtsProp': false,
      'transMismaInst': false,
      'transOtraInst': false,
      'beneficiarios': true
    };
    this.showNuevoBeneficiario = false;
    this.ingtoken = false;
    this.intentoEditar = false;
    this.intentoEditarlBancos = false;
    this.pinValid = false;
    this.continueProcess = false;
    this.intentoCodInstOrdenante = false;
  }


  //Método para tomar la lista de bancos y poblar la variable
  retornarBancosEditar() {
    this.intentoBancos = false;
    this.intentoEditarlBancos = true;
    this.intentoBene = true;
    this.lbancosInicial = [];
    let auxi: Ibancos = {
      id: '',
      nombre: '',
      efi: '',
      siglas: '',
      codInstOrdenante: '',
      codInstReceptor: '',
      codInstAbabin: ''
    };
    let enviobancos = {
      transaccion: '010003-lv-bancos-beneficiario.xml',
      alias: 'vpif0',
      'cri^tjib0^CPERSONA^NORMAL': this.bandTarjetaCredito == 2 ? '' : ''
    };
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, enviobancos).then((data: any) => {
      for (const banco of data) {
        auxi.id = banco.CPERSONA;
        auxi.nombre = banco.NOMBRELEGAL;
        auxi.efi = banco.CUENTABANCOINSTITUCION;
        auxi.siglas = banco.SIGLAS;
        auxi.codInstOrdenante = banco.CODIGOINSTITUCIONON2ORDENANTE;
        auxi.codInstReceptor = banco.CODIGOINSTITUCIONON2RECEPTOR;
        auxi.codInstAbabin = banco.CODIGOINSTITUCIONABABIN;
        this.lbancos.push(auxi);
        auxi = {
          id: '',
          nombre: '',
          efi: '',
          siglas: '',
          codInstOrdenante: '',
          codInstReceptor: '',
          codInstAbabin: ''
        };
      }
      this.lbancos = this.lbancos.sort((a, b) => {
        var a1 = a.nombre;
        var b1 = b.nombre;
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      if (this.bandTarjetaCredito != 2) {
        this.base.insertarConsulta('lbancos', this.lbancos);
      } else {
        this.base.insertarConsulta('lbancosTc', this.lbancos);
      }
      this.lbancosInicial = this.lbancos;
      if (this.localBenefRegisterBand) { //Si es un registro de beneficiario interno se selecciona automaticamente la propia entidad
        this.lbancos = this.lbancos.filter(x => { return x.id == "2" }); //Se filtra unicamente misma institucion
        this.controlBancos.setValue("2");
        this.tomarValorBancos("2");
      }
      this.intentoEditarlBancos = false;
      this.intentoBancos = true;
      this.intentoBene = false;
      this.showNuevoBeneficiario = true;
    }, (err) => {
      this.intentoEditarlBancos = false;
      this.intentoBancos = true;
      this.intentoBene = false;
      this.showNuevoBeneficiario = true;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
        this.band = true;
        this.lbancos = this.bandTarjetaCredito != 2 ? this.base.retornarConsulta('lbancos') : this.base.retornarConsulta('lbancosTc');
        if (!this.lbancos) {
          this.lbancos = [];
        }
      }
    });
  }

  //Método para tomar la lista de bancos y poblar la variable
  retornarBancos() {
    this.intentoBancos = false;
    this.lbancosInicial = [];
    let auxi: Ibancos = {
      id: '',
      nombre: '',
      efi: '',
      siglas: '',
      codInstOrdenante: '',
      codInstReceptor: '',
      codInstAbabin: ''
    };
    let enviobancos = {
      transaccion: '010003-lv-bancos-beneficiario.xml',
      alias: 'vpif0',
      'cri^tjib0^CPERSONA^NORMAL': this.bandTarjetaCredito == 2 ? '' : ''
    };
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, enviobancos).then((data: any) => {
      for (const banco of data) {
        auxi.id = banco.CPERSONA;
        auxi.nombre = banco.NOMBRELEGAL;
        auxi.efi = banco.CUENTABANCOINSTITUCION;
        auxi.siglas = banco.SIGLAS;
        auxi.codInstOrdenante = banco.CODIGOINSTITUCIONON2ORDENANTE;
        auxi.codInstReceptor = banco.CODIGOINSTITUCIONON2RECEPTOR;
        auxi.codInstAbabin = banco.CODIGOINSTITUCIONABABIN;
        this.lbancos.push(auxi);
        auxi = {
          id: '',
          nombre: '',
          efi: '',
          siglas: '',
          codInstOrdenante: '',
          codInstReceptor: '',
          codInstAbabin: ''
        };
      }
      this.lbancos = this.lbancos.sort((a, b) => {
        var a1 = a.nombre;
        var b1 = b.nombre;
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      if (this.bandTarjetaCredito != 2) {
        this.base.insertarConsulta('lbancos', this.lbancos);
      } else {
        this.base.insertarConsulta('lbancosTc', this.lbancos);
      }
      this.lbancosInicial = this.lbancos;
      if (this.localBenefRegisterBand) { //Si es un registro de beneficiario interno se selecciona automaticamente la propia entidad
        this.lbancos = this.lbancos.filter(x => { return x.id == "2" }); //Se filtra unicamente misma institucion
        this.controlBancos.setValue("2");
        this.tomarValorBancos("2");
      }
      this.intentoBancos = true;
      this.intento = false;
    }, (err) => {
      this.intento = false;
      this.intentoBancos = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
        this.band = true;
        this.lbancos = this.bandTarjetaCredito != 2 ? this.base.retornarConsulta('lbancos') : this.base.retornarConsulta('lbancosTc');
        if (!this.lbancos) {
          this.lbancos = [];
        }
      }
    });
  }

  funcionRetornarBanco(e) {
    if (!this.intentoBancos) {
      this.retornarBancos();
    }
  }

  tomarValorBancos(valor) {
    this.intentoCodInstOrdenante = false;
    this.beneficiarioingreso.cpersona_empresabancaria = valor;
    for (const iterator of this.lbancos) {
      if (valor == iterator.id) {
        this.beneficiarioingreso.nombre_banco = iterator.nombre;
        this.beneficiarioingreso.id_banco = iterator.id;
        this.beneficiarioingreso.codigoInstitucionon2Ordenante = iterator.codInstOrdenante
      }
    }
    this.lbancos = this.lbancosInicial
    if (this.beneficiarioingreso.codigoInstitucionon2Ordenante != 'null' && this.beneficiarioingreso.id_banco != '2') {
      this.continueProcess = false;
      this.intentoCodInstOrdenante = true;
    }
    else if (this.beneficiarioingreso.codigoInstitucionon2Ordenante == 'null' && this.beneficiarioingreso.id_banco != '2') {
      this.continueProcess = true;
    } else {
      this.intentoCodInstOrdenante = false;
      this.continueProcess = false;
      this.infoLocalBenefQuery = false;
    }

  }

  tomarValorCuentas(valor) {
    this.beneficiarioingreso.tipocuenta = valor;
  }

  tomarValorMonedas(valor) {
    this.beneficiarioingreso.cmoneda = valor;
  }

  tomarValorTipoId(valor) {
    this.beneficiarioingreso.ctipoidentificacion = valor;
    if (valor == 'CED') {
      this.identificacion = this.validators.idForm();
    } else {
      if (valor == 'PAS') {
        this.identificacion = this.validators.pasForm();
      }
      else {
        this.identificacion = this.validators.rucForm();
      }
    }
  }

  //Método para filtrar la lista de bancos
  applyFilter(filterValue: any) {
    this.lbancos = this.lfiltradobancos.filter((banco) => (banco.efi + banco.nombre).indexOf(filterValue) > -1);
  }

  //Método para poblar la lista de Monedas
  retornarMonedas() {
    let auxi: IMonedas = {
      cmoneda: '',
      descripcion: ''
    };
    let enviomonedas = {
      transaccion: '010003-lv-monedas-beneficiario.xml',
      alias: 'tm0'
    };
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, enviomonedas).then((data: any) => {
      for (const moneda of data) {
        auxi.cmoneda = moneda.CMONEDA;
        auxi.descripcion = moneda.DESCRIPCION;
        this.lmonedas.push(auxi);
        auxi = {
          cmoneda: '',
          descripcion: ''
        };
      }
      this.controlMoneda.disable();
      this.controlMoneda.setValue('USD');
      this.beneficiarioingreso.cmoneda = this.controlMoneda.value;
      this.base.insertarConsulta('lmoneda', this.lmonedas);
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet
        this.intento = false;
        this.band = true;
        this.lmonedas = [];
        this.lmonedas = this.base.retornarConsulta('lmoneda');
      }
    });
  }

  limpiarIngreso(afterRegisterBenefOk) {
    this.intento = false;
    this.intentop = false;
    this.bandTransfer = false;
    this.cuenta = '';
    this.tomarValorBancos('');
    this.tomarValorCuentas('');
    this.tomarValorMonedas('');
    this.tomarValorTipoId('');
    this.token.setValue('');
    this.controlBancos.reset();
    this.controlTCuenta.reset();
    this.controlMoneda.reset();
    this.controlIdTipo.reset();
    this.numcuenta.reset();
    this.identificacion.reset();
    this.nombre.reset();
    this.telefono.reset();
    this.email.reset();
    this.amountFormControl.reset();
    this.token.reset();
    this.controlBancos.enable();
    this.controlTCuenta.enable();
    this.controlMoneda.enable();
    this.controlIdTipo.enable();
    this.numcuenta.enable();
    this.identificacion.enable();
    this.nombre.enable();
    this.telefono.enable();
    this.email.enable();
    this.token.enable();
    this.amountFormControl.enable();
    this.disableButton = false;
    this.intentoEditar = false;
    this.intentoEditarlBancos = false;
    this.infoLocalBenefQuery = false;
    this.infoLocalBenef = {};
    if (!afterRegisterBenefOk || !(this.infoLocalBenefQuery || this.autoSelectedEFI)) {
      this.tomarValorBancos('');
      this.controlBancos.reset();
      this.autoSelectedEFI = false;
      this.beneficiarioingreso.nombre_banco = "";
      this.beneficiarioingreso.id_banco = "";
    }
    this.mostrarCron = false;
    this.enableCronoIn = false;
  }

  selectCheck() {
    var cont = 0;
    for (const bene of this.datos2) {
      if (bene.caducar) {
        cont = cont + 1;
      }
    }
    if (cont > 0) {
      this.bandBtnCaducar = true;
    }
    else {
      this.bandBtnCaducar = false;
    }
  }

  obtainOwnAccount() {
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'usuario': '',
      'alias': 'tc0',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN': '',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
      'cri^tctp0^CTRANSACCION^JOIN': '6071',
      'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
    };
    envio['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
      if (data) {
        this.cuenta = this.account !== "" ? this.account : this.transformadorCuentasDesde(data);
      }
    }, (err) => {
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
  }

  transformadorCuentasDesde(value) {
    var cuenta = '';
    for (let i = 0; i < value.length; i++) {
      if (value[i].CSUBSISTEMA == "04" && value[i].CGRUPOPRODUCTO == "04" && value[i].CPRODUCTO == "406") {
        cuenta = value[i].CCUENTA;
        break;
      }
    }
    return cuenta;
  }

  realizarTransferMenu(cuentaBen: any, cpersona_empresabancaria: any, moneda: any, tarjetaCredito: any, isFromMenu: boolean) {
    if (isFromMenu) {
      this.RealizarTransferenciaDirecta(cuentaBen, cpersona_empresabancaria, moneda, tarjetaCredito)
    } else {
      this.alerta.generarDialogoSeguroRealizarTransferencia(this.idiomas).then((data) => {
        if (data) {
          this.RealizarTransferenciaDirecta(cuentaBen, cpersona_empresabancaria, moneda, tarjetaCredito)
        }
      });
    }

  }

  RealizarTransferenciaDirecta(cuentaBen: any, cpersona_empresabancaria: any, moneda: any, tarjetaCredito: any) {
    this.intentoBene = true
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'usuario': '',
      'alias': 'tc0',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN': '',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
      'cri^tctp0^CTRANSACCION^JOIN': cpersona_empresabancaria == "2" ? '6074' : '6071',
      'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
    };
    envio['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
      if (data) {
        let ccuenta = this.account !== "" && this.account !== undefined && this.account !== null ? this.account : data[0].CCUENTA;
        let sendTransferJSON = {
          cuentaOrigen: ccuenta,
          cuentaDestino: cuentaBen,
          monto: '0',
          referencia: '',
          moneda: moneda,
          cpersonaBanco: cpersona_empresabancaria,
          isBeneQR: '0',
          beneficiaryExist: '0',
          resender: true
        };
        if (cpersona_empresabancaria == "2") {
          sendTransferJSON['tarPendType'] = 'transferCtasTer';
          this.habilitarFunciones('transMismaInst', sendTransferJSON);
        }
        if (cpersona_empresabancaria != "2" && tarjetaCredito == 0) {
          sendTransferJSON['tarPendType'] = 'transferCtasOtr';
          this.habilitarFunciones('transOtraInst', sendTransferJSON);
        }
        if (cpersona_empresabancaria != "2" && tarjetaCredito == 1) {
          sendTransferJSON['tarPendType'] = 'transferCtasOtrTarCred';
          this.habilitarFunciones('transTarCred', sendTransferJSON);
        }
      }
      this.intentoBene = false
    }, (err) => {
      this.intentoBene = false
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
  }

  RealizarTransferencia() {
    this.intentoBene = true
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'usuario': '',
      'alias': 'tc0',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN': '',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
      'cri^tctp0^CTRANSACCION^JOIN': this.beneficiarioingreso.cpersona_empresabancaria == "2" ? '6074' : '6071',
      'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
    };
    envio['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
      if (data) {
        let ccuenta = this.account !== "" && this.account !== undefined && this.account !== null ? this.account : data[0].CCUENTA;
        let sendTransferJSON = {
          cuentaOrigen: ccuenta,
          cuentaDestino: this.ccuentaBeneficiario,
          monto: '0',
          referencia: '',
          moneda: this.cmonedaBeneficiario,
          cpersonaBanco: this.cpersonaEmpresaBancariaBeneficiario,
          isBeneQR: '0',
          beneficiaryExist: '0',
          resender: true
        };
        if (this.beneficiarioingreso.cpersona_empresabancaria == "2") {
          sendTransferJSON['tarPendType'] = 'transferCtasTer';
          this.habilitarFunciones('transMismaInst', sendTransferJSON);
        }
        if (this.beneficiarioingreso.cpersona_empresabancaria != "2" && this.bandTarjetaCredito != 2) {
          sendTransferJSON['tarPendType'] = 'transferCtasOtr';
          this.habilitarFunciones('transOtraInst', sendTransferJSON);
        }
        if (this.beneficiarioingreso.cpersona_empresabancaria != "2" && this.bandTarjetaCredito == 2) {
          sendTransferJSON['tarPendType'] = 'transferCtasOtrTarCred';
          this.habilitarFunciones('transTarCred', sendTransferJSON);
        }
      }
      this.intentoBene = false
    }, (err) => {
      this.intentoBene = false
      if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
        this.alerta.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
        } else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
  }

  doBlur() {
    document.getElementById('btnCerrarSesion').blur();
  }

  habilitarFunciones(funcionalidad, data) {
    this.dataTarPend = data;
    this.bandsFuncion = {
      'transCtsProp': false,
      'transMismaInst': false,
      'transOtraInst': false,
      'beneficiarios': false,
    };
    if (funcionalidad == 'beneficiarios' && this.bandTarjetaCredito != 2) {
      this.mostarMisma = true;
      this.mostrarOtros = false;
      this.account = "";
      this.orden.setValue('1');
    } else {
      this.mostarMisma = false;
      this.mostrarOtros = true;
      this.account = "";
      this.orden.setValue('2');
    }
    this.bandsFuncion[funcionalidad] = true;
    this.alerta.clearAlerta();
    this.datosMism = this.linternos
    this.datosOtros = this.lexternos
  }

  scannQrInterna() {
    this.alerta.generarDialogoQR(this.idiomas, '', false).then((data) => {
      if (data) {
        var dataQR = data
        this.intentoBene = true
        const envio = {
          'transaccion': '186000-lv-cuentas-in.xml',
          'usuario': '',
          'alias': 'tc0',
          'cri^tc0^CESTATUSCUENTA^JOIN': '002',
          'cri^tc0^CSUBSISTEMA^JOIN': '04',
          'cri^tu0^CUSUARIO^JOIN': '',
          'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
          'cri^tctp0^CTRANSACCION^JOIN': '6074',
          'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
        };
        envio['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
        this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
          if (data) {
            let ccuenta = data[0].CCUENTA;
            this.consultarBeneficairioQR(dataQR[0], dataQR[1], dataQR[2], dataQR[3], dataQR[4], dataQR[5], dataQR[6], dataQR[7], dataQR[8], ccuenta, true)
          }
        }, (err) => {
          this.intentoBene = false
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            this.alerta.presentarAlertaX(err.error.mensajeUsuario);
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
            } else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError);
            }
          }
        });
      }
    });
  }


  scannQrExterno() {
    this.alerta.generarDialogoQR(this.idiomas, '', false).then((data) => {
      if (data) {
        var dataQR = data
        this.intentoBene = true
        const envio = {
          'transaccion': '186000-lv-cuentas-in.xml',
          'usuario': '',
          'alias': 'tc0',
          'cri^tc0^CESTATUSCUENTA^JOIN': '002',
          'cri^tc0^CSUBSISTEMA^JOIN': '04',
          'cri^tu0^CUSUARIO^JOIN': '',
          'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
          'cri^tctp0^CTRANSACCION^JOIN': '6071',
          'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
        };
        envio['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
        this.api.postProvider2InternetCheck('/listadevalores', this.base.id_token, envio).then((data: any) => {
          if (data) {
            let ccuenta = data[0].CCUENTA;
            this.consultarBeneficairioQR(dataQR[0], dataQR[1], dataQR[2], dataQR[3], dataQR[4], dataQR[5], dataQR[6], dataQR[7], dataQR[8], ccuenta, false)
          }
        }, (err) => {
          this.intentoBene = false
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            this.alerta.presentarAlertaX(err.error.mensajeUsuario);
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
            } else {
              this.alerta.presentarAlerta(this.idiomas.ServidorError);
            }
          }
        });
      }
    });
  }
  comaToDot(event) {
    if (event.key === ',') {
      event.target.value += '.';
      event.preventDefault();
    }
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace("$", '').replace(/\,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0];
          let decimalPart = amountArrayDotSplit[1];
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  caretInputAmount(event) {
    event.target.value = event.target.value.replace(/,/g, '');
    let amountArrayDotSplit = event.target.value.split(".");
    if (amountArrayDotSplit.length > 1) {
      event.target.selectionStart = amountArrayDotSplit[0].length;
      event.target.selectionEnd = amountArrayDotSplit[0].length;
    }
    else {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
    }
  }
}

interface IBeneficiarioC {
  id: string,
  tipo: string,
  etiqueta: string,
  nombreBeneficiario: string,
  identificacionBeneficiario: string,
  cpersona_banco: string,
  cbanco: string,
  efiInstFinan: string,
  fregistro: string,
  tipodesc: string,
  cmoneda: string,
  telefono: string,
  email: string,
  montoMaximo: String,
  ctipoIdentificacion: string,
  versionControl: string,
  caducar: boolean,
}

interface Ibancos {
  id: string,
  nombre: string,
  efi: string,
  siglas: string,
  codInstOrdenante: string,
  codInstReceptor: string,
  codInstAbabin: string
}

interface IMonedas {
  cmoneda: string,
  descripcion: string,
}