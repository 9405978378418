import { Injectable } from "@angular/core";

@Injectable()
export class Idioma {

  constructor() {
  }
  ingles = {
    "AGUA": "Water Bill",
    "ALPHANUMERICPASSWORDHB": "Combination of numbers and letters.",
    "Abono": "Payment",
    "Aceptar": "OK",
    "AceptarPreApro": "Accept Loan Offer",
    "Actualizar": "Refresh",
    "ActualizarMontos": "Update Amount",
    "AdministracionCuenta": "Account Management",
    "Administracion": "Account Management",
    "AdministracionMob": "Management",
    "AhorroProgramado": "Scheduled Saving",
    "AlertCambioEmail": "Email was successfully updated",
    "AlertCambioPsw": "Password was successfully updated",
    "AlertClaveA": "Incorrect entry of current password (wrong)?",
    "AlertCoincidencia": "Passwords do not match",
    "AlertCon": "Incorrect entry of email (wrong)?",
    "AlertaDes": "Option Not Available in Offline Mode",
    "AlertaID": "Web Service NOT AVAILABLE",
    "AlertaService": "Service NOT AVAILABLE, try again later",
    "AlertaPreg": "Questions have not been found?",
    "AlertaPswd": "Incorrect answer",
    "AnadirCuenta": "New Account",
    "AnadirTarjeta": "New Credit Card",
    "ApellidoMaterno": "Mother's last name",
    "ApellidoPaterno": "Last Name",
    "Apellidos": "Last Name",
    "AperturaCuentas": "New Account",
    "Atrasado": "Overdue",
    "Atrasos": "Past due",
    "BalanceBloqueado": "Blocked Balance",
    "BalanceContable": "Accounting Balance",
    "Banco": "Bank",
    "BancoBeneficiario": "Beneficiary Bank",
    "Beneficiarios": "Beneficiaries",
    "Bloqueado": "Blocked",
    "Bloqueos": "LOCKS",
    "BtnEmail": "Email change",
    "BtnPassword": "Change of password",
    "BtnDireccion": "Change of address",
    "Calcular": "Calculate",
    "CATALOGO": "Catalog",
    "CDebito": "Debit Account",
    "CDestino": "Destination account",
    "CInteres": "Interest",
    "CMora": "Past due",
    "CNacionalidad": [
      { "value": "EC", "viewValue": "Ecuatorian" },
      { "value": "PE", "viewValue": "Peruvian" },
      { "value": "CO", "viewValue": "Colombian" }
    ],
    "COMPRAS": "Shopping",
    "CONSEJO_JUDICATURA": "Judicial Council",
    "COrigen": "Origin account",
    "CPagadas": "Paid installments",
    "CPagar": "Pending installments",
    "CPendientes": "Pending installments",
    "CSexo": [
      { "value": "M", "viewValue": "Male" },
      { "value": "F", "viewValue": "Female" }
    ],
    "CTransTarjServRec": "Local History",
    "CTrans": "Transfers made",
    "CTarj": "Payments Made",
    "CServ": "Payments Made",
    "CTransferencias": "History Tranferences",
    "CTransferenciasTarjeta": "History Credit Payment",
    "Caducar": "Delete",
    "Canal": "Channel",
    "Cantidad": "Quantity",
    "CapReducido": "Unpaid Balance",
    "CapReducidoMobile": "B.Unpaid",
    "Capitalpl": "Capital",
    "Capitalpr": "Loan Amount",
    "Cargos": "Charges",
    "CedulaInv": "Invalid Id",
    "CerrarSesion": "Sign Off",
    "Cgirada": "Origin Acount",
    "ChatBot": "Chat Bot",
    "Cheque": "Check",
    "ChequesLocales": "LOCAL CHECKS",
    "Chequesotros": "OTHER CHECKS",
    "CodigoServicio": "Service Code",
    "Comision": "Commission",
    "ComisionReprogramada": "I. Rescheduled",
    "ConServPagados": "Paid Services History",
    "ConServicios": "Services History",
    "Concepto": "Concept",
    "ConfCoNueva": "Confirm your new password",
    "Confirmar": "Confirmation Pending",
    "Confirmacio": "Confirmation",
    "ConsulCuota": "Installemt chart",
    "ConsultaOffLinePagoServicio": "CANNOT EXECUTE QUERY OF PAYMENT VALUE WHILE IN OFFLINE MODE",
    "ConsultaPrevia": "There is no previous query",
    "Consultar": "Search",
    "Continuar": "Continue",
    "ContraActual": "Enter current password",
    "ContraNueva": "Enter new password",
    "Contrasena": "Password",
    "Coordenadas": "Coordinates",
    "CorreoActual": "Current E-Mail",
    "CorreoE": "New E-Mail",
    "TelefonoE": "New phone",
    "DireccionE": "New address",
    "CporCobrar": "Accounts Receivable",
    "CreacionPersonas": "New Customer",
    "CrearCuenta": "Account created successfully",
    "CtasPlazo": "TERM DEPOSITS",
    "CtasPlazoG": "TERM DEPOSITS",
    "CtasPrestamos": "LOANS",
    "CtasPrestamosM": "LOANS",
    "CtasPrestamosG": "LOANS",
    "CtasPrestamosPc": "Loans",
    "CtasVista": "SAVING ACCOUNTS",
    "CtasVistaG": "SAVING ACCOUNTS",
    "Cuenta": "Account",
    "CuentaAhorrosDebCred": "Savings Account for DB and CR",
    "CuentaDestino": "Credit Account",
    "CuentaOrigen": "Debit Account",
    "Cuota": "Interest Rate",
    "CuotaPrestamos": "Installment",
    "Cupo": "Quota / Limit",
    "DatosBeneficiario": "Beneficiary information",
    "DatosCuenta": "Account Information",
    "DatosDebitosCreditos": "Debits / Credits Information",
    "DatosInversion": "Investment Information",
    "DatosTarjeta": "Credit Card Information",
    "DatosTarjetaCredito": "CREDIT CARD PAYMENT INFORMATION",
    "DatosTransferencia": "TRANSFER INFORMATION",
    "DatosTransferencias": "TRANSFER DETAIL",
    "BloqueoCuenta": "ACCOUNT BLOCKING",
    "DatosBloqueoCuenta": "ACCOUNT BLOCKING INFORMATION",
    "MotivoBloqueoCuenta": "Describe the reason for the blockage",
    "DatosUsuario": "User information",
    "DebeLogin": "Please sign in again",
    "DepCert": "Share Deposits",
    "DepCertApo": "Share Deposits",
    "Depositos": "Deposits",
    "DepositosaPlazo": "TERM DEPOSITS",
    "Desbloquear": "Unlock",
    "DescargarComprobante": "Download Proof",
    "CompartirComprobante": "Share Voucher",
    "Compartir": "Share",
    "VisualizarComprobante": "Visualizar y Share",
    "DescargarEstado": "Download Account Statement",
    "Descargar": "Download",
    "DescMasTarde": "You are disconnected, try again later",
    "Desconectado": "At the moment you are disconnected",
    "Descripcion": "Description",
    "DescripcionServicio": "Service Description",
    "DesdeCuenta": "From Account",
    "DetServ": "SERVICE DETAIL",
    "DetalleAdicional": "ADDITIONAL DETAIL",
    "DetalleAmortizacion": "INSTALLMENT CHART",
    "DetalleP": "PAYMENT DETAIL",
    "DetalleB": "BENEFICIARY DETAIL",
    "DetallePago": "INTALLMENT CHART",
    "DetalleTransacciones": "TRANSACTIONS DETAIL",
    "DetalleTransferencia": "Detail",
    "DetalleServicios": "SERVICE DETAIL",
    "Detalles": "Details",
    "Dias": "Days",
    "DiasMora": "Days overdue",
    "DiaMes": "Day of the month",
    "Direccion": "Address",
    "DireccionEMail": "E-mail Address",
    "Disponible": "Available",
    "Editar": "Edit",
    "EN": "English",
    "ES": "Spanish",
    "ElimInfo": "All this information will be deleted",
    "Empresa": "Company",
    "Entidad": "Entity",
    "EntidadDestino": "Credit Entity",
    "Enviar": "Submit",
    "EnviarTranferCtasPropias": "TRANSFER OWN ACCOUNT",
    "EnvioToken": "OTP Sent",
    "ErrorChatBot": "Oops! There was an error, try again later. If the error continues please contact us",
    "ErrorChatBotNoInternet": "To use the Chat Bot services you should be connected to Internet, please try again later...",
    "ErrorJWT": "Authentication error: Expiration time without use.",
    "Escribiendo": "typing...",
    "Espanol": "Spanish",
    "Estado": "Status",
    "EstadoCivil": "Civil Status",
    "EVENTOS": "Events",
    "ExcNumSesion": "Number of attempts exceeded",
    "FCapitalizacion": "Capitalization",
    "FConfirmacion": "Confirmation Date",
    "FDeposito": "Deposit Date",
    "FDesde": "Date From",
    "FHasta": "Date To",
    "FPago": "Payment Date",
    "FRegistro": "Registration Date",
    "FTrasaccion": "Transaction Date",
    "Fabono": "Payment Date",
    "FaltaConsultaBeneficiarios": "The beneficiary search was not carried out",
    "FaltaConsultaCuentasTransferencias": "There is no previous account query for transfers or payments",
    "FaltaConsultaTransferencias": "The transfers search was not carried out",
    "Fecha": "Date",
    "FechaApertura": "Opening Date",
    "FechaNacimiento": "Date of Birth",
    "FechaVencimiento": "Expiration Date",
    "FiltrarResultados": "Search",
    "Filtro": "FILTERS",
    "Filtros": "FILTERS",
    "FproxPago": "Next Payment",
    "FrIntereses": "Interest Frequency",
    "Frecuencia": "Frequency",
    "FrecuenciaPagoInteres": "Interest Payment Frequency",
    "GIROS": "Payment Orders",
    "GUAMOTE": "Guamote",
    "GrafPosiCons": "GRAPHIC SUMMARY",
    "GrafTrans04": "BALANCES EVOLUTION",
    "GrafTrans05": "TERM DEPOSITS TRANSACTIONS",
    "GrafTrans06": "LOAN BALANCE EVOLUTION",
    "Grande": "Large",
    "GrupoProducto": "Product Group",
    "HTrasaccion": "Transaction Time",
    "HaciaCuenta": "To Account",
    "HaciaTarjeta": "To Credit Card",
    "Hora": "Time",
    "Hoy": "Today",
    "IESS": "IESS",
    "INTERNET": "Internet",
    "Identificacion": [
      { "value": "CED", "viewValue": "Identification Card" },
      { "value": "PAS", "viewValue": "Passport" },
      //{ "value": "RUC", "viewValue": "RUC" }
    ],
    "Identificacion1": "Identification",
    "IdentificacionBeneficiario": "Beneficiary Identification",
    "Idioma": "Language",
    "IdiomaSeleccionado": "EN",
    "Imprimir": "Print",
    "Impuesto": "Tax",
    "Independiente": "Independent",
    "Ingles": "English",
    "Inicio": "Start",
    "InstFinan": "Financial Institution",
    "Interes": "Interest",
    "InteresPagar": "Interest pending",
    "InteresTotal": "Total Interest",
    "InteresesMora": "Overdue charge",
    "InteresesMoraPrestamoVencido": "Past Due Interest",
    "InteresesT": "Total Interest",
    "LUZ": "Electricity",
    "LastLogin": "Last login",
    "Limpiar": "Clear",
    "LoanAccount": "Loan Account",
    "LoginOK": "Successful Login",
    "LoginOff": "Offline mode",
    "LoginPassExtraMessage": "Do not deliver the data of your Accounts, cards, password by any means. We do not request data updates via email. We do not ask for your e-mail, nor do we attach links to the Virtual Cooperative. It is advisable to write directly in the internet browser. Do not enter the portal by clicking on a link from an email, even if it seems to be an email from the Julian Lorente Cooperative, what they are looking for is to obtain your data.<br>I have read, understood and I promise to practice security measures before exposed, each time I use the electronic channels associated with my account.",
    "LoginUserCreatedCallCenterValidation": "Our Call Center will contact you within a maximum of 24 working hours to validate the information and enable your Virtual Cooperative, in case of emergency dial 06-2997000 option 1 for validation from Monday to Friday from 08:00 to 17:00.<br>Make sure that your username and password are not easily related to the number of your cards, contract or important dates such as birthdays, anniversaries, etc. Do not write them down anywhere and do not disclose them to anyone, since they are the keys to access your accounts and your money. Remember that the security of this data is your responsibility.",
    "LoginRecoverUsrPassCallCenterValidation": "Our Call Center will contact you within a maximum of 24 working hours to validate the information and enable your Virtual Cooperative, in case of emergency dial 06-2997000 option 1 for validation from Monday to Friday from 08:00 to 17:00.",
    "LoginSecureURL": "Before entering your data on this screen, check if the address in the bar is: <b>https://enlinea.fin.ec/#/</b>",
    "LoseInfo": "Information in this form will be lost.",
    "LugarNacimiento": "Place of Birth",
    "MOriginal": "Original Balance",
    "MUNICIPIOS": "City Council",
    "ManteElimInfo": "This action will delete all information of users without interaction in last",
    "Mantenimiento": "Maintenance",
    "Mediana": "Medium",
    "Mensaje": "Message",
    "MensajeTransferExitosa": "Your transfer has been made successfully",
    "TituloTransferExitosa": "Successful Transfer",
    "MensajePagoRecarExitosa": "Your Payment has been made successfully",
    "MensajeConfirmacionReq": "Do you agree with your requirement?",
    "MensajeConfirmacionRat": "Do you agree with your rating?",
    "MensajeEdge": "It is recommended to run application in GoogleChrome or Mozilla Firefox",
    "MensajePagServ": "Payment successfully, do you want to register this service",
    "Moneda": "Currency",
    "Monto": "Amount",
    "MontoAper": "Opening Amount",
    "MontoAutorizado": "Autorized Quota",
    "MontoInvertir": "Amount To Invest",
    "MontoMax": "Max Amount",
    "MontoMaximo": "Maximun Amounts",
    "MontosMaximos": "Maximun Amount",
    "MontoMaximoExedido": "Amount exceeds limit",
    "MontoMaximoTransferencia": "Maximun Quota",
    "MontoMin": "Min Amount",
    "MontoRecarga": "Recharge Amount",
    "MontoTransferencia": "Amount",
    "Montomax": "Maximun Ammount",
    "Montomin": "Minimun Ammount",
    "MontosActualizados": "Updated amounts",
    "Movimientos": "Movements",
    "NCuenta": "N° Account",
    "NImgAndQ": "Request new images and questions",
    "NQ": "Request new questions",
    "NToken": "Request new OTP",
    "Nacionalidad": "Citizenship/ Nationality",
    "NadaCadServicio": "No service selected",
    "NadaCaducar": "No beneficiary selected",
    "NoApiServerResp": "No server response, offline mode actived",
    "NoConectado": "You are not connected to the internet. Your request is in Pending Tasks",
    "NoConexVerif": "NO CONNECTION FOR AUTHENTICATION",
    "NoConsultaPreviaConsultaTransf": "There is no previous query for transfer type selected",
    "NoConsultaPreviaCta": "There is no previous query with selected account",
    "NoCtaAhorros": "There is no savings accounts",
    "NoInternet": "You are disconnected",
    "NoMontosActualizados": "Quota not updated",
    "NoPrestamosCuentas": "There is no loan accounts",
    "NoTieneBeneficiarios": "No registered beneficiaries",
    "NoTieneNotificaciones": "No Notifications To Show",
    "NoTieneActualizacionDatos": "Please, in order to request a loan, go to the data update option, and update your data",
    "NoTieneServicios": "No Services To Show",
    "NoTieneServiciosPagados": "No Paid Services",
    "NoTieneTareasPend": "No Pending Tasks To Show",
    "NoTieneTransferencias": "No transfers for the selected type and date",
    "NoTrans": "There are no transactions",
    "NoTransFechas": "No transactions found for the selected date",
    "NoUsrMigCompleteData": "DEAR CLIENT, YOU MUST REGISTER YOUR USER NAME USING THE OPTION '$newUserText'.",
    "NombreBeneficiario": "Beneficiary Name",
    "Nombres": "Name and last name",
    "Notificacion": "Notification",
    "Notificaciones": "Notifications",
    "NuevaContrasenia": "¿Forgot your password?",
    "Nuevo": "New",
    "NuevoUsuario": "New user",
    "NuevoUsuarioInvalido": "You must enter letters and numbers",
    "NuevoUsuarioLength": "At least 6 characters",
    "NumCelular": "Cell Phone - Contract Number",
    "NumCuota": "Nro.",
    "NumCuotaPen": "No. Pending installemnt",
    "NumCuotaVen": "No. Pas due installment",
    "NumCuotas": "Installments",
    "NumCuotasPendientes": "Outstanding Fees",
    "Numero": "Number",
    "NumeroCargas": "N° of dependents",
    "NumeroCuenta": "Account Number",
    "NumeroPagos": "Maximun Term",
    "NumeroTarjeta": "Card Number",
    "NumeroTelefonoCelular": "Cellphone Number",
    "OBLIGACIONES_PATRONALES": "Employer Obligations",
    "ONLYNUMPASSWORDHB": "Just numbers combination",
    "Offline": "Dear client. The Offline mode of the application has been activated, by entering you can see the last consultations you made online. Thanks for your understanding..",
    "Oficina": "Office",
    "Online": "Online",
    "Opciones": "More Options",
    "OpcionesConsTransf": [{ "value": "1", "viewValue": "Own Accounts" }, { "value": "2", "viewValue": "Third Party Accounts" }, { "value": "3", "viewValue": "To Other Financial Entities" }],
    "Operadora": "Operator",
    "Other": "Other",
    "Otp": "Enter your security token to login into the system.",
    "PLANES": "Plan",
    "PPS_MIES": "PPS - MIES",
    "Pagadero": "Payable",
    "Pagada": "Paid out",
    "Pagar": "Pay",
    "Pago": "PAYMENT",
    "PagoServicios": [
      { "codServ": "6000", "viewValue": "Payment Services" },
      { "codServ": "6001", "viewValue": "Recharges" },
    ],
    "Pagos": "PAYMENTS",
    "PassMal": "WRONG PASSWORD",
    "Pequena": "Small",
    "Pignorado": "Pledged",
    "Pignorados": "PLEDGED",
    "Plazo": "Term",
    "PlazoInversion": "Term of Investment (Days)",
    "PlazoMax": "Max Term",
    "PlazoMin": "Min Term",
    "Plazomax": "Maximun Term",
    "Plazomin": "Minimun Term",
    "PosicionConsolidada": "All Your Accounts",
    "GraficoConsolidada": "Consolidated Graph",
    "PresionaSalir": "Press again to quit",
    "PrestamoPreaprobado": "ONLINE CREDIT",
    "Prestamos": "LOANS",
    "PrimerNombre": "First Name",
    "Producto": "Product",
    "Proximo": "Next Payment",
    "Pssw": "PASSWORD",
    "PassExpirado": "EXPIRED PASSWORD",
    "PsswConfirm": "CONFIRM PASSWORD",
    "RECARGAS": "Recharges",
    "RECOLECCION_BASURA": "Garbage Collection",
    "Recargas": "Recharges",
    "Reacudaciones": "Collections",
    "Rechazar": "Cancel",
    "RechazarPreApro": "Reject",
    "Recuperar": "Recover",
    "RecuperarUsuario": "¿Forgot your user name?",
    "RedirLogin": "You will be redirected to Login",
    "Reenviar": "Repeat",
    "Referencia": "Reference",
    "RegServicio": "Service record",
    "RegViejo": "Oldest data belongs to user",
    "Regresar": "Go Back",
    "REGISTRO_PROPIEDAD": "Property Registry",
    "SERVICIOS_FINANCIEROS": "Financial services",
    "RECAUDACIONES": "Collections",
    "PEAJE": "Toll",
    "RepeatTransfer": "Transfer form will be loaded to repeat transaction.",
    "RepeatRecharge": "The recharge form will be charged to repeat the transaction.",
    "RepeatServicePay": "The service payment form will be loaded for repeat transactions.",
    "ReporteDialog": "Date required to download report",
    "ReporteServDialog": "Account number required to download report",
    "Retiros": "Savings Withdrawal",
    "saveUser": "Do you want your username to be remembered, on this device, for future revenue?",
    "SAhorros": "SAVINGS BALANCE",
    "SCapital": "Principal Balance",
    "SDisponible": "Cash Balance",
    "SERVICIOS_VARIOS": "Other Services",
    "SIntereses": "Interest",
    "SPrestamos": "LOAN BALANCE",
    "SRI": "SRI",
    "Saldo": "Balance",
    "SaldoA": "Current Balance",
    "SaldoAhorros": "Savings Balance",
    "SaldoCapitalTotal": "Total Principal Balance",
    "SaldoCapitalVendido": "Pincipal Balance Due",
    "SaldoContable": "Accounting Balance",
    "SaldoDisponible": "Available Balance",
    "SaldoEfectivizar": "Pending Balance",
    "SaldoPrestamos": "Loan Balance",
    "SaldoTotal": "Total Balance",
    "SaldoV": "Past Due Balance",
    "SegCerrSesion": "Are you sure to logout",
    "SegContTx": "Are you sure to continue with the transaction?",
    "SegEditTarea": "If you edit the task, it will be removed from pending tasks.",
    "SegElimTarea": "Are you sure to delete this task",
    "SegToken": "SECURITY OTP",
    "SegundoNombre": "Middle name",
    "Seguro": "Insurance",
    "SeguroContinuar": "Are you sure to continue?",
    "SeguroContinuarTransferencia": "Do you want to make the transfer?",
    "SeguroCancelar": "Are you sure to cancel",
    "Seguros": "Other",
    "ServPagados": "PAYED SERVICES",
    "ServReg": "Frequent Services",
    "Servicio": "Service",
    "Servicios": "SERVICES",
    "ServiciosMin": "Services",
    "ServidorError": "Transaction Error",
    "Sexo": "Gender",
    "Siguiente": "Next",
    "Sobregiro": "Overdraft",
    "SolicitarToken": "Request OTP",
    "SolicitudDepositoPlazo": "Fixed Term Deposit Application",
    "Suministro": "Supply",
    "TARJETA": "Card",
    "TELEFONO": "Phone",
    "TRANSITO": "Transit",
    "TamanoFuente": "Font Size",
    "TarPend": "Pending Transactions",
    "Tarjeta": "Credit Cards",
    "TarjetasCredito": "Credit Card Management",
    "Tasa": "Rate",
    "Telefono": "Phone Number",
    "Tema": "Theme",
    "TemaClaro": "Light",
    "TemaClaroAzul": "Light Blue",
    "TemaClaroGris": "Light Gray",
    "TemaClaroVerde": "Light Green",
    "TemaOscuro": "Dark",
    "TiempoFuncionamientoNegocio": "Operating Business Time (Months)",
    "Tipo": "Type",
    "TipoCuenta": "Account Type",
    "TipoNegocio": "Business Type",
    "TipoTransaccion": "Transaction Type",
    "TipoVivienda": "House Type",
    "TiposCuentaBeneficiario": [
      { "value": "AH", "viewValue": "Savings" },
      { "value": "CO", "viewValue": "Checking" },
      { "value": "TC", "viewValue": "Credit Card" }
    ],
    "TituloDialogos": "Coop Virtual PJL",
    "TituloIngresoBeneficiario": "Beneficiaries Registration",
    "TituloIngresoBeneficiarioTc": "Credit Card Registration",
    "TituloPreguntas": "PLEASE ANSWER YOUR SECRET QUESTIONS",
    "Token": "Security OTP",
    "TokenDeSeguridad": "Security OTP",
    "TotCuota": "Total Installment",
    "Total": "Total",
    "TotalVencidoPagar": "Total Due to Pay",
    "Transaccion": "Transaction",
    "TransaccionDescCanal": "Channel Description",
    "TransaccionE": "Successful Transaction",
    "TransaccionEPer": "Successful transaction, please continue with the next step to enter documentst",
    "TransaccionExitosa": "Successful Transaction",
    "Transacciones": "TRANSACTIONS",
    "TransaccionesWeb": [
      { "codTransf": "6071", "viewValue": "Other Institutions" },
      { "codTransf": "6073", "viewValue": "Own Accounts" },
      { "codTransf": "6074", "viewValue": "Third Party Accounts" }
    ],
    "Transferencias": "TRANSFERS",
    "transfererir": "Transfer",
    "TransferenciasAOtros": "Other Institutions",
    "TransferenciasATarjetas": "Credit Card Transfers",
    "TransferenciasCuentasPropias": "Own Accounts",
    "TransferenciasTarjetaCredito": "Credit Card Payment",
    "TransferenciasTerceros": "Third Party",
    "UbicacionNegocio": "Business Location",
    "UltimaBusqueda": "The last search will be displayed",
    "UltimaConexion": "Last Connection: ",
    "UltimasTransferencias": "LAST TRANSFERS",
    "UltimasTransferenciasUpC": "LAST TRANSFERS",
    "UserTotallyBloquedCallCenterContact": "Dear member, the answers to your validation questions were incorrect, for your security your user has been blocked, to activate it, contact our Call Center at 06-2997000 option 1 or come to any of our offices.",
    "Usr": "USER",
    "Usuario": "User",
    "VALIDATE_CAPITAL": "Minimum one uppercase letter required",
    "VALIDATE_MINPASS": "Minimum $MINPASSWORDHB characters.",
    "VALIDATE_REPEATED": "It can not be equal to $ITERPASSWORD previous password.",
    "VALIDATE_SPECIALPASS": "Minimum one special character.",
    "VBloqueado": "Blocked Value",
    "VConfirmar": "To Effective",
    "VMora": "Over due balance",
    "VPagar": "Pending to Pay",
    "VPignorado": "Pledged Value",
    "ValCuota": "Payment Amount",
    "ValidarCampo": "The field is required",
    "ValidarNoCarEsp": "The field can only receive letters and numbers",
    "ValidarUsuario": "The user name is required",
    "Valor": "Value",
    "ValoresCalcular": "Amounts To Calculate",
    "Vencimientopl": "Due Date",
    "VencimientoplMob": "Due Date",
    "Vencimientopr": "Due Date",
    "FEfectivizacion": "Effectivization Date",
    "btnIngresar": "Log in",
    "consPagServ": "Queries Services Payment",
    "estadoCivil": [
      { "value": "001", "viewValue": "Single" },
      { "value": "002", "viewValue": "Married" },
      { "value": "003", "viewValue": "Divorced" },
      { "value": "004", "viewValue": "Consensual Union" }
    ],
    "favImg": "Favorite picture",
    "gestionServicios": "Frequent Payments",
    "idSocio": "Client Id",
    "idTipo": "Id Type",
    "InteresGanado": "Interest to win at the end",
    "NetoGanado": "Net to receive",
    "informacionCorrecta": "¿Is the information correct?",
    "keepUserInfo": "Keep my information for offline support",
    "lblServicios": "Services",
    "lblTransferencias": "Local Transfers",
    "lblTransferenciasMob": "Transfers",
    "loanPayment": "Loan Payment",
    "mensajeContra": "The password must comply with the following characteristics:",
    "migratedUserNameChange": "Username Update",
    "minAccountNumberLength": "Minimum 5 digits",
    "montoDesde": "Amount between: ",
    "montoEnteroRecargas": "Just integer amounts",
    "montoIntervaloRecargas": "with intervals of: ",
    "montoMaximoRecargas": "Maximun amount: ",
    "montoMinimoRecargas": "Minimun amount: ",
    "msgConActual": "Current password required",
    "msgConFNueva": "Confirmation password required",
    "msgConNueva": "New password required",
    "msgCorreo": "Email required",
    "msgCorreoE": "The email format is incorrect",
    "msgTelefono": "Phone required",
    "msgTelfonoE": "The phone format is incorrect",
    "msgPregunta": "Answer required",
    "msjCerrarSesion": "If you login now, other active sessions will be expired.",
    'msjAcuerdoPrivacidad': 'Privacy agreement.',
    "msjContacto": "Contact the institution for more information.",
    "msjContrasenaRequerida": "The password is required",
    "msjContrato": "Dear customer, to use this  application you must accept the following contract:",
    "msjExedeTamano": "The field has too many digits",
    "msjIdRequerido": "The identification is required",
    "msjMin": "The field has too few digits",
    "msjNombre": "Name is required",
    "msjOtpminmax": "The OTP must be 6 digits",
    "msjSeleccionImgQ": "Please complete the following security form:",
    "msjSoloEmail": "Valid email address required",
    "msjSoloNumeros": "The field can only accept numbers",
    "msjSoloNumerosLetras": "The field can only accept alphanumeric characters",
    "msjSoloTexto": "The field can only accept text",
    "msjalertaPreguntas": "YOU MUST CHOOSE AN IMAGE AND ANSWER ALL QUESTIONS",
    "msjalertaPreguntasPass": "YOU MUST CHOOSE AN IMAGE AND ANSWER ALL QUESTIONS",
    "msjalertaQ": "YOU MUST ANSWER ALL QUESTIONS",
    "msjalertaSesion": "An Active user session have been detected on another device.",
    "noAllowedAmount": "Amount Not allowed",
    "noIdAccountMatch": "Identification does not correspond to the account entered",
    "noRepetirTransferencia": "Transfer cannot be resent.<br>Origin or Destination account is no longer available.",
    "usuarioSospechoso": "DEAR USER, A PASSWORD CHANGE WAS DETECTED, PLEASE CONTACT US TO VALIDATE YOUR IDENTITY.",
    "nombreSocio": "Client name",
    "numeroCuotas": "Number of installments between: ",
    "offlineBlocked": "OFFLINE MODE NOT ALLOWED",
    "pagServ": "Services Payment",
    "pagRec": "Payment of Collections",
    "paymentSettings": "Payment Options",
    "paymentValueZero": "No payment value",
    "sendPayment": "Send Payment",
    "tipoNegocio": [
      { "value": "I", "viewValue": "Independent" },
      { "value": "D", "viewValue": "Dependent" }
    ],
    "tipoTransaccionL": [
      { "codTx": "PSE", "viewValue": "Pay Services" },
      { "codTx": "TRF", "viewValue": "Web Transfers" }
    ],
    "tipoVivienda": [
      { "value": "P", "viewValue": "Own" },
      { "value": "A", "viewValue": "Leased" }
    ],
    "NuevoSocio": "Be partner",
    "optionalTextLogin": "Cooperativa Virtual does not request by any means personal, financial information, passwords, account numbers or cards.",
    "tablaDocumentos": "Purchase History",
    "NuevoProducto": "New Product",
    "Eliminar": "Remove",
    "Ecommerce": "E-Commerce",
    "Home": "Home",
    "Tiendas": "Stores",
    "ComprasRealizadas": "Purchases Made",
    "AdminProductos": "Store Management",
    "VentasRealizadas": "Store Movements",
    "AdminTienda": "Store Management",
    "CompraT": "Your purchase is:",
    "FrEntrega": "Delivery method:",
    "Contado": "Counted:",
    "RAlmacen": "Pick up at the store:",
    "Cuotas": "12 odds of:",
    "NCuotas": "Cannot pay in installments",
    "EDomicilio": "Home delivery:",
    "Subtotal": "Subtotal:",
    "IVA": "VAT:",
    "TotalEc": "Total:",
    "Direcciones": "Address",
    "DireccionesRef": "Address Reference",
    "MensajeCompra": "THANK YOU FOR YOUR PURCHASE",
    "MensajeToken": "Communicate this number to the store so they can deliver your purchase:",
    "MensajeFactura": "Your invoice has been sent to your email. If you want to print it click ",
    "Aqui": "here",
    "MensajeCedula": "Remember you must present your ID to withdraw or receive your product",
    "Ccompra": "Confirm Purchase",
    "Cerrar": "Close",
    "PContado": "Price Counted:",
    "Comprar": "Purchase",
    "NProductos": "THE SHOP DOES NOT HAVE ANY REGISTERED PRODUCTS",
    "NCompras": "THERE ARE NOT REGISTERED PURCHASES",
    "NVentas": "THE SHOP DOES NOT HAVE ANY REGISTERED SALES",
    "AdministracionTienda": "Store Management",
    "TablaProducto": "Products Table",
    "Categoria": "Categories",
    "MantenimientoTienda": "Store Maintenance",
    "IngresoProducto": "Product Entry",
    "EditarProducto": "Product Edit",
    "EliminarProducto": "Are you sure you want to delete the product.. ??",
    "CargarProductos": "Load Lot Products",
    "SeleccionarImagen": "Select Image",
    "SeleccionarArchivo": "Select File",
    "Precio": "Price",
    "GuardarCambios": "Save Changes",
    "NoDisponible": "Not Available",
    "Diferir": "Counted",
    "NoDiferir": "Dues",
    "Caracteristica1": "Main Feature",
    "Caracteristica2": "Secondary Feature",
    "FCompra": "Purchase Date",
    "FEntrega": "Deliver Date",
    "NOrden": "Order No",
    "Recibo": "Invoice",
    "Cliente": "Client",
    "VComprar": "Buy again",
    "CVenta": "Confirm sale",
    "TarOffline": "Offline Transactions",
    "VUnitario": "Unit value",
    "NoConectadoEcomm": "You are not connected to the internet. Your request is in Offline Transactions",
    "CompraR": "PURCHASE MADE",
    "msgPrea1": "The value of the fee exceeds the maximum allowed of $",
    "msgPrea2": ". Please increase the term or decrease the amount of the credit.",
    "NoAceptarPreApro": "No thanks",
    "InfoTransferencias": "* Transfers made after 16:30, to entities without direct payment will be processed the next business day.",
    "InforTransBene": "Select the beneficiary's account number and transfer your money instantly.",
    "InforTransBeneTar": "Select the credit card number and pay for your service instantly.",
    "InforPagServ": "Select the supply you want to cancel and make your payments instantly",
    "Agencias": "Agencies",
    "Cajeros": "ATM",
    "Simuladores": "Simulators",
    "Creditos": "Credits",
    "DepositoP": "Fixed Term Deposit",
    "Informacion": "INFORMATION",
    "Contactos": "Contacts",
    "Ayuda": "Help",
    "SoloClientes": "ONLY FOR CLIENTS",
    "IngresarBM": "Enter Mobile Banking",
    "IngresarBW": "Enter Web Banking",
    "Ahorros": "Savings",
    "BienvenidoM": "Welcome to COAC Padre Julián Lorente Mobile Banking",
    "BienvenidoW": "Welcome to COAC Padre Julián Lorente Banking",
    "BienvenidoD": "Welcome",
    "BienvenidoDC": "Welcome to your PJL Virtual Coop",
    "BienvenidoDM": "Find and access all your financial services in a timely manner",
    "RegresarI": "Return to Home",
    "Escoger": "Choose your menu option",
    "Telefonos": "Phones",
    "HorAtencion": "Attention schedule",
    "PagosProgramados": "Scheduled Payments",
    "RegPagosProgramados": "Register Payments",
    "ManPagosProgramados": "Maintenance Payments",
    "TransaccionesPro": "Transactions",
    "Dia": "Day",
    "Intento": "Trieds",
    "PlazoMaximo": "Deadline",
    "NoPagosProgramados": "No Scheduled Payments Recorded",
    "TransaccionesPagosPro": [
      { "id": "6000", "value": "Payment Services" },
      { "id": "6001", "value": "Recharges" },
      { "id": "6071", "value": "Other Institutions Transfers" },
      { "id": "6073", "value": "Own Account Transfers - Own Institution" },
      { "id": "6074", "value": "Third Parties Transfers - Own Institution" }
    ],
    "IniciarSesion": "Login",
    "OlvidoContrasenia": "Did you forget your password?",
    "NoTienesCuenta": "You still do not have an account with us?",
    "Registrate": "Register",
    "Registrar": "Register",
    "RecuperarContrasenia": "Password Recover",
    "RecuperarUsuarioT": "User Recover",
    "RegistrarUsuario": "User Register",
    "RegistrarSocio": "Client Registration",
    "DesbloquearUsuario": "Unlock User",
    "RegistrarHuella": "Do you want to register the biometric credentials of this device?",
    "RegistrarHuellaB": "Biometric Authentication Register",
    "DesvincularHuellaB": "Unlink Biometric device",
    "RegistrarHuellaBA": "Dear member, to REGISTER your biometric credentials, you need to enter your current password",
    "RegistrarHuellaDBA": "Dear member, to UNLINK your mobile device, you need to enter your current password",
    "HuellaRegistradaTel": "User already has a registered BIOMETRIC CREDENTIAL",
    "HuellaRegistradaTelUsu": "User already has a registered BIOMETRIC CREDENTIAL",
    "RegistrarHuellaExitosa": "Biometric authentication successfully registered!<br>Try using it on your next login",
    "ErrorRegistrarHuellaExitosa": "Problems registering biometric credentials",
    "ErrorHuella": "Invalid biometric credentials!",
    "ErrorHuellaPassword": "A change in your authentication was detected, REGISTER your biometric credentials when you log in",
    "btnAtras": "Back",
    "btnListo": "Ready",
    "btnSaltar": "Skip",
    "MensajeTransferencias": "Make your transfers in an agile and efficient way",
    "MensajeServicios": "Make your payments in a fast, from where you are.",
    "IngresoTarjetasCredito": "Ingresa una Tarjeta de Crédito",
    "IngresoBeneficiarios": "Ingresa un Beneficiario",
    "ServicioEscoger": "Choose a Service to Pay",
    "BtnInfoPersona": "Personal information",
    "BtnContactAsesor": "Contact PJL advisor",
    "BtnCalificar": "Rate us",
    "btnBloqueoCuenta": "Account Blocking",
    "BtnCalificanos": "Rate",
    "BtnPreguntas": "Frequently asked questions",
    "BtnEnviarReq": "Send Request",
    "Observacion": "Write here your Request",
    "ObservacionF": "Observations",
    "observacionRating": "Write your Comment Here",
    "Rating": "Your Rating",
    "MiCuenta": "MY ACCOUNT",
    "ServiciosFinancieros": "FINANCIAL SERVICES",
    "CuentaA": "Acc:",
    "ConsultaDetalle": "SEE THE DETAIL",
    "CuentaSocio": "ACC. CLIENT",
    "Ahorro": "SAVING",
    "Inversion": "INVESTMENT",
    "InversionPC": "Investment",
    "Credito": "CREDIT",
    "Tarjetas": "CARDS",
    "TarjetasPc": "Cards",
    "Splash1": "Check cash balances wherever you are",
    "Splash2": "Make your transfers and payment of services from anywhere",
    "Splash3": "Manage your money from anywhere in an agile and secure way",
    "FingerprintButton": "Fingerprint /",
    "FingerprintButtonF": "Face ID",
    "PasswordButton": "Password",
    "EsperePorfavor": "Please wait...",
    "RegistrandoHuella": "We're registering your device credentials...",
    "SolicitarTurno": "REQUEST SHIFT",
    "Confirmacion": "Confirm",
    "Felicitaciones": "Congratulations",
    "GraficaPosicionConsolidad": [
      { "descripcion": "ACC. CLIENT", "viewValue": "ACC. CLIENT" },
      { "descripcion": "SAVING DEP.", "viewValue": "SAVING DEPOSITS" },
      { "descripcion": "TERM DEP.", "viewValue": "TERM DEPOSITS" },
      { "descripcion": "LOANS", "viewValue": "LOANS" }
    ],
    "Divisa": "Badge",
    "TarjetaTCR": "TCR",
    "PasswordCambiado": "PASSWORD SUCCESSFULLY CHANGED",
    "TarjetaReg": "THE CREDIT CARD IS ALREADY REGISTERED WITH THE IDENTIFICATION",
    "BeneficiarioReg": "THE ACCOUNT NUMBER IS ALREADY REGISTERED WITH THE IDENTIFICATION",
    "tarjetaCredito": "Credit cards",
    "tarjetaMarca": "Card",
    "tarjetaTipo": "Type",
    "tarjetaNumero": "Number",
    "factAnterior": "Previous Billing",
    "saldoAnteriorPagar": "Balance to Pay",
    "fechaAnteriorMaxPago": "Maximum Payment Date",
    "fechaAnteriorMaxPagoC": "Max. D. Payment",
    "consumosMes": "Month Consumptions",
    "pagosMes": "Month Payment",
    "factActual": "Actual Billing",
    "saldoActualPagar": "Balance to Pay",
    "fechaActualMaxPago": "Maximum Payment Date",
    "minPagar": "Minimum to Pay",
    "tituloMovTC": "DETAIL OF PAYMENTS AND CONSUMPTIONS",
    "fechaMovTC": "Date",
    "referenciaMovTC": "Reference",
    "descEstabMovTC": "Description / Establishment",
    "tipoMovTC": "Type",
    "detalleMovTC": "Detail",
    "valorPagosMovTC": "Value Payments",
    "valorConsumosMovTC": "Consumption Value",
    "saldoConsumoMovTC": "Consumption Balance",
    "saldoDiferidoMovTC": "Deferred Balance",
    "Totales": "Totals",
    "TotalGeneral": "General Totals",
    "tarjetaCupo": "Card Quota",
    "tarjetaCupoDisponible": "Available Quota",
    "tcMovsPorFecha": "By date",
    "tcMovsUltimoCorte": "Last period",
    "tcTitularTarjeta": "Card Holder",
    "tcPropias": "Local Own Cards",
    "tcTerceros": "External or Third Party Cards",
    "pagoTotalTC": "Total Payment",
    "pagoMinimoTC": "Minimum Payment",
    "OpcionesConsultaPagoTC": [{ "value": "1", "viewValue": "Local Own Cards" }, { "value": "2", "viewValue": "External or Third Party Cards" }],
    "estodoCuentaTC": "Account Status",
    "avancesEfectivoTC": "Cash Advances",
    "diferimientoConsumosTC": "Consumption Deferral Solicitude",
    "bloqueoTC": "Lockout Request for Loss or Theft",
    "OpcionesBloqueoTC": [{ "value": "1", "viewValue": "LOSS" }, { "value": "2", "viewValue": "THEFT" }],
    "Motivo": "Savings Motive",
    "OpcionesTelefBloqueo": [{ "value": "1", "viewValue": "PHONE" }, { "value": "2", "viewValue": "CELLPHONE" }],
    "Contacto": "Contact",
    "fechaExpiracionTC": "Expiration Date",
    "tcCVV": "C V V",
    "tcTipoAvance": "Advance Type",
    "tcAvancesTipos": [{ "value": "Corriente", "viewValue": "Current" }, { "value": "Diferido", "viewValue": "Deferred" }],
    "tcSaldoAnterior": "Previous Balance",
    "tcSaldoActual": "Actual balance",
    "tcPagosCreditos": "Payments / Credits",
    "tcConsumosDebitos": "Consumption / Debits",
    "tituloTablaDiferimientoConsumosTC": "DETAIL OF CONSUMPTIONS",
    "tipoDiferido": "Deferral type",
    "diferirConsumoTc": "To Defer",
    "montoDiferirTc": "Amount to Defer",
    "plazoDiferirTc": "Term to Defer",
    "meses": "months",
    "Femision": "Date of issue",
    "Utilizado": "Used",
    "FEmision": "Issue Date",
    "Periodo": "Period",
    "PagoCredito": "Payments / Credits",
    "ConsumoDebito": "Consumption / Debits",
    "TotalPagar": "Total to Pay",
    "MsgTelefono": "You can contact the phones:",
    "MsgCorreo": "or to the email address:",
    "PlazoFijo": "Investments",
    "PASSWORDNOVIGENTE": "Dear member:<br><br>"
      + "Your <b>Virtual Coop</b> password has expired, for your security it is requested to change<br>"
      + "for a new personal and non-transferable password.<br><br>"
      + "Thank you for using our services. Any concerns please communicate to 06-2997000<br>"
      + "option 1 or sugerencias@23julio.fin.ec<br><br>"
      + "Cordially:<br>"
      + "<b>Cooperativa Financiera Julian Lorente</b>",
    // Plazo fijo
    "PlazoFijoA": "Online Term Deposit",
    "PInvertir": "Enter the capital:",
    "PlazoSugerido": "Suggested term:",
    "OtroPlazo": "Another term:",
    "POpcion": "Select your option",
    "InteresGando": "Earned interests",
    "InteresGandoMob": "Earned interests",
    "CuentaDebito": "Select debit account:",
    "listaAgencias": "Choose the closest agency:",
    "Agencia": "Agency",
    "AceptarT1": " I accept ",
    "AceptarT2": "termns and conditions",
    "AceptarT3": "Certification of Tax Residence",
    "InteresG": "Earned interest",
    "FVencimiento": "Due Date",
    "PRecibir": "Net to receive",
    "PMensaje2": "Select interest payment: ",
    "plazoDesde": "Term between ",
    //Preaprobado
    "ValorEntero": "Only integer number",
    "CreditoError": "NO SE HA PODIDO GENERAR EL CRÉDITO",
    "NMaximo": "Max Number:",
    "NMinimo": "Min Number:",
    "MMinimo": "Min Amount:",
    "MensajeAH": "YOUR SCHEDULED SAVINGS REQUIREMENT CANNOT BE CONCLUDED DUE TO LACK OF UPDATING YOUR DATA, PLEASE GO TO THE NEAREST AGENCY",
    "MensajeCP": "YOUR ONLINE CREDIT REQUIREMENT CANNOT BE CONCLUDED DUE TO LACK OF UPDATING YOUR DATA, PLEASE GO TO THE NEAREST AGENCY",
    "MensajeCPG": "THE CREDIT INFORMATION WILL BE SUBMITTED THE FOLLOWING DAY.",
    "MensajeIL": "YOUR ONLINE INVESTMENT REQUIREMENT CANNOT BE CONCLUDED DUE TO LACK OF UPDATING YOUR DATA, PLEASE GO TO THE NEAREST AGENCY",
    "FijoPago": "First Date of Payment",
    "PrestamoPreaprobadoT": "Online Credit",
    "PrestamoPreaprobadoTMenu": "Pre-approved loans",
    "PrestamoPreaprobadoTMob": "Online Credit",
    "FinicioPagos": "Payment Start Date",
    "EstadoCuenta": "Account Statement",
    "CorteCuenta": "Account Cutoff",
    "NOperacion": "N° Account",
    "VerCertificado": "See Certificate",
    "TransferenciasAOtrosExt": "Outer Transfers",
    "FValor": "Date Value",
    "CSwift": 'SWIFT Code',
    "DirBanco": "Bank Direction",
    "TPago": "Payment Type",
    "DBeneficiario": "Beneficiary Direction",
    "CargosAFavor": "Charges in favor of",
    "CTransferenciasExt": "Outside Transfer History",
    "PorBeneficiario": "Beneficiary Percentage",
    "BCorresInter": "CORRESPONDENT OR INTERMEDIARY BANK",
    "BBeneficiario": "BENEFICIARY BANK",
    "DireccionB1": "Bank Address 1",
    "DireccionB2": "Bank Address 2",
    "DireccionB3": "Bank Address 3",
    "CodigoABA": "ABA Code",
    "CuentaBInter": "No. Intermediary Bank Account",
    "BenefFinal": "FINAL BENEFICIARY",
    "FDesdeHasta": "DATE TO MUST BE GREATER THAN DATE FROM",
    "OTPUnlockBCPMessage": "ENTER SECURITY OTP SENT TO CONTINUE WITH UNLOCK BY VALIDATION",
    "MensajeTranferenciasSPI": "Dear partners, due to the upcoming New Year festivities we inform you that all transfers to other financial institutions that are made through our web application until 4:00 p.m. on Thursday, December 30, will be processed the same day, transactions made after The appointed time will be sent on Monday, January 3, 2022.",
    "RealizarTransferencia": "Make Transfer",
    "RealizarPagoTarjeta": "Make Card Payment",
    'CredioPreaprobado': 'Preapproved Credit',
    'CreditoPreaprobado': 'Preapproved',
    "Transparencia": "Transparency",
    "CertificadoBancario": "Bank Certificate",
    "Descuentos": "Accept Legal Discounts",
    "FondoReserva": "Irrepartible Reserve Fund",
    "TesoroNacional": "National Treasure",
    "ImpuestoSolca": "SOLCA tax",
    "SeguroDesgravamen": "Relief Insurance",
    "AhorroProgramadoT": "Scheduled Savings initial value",
    "MensajeNoPreaprobado": "Dear client, your don't have a preaproved credit, go to the nearest office of Cooperative and request further information.",
    "MontomaxPre": "Maximun Amount",
    "Tinteres": "Interest Tax",
    "CuentaAhorro": "Savings Account",
    "TexoAcepta": "Dear customer, once you accept the agreed conditions of the online credit you will not be able to reverse it. Are you sure to continue with the transaction?",
    "TexoNoAcepta": "Dear customer, once the online credit is rejected, you will not be able to reverse it. Are you sure to continue with the transaction?",
    "AutorizacionAhorroP": "Authorization Programmed Savings",
    "AutorizaGAhorroP": " established by the Cooperative; I declare that I received all the information of this product.",
    "AutorizoAhorroP": "I expressly authorize to the Cooperativa de Ahorro y Crédito Julian Lorente, the creation of programmed savings, under the ",
    "AutorizaGCredPer": "Credit and Personal Information",
    "AutorizoCredPer": "I empower and authorize to the Cooperativa de Ahorro y Crédito Julian Lorente, to proceed to consult the information regarding my assets, liabilities and personal data, as well as to obtain data regarding my behavior credit, management of my account (s) of any kind and in general about the fulfillment of my financial and business obligations; in the same way I expressly authorize that, in the event of the eventual concession of the credit operation (s), the financial institution report the information regarding my payment behavior and, therefore, compliance with the credit obligations that I have freely and voluntarily accepted.",
    "MontoMensual": "Monthly amount",
    "AutorizoBloqueoCuenta": "I authorize the blocking of my account.",
    "ErrorCuentaVista": "ERROR GETTING ACCOUNT INFORMATION",
    "Fvigencia": "Effective Date",
    "FvigenciaC": "Effective Date",
    "PagCreditoLinea": "Online Credits",
    "SolTarjeta": "Debit Card Request",
    "SolTarjetaTitulo": "REQUEST ADDITIONAL DEBIT CARD",
    "NombreTrajeta": "Name you want printed on your card",
    "ColorTarjeta": "Choose your additional credit card design",
    "OficinaEntrega": "Withdrawal Office",
    "SolEnviada": "DEAR PARTNER, A DEBIT CARD APPLICATION ALREADY SENT ON:",
    "SolRegistrada": "Dear member, your request for the issuance of a Debit Card has been registered successfully; Please go to the withdrawal office of your choice within 5 working days, to be able to withdraw your card.",
    "PlazoFijoR": "Online Investment",
    "LicitudFondosR": "Legality of Funds",
    "And": " and ",
    "CreditoApro": "Contract",
    'IMPUESTOS': 'Taxes',
    'Sucursal': 'Branch Office',
    "Certificado": "Certificates",
    "BancaPersonas": "Income",
    "BancaEmpresas": "Business",
    'MensajeEstadoCuenta': 'THE SELECTED DATE CANNOT EXCEED THE LAST 12 MONTHS',
    "MasIva": "+ VAT",
    "Frecuencias": [
      /*{"value": 0, "viewValue": "AT DUE", "nDays": 1},
      {"value": 1, "viewValue": "DAILY", "nDays": 1},
      {"value": 2, "view value": "WEEKLY", "nDays": 5},
      {"value": 4, "viewValue": "BIWENNLY", "nDays": 15},*/
      { "value": 5, "view value": "MONTHLY", "nDays": 30 },
      /*{"value": 6, "view value": "QUARTERLY", "nDays": 90},
      {"value": 7, "view value": "SEMESTERLY", "nDays": 180},
      {"value": 8, "view value": "YEARLY", "nDays": 360},
      {"value": 12, "view value": "SEPTEMBER", "nDays": 210},
      {"valor": 13, "valorvista": "FOUR-MONTHLY", "nDays": 120},
      {"value": 14, "view value": "BIANNUAL", "nDays": 720}*/
    ],
    "VDiferimiento": "Deferral Value",
    "IDiferimiento": "Deferral Interest",
    "MisCuentas": "My Accounts",
    "Perfil": "Profile",
    "PServicios": "Services",
    "Internas": "CUENTAS PJL.",
    "Otros": "External",
    "NombreE": "Company Name",
    "NombreD": "Debtor Name",
    "NoTienePagos": "No Pending Payments",
    "NumeroOrden": "Order Number",
    'NEstudios': 'Level of Studies',
    'FVivienda': 'Housing Date',
    'FITrabajo': 'Work Entry Date',
    'FIngresos': 'Source Income',
    'TIngreso': 'Income Type',
    'MIngreso': 'Income Amount',
    "GeneratePDF": "PDF Format",
    "GenerateXLS": "XLS Format",
    "DatosPersonales": "Personal Data",
    "DireccionesTelefonos": "Directions and Phones",
    "FuenteIngresos": "Income Source",
    'PagosMenu': 'Loans',
    'PagoPrestamos': 'Pay Loans',
    "Normal": "Normal",
    "Estudiantil": "Student",
    "Diferenciada": "Differentiated",
    "TarifayPasajeros": "Fares and Passengers",
    "EscogeTarifaMsg": "Choose your correct rate and avoid being fined",
    "FechaHora": "Date and time of the ticket",
    "ResumenCompra": "Summary Purchase",
    "NumeroPasajeros": "Number Passengers",
    "AlertaTicketsExcedidos": "You have exceeded the maximum number of tickets for your purchase",
    "TranviaCuenca": "Cuenca Tram",
    "Pasajero": "Passengers",
    "VigenciaDesde": "Valid From",
    "VigenciaHasta": "Valid Until",
    "ValidoHasta": "Valid Until",
    "TotalTicket": "Total Tickets",
    "ComprarTickets": "Buy Tickets",
    "ServicioNoDisponible": "Service not available, try again later.",
    'SolicitudCredito': 'Credit Request',
    'TipoCredito': 'Credit destination',
    'TituloSolicitudCredito': 'Online Credit Application',
    'DatosSolicitados': 'Data of the requested credit:',
    'MontoSolicitado': 'Amount Requested',
    'CuotaMensual': 'Monthly Fee',
    'PlazoMeses': 'Term in Months',
    'CuotaAjustada': 'Adjusted Fee',
    'GarPropuesta': 'Proposed warranty ',
    'Descripicion': 'Description',
    'Avaluo': 'Commercial Value',
    'Garantes': 'or Guarantors ',
    'CTipo': 'Fee Type',
    'Cuotamin': 'The allowed value for the fee is between',
    'tipoGarantia': 'Warranty Type',
    'tipoBien': 'Type of Property',
    'destinoGarantia': 'Destination of Credit as Guarantee',
    'valorGrantizado': 'THE GUARANTEED VALUE IS LESS THAN THE VALUE OF THE PRODUCT',
    'CargarDocumento': 'Upload Document',
    'DescripcionDocumento': 'Document Description',
    'Requisitos': 'Requirements',
    'Garantia': 'Warranty',
    'Archivo': 'File',
    'CargaPendiente': 'Pending',
    'ArchivoGargado': 'Loaded',
    "TipoCertificado": "Certificate Type",
    "tipoCertificado": [
      { "value": "AC", "viewValue": "Active Account" },
      { "value": "AB", "viewValue": "Average Balance" },
      { "value": "C", "viewValue": "Credit" }
    ],
    "CuentaActiva": "Active Account",
    "SaldoPromedio": "Average Balance",
    "Crediticio": "Credit",
    "Nota": "Note",
    "Guardar": "Save",
    "GuardarBene": "Save Beneficiary",
    "GenerarSolicitud": "Generate Solicitude",
    'Acuerdo': 'For my own rights and those I represent, I authorize the Entity to obtain at any time and as many times as necessary from any source of information, including the risk center and the credit information bureaus, personal information about the behavior of my credit(s). In the same way, I authorize to transfer or deliver the information referring to all behavior of my credit(s) to competent authorities, control organisms, credit information bureaus and all those who legally request them, I also authorize to know such credit behavior to credit evaluation purposes, exempting the Entity from any responsibility, submitting myself to the provisions of the credit regulations.',
    'ApellidoNombre': 'SURNAMES AND NAMES',
    "Identificacion2": "IDENTIFICATION",
    "Fecha2": "DATE",
    "porcentajeF": "Percentage to financer",
    "Profesion": "Profession",
    "ActualizacionDatos": "Data Update",
    "InformacionBasica": "Basic Information",
    "DirecionTelefonos": "Address and Telephones",
    "ActividadEconomica": "ECONOMIC ACTIVITY (IF YOUR MAIN ACTIVITY IS DIFFERENT, UPDATE IN PERSON)",
    "ActividadEconomicaIngAct": "Activity Information (Income and Assets)",
    "ActividadEconomicaEgPas": "Activity Information (Expenses and Liabilities)",
    "Referencias": "References",
    "PersonaExpuestaPEP": "Politically Exposed Person (PEP / TAX RESIDENCE)",
    "DeclaracionAutorizacion": "Declaration and Authorization",
    "MsgDeclaracionAutorizacionTit": "PROMOTIONS AND ADVERTISING",
    "MsgDeclaracionAutorizacionTitD": "DATA STORAGE",
    "MsgDeclaracionAutorizacion": "In accordance with the provisions of Article 2 of the Law on Electronic Commerce, Electronic Signatures and Data Messages, I declare that the information I provide is authentic and truthful, therefore, I exempt the Cooperative from all liability, including with respect to third parties, if this statement is false or erroneous; and, I also authorize the Cooperativa de Ahorro y Crédito, the verification of the same, submitting to the pertinent laws in case of falsifying the truth and being ready to recognize the information before the competent authority in case of being necessary.",
    "MsgDeclaracionAutorizacionA": "I expressly and voluntarily authorize to Cooperativa de Ahorro y Crédito, in orden to validate my means of notification and contacts provided to the entity; and send me and/or contact my e-mail, mobile numbers, conventional numbers: notices, communications, temporary passwords, notifications of access to electronic channels, notifications of expiration of credit operations, reminders of payment dates, notifications of making transfers codes, security codes, digital marketing campaigns by any electronic means, informative and/or commercial calls, collection procedures, electronic sales receipts, information on products and services offered by Cooperativa de Ahorro y Crédito, and other notifications or information that Cooperativa de Ahorro y Crédito considers important to let me know. In addition, I authorize to carry out the control, management and monitoring procedures of the risks legally established in the law.",
    "MsgDeclaracionAutorizacionD": "I expressly and voluntarily authorize to Cooperativa de Ahorro y Crédito , to register and store in its databases, and apply the treatment and protection mechanisms that guarantee the confidentiality and security of the data which has been entered and updated. The data will be kept in accordance with: 1.- During the duration of my business relationship with Cooperativa de Ahorro y Crédito; 2.- Until I request the revocation of this consent, as long as I said revocation is applicable within the framework of the law and/or 3.- Until Cooperativa de Ahorro y Crédito considers it necessary to store my data.",
    "MsgPersonaExpuestaPEPAcp": "ARE YOU A POLITICALLY EXPOSED PERSON OR DO YOU HAVE LINKS WITH POLITICALLY EXPOSED PERSONS?",
    "Si": "Yes",
    "No": "No",
    "tipoIdentificacion": "Type of Identification",
    "identificacion": "Identification",
    "apellidos": "Surname",
    "nombres": "Names",
    "nacionalidad": "Nationality",
    "fechaNacimiento": "Date of Birth",
    "genero": "Gender",
    "correoElectronico": "Email",
    'NProfesion': 'Profession/Title',
    'miembrosFamilia': 'N° Family Members',
    'cargasFamiliares': 'N° Family Charges',
    'primerNombre': 'First Name',
    'primerApellido': 'First Surname',
    'segundoNombre': 'Second Name',
    'segundoApellido': 'Second Surname',
    "InformacionConyuge": "INFORMATION OF SPOUSE OR CLIENT",
    'tiemposVivienda': 'Years in the Dwelling',
    'tipoTelefono': 'Type Phone',
    'operadora': 'Operator',
    'area': 'Area',
    'pais': 'Country',
    'provincia': 'Province',
    'ciudad': 'City',
    'parroquia': 'Parish',
    'canton': 'Canton',
    'actividadPrincipal': 'Main Activity',
    'origenIngresos': 'Origin of Income',
    'lugarTrabajo': 'Workplace',
    'cargo': 'Load',
    'direccionTrabajo': 'Work Address',
    'telefonoTrabajo': 'Work Phone',
    'celularTrabajo': 'Work Cell',
    'relacion': 'Relation',
    'mensajeActualizacionRevision': 'PLEASE CONTINUE TO UPDATE THE MISSING INFORMATION',
    "Ingresos": "INCOME (MONTHLY VALUES)",
    "Activos": "ASSETS",
    "Arriendos": "RENTALS",
    "Comercio": "TRADE",
    "InteresInversiones": "INVESTMENT INTEREST",
    "OtrosIngresos": "OTHER INCOME",
    "PensionJubilacion": "RETIREMENT PENSION",
    "PensionesAlimenticias": "FOOD PENSIONS",
    "Remesas": "REMITTANCES",
    "SueldoConyugue": "SPOUSE SALARY",
    "SueldoMensual": "MONTHLY SALARY",
    "Bancos": "BANKS",
    "BienesMuebles": "MOVABLE PROPERTY",
    "Caja": "CASH REGISTER",
    "CuentasPorCobrar": "RECEIVABLE ACCOUNTS ",
    "Inversiones": "INVESTMENTS",
    "Maquinaria": "MACHINERY",
    "Mercaderias": "GOODS",
    "Terrenos": "LANDS",
    "Vehiculos": "VEHICLES",
    "Vivienda": "HOUSING",
    "Egresos": "EXPENSES (MONTHLY VALUES)",
    "Pasivos": "LIABILITIES",
    "Alimentacion": "FEEDING",
    "Arriendo": "RENTING",
    "CuotaCasasComerciales": "COMMERCIAL HOUSES FEE",
    "CuotaPrestamo": "LOAN FEE",
    "CuotaTrajetaCredito": "CREDIT CARD FEE",
    "Educacion": "EDUCATION",
    "OtrosGastos": "OTHER EXPENSES",
    "Salud": "HEALTH",
    "ServiciosBasicos": "BASIC SERVICES",
    "Transporte": "TRANSPORTATION",
    "Vestuario": "CLOTHING",
    "CasasComerciales": "COMMERCIAL HOUSES",
    "CuentasPorPagar": "PAYABLE ACCOUNTS ",
    "TrajetaCredito": "CREDIT CARDS",
    "MensajeActualizacionDatos": "DEAR MEMBER TO ACCESS THE INCENTIVE, PLEASE UPDATE YOUR DATA.",
    "PrimerPaso": "Info. Bas.",
    "SegundoPaso": "Dir. Telf.",
    "TercerPaso": "Act. Eco.",
    "CuartoPaso": "Act. Eco. Ing y Act",
    "QuintoPaso": "Act. Eco. Eg y Pas",
    "SextoPaso": "Ref.",
    "SeptimoPaso": "PEP",
    "OctavoPaso": "Decl. Aut.",
    "NoTienePasosPrevios": "DEAR USER, PLEASE CARRY OUT THE PREVIOUS STEPS TO ACTIVATE THE CURRENT ONE.",
    "Minimo": "Minimum",
    "Digitos": "digits",
    "Referencia1": "Reference 1",
    "Referencia2": "Reference 2",
    "DatosCompletos": "YOUR DATA IS CORRECTLY UPDATED",
    "Etnia": "Ethnicity",
    'DestinoCredito': 'To obtain the list of documents you must complete the information of the credit destination',
    'valorGarantia': 'THE GUARANTEE ENTERED INTO DOES NOT COVER THE VALUE OF THE CREDIT REQUESTED',
    'Registro': 'Client not registered in the system',
    'PorcentajeA22': 'The information entered will be subject to verification by the institution.',
    'PorcentajeA13': 'The information entered will be subject to verification by the institution.',
    'AutorizacionBuro': 'CONSULTATION AUTHORIZATION IN THE CREDIT BUREAU',
    'Atentamente': 'Sincerely',
    "IdentificacionG": "Dear Member, you should not enter your information, but rather the identification of the guarantor.",
    "MensajeA22": "ACCORDING TO YOUR WARRANTY, THE MAXIMUM AMOUNT TO REQUEST IS ",
    "MensajeA13": "AMOUNT TO BE GRANTED ACCORDING TO THE APPRAISAL OF THE GOOD",
    "numeroCuotaNovalido": "The number of installments for the requested amount is not valid",
    "InternasBene": "Internal",
    "OtrosBene": "External",
    "CampoNoModificable": "Field not modifiable",
    "MsgNCargasFamiliares": "Consider the number of people who are economically dependent on you",
    "Coperadora": [
      { "value": "1", "viewValue": "CNT" },
      { "value": "2", "viewValue": "MOVISTAR" },
      { "value": "3", "viewValue": "CLARO" },
      { "value": "4", "viewValue": "TWENTI" }
    ],
    "TCuota": "Quota Type",
    "TipoCuotas": [
      { "value": "AMG", "viewValue": "French" },
      { "value": "CAP", "viewValue": "German" }
    ],
    "SCuota": "N. Fees",
    "VCuota": "Fee Value",
    "TScoring": "Total Scoring Value",
    "DeudorFinanciero": "Debtor In The Financial Sist.",
    "TInstitucion": "Titles Outside the Institution",
    "TPermanencia": "Dwell Time",
    "tituloMensajeBloqueoCuenta": "ARE YOU SURE TO BLOCK YOUR ACCOUNT?",
    "MensajeBloqueoCuenta": "Dear user, when you perform this action, your account will be temporarily restricted, so you will not be able to make ATM withdrawals, transfers, create digital products or access other benefits of Coop Virtual PJL. Once the acceptance of the account blocking is confirmed, you can go to the nearest agency, reactivate the platform or request more information about the account blocking.",
    "SCredito": "Credit Request",
    "PrestamoS": "Loan Requested",
    "UnosDatos": "A few more information: ",
    "ModalidadTrabajo": "Work Modality",
    "AhorrosVistaFirst": "Savings",
    "AhorrosVistaSecond": "Vista",
    "AhorrosProgramadoFirst": "Savings",
    "AhorrosProgramadoSecond": "Scheduled",
    "CertificadosAportacionFirst": "Certificate",
    "CertificadosAportacionSecond": "Contribution",
    "DeseaCredito": "I AM NOT A MEMBER YET BUT I WANT A LOAN (click HERE)",
    "RegGiros": "Order Registry",
    "ConGiros": "Query Order",
    "Giros": "Orders",
    "MontoDebitar": "Debit Ammount",
    "CuentaDebitar": "Debit Account",
    "AcNumeroTel": "Current phone number",
    "AcDireccion": "Current Address",
    "EsperaToken": "OTP validity time",
    "Instalar": "Install",
    "App": "App",
    "TituloInstalarApp": "Install app on your Device",
    "InstalariOS": "Or to install the app, tap the share button",
    "InstalariOSTienda": "Download the application from the Apple Store.",
    "InstalariOSTiendaSafari": "Download the application from the Apple Store or through the Safari browser.",
    "InstalariOSSec": "Then sipe up to find and tap 'Add to home screen'",
    "InstalariOSThird": "And once the application is installed on the device, you can proceed to use it by selecting it from the start menu.",
    "InstalarAndroid": "Or download it directly from the following button",
    "InstalarAndroidTienda": "Download the application from Google Play Store.",
    "InstalarAndroidTiendaCrhome": "Download the application from the Google Play Store or through the Chrome browser.",
    "InstaladoAndroid": "The mobile application was installed correctly, you can proceed to use it from your mobile device by selecting it from the start menu.",
    "TienePreApro": "You have a pre-approved loan from",
    "TienePreAproAntiTit": "Salary Advance",
    "TienePreAproAnti": "You have a salary advance available for",
    "TienePreAproAntiD": ". Click here for more information",
    "TransferirMobMen": "Transfer",
    "RegresarBene": "Choose the Beneficiary",
    "RegresarServ": "Choose the Service",
    "RegresarTarj": "Choose Credit Card",
    "RegresarIni": "Return to Home",
    "DesvincularDispositivo": "Unpair",
    "DesvincularDispositivoTot": "Unpair device",
    "DesvincularDispositivoTotMens": "You already have a paired device, to unpair it, please enter the following information",
    "Dispositivo": "Device",
    "VincularDispositivo": "FitToken",
    "LeerQR": "Read QR Code:",
    "IngresarPin": "Enter PIN / Fingerprint / Face ID",
    "IngresarPinLog": "Enter PIN",
    "DispAVincular": "Device to Pair",
    "DispVinculado": "Device Paired",
    "NombreDispositivo": "Device Name",
    "FechaRegistro": "Register Date",
    "Vincular": "Pair",
    "MsgDispositivoVinculado": "Dear customer, to use the FitToken device you must first log in to the mobile device and accept the permissions to receive push notifications.",
    "SolicitudEnviada": "Request Sent to your device",
    "SolicitudNoVigAutorizada": "Request not valid or Authorized",
    "MsgNotificacionesPush": "Dear customer, if the authorization notification does not arrive on your mobile device you can authorize it by opening the application",
    "DispositivoDesvinculado": "Device Unpaired",
    "ValidandoPin": "PIN Validation",
    "ValidandoBiometric": "Validating Biometric Credential",
    "AutorizacionTransferencia": "Transfer Authorization",
    "AutorizacionBeneficiario": "Beneficiary Authorization",
    "AutorizacionMontosMaximos": "Max Ammount Authorization",
    "AutorizacionBloqueoCuenta": "Authorize Account Blocking",
    "SolicitudAutorizada": "Authorized Request",
    "ServicioMobMen": "Services",
    "PrestamoMobMen": "Loan",
    "DatosContacto": "Contact information",
    "SolicitarAutorizacion": "Request Authorization",
    "Autorizacion": "Authorization",
    "RegSoftToken": "Register fit-token",
    "DesSoftToken": "Unpair fit-token",
    "EliminarPin": "Delete Pin",
    "FechaVinculacion": "Paired Date",
    "CodigoQR": "QR Code",
    "MisContactos": "My Contacts",
    "AutorizacionLogin": "Login Authorization",
    "AddFavorites": "Add to Favorites",
    "IngresarReferencia": "Please, enter a reference",
    "NoConexGuradar": "NO CONNECTION TO SAVE",
    "Ejecutar": "Execute",
    "TituloAutorizacion": "Authorization for the processing of your personal data.",
    "MsgAutorizacion": "In compliance with the provisions of Law 81 of March 26, 2019, Executive Decree No. 285 of May 28, 2021 and Agreement 001-2022 of February 24, 2022, all related to the Protection of Personal Data, Coop Virtual PJL, in its capacity as responsible for the processing of personal data, recognizes the importance of privacy, security, sensitivity and confidentiality of the data of our clients, collaborators, shareholders, suppliers and others. ; therefore, in terms of personal data protection, we request your consent to process personal data in the manner indicated within our policy.",
    "MsgAutorizar": "You authorize the processing of your data:",
    "ScanearCodigoQR": "Scan QR code:",
    "MsgScanearCodigo": "Dear Customer, download Coop Virtual PJL application on your mobile application from Google Play or Apple Store and scan the following QR code:",
    "MsgScanearCodigoTran": "Dear user, please show your QR code to receive a payment or transfer.",
    "MsgCerrarCodigo": "Once the pairing with your cell phone is complete, you can close this window.",
    "Copiar": "Copy to clipboard",
    "CopiarMsg": "Account copied to clipboard",
    "CodigoArea": "Area Code",
    "FiltrarResultadosPaises": "Search area code",
    'NombresCuenta': [
      { 'descripcion': 'CERTIFICADOS DE APORTACION', 'viewValue': 'CONTRIBUTION CERTIFICATES' },
      { 'descripcion': 'CUENTA BASICA', 'viewValue': 'BASIC ACCOUNT' },
      { 'descripcion': 'AHORRO PROGRAMADO ESCOLAR', 'viewValue': 'SCHOOL PROGRAMMED SAVINGS' },
      { 'descripcion': 'AHORRO PROGRAMADO NAVIDENO', 'viewValue': 'CHRISTMAS SCHEDULED SAVINGS' },
      { 'descripcion': 'AHORRO CONVENIO', 'viewValue': 'AGREEMENT SAVINGS' },
      { 'descripcion': 'AHORRO CLIENTES', 'viewValue': 'SAVINGS CUSTOMERS' },
      { 'descripcion': 'AHORRO SOCIO', 'viewValue': 'MEMBER SAVINGS' },
      { 'descripcion': 'AHORRO ENCAJE', 'viewValue': 'LACE SAVINGS' },
      { 'descripcion': 'AHORRO PROGRAMADO INFANTIL', 'viewValue': 'CHILDRENS PROGRAMMED SAVINGS' },
      { 'descripcion': 'AHORRO PLUS', 'viewValue': 'PLUS SAVINGS' },
      { 'descripcion': 'AHORRO PROGRAMADO', 'viewValue': 'PROGRAMMED SAVINGS' },
      { 'descripcion': 'AHORRO PROGRAMADO CLIENTES', 'viewValue': 'CUSTOMER PROGRAMMED SAVINGS' },
      { 'descripcion': 'AHORRO OBLIGATORIO', 'viewValue': 'MANDATORY SAVINGS' },
      { 'descripcion': 'AHORRO PROGRAMADO GOLD', 'viewValue': 'GOLD PROGRAMMED SAVINGS' },
      { 'descripcion': 'AHORRO PROGRAMADO FUTURO', 'viewValue': 'FUTURE PROGRAMMED SAVINGS' },
      { 'descripcion': 'AHORRO PROGRAMADO EJECUTIVO', 'viewValue': 'EXECUTIVE PROGRAMMED SAVINGS' },
      { 'descripcion': 'CUENTAS CORRESPONSALIA', 'viewValue': 'CORRESPONDENT ACCOUNTS' },
      { 'descripcion': 'CUENTAS CORRIENTES', 'viewValue': 'CURRENT ACCOUNTS' },
      { 'descripcion': 'CUENTAS AHORROS', 'viewValue': 'SAVINGS ACCOUNTS' },
      { 'descripcion': 'CUENTAS POR PAGAR', 'viewValue': 'ACCOUNTS PAYABLE' },
      { 'descripcion': 'DEPOSITOS A PLAZO FIJO', 'viewValue': 'FIXED-TERM DEPOSITS' },
      { 'descripcion': 'CERT.AHORRO ACTIVOS', 'viewValue': 'ASSET SAVINGS CERT.' },
      { 'descripcion': 'AHORRO COOP VIRTUAL', 'viewValue': 'VIRTUAL COOP SAVINGS' },
      { 'descripcion': 'MICROCREDITO CONAFIPS ZONA A', 'viewValue': 'CONAFIPPS ZONE A MICROCREDIT' },
      { 'descripcion': 'MICROCREDITO CONAFIPS ZONA B', 'viewValue': 'CONAFIPPS ZONE B MICROCREDIT' },
      { 'descripcion': 'PRESTAMO CREDI AHORRO MICRO', 'viewValue': 'CREDI MICRO SAVINGS LOAN' },
      { 'descripcion': 'PRESTAMO VIVIENDA CFN', 'viewValue': 'CFN HOUSING LOAN' },
      { 'descripcion': 'PRESTAMO MICROCREDITO', 'viewValue': 'MICROCREDIT LOAN' },
      { 'descripcion': 'PRESTAMO EMERGENTE MICROCREDIT', 'viewValue': 'EMERGING LOAN MICROCREDIT' },
      { 'descripcion': 'PRESTAMO CONSUMO CREDI AGIL', 'viewValue': 'CREDI AGIL CONSUMER LOAN' },
      { 'descripcion': 'PRESTAMO CONSUMO', 'viewValue': 'CONSUMER LOAN' },
      { 'descripcion': 'PRESTAMO CONSUMO CONVENIO', 'viewValue': 'CONSUMER LOAN AGREEMENT' },
      { 'descripcion': 'PRESTAMO MICRO GARA-FOGEPS VIP', 'viewValue': 'MICRO GARA-FOGEPS VIP LOAN' },
      { 'descripcion': 'PRESTAMO MICROACUM AMPLIAD-VIP', 'viewValue': 'EXTENDED-VIP MICROACUM LOAN' },
      { 'descripcion': 'PRESTAMO MICRO EMPRENDE MUJER', 'viewValue': 'MICRO ENTREPRENEURSHIP LOAN FOR WOMEN' },
      { 'descripcion': 'PRESTAMO CONSUMO ORD-PRIOR-B2B', 'viewValue': 'ORD-PRIOR-B2B CONSUMER LOAN' },
      { 'descripcion': 'MICROCREDITO CONAFIPS ZONA C', 'viewValue': 'CONAFIPPS ZONE C MICROCREDIT' },
      { 'descripcion': 'PRESTAMO PRODUCTIVO SECTORIAL', 'viewValue': 'SECTOR PRODUCTIVE LOAN' },
      { 'descripcion': 'PRESTAMO VIVIENDA', 'viewValue': 'HOUSING LOAN' },
      { 'descripcion': 'PRESTAMO MICROACUM SIMPLE MVIP', 'viewValue': 'MVIP SIMPLE MICROACUM LOAN' },
      { 'descripcion': 'PRESTAMO CONSUMO ORDINARIO', 'viewValue': 'ORDINARY CONSUMPTION LOAN' },
      { 'descripcion': 'PRESTAMO PRODUCTIVO', 'viewValue': 'PRODUCTIVE LOAN' },
      { 'descripcion': 'PRESTAMO VIVIENDA CONAFIPS', 'viewValue': 'CONAFIPS HOUSING LOAN' },
      { 'descripcion': 'PRESTAMO MICRO ECOLOGICO', 'viewValue': 'MICRO ECOLOGICAL LOAN' },
      { 'descripcion': 'PRESTAMO CREDI AHORRO CONSUMO', 'viewValue': 'CREDI CONSUMPTION SAVINGS LOAN' },
      { 'descripcion': 'PRESTAMO MICRO EMPRENDEDOR', 'viewValue': 'MICRO ENTREPRENEUR LOAN' },
      { 'descripcion': 'PRESTAMO CONSUMO ORD-PRIOR-VIP', 'viewValue': 'ORD-PRIOR-VIP CONSUMPTION LOAN' },
      { 'descripcion': 'CONSUMO INIAP', 'viewValue': 'INIAP CONSUMPTION' },
      { 'descripcion': 'PRESTAMO PRODUCTIVO TITULARIZADO', 'viewValue': 'SECURED PRODUCTIVE LOAN' },
      { 'descripcion': 'PRESTAMO CONSUMO TITULARIZADO', 'viewValue': 'SECURED CONSUMER LOAN' },
      { 'descripcion': 'INVERSIONES EN OTRAS INSTITUCIONES', 'viewValue': 'INVESTMENTS IN OTHER INSTITUTIONS' },
      { 'descripcion': 'PLAZO FIJO BANCOS', 'viewValue': 'FIXED TERM BANKS' },
      { 'descripcion': 'DPF VENCIDO', 'viewValue': 'DPF EXPIRED' },
      { 'descripcion': 'FUSIÓN MINORISTA', 'viewValue': 'RETAIL MERGER' },
      { 'descripcion': 'FUSIÓN ACUMULACIÓN SIMPLE', 'viewValue': 'SIMPLE ACCUMULATION FUSION' },
      { 'descripcion': 'FUSIÓN ACUMULACIÓN AMPLIADA', 'viewValue': 'FUSION EXPANDED ACCUMULATION' },
      { 'descripcion': 'DPF INTELIGENTE', 'viewValue': 'SMART DPF' },
      { 'descripcion': 'COMPRA DE CARTERA', 'viewValue': 'PORTFOLIO PURCHASE' },
      { 'descripcion': 'AHORRO PROGRAMADO EN LINEA', 'viewValue': 'ONLINE SCHEDULED SAVINGS' },
      { 'descripcion': 'DPF EN LINEA', 'viewValue': 'DPF ONLINE' },
      { 'descripcion': 'MICROCRÉDITO LC1 MIN', 'viewValue': 'MICROCREDIT LC1 MIN' },
      { 'descripcion': 'PRESTAMOS VENDIDOS CONSUMO', 'viewValue': 'LOANS SOLD CONSUMPTION' },
      { 'descripcion': 'PRESTAMOS VENDIDOS MICROCREDITO', 'viewValue': 'LOANS SOLD MICROCREDIT' },
      { 'descripcion': 'PRESTAMOS VENDIDOS PRODUCTIVO', 'viewValue': 'PRODUCTIVE SOLD LOANS' },
      { 'descripcion': 'PRESTAMOS VENDIDOS INMOBILIARIO', 'viewValue': 'REAL ESTATE LOANS SOLD' },
      { 'descripcion': 'CREDIINVERSIÓN', 'viewValue': 'CREDITINVESTMENT' }
    ],
    "Desinstalar": "Uninstall",
    "DesintalarAplicacion": "If you already uninstall the application but have problems, you can force the deletion of temporary files with the following button:",

    //FaceLiveness
    "timeoutHeaderText": "Time out",
    "timeoutMessageText": "Face didn't fill oval within time limit. Try again and completely fill oval with face within 7 seconds.",
    "faceDistanceHeaderText": "Check failed during countdown",
    "faceDistanceMessageText": "Avoid moving closer during countdown and ensure only one face is in front of camera.",
    "clientHeaderText": "Client error",
    "clientMessageText": "Check failed due to client issue",
    "serverHeaderText": "Server issue",
    "serverMessageText": "Cannot complete check due to server issue",
    "landscapeHeaderText": "Landscape orientation not supported",
    "landscapeMessageText": "Rotate your device to portrait (vertical) orientation.",
    "portraitMessageText": "Ensure your device remains in portrait (vertical) orientation for the check’s duration.",
    "tryAgainText": "Try again",
    "instructionsHeaderHeadingText": "Biometric validation",
    "instructionsHeaderBodyText": "You will go through a facial identification process, to validate that you are a real person.",
    "instructionsBeginCheckText": "Begin check",
    "photosensitivyWarningHeadingText": "Warning",
    "photosensitivityWarningHeadingText": "Warning",
    "photosensitivyWarningBodyText": "For a correct verification you must remain completely still after fitting your face in the oval and you must not move the mobile device",
    "photosensitivityWarningBodyText": "For a correct verification you must remain completely still after fitting your face in the oval and you must not move the mobile device",
    "photosensitivyWarningInfoText": "A small percentage of individuals may experience epileptic seizures when exposed to colored lights. Use caution if you, or anyone in your family, have an epileptic condition.",
    "instructionListHeadingText": "Follow the instructions to complete the check:",
    "goodFitCaptionText": "Good fit",
    "goodFitAltText": "Ilustration of a person's face, perfectly fitting inside of an oval.",
    "tooFarCaptionText": "Too far",
    "tooFarAltText": "Illustration of a person's face inside of an oval; there is a gap between the perimeter of the face and the boundaries of the oval.",
    "instructionListStepOneText": "When an oval appears, fill the oval with your face within 7 seconds.",
    "instructionListStepTwoText": "Maximize your screen's brightness.",
    "instructionListStepThreeText": "Make sure your face is not covered with sunglasses or a mask.",
    "instructionListStepFourText": "Move to a well-lit place that is not in direct sunlight.",
    "cameraMinSpecificationsHeadingText": "Camera does not meet minimum specifications",
    "cameraMinSpecificationsMessageText": "Camera must support at least 320*240 resolution and 15 frames per second.",
    "cameraNotFoundHeadingText": "Camera not accessible.",
    "cameraNotFoundMessageText": "Check that camera is connected and camera permissions are enabled in settings before retrying.",
    "retryCameraPermissionsText": "Retry",
    "cancelLivenessCheckText": "Cancel Liveness check",
    "recordingIndicatorText": "Rec",
    "hintMoveFaceFrontOfCameraText": "Move face in front of camera",
    "hintTooManyFacesText": "Ensure only one face is in front of camera",
    "hintFaceDetectedText": "Face detected",
    "hintCanNotIdentifyText": "Move face in front of camera",
    "hintTooCloseText": "Move back",
    "hintTooFarText": "Move closer",
    "hintHoldFacePositionCountdownText": "Hold face position during countdown",
    "hintConnectingText": "Connecting...",
    "hintVerifyingText": "Verifying...",
    "hintIlluminationTooBrightText": "Move to dimmer area",
    "hintIlluminationTooDarkText": "Move to brighter area",
    "hintIlluminationNormalText": "Lighting conditions normal",
    "hintHoldFaceForFreshnessText": "Hold still",
    "ErrorLiveness": "Error during liveness detection",
    "ValidacionLiveness": "The face liveness was not successful",
    "MensajeSoftOtp": "Dear customer, you do not have a transaction pending authorization",
    "GenerarOTP": "Generate OTP",
    "ClaveDigital": "Digital key",
    "MsgClaveDigitalWEB": "The digital key has been sent to your email.",
    "MsgClaveDigitalMOB": "To obtain the digital key you must open the COOP VIRTUAL PJL application and press the \"Digital key\" button on your mobile device.",
    "hintCenterFaceText": "Center your face and press the button",
    "startScreenBeginCheckText": "Start video check",
    "waitingCameraPermissionText": "Waiting for you to allow camera permission.",
    "TieneSolicitud": "You have a request and want to review it, complete it or cancel it",
    "CertificadoCuentaActiva": "Active Account Certificate",
    "CertificadoConsolidado": "Consolidated Certificate",
    "Certificados": "Certificates",
    "PrevisualizarCertificado": "Preview",
    "GenerarCertificado": "Generate",
    "CertificarioBancario": "BANK CERTIFICATE",
    "ValidacionCertificados": "Validation of Certificates",
    "descripcionValidacionCertificados": "The certificates in electronic format issued by Cooperativa de Ahorro y Crédito Padre Julián Lorente, must be validated by the institution or person requesting it. Enter the Certificate No. located at the bottom of the document's barcode.",
    "Importante": "IMPORTANT: ",
    "descripcionImportante": "The only valid information is the one generated on the screen; the displayed certificate can be downloaded if required by the procedure.",
    "codigoDeImagen": "Please enter the code from the image *",
    "CreditoPrea": "PRE. CRED",
    "validarCertificado": "Validate Certificate",
    "labelValidacionCertificado": "Validate your PJL Certificate",
    "numeroDocumento": "DOCUMENT NUMBER",
    "ejemploNumeroDocumento": "WEB-11-19990101-000001 ó PC-22-19990101-000001",
    "mensajeInformativoCertificadoBancario": "YOU CAN FIND THE CERTIFICATE NUMBER BELOW THE DOCUMENT'S BARCODE.",
    "cardNumber": "Card Number",
    "FechaEmision": "Date Of Issue",
    "DialogForSendReportToEmail": "The certificate was sent to your email. Please check.",
    "EnviarSPI": "Do you want to send the transfer through the SPI?",
    "DialogoParaEnviarNotificacionAlCorreo": "Blocked Account.",
    "NumCuotasA": "Savings Term",
    "AgenciasC": "Select your nearest agency",
    "MensBene": "NO CRITERIA MATCH YOUR SEARCH"
  }

  espanol = {
    "AGUA": "Agua",
    "ALPHANUMERICPASSWORDHB": "Combinación de números y letras.",
    "Abono": "Abono",
    "Aceptar": "Aceptar",
    "AceptarPreApro": "Aceptar Préstamo",
    "Actualizar": "Actualizar",
    "ActualizarMontos": "Actualizar Montos",
    "AdministracionCuenta": "Administración de Cuenta",
    "Administracion": "Administración de Cuenta",
    "AdministracionMob": "Administración",
    "AhorroProgramado": "Ahorro Programado",
    "AlertCambioEmail": "Correo electrónico fue actualizado exitosamente",
    "AlertCambioPsw": "Contraseña actualizada exitosamente",
    "AlertClaveA": "Ingreso incorrecto de clave actual",
    "AlertCoincidencia": "Las contraseñas no coinciden",
    "AlertCon": "Ingreso incorrecto de correo electrónico",
    "AlertaDes": "Administración no Disponible en Modo Offline",
    "AlertaID": "Web Service NO DISPONIBLE",
    "AlertaService": "Servicio NO DISPONIBLE, intente mas tarde",
    "AlertaPreg": "Preguntas no encontradas",
    "AlertaPswd": "Respuesta incorrecta",
    "AnadirCuenta": "Nueva Cuenta",
    "AnadirTarjeta": "Nueva Tarjeta Crédito",
    "ApellidoMaterno": "Apellido Materno",
    "ApellidoPaterno": "Apellido Paterno",
    "Apellidos": "Apellidos",
    "AperturaCuentas": "Nueva Cuenta",
    "Atrasado": "Vencido",
    "Atrasos": "Atrasos",
    "BalanceBloqueado": "Balance Bloqueado",
    "BalanceContable": "Balance Contable",
    "Banco": "Banco",
    "BancoBeneficiario": "Banco Beneficiario",
    "Beneficiarios": "Beneficiarios",
    "Bloqueado": "Bloqueado",
    "Bloqueos": "BLOQUEOS",
    "BtnEmail": "Cambio de correo electrónico",
    "BtnPassword": "Cambio de contraseña",
    "BtnDireccion": "Cambio de dirección",
    "Calcular": "Calcular",
    "CATALOGO": "Catálogo",
    "CDebito": "Cuenta Débito",
    "CDestino": "Cuenta Destino",
    "CInteres": "Interés",
    "CMora": "Mora",
    "CNacionalidad": [
      { "value": "EC", "viewValue": "Ecuatoriana" },
      { "value": "PE", "viewValue": "Peruana" },
      { "value": "CO", "viewValue": "Colombiana" }
    ],
    "COMPRAS": "Compras",
    "CONSEJO_JUDICATURA": "Consejo Judicatura",
    "COrigen": "Cuenta Origen",
    "CPagadas": "Cuotas Pagadas",
    "CPagar": "Cuotas por Pagar",
    "CPendientes": "Cuotas Pendientes",
    "CSexo": [
      { "value": "M", "viewValue": "Masculino" },
      { "value": "F", "viewValue": "Femenino" }
    ],
    "CTransTarjServRec": "Consultas Locales",
    "CTrans": "Transferencias realizadas",
    "CTarj": "Pagos Realizado",
    "CServ": "Pagos Realizado",
    "CTransferencias": "Consultas de Transferencias",
    "CTransferenciasTarjeta": "Consultas Pago Tarjetas",
    "Caducar": "Eliminar",
    "Canal": "Canal",
    "Cantidad": "Cantidad",
    "CapReducido": "Capital Reducido",
    "CapReducidoMobile": "C. Reducido",
    "Capitalpl": "Capital",
    "Capitalpr": "Capital",
    "Cargos": "Cargos",
    "CedulaInv": "Identificación Inválida",
    "CerrarSesion": "Cerrar Sesión",
    "Cgirada": "Cuenta Girada",
    "ChatBot": "Chat Bot",
    "Cheque": "Cheque",
    "ChequesLocales": "CHEQUES LOCALES",
    "Chequesotros": "CHEQUES OTRAS PLAZAS",
    "CodigoServicio": "Código de Servicio",
    "Comision": "Comisión",
    "ComisionReprogramada": "Interés Repr.",
    "ConServPagados": "Consultar Servicios Pagados",
    "ConServicios": "Consultar Servicios",
    "Concepto": "Concepto",
    "ConfCoNueva": "Confirme contraseña nueva",
    "Confirmar": "Por Confirmar",
    "Confirmacion": "Confirmar",
    "Felicitaciones": "Felicitaciones",
    "ConsulCuota": "Tabla de pagos",
    "ConsultaOffLinePagoServicio": "NO SE PUEDE CONSULTAR EL VALOR A PAGAR, DEBIDO A QUE SE ENCUENTRA EN MODO OFFLINE",
    "ConsultaPrevia": "No existe consulta previa",
    "Consultar": "Consultar",
    "Continuar": "Continuar",
    "ContraActual": "Contraseña actual",
    "ContraNueva": "Contraseña nueva",
    "Contrasena": "Contraseña",
    "Coordenadas": "Coordenadas",
    "CorreoActual": "Correo electrónico actual",
    "CorreoE": "Nuevo correo electrónico",
    "TelefonoE": "Nuevo teléfono",
    "DireccionE": "Nueva dirección",
    "CporCobrar": "Cuentas Por Cobrar",
    "CreacionPersonas": "Nuevo Cliente",
    "CrearCuenta": "Cuenta creada con éxito",
    "CtasPlazo": "DEPÓSITOS A PLAZO",
    "CtasPlazoG": "DEPÓSITOS A PLAZO",
    "CtasPrestamos": "PRÉSTAMOS",
    "CtasPrestamosM": "PRÉSTAMO",
    "CtasPrestamosG": "PRÉSTAMOS",
    "CtasPrestamosPc": "Préstamos",
    "CtasVista": "CUENTAS VISTA",
    "CtasVistaG": "CUENTAS VISTA",
    "Cuenta": "Cuenta",
    "CuentaAhorrosDebCred": "Cuenta de Ahorros Para DB y CR",
    "CuentaDestino": "Cuenta Destino",
    "CuentaOrigen": "Cuenta Origen",
    "Cuota": "Tasa de Interés",
    "CuotaPrestamos": "Cuota",
    "Cupo": "Cupo",
    "DatosBeneficiario": "Datos del Beneficiario",
    "DatosCuenta": "Datos de la Cuenta",
    "DatosDebitosCreditos": "Datos de Débitos / Créditos",
    "DatosInversion": "Datos de la Inversión",
    "DatosTarjeta": "Datos de la Tarjeta de Crédito",
    "DatosTarjetaCredito": "DATOS PAGO TARJETA DE CRÉDITO",
    "DatosTransferencia": "DATOS TRANSFERENCIA",
    "DatosTransferencias": "Datos Transferencias",
    "DatosUsuario": "Datos del usuario",
    "DebeLogin": "Debe ingresar nuevamente al sistema",
    "BloqueoCuenta": "BLOQUEO DE CUENTA",
    "DatosBloqueoCuenta": "Datos Bloqueo de Cuenta",
    "MotivoBloqueoCuenta": "Describa el motivo de bloqueo",
    "DepCert": "Depósitos Cert.",
    "DepCertApo": "Depósitos Certificados Aportación",
    "Depositos": "Depósitos",
    "DepositosaPlazo": "DEPÓSITOS A PLAZO",
    "Desbloquear": "Desbloquear",
    "DescargarComprobante": "Descargar Comprobante",
    "CompartirComprobante": "Compartir Comprobante",
    "Compartir": "Compartir",
    "VisualizarComprobante": "Visualizar y Compartir",
    "DescargarEstado": "Descargar Estado de Cuenta",
    "Descargar": "Descargar",
    "DescMasTarde": "Se ha desconectado, inténtelo más tarde",
    "Desconectado": "Por el momento estás desconectado",
    "Descripcion": "Descripción",
    "DescripcionServicio": "Descripción de Servicio",
    "DesdeCuenta": "Desde Cuenta",
    "DetServ": "DETALLE DEL SERVICIO",
    "DetalleAdicional": "DETALLE ADICIONAL",
    "DetalleAmortizacion": "TABLA DE AMORTIZACIÓN",
    "DetalleP": "DETALLE DE PAGO",
    "DetalleB": "DETALLE DE BENEFICIARIO",
    "DetallePago": "TABLA DE PAGOS",
    "DetalleTransacciones": "DETALLE TRANSACCIONES",
    "DetalleTransferencia": "Detalle",
    "DetalleServicios": "DETALLE SERVICIO",
    "Detalles": "Detalles",
    "Dias": "Días",
    "DiasMora": "Días Mora",
    "DiaMes": "Día del Mes",
    "Direccion": "Dirección",
    "DireccionEMail": "Dirección de E-Mail",
    "Disponible": "Disponible",
    "Editar": "Editar",
    "EN": "Inglés",
    "ES": "Español",
    "ElimInfo": "Se eliminará toda esta información",
    "Empresa": "Empresa",
    "Entidad": "Entidad",
    "EntidadDestino": "Entidad Destino",
    "Enviar": "Enviar",
    "EnviarTranferCtasPropias": "TRANSFERENCIA CUENTAS PROPIAS",
    "EnvioToken": "OTP Enviado",
    "ErrorChatBot": "Ups!  Hubo un error, intétalo más tarde. Si el error continúa por favor comunicate con nosotros ",
    "ErrorChatBotNoInternet": "Para usar los servicios del Chat Bot debes tener conexión a internet, por favor inténtalo más tarde...",
    "ErrorJWT": "Error de autenticación: Caducidad tiempo sin uso.",
    "Escribiendo": "escribiendo...",
    "Espanol": "Español",
    "Estado": "Estatus",
    "EstadoCivil": "Estado Civil",
    "ExcNumSesion": "Ha excedido el número de intentos permitidos",
    "FCapitalizacion": "Fecha Capitalización",
    "FConfirmacion": "Fecha Confirmación",
    "FDeposito": "Fecha Depósito",
    "FDesde": "Fecha Desde",
    "FHasta": "Fecha Hasta",
    "FPago": "Fecha de Pago",
    "FRegistro": "Fecha Registro",
    "FTrasaccion": "Fecha Transacción",
    "Fabono": "Fecha Abono",
    "FaltaConsultaBeneficiarios": "No se realizó Consulta De Beneficiarios",
    "FaltaConsultaCuentasTransferencias": "No existe consulta previa de cuentas para transferencias o pagos",
    "FaltaConsultaTransferencias": "No se realizó Consulta De Transferencias",
    "Fecha": "Fecha",
    "FechaApertura": "Fecha Apertura",
    "FechaNacimiento": "Fecha Nacimiento",
    "FechaVencimiento": "Fecha Vencimiento",
    "FiltrarResultados": "Ingrese su búsqueda",
    "Filtro": "FILTROS",
    "Filtros": "FILTROS",
    "FproxPago": "Próximo Pago",
    "FrIntereses": "Frecuencia Intereses",
    "Frecuencia": "Frecuencia",
    "FrecuenciaPagoInteres": "Frecuencia de Pago de Interés",
    "GIROS": "Giros",
    "GUAMOTE": "Guamote",
    "GrafPosiCons": "RESUMEN GRÁFICO",
    "GrafTrans04": "EVOLUCIÓN DE SALDOS",
    "GrafTrans05": "TRANSACCIONES DEPÓSITOS A PLAZO",
    "GrafTrans06": "EVOLUCIÓN SALDOS PRÉSTAMO",
    "Grande": "Grande",
    "GrupoProducto": "Grupo del Producto",
    "HTrasaccion": "Hora Transacción",
    "HaciaCuenta": "A Cuenta",
    "HaciaTarjeta": "A Tarjeta de Crédito",
    "Hora": "Hora",
    "Hoy": "Hoy",
    "IESS": "IESS",
    "INTERNET": "Internet",
    "Identificacion": [
      { "value": "CED", "viewValue": "Cédula" },
      { "value": "PAS", "viewValue": "Pasaporte" },
      //{ "value": "RUC", "viewValue": "RUC" }
    ],
    "Identificacion1": "Identificación",
    "IdentificacionBeneficiario": "Identificación Beneficiario",
    "Idioma": "Idioma",
    "IdiomaSeleccionado": "ES",
    "Imprimir": "Imprimir",
    "Impuesto": "Impuesto",
    "Independiente": "Independiente",
    "Ingles": "Inglés",
    "Inicio": "Inicio",
    "InstFinan": "Institución Financiera",
    "Interes": "Interés",
    "InteresPagar": "Interés Pagar",
    "InteresTotal": "Interés Total",
    "InteresesMora": "Mora",
    "InteresesMoraPrestamoVencido": "Intereses Mora + Intereses Préstamo Vencido",
    "InteresesT": "Intereses Totales",
    "LUZ": "Luz",
    "LastLogin": "Último login",
    "Limpiar": "Limpiar",
    "LoanAccount": "Cuenta Préstamo",
    "LoginOK": "Ingreso correcto",
    "LoginOff": "Ha ingresado en modo Offline",
    "LoginPassExtraMessage": "No entregue los datos de sus Cuentas, tarjetas, contraseña por ningún medio. No solicitamos la actualización de datos vía correo electrónico. No preguntamos su e-mail, ni adjuntamos links a la Cooperativa Virtual. Es recomendable escribir directamente en el navegador de internet. No ingrese en el portal haciendo clic en un link desde un correo electrónico, aunque parezca ser un correo de la Cooperativa Julian Lorente, lo que buscan es obtener sus datos.<br>He leído, comprendido y me comprometo a practicar las medidas de seguridad antes expuestas, cada vez que haga uso de los canales electrónicos asociados a mi cuenta.",
    "LoginUserCreatedCallCenterValidation": "Nuestro Call Center se contactará con usted en máximo 24 horas laborables para validar la información y habilitar su Cooperativa Virtual, en caso de emergencia marque al 06 -2997000 opción 1 para validación de lunes a viernes de 08:00 a 17:00 horas.<br>Asegúrese que su usuario y contraseña no sean de fácil relación con el número de sus tarjetas, contrato o fechas importantes como cumpleaños, aniversarios, etc. No las anote en ningún lado y no las de a conocer a ninguna persona, ya que son las llaves de acceso a sus cuentas y a su dinero. Recuerde que la seguridad de estos datos es responsabilidad suya.",
    "LoginRecoverUsrPassCallCenterValidation": "Nuestro Call Center se contactará con usted en máximo 24 horas laborables para validar la información y habilitar su Cooperativa Virtual, en caso de emergencia marque al 06 -2997000 opción 1 para validación de lunes a viernes de 08:00 a 17:00 horas.",
    "LoginSecureURL": "Antes de ingresar sus datos en esta pantalla, verifique si la dirección en la barra es: <b>https://bancavirtual.lorente.fin.ec//#/login</b>",
    "LoseInfo": "Se perderá la información del formulario actual.",
    "LugarNacimiento": "Lugar de Nacimiento",
    "MOriginal": "Capital Original",
    "MUNICIPIOS": "Municipio",
    "ManteElimInfo": "Se eliminará toda la información de los usuarios sin actividad en los últimos",
    "Mantenimiento": "Mantenimiento",
    "Mediana": "Mediana",
    "Mensaje": "Mensaje",
    "MensajeTransferExitosa": "Tu transferencia se realizó con éxito",
    "TituloTransferExitosa": "Transferencia Exitosa",
    "MensajePagoRecarExitosa": "Tu Pago se realizó con éxito",
    "tituloMensajeBloqueoCuenta": "¿ESTÁ SEGURO DE BLOQUEAR SU CUENTA?",
    "MensajeBloqueoCuenta": "Estimado usuario al realizar esta acción, tu cuenta quedará temporalmente restringida, por lo que no podrás realizar retiros en cajeros automáticos, transferencias, crear productos digitales o acceder a otro beneficio de Coop Virtual PJL. Una vez se confirme la aceptación del bloqueo de cuenta puede acercarse a la agencia más cercana, reactivar la plataforma o solicitar más información del bloqueo de cuenta.",
    "MensajeConfirmacionReq": "Estás de acuerdo con tu requerimiento?",
    "MensajeConfirmacionRat": "Estás de acuerdo con tu calificación?",
    "MensajeEdge": "Se recomienda usar la aplicación en GoogleChrome o Mozilla Firefox",
    "MensajePagServ": "Pago realizado, desea registrar este servicio",
    "Moneda": "Moneda",
    "Monto": "Monto",
    "MontoAper": "Monto Apertura",
    "MontoAutorizado": "Monto Autorizado",
    "MontoInvertir": "Monto A Invertir",
    "MontoMax": "Monto Max.",
    "MontoMaximo": "Montos Máximos",
    "MontosMaximos": "Montos Máximos",
    "MontoMaximoExedido": "Monto máximo excede límite",
    "MontoMaximoTransferencia": "Montos Máximos",
    "MontoMin": "Monto Min",
    "MontoRecarga": "Monto a Recargar",
    "MontoTransferencia": "Monto",
    "Montomax": "Monto Máximo",
    "Montomin": "Monto Mínimo",
    "MontosActualizados": "Montos actualizados",
    "Movimientos": "Movimientos",
    "NCuenta": "N° Cuenta",
    "NImgAndQ": "Solicitar nuevas imágenes y preguntas",
    "NQ": "Solicitar nuevas preguntas",
    "NToken": "Solicitar nuevo OTP",
    "Nacionalidad": "Nacionalidad",
    "NadaCadServicio": "Ningún servicio seleccionado",
    "NadaCaducar": "Ningún beneficiario seleccionado",
    "NoApiServerResp": "Sin respuesta de servidor, se activará modo offline",
    "NoConectado": "No está conectado a internet. Su solicitud se encuentra en Tareas Pendientes",
    "NoConexVerif": "SIN CONEXIÓN PARA AUTENTICAR",
    "NoConsultaPreviaConsultaTransf": "No existe consulta previa para tipo de transferencia seleccionada",
    "NoConsultaPreviaCta": "No existe consulta previa para cuenta seleccionada",
    "NoCtaAhorros": "No existen cuentas de ahorros",
    "NoInternet": "Estas desconectado",
    "NoMontosActualizados": "No actualizo el valor de montos",
    "NoPrestamosCuentas": "No existen cuentas de préstamos",
    "NoTieneBeneficiarios": "No tiene beneficiarios registrados",
    "NoTieneNotificaciones": "No Tiene Notificaciones Para Mostrar",
    "NoTieneActualizacionDatos": "Por favor, para poder solicitar un crédito, vaya a la opción de actualización de datos, y actualice sus datos",
    "NoTieneServicios": "No Tiene Servicios Para Mostrar",
    "NoTieneServiciosPagados": "No Tiene Servicios Pagados",
    "NoTieneTareasPend": "No Tiene Tareas Pendientes Para Mostrar",
    "NoTieneTransferencias": "No tiene transferencias para el tipo y fecha seleccionados",
    "NoTrans": "No hay registro de transacciones",
    "NoTransFechas": "No existen registros de transacciones desde la fecha seleccionada",
    "NoUsrMigCompleteData": "ESTIMADO CLIENTE, ES NECESARIO REGISTRAR SU USUARIO HACIENDO CLIC EN LA OPCIÓN '$newUserText'.",
    "NombreBeneficiario": "Nombre Beneficiario",
    "Nombres": "Nombre y Apellido",
    "Notificacion": "Notificación",
    "Notificaciones": "Notificaciones",
    "NuevaContrasenia": "¿Olvidó su contraseña?",
    "Nuevo": "Nuevo",
    "NuevoUsuario": "Nuevo usuario",
    "NuevoUsuarioInvalido": "Debe ingresar letras y números",
    "NuevoUsuarioLength": "Al menos 6 caracteres",
    "NumCelular": "Número Celular - Contrato",
    "NumCuota": "Nro.",
    "NumCuotaPen": "No.Cuota Pendiente",
    "NumCuotaVen": "No.Cuota Vencida",
    "NumCuotas": "No.Cuotas",
    "NumCuotasPendientes": "Cuotas Pendientes",
    "Numero": "Número",
    "NumeroCargas": "N° de Cargas",
    "NumeroCuenta": "Número Cuenta",
    "NumeroPagos": "Plazo Máximo",
    "NumeroTarjeta": "Número Tarjeta",
    "NumeroTelefonoCelular": "Número Celular",
    "OBLIGACIONES_PATRONALES": "Obligaciones Patronales",
    "ONLYNUMPASSWORDHB": "Combinación solo numérica.",
    "Offline": "Estimado cliente. Se ha activado el modo Offline del aplicativo, ingresando podra observar las ultimas consultas que realizó en línea. Gracias por su comprensión.",
    "Oficina": "Oficina",
    "Online": "En Línea",
    "Opciones": "Más Opciones",
    "OpcionesConsTransf": [{ "value": "1", "viewValue": "Cuentas Propias" }, { "value": "2", "viewValue": "Cuentas de Terceros" }, { "value": "3", "viewValue": "Otras Instituciones" }],
    "Operadora": "Operadora",
    "Other": "Otro",
    "Otp": "Ingrese su token de seguridad para iniciar sesión en el sistema.",
    "PLANES": "Planes",
    "PPS_MIES": "PPS - MIES",
    "Pagadero": "Pagadero",
    "Pagada": "Pagado",
    "Pagar": "Pagar",
    "Pago": "PAGO",
    "PagoServicios": [
      { "codServ": "6000", "viewValue": "Pagos Servicios" },
      { "codServ": "6001", "viewValue": "Recargas" },
    ],
    "Pagos": "PAGOS",
    "PassMal": "CONTRASEÑA INCORRECTO",
    "Pequena": "Pequeña",
    "Pignorado": "Pignorado",
    "Pignorados": "PIGNORADOS",
    "Plazo": "Plazo",
    "PlazoInversion": "Plazo de la Inversión",
    "PlazoMax": "Plazo Max",
    "PlazoMin": "Plazo Min",
    "Plazomax": "Plazo Máximo",
    "Plazomin": "Plazo Mínimo",
    "PosicionConsolidada": "Posición Consolidada",
    "GraficoConsolidada": "Gráfico Consolidado",
    "PresionaSalir": "Presiona una vez más para salir",
    "PrestamoPreaprobado": "CRÉDITO EN LINEA",
    "Prestamos": "PRÉSTAMOS",
    "PrimerNombre": "Primer Nombre",
    "Producto": "Producto",
    "Proximo": "Próximo Pago",
    "Pssw": "CONTRASEÑA",
    "PassExpirado": "CONTRASEÑA EXPERIDA",
    "PsswConfirm": "CONFIRMAR CONTRASEÑA",
    "RECARGAS": "Recargas",
    "RECOLECCION_BASURA": "Recolección de Basura",
    "Recargas": "Recargas",
    "Reacudaciones": "Reacudaciones",
    "Rechazar": "Cancelar",
    "RechazarPreApro": "Rechazar",
    "Recuperar": "Recuperar",
    "RecuperarUsuario": "¿Olvidó su usuario?",
    "RedirLogin": "Se le redireccionará a la pantalla de Login",
    "Reenviar": "Repetir",
    "Referencia": "Referencia",
    "RegServicio": "Registro de Servicio",
    "RegViejo": "El registro más antiguo pertenece al usuario",
    "Regresar": "Regresar",
    "RepeatTransfer": "El formulario de transferencia se cargará para repetir la transacción.",
    "RepeatRecharge": "El formulario de recarga se cargará para repetir la transacción.",
    "RepeatServicePay": "El formulario de pago de servicio se cargará para repetir la transacción.",
    "RegisterRecharge": "El formulario de recarga se cargará para realizar transacción.",
    "RegisterServicePay": "El formulario de pago de servicio para realizar la transacción.",
    "ReporteDialog": "Ingreso de fecha necesario para descargar reporte",
    "ReporteServDialog": "Ingreso de cuenta necesario para descargar reporte",
    "Retiros": "Retiros de Ahorros",
    "saveUser": "¿Desea que se recuerde su usuario, en este dispositivo, para futuros ingresos?",
    "SAhorros": "SALDO AHORROS",
    "SCapital": "Saldo Capital",
    "SDisponible": "Saldo Disponible",
    "SERVICIOS_VARIOS": "Servicios Varios",
    "SIntereses": "Interés",
    "SPrestamos": "SALDO PRÉSTAMOS",
    "SRI": "SRI",
    "Saldo": "Saldo",
    "SaldoA": "Saldo Actual",
    "SaldoAhorros": "Saldo Ahorros",
    "SaldoCapitalTotal": "Saldo Capital Total",
    "SaldoCapitalVendido": "Saldo Capital Vendido",
    "SaldoContable": "Saldo Contable",
    "SaldoDisponible": "Saldo Disponible",
    "SaldoEfectivizar": "Saldo Efectivizar",
    "SaldoPrestamos": "Saldo Préstamos",
    "SaldoTotal": "Saldo Total",
    "SaldoV": "Saldo Vencido",
    "SegCerrSesion": "Está seguro que desea cerrar sesión",
    "SegContTx": "Está seguro de continuar con la transacción?",
    "SegEditTarea": "Si edita la tarea, esta será eliminada de tareas pendientes.",
    "SegElimTarea": "Está seguro que desea eliminar esta tarea",
    "SegToken": "OTP DE SEGURIDAD",
    "SegundoNombre": "Segundo Nombre",
    "Seguro": "Seguro",
    "SeguroContinuar": "¿Seguro desea continuar?",
    "SeguroContinuarTransferencia": "¿Quiere realizar la transferencia?",
    "SeguroCancelar": "¿Seguro que quiere cancelar?",
    "Seguros": "Otros",
    "ServPagados": "SERVICIOS PAGADOS",
    "ServReg": "Servicios Frecuentes",
    "Servicio": "Servicio",
    "Servicios": "SERVICIOS",
    "ServiciosMin": "Servicios",
    "ServidorError": "Error en la Transacción",
    "Sexo": "Género",
    "Siguiente": "Siguiente",
    "Sobregiro": "Sobregiro",
    "SolicitarToken": "Solicitar OTP",
    "SolicitudDepositoPlazo": "Solicitud de Depósito a Plazo",
    "Suministro": "Suministro",
    "TARJETA": "Tarjeta",
    "TELEFONO": "Teléfono",
    "TRANSITO": "Tránsito",
    "TamanoFuente": "Tamaño de Fuente",
    "TarPend": "Transacciones Pendientes",
    "Tarjeta": "Tarjetas de Crédito",
    "TarjetasCredito": "Gestión de Tarjetas",
    "Tasa": "Tasa",
    "Telefono": "Número de Teléfono",
    "Tema": "Tema",
    "TemaClaro": "Claro",
    "TemaClaroAzul": "Claro Azul",
    "TemaClaroGris": "Claro Gris",
    "TemaClaroVerde": "Claro Verde",
    "TemaOscuro": "Oscuro",
    "TiempoFuncionamientoNegocio": "Tiempo Funcionamiento Negocio (Meses)",
    "Tipo": "Tipo",
    "TipoCuenta": "Tipo Cuenta",
    "TipoNegocio": "Tipo Negocio",
    "TipoTransaccion": "Tipo de Transacción",
    "TipoVivienda": "Tipo Vivienda",
    "TiposCuentaBeneficiario": [
      { "value": "AH", "viewValue": "Ahorros" },
      { "value": "CO", "viewValue": "Corriente" },
      { "value": "TC", "viewValue": "Tarjeta de Crédito" }
    ],
    "TituloDialogos": "Coop Virtual PJL",
    "TituloIngresoBeneficiario": "Ingreso de Beneficiarios",
    "TituloIngresoBeneficiarioTc": "Ingreso de Tarjetas de Crédito",
    "TituloPreguntas": "FAVOR RESPONDA SUS PREGUNTAS SECRETAS",
    "Token": "OTP de Seguridad",
    "TokenDeSeguridad": "OTP de Seguridad",
    "TotCuota": "Cuota",
    "Total": "Total",
    "TotalVencidoPagar": "Total Vencido a Pagar",
    "Transaccion": "Transacción",
    "TransaccionDescCanal": "Canal - Descripción",
    "TransaccionE": "Transacción Exitosa",
    "TransaccionEPer": "Transacción Exitosa, por favor continue con el siguiente paso para el ingreso de documentos",
    "TransaccionExitosa": "Transacción realizada correctamente",
    "Transacciones": "TRANSACCIONES",
    "TransaccionesWeb": [
      { "codTransf": "6071", "viewValue": "Otras Instituciones" },
      { "codTransf": "6073", "viewValue": "Cuentas Propias" },
      { "codTransf": "6074", "viewValue": "Cuentas a Terceros" }
    ],
    "Transferencias": "TRANSFERENCIAS",
    "transfererir": "Transferir",
    "TransferenciasAOtros": "Otras Instituciones",
    "TransferenciasATarjetas": "Transferencias Tarjeta de Crédito",
    "TransferenciasCuentasPropias": "Cuentas Propias",
    "TransferenciasTarjetaCredito": "Pago Tarjeta de Crédito",
    "TransferenciasTerceros": "Cuentas de Terceros",
    "UbicacionNegocio": "Ubicación del Negocio",
    "UltimaConexion": "Ultima Conexión: ",
    "UltimaBusqueda": "Se mostrará la última búsqueda",
    "UltimasTransferencias": "ÚLTIMAS TRANSFERENCIAS",
    "UltimasTransferenciasUpC": "Últimas Transferencias",
    "UserTotallyBloquedCallCenterContact": "Estimado cliente, las respuestas a sus preguntas de validación fueron incorrectas, por su seguridad su usuario ha sido bloqueado, para activarlo comuníquese a nuestro Call Center al 06-2997000 opción 1 o acérquese a cualquiera de nuestras oficinas.",
    "Usr": "USUARIO",
    "Usuario": "Usuario",
    "VALIDATE_CAPITAL": "Mínimo una letra mayúscula.",
    "VALIDATE_MINPASS": "Mínimo $MINPASSWORDHB caracteres.",
    "VALIDATE_REPEATED": "No puede ser igual a las $ITERPASSWORD contraseñas anteriores.",
    "VALIDATE_SPECIALPASS": "Mínimo un caracter especial.",
    "VBloqueado": "Valor Bloqueado",
    "VConfirmar": "por Confirmar",
    "VMora": "Valor en Mora",
    "VPagar": "Valor a Pagar",
    "VPignorado": "Valor Pignorado",
    "ValCuota": "Valor Cuota",
    "ValidarCampo": "El campo es requerido",
    "ValidarNoCarEsp": "El campo solo puede recibir letrasy números",
    "ValidarUsuario": "El usuario es requerido",
    "Valor": "Valor",
    "ValoresCalcular": "Valores a Calcular",
    "Vencimientopl": "Fecha Vencimiento",
    "VencimientoplMob": "F. Vencimiento",
    "Vencimientopr": "Fecha Vencimiento",
    "FEfectivizacion": "Fecha Efectivización",
    "btnIngresar": "Ingresar",
    "consPagServ": "Consultas Pago Servicios",
    "estadoCivil": [
      { "value": "001", "viewValue": "Soltero" },
      { "value": "002", "viewValue": "Casado" },
      { "value": "003", "viewValue": "Divorciado" },
      { "value": "004", "viewValue": "Unión de Hecho" }
    ],
    "favImg": "Imagen favorita",
    "gestionServicios": "Gestión de Servicios",
    "idSocio": "Id Cliente",
    "idTipo": "Tipo de Identificación",
    "InteresGanado": "Interés a ganar al final",
    "NetoGanado": "Neto a recibir",
    "informacionCorrecta": "¿La información es correcta?",
    "keepUserInfo": "Mantener mi información para soporte offline",
    "lblServicios": "Servicios",
    "lblTransferencias": "Transferencias Locales",
    "lblTransferenciasMob": "Transferencias",
    "loanPayment": "Pago de Préstamo",
    "mensajeContra": "La contraseña debe cumplir con las siguientes características:",
    "migratedUserNameChange": "Actualización de Nombre de Usuario",
    "minAccountNumberLength": "Mínimo 5 digitos",
    "montoDesde": "Monto entre: ",
    "montoEnteroRecargas": "Solo montos enteros",
    "montoIntervaloRecargas": "con intervalos de: ",
    "montoMaximoRecargas": "Monto máximo: ",
    "montoMinimoRecargas": "Monto mínimo: ",
    "msgConActual": "Contraseña actual requerida",
    "msgConFNueva": "Confirmación de contraseña requerida",
    "msgConNueva": "Contraseña nueva requerida",
    "msgCorreo": "Correo electrónico requerido",
    "msgCorreoE": "El formato de correo es incorrecto",
    "msgTelefono": "Teléfono requerido",
    "msgTelfonoE": "El formato de teléfono es incorrecto",
    "msgPregunta": "Respuesta requerida",
    "msjCerrarSesion": "Si ingresa las sesiones activas serán expiradas.",
    'msjAcuerdoPrivacidad': 'Acuerdo de privacidad.',
    "msjContacto": "Contáctese con la institución para más información",
    "msjContrasenaRequerida": "La contraseña es requerida",
    "msjContrato": "Estimado cliente, para usar la aplicación debe aceptar el siguiente contrato:",
    "msjExedeTamano": "El campo tiene demasiados dígitos",
    "msjIdRequerido": "La identificación es requerida",
    "msjMin": "El campo tiene pocos dígitos",
    "msjNombre": "El nombre es requerido",
    "msjOtpminmax": "El OTP debe ser de 6 dígitos",
    "msjSeleccionImgQ": "Por favor complete el siguiente formulario de seguridad:",
    "msjSoloEmail": "Debe ingresar un email válido",
    "msjSoloNumeros": "El campo solo puede recibir números",
    "msjSoloNumerosLetras": "El campo solo puede recibir números y letras",
    "msjSoloTexto": "El campo solo puede recibir texto",
    "msjalertaPreguntas": "DEBE ELEGIR UNA IMAGEN Y CONTESTAR TODAS LAS PREGUNTAS",
    "msjalertaPreguntasPass": "DEBE INGRESAR UNA NUEVA CONTRASEÑA Y CONTESTAR TODAS LAS PREGUNTAS",
    "msjalertaQ": "DEBE CONTESTAR TODAS LAS PREGUNTAS",
    "msjalertaSesion": "Usuario con sesión activa en otro dispositivo.",
    "noAllowedAmount": "Cantidad no Permitida",
    "noIdAccountMatch": "Identificación no corresponde a cuenta ingresada",
    "noRepetirTransferencia": "La transferencia no puede ser reenviada.<br>Cuenta origen o destino ya no está disponible.",
    "usuarioSospechoso": "ESTIMADO USUARIO, SE DETECTÓ UN CAMBIO DE CLAVE, POR FAVOR COMUNIQUESE CON NOSOTROS PARA VALIDAR SU IDENTIDAD.",
    "nombreSocio": "Nombre del Cliente",
    "numeroCuotas": "Número de cuotas entre: ",
    "offlineBlocked": "MODO OFFLINE BLOQUEADO",
    "pagServ": "Pago Servicios",
    "pagRec": "Pago Recaudaciones",
    "paymentSettings": "Opciones de Pago",
    "paymentValueZero": "Sin valor de pago",
    "sendPayment": "Enviar Pago",
    "tipoNegocio": [
      { "value": "I", "viewValue": "Independiente" },
      { "value": "D", "viewValue": "Dependiente" }
    ],
    "tipoTransaccionL": [
      { "codTx": "PSE", "viewValue": "Pago de Servicios" },
      { "codTx": "TRF", "viewValue": "Transferencias Web" }
    ],
    "tipoVivienda": [
      { "value": "P", "viewValue": "Propia" },
      { "value": "A", "viewValue": "Arrendada" }
    ],
    "PASSWORDNOVIGENTE": "Estimado cliente/a:<br><br>"
      + "Su contraseña <b>Cooperativa Virtual</b> se encuentra caducada, por su seguridad se solicita<br>"
      + "realizar el cambio por una nueva clave personal e intransferible.<br><br>"
      + "Gracias por utilizar nuestros servicios eletrónicos. Cualquier inquietud por favor<br>"
      + "comunicarse al 06-2997000 opción 1 o sugerencias@23julio.fin.ec<br><br>"
      + "Cordialmente:<br>"
      + "<b>Cooperativa Financiera Julian Lorente</b>",
    "PasswordCambiado": "PASSWORD CAMBIADO CON ÉXITO",
    // Plazo fijo
    "PlazoFijo": "Inversiones en Línea",
    "PlazoFijoA": "Depósito a Plazo en Línea",
    "NuevoSocio": "Ser socio",
    "optionalTextLogin": "Cooperativa Virtual no solicita por ningún medio información personal, financiera, claves, números de cuentas o tarjetas.",
    "tablaDocumentos": "Historial de Compras",
    "NuevoProducto": "Nuevo Produto",
    "Eliminar": "Eliminar",
    "Ecommerce": "Tienda en Línea",
    "Home": "Inicio",
    "Tiendas": "Tiendas",
    "ComprasRealizadas": "Compras Realizadas",
    "AdminProductos": "Administración Tienda",
    "VentasRealizadas": "Movimientos Tienda",
    "AdminTienda": "Administracíon Tienda",
    "CompraT": "Su compra es:",
    "FrEntrega": "Forma de entrega:",
    "Contado": "Contado:",
    "RAlmacen": "Retira del almacén:",
    "Cuotas": "12 cuotas de:",
    "NCuotas": "No se puede pagar a cuotas",
    "EDomicilio": "Entrega a domicilio:",
    "Subtotal": "Subtotal:",
    "IVA": "IVA:",
    "TotalEc": "Total:",
    "Direcciones": "Direccion",
    "DireccionesRef": "Referencia Direccion",
    "MensajeCompra": "GRACIAS POR SU COMPRA",
    "MensajeToken": "Comunicar este numero a la tienda para que le entreguen su compra:",
    "MensajeFactura": "Su factura ha sido enviada a su correo electrónico. Si desea imprimir su recibo click ",
    "Aqui": "aquí",
    "MensajeCedula": "Recuerde debe presentar su cédula para retirar o recibir suproducto",
    "Ccompra": "Confirmar Compra",
    "Cerrar": "Cerrar",
    "PContado": "Precio Contado:",
    "Comprar": "Comprar",
    "NProductos": "LA TIENDA NO TIENE PRODUCTOS REGISTRADOS",
    "NCompras": "NO HAY COMPRAS REGISTRADAS",
    "NVentas": "LA TIENDA NO TIENE VENTAS REGISTRADAS",
    "AdministracionTienda": "Administración Tienda",
    "TablaProducto": "Tabla Productos",
    "Categoria": "Categorías",
    "MantenimientoTienda": "Mantenimiernto Tienda",
    "IngresoProducto": "Ingreso de productos",
    "EditarProducto": "Editar productos",
    "EliminarProducto": "Está seguro que quiere eliminar el producto..??",
    "CargarProductos": "Cargar Lote Productos",
    "SeleccionarImagen": "Seleccionar Imagen",
    "SeleccionarArchivo": "Seleccionar Archivo",
    "Precio": "Precio",
    "GuardarCambios": "Guardar Cambios",
    "NoDisponible": "No Disponible",
    "Diferir": "Contado",
    "NoDiferir": "Cuotas",
    "Caracteristica1": "Característica Principal",
    "Caracteristica2": "Característica Secundaria",
    "FCompra": "Fecha Compra",
    "FEntrega": "Fecha Entrega",
    "NOrden": "N° Orden",
    "Recibo": "Recibo",
    "Cliente": "Cliente",
    "VComprar": "Comprar nuevamente",
    "CVenta": "Confirmar venta",
    "TarOffline": "Transacciones Offline",
    "VUnitario": "Valor Unitario",
    "NoConectadoEcomm": "No está conectado a internet. Su solicitud se encuentra en Transacciones Offline",
    "CompraR": "COMPRA REALIZADA",
    "EVENTOS": "Eventos",
    "REGISTRO_PROPIEDAD": "Registro Propiedad",
    "SERVICIOS_FINANCIEROS": "Servicios Financieros",
    "RECAUDACIONES": "Recaudación",
    "PEAJE": "Peaje",
    "msgPrea1": "El valor de la cuota supera el máximo permitido de $",
    "msgPrea2": ". Por favor aumente el plazo o disminuya el monto del crédito.",
    "NoAceptarPreApro": "No gracias",
    "InfoTransferencias": "* Las transferencias realizadas después de las 16:30, a entidades sin pago directo se procesarán al siguiente día laborable.",
    "InforTransBene": "Selecciona el número de cuenta del beneficiario y transfiere tu dinero al instante.",
    "InforTransBeneTar": "Selecciona el número de tarjeta de crédito y paga tu servicio al instante.",
    "InforPagServ": "Selecciona el suministro que deseas cancelar y realiza tus pagos al instante",
    "Agencias": "Agencias",
    "Cajeros": "Cajeros",
    "Simuladores": "Simuladores",
    "Creditos": "Preaprobados",
    "DepositoP": "Depósito a Plazo Fijo",
    "Informacion": "INFORMACIÓN",
    "Contactos": "Contactos",
    "Ayuda": "Ayuda",
    "SoloClientes": "SOLO PARA CLIENTES",
    "IngresarBM": "Ingresar a Banca Móvil",
    "IngresarBW": "Ingresar a Banca Web",
    "Ahorros": "Ahorros",
    "BienvenidoM": "Bienvenido a Banca Móvil COAC Padre Julián Lorente",
    "BienvenidoW": "Bienvenido a Banca Web COAC Padre Julián Lorente",
    "BienvenidoD": "Bienvenido",
    "BienvenidoDC": "Bienvenido a tu Coop Virtual PJL",
    "BienvenidoDM": "Encuentra y accede de manera oportuna a todos tus servicios financieros",
    "RegresarI": "Regresar a Inicio",
    "Escoger": "Escoja una opción del menú",
    "Telefonos": "Teléfonos",
    "HorAtencion": "Horarios de Atención",
    "PagosProgramados": "Pagos Programados",
    "RegPagosProgramados": "Registro Pagos",
    "ManPagosProgramados": "Mantenimiento Pagos",
    "TransaccionesPro": "Transacciones",
    "Dia": "Día",
    "Intento": "Intentos",
    "PlazoMaximo": "Plazo Máximo",
    "NoPagosProgramados": "No Tiene Pagos Programados Registrados",
    "TransaccionesPagosPro": [
      { "id": "6000", "value": "Pagos Servicios" },
      { "id": "6001", "value": "Recargas" },
      { "id": "6071", "value": "Transferencias a Otras Instituciones" },
      { "id": "6073", "value": "Transferencias Cuentas Propias - Misma Institución" },
      { "id": "6074", "value": "Transferencias a Terceros - Misma Institución" }
    ],
    "IniciarSesion": "Iniciar Sesión",
    "OlvidoContrasenia": "¿Olvidaste tu contraseña?",
    "NoTienesCuenta": "¿Aún no tienes cuenta con nosotros?",
    "Registrate": "Regístrate",
    "Registrar": "Registrar",
    "RecuperarContrasenia": "Recuperar Contraseña",
    "RecuperarUsuarioT": "Recuperar Usuario",
    "RegistrarUsuario": "Registrar Usuario",
    "RegistrarSocio": "Registro Cliente",
    "DesbloquearUsuario": "Desbloquear Usuario",
    "RegistrarHuella": "¿Desea registrar las credenciales biométricas de este dispositivo?",
    "RegistrarHuellaB": "Registrar Credencial Biométrica",
    "DesvincularHuellaB": "Desvincular dispositivo Biometrico",
    "RegistrarHuellaBA": "Estimado soci@, para REGISTRAR sus credenciales biométricas, necesita ingresar su contraseña actual",
    "RegistrarHuellaDBA": "Estimado soci@, para DESVINCULAR su dispositivo móvil, necesita ingresar su contraseña actual",
    "HuellaRegistradaTel": "Usuario ya posee una CREDENCIAL BIOMETRICA registrada.",
    "HuellaRegistradaTelUsu": "Usuario ya posee una CREDENCIAL BIOMETRICA registrada.",
    "RegistrarHuellaExitosa": "Credenciales biométricas registradas exitosamente!<br>Intenta usarlo en tu siguiente ingreso",
    "ErrorRegistrarHuellaExitosa": "Problemas al registrar credenciales biometricas",
    "ErrorHuella": "Credenciales biometricas inválidas!",
    "ErrorHuellaPassword": "Se detectó un cambio en su autenticación, REGISTRAR sus credenciales biométricas al iniciar sesión",
    "btnAtras": "Atras",
    "btnListo": "Listo",
    "btnSaltar": "Skip",
    "MensajeTransferencias": "Realiza tus transferencias de forma ágil y eficaz",
    "MensajeServicios": "Realiza tus pagos de forma rápida, desde donde estés.",
    "IngresoTarjetasCredito": "Ingresa una Tarjeta de Crédito",
    "IngresoBeneficiarios": "Ingresa un Beneficiario",
    "ServicioEscoger": "Escoge un Servicio a Pagar",
    "BtnInfoPersona": "información personal",
    "BtnContactAsesor": "Contactár Asesor PJL",
    "BtnCalificar": "Califícanos",
    "BtnCalificanos": "Calificar",
    "btnBloqueoCuenta": "Bloqueo De Cuenta",
    "BtnPreguntas": "Preguntas Frecuentes",
    "BtnEnviarReq": "Enviar Requerimiento",
    "Observacion": "Escribe aquí tu Requerimiento",
    "ObservacionF": "Observaciones",
    "observacionRating": "Escribe aquí tu Comentario",
    "Rating": "Tu Calificación",
    "MiCuenta": "MI CUENTA",
    "ServiciosFinancieros": "SERVICIOS FINANCIEROS",
    "CuentaA": "Cta:",
    "ConsultaDetalle": "CONSULTA EL DETALLE",
    "CuentaSocio": "CTA. CLIENTE",
    "Ahorro": "AHORROS",
    "AhorroMob": "AHORRO",
    "Inversion": "INVERSIÓNES",
    "InversionPC": "Inversiones",
    "InversionMob": "INVERSIÓN",
    "Credito": "CRÉDITO",
    "Tarjetas": "TARJETAS",
    "TarjetasPc": "Tarjetas",
    "Splash1": "Consulta de saldos de efectivo ¡donde estés!",
    "Splash2": "Realiza tus transferencias y pago de servicios desde cualquier lugar",
    "Splash3": "Administra tu dinero desde cualquier lugar de manera ágil y segura",
    "FingerprintButton": "Huella /",
    "FingerprintButtonF": "Face ID",
    "PasswordButton": "Contraseña",
    "EsperePorfavor": "Espere por favor...",
    "RegistrandoHuella": "Estamos registrando tus credenciales del dispositivo...",
    "SolicitarTurno": "SOLICITAR TURNO",
    "GraficaPosicionConsolidad": [
      { "descripcion": "CTA. CLIENTE", "viewValue": "CTA. CLIENTE" },
      { "descripcion": "DEP. VISTA", "viewValue": "DEPÓSITOS A LA VISTA" },
      { "descripcion": "DEP. PLAZO", "viewValue": "DEPÓSITOS A PLAZO" },
      { "descripcion": "PRÉSTAMOS", "viewValue": "PRÉSTAMOS" }
    ],
    "Divisa": "Divisa",
    "TarjetaTCR": "TCR",
    "TarjetaReg": "LA TARJETA DE CÉRDITO YA SE ENCUENTRA REGISTRADA CON LA IDENTIFICACION",
    "BeneficiarioReg": "EL NÚMERO DE CUENTA YA SE ENCUENTRA REGISTRADO CON LA IDENTIFICACION",
    "tarjetaCredito": "Tarjetas de Crédito",
    "tarjetaMarca": "Tarjeta",
    "tarjetaTipo": "Tipo",
    "tarjetaNumero": "Número",
    "factAnterior": "Facturación Anterior",
    "saldoAnteriorPagar": "Saldo a Pagar",
    "fechaAnteriorMaxPago": "Fecha Máxima de Pago",
    "fechaAnteriorMaxPagoC": "F. Máx. Pago",
    "consumosMes": "Consumos del mes",
    "pagosMes": "Pagos del mes",
    "factActual": "Facturación Actual",
    "saldoActualPagar": "Saldo a Pagar",
    "fechaActualMaxPago": "Fecha Máxima de Pago",
    "minPagar": "Mínimo a Pagar",
    "tituloMovTC": "DETALLE DE PAGOS Y CONSUMOS",
    "fechaMovTC": "Fecha",
    "referenciaMovTC": "Referencia",
    "descEstabMovTC": "Descripción/Establecimiento",
    "tipoMovTC": "Tipo",
    "detalleMovTC": "Detalle",
    "valorPagosMovTC": "Valor Pagos",
    "valorConsumosMovTC": "Valor Consumos",
    "saldoConsumoMovTC": "Saldo Consumo",
    "saldoDiferidoMovTC": "Saldo Diferido",
    "Totales": "Totales",
    "TotalGeneral": "Total General",
    "tarjetaCupo": "Cupo Tarjeta",
    "tarjetaCupoDisponible": "Cupo Disponible",
    "tcMovsPorFecha": "Por fecha",
    "tcMovsUltimoCorte": "Último corte",
    "tcTitularTarjeta": "Titular Tarjeta",
    "tcPropias": "Mis Tarjetas Locales",
    "tcTerceros": "Tarjetas Externas o de Terceros",
    "pagoTotalTC": "Pago Total",
    "pagoMinimoTC": "Pago Mínimo",
    "OpcionesConsultaPagoTC": [{ "value": "1", "viewValue": "Mis Tarjetas Locales" }, { "value": "2", "viewValue": "Tarjetas Externas o de Terceros" }],
    "estodoCuentaTC": "Estado de Cuenta",
    "avancesEfectivoTC": "Avances en Efectivo",
    "diferimientoConsumosTC": "Solicitud de Diferimiento de Consumos",
    "bloqueoTC": "Solicitud de Bloqueo por Pérdida o Robo",
    "OpcionesBloqueoTC": [{ "value": "1", "viewValue": "PÉRDIDA" }, { "value": "2", "viewValue": "ROBO" }],
    "Motivo": "Motivo de Ahorro",
    "OpcionesTelefBloqueo": [{ "value": "1", "viewValue": "CONVENCIONAL" }, { "value": "2", "viewValue": "CELULAR" }],
    "Contacto": "Contacto",
    "fechaExpiracionTC": "Fecha Expiración",
    "tcCVV": "C V V",
    "tcTipoAvance": "Tipo Avance",
    "tcAvancesTipos": [{ "value": "Corriente", "viewValue": "Corriente" }, { "value": "Diferido", "viewValue": "Diferido" }],
    "tcSaldoAnterior": "Saldo Anterior",
    "tcSaldoActual": "Saldo Actual",
    "tcPagosCreditos": "Pagos / Créditos",
    "tcConsumosDebitos": "Consumos / Débitos",
    "tituloTablaDiferimientoConsumosTC": "DETALLE DE CONSUMOS",
    "tipoDiferido": "Tipo Diferido",
    "diferirConsumoTc": "Diferir",
    "montoDiferirTc": "Monto a Diferir",
    "plazoDiferirTc": "Plazo a Diferir",
    "meses": "meses",
    "FEmision": "Fecha Emisión",
    "Utilizado": "Utilizado",
    "Periodo": "Período",
    "PagoCredito": "Pagos / Créditos",
    "ConsumoDebito": "Consumos / Débitos",
    "TotalPagar": "Total a Pagar",
    "MsgTelefono": "Puedes comunicarte a los teléfonos:",
    "MsgCorreo": "o a la dirección de correo electrónico:",
    "PInvertir": "Ingrese el capital:",
    "PlazoSugerido": "Plazo sugerido:",
    "OtroPlazo": "Otro plazo:",
    "POpcion": "Elija opción",
    "InteresGando": "Intereses Ganados",
    "InteresGandoMob": "I. Ganados",
    "CuentaDebito": "Seleccione cuenta débito:",
    "listaAgencias": "Elija la agencia más cercana:",
    "Agencia": "Agencia",
    "AceptarT1": " Acepto ",
    "AceptarT2": "términos y condiciones",
    "AceptarT3": "Certificación de Residencia Fiscal",
    "Femision": "Fecha de emisión",
    "InteresG": "Interés ganado",
    "FVencimiento": "Fecha de vencimiento",
    "PRecibir": "Neto a recibir",
    "PMensaje2": "Seleccione el pago del interés: ",
    "plazoDesde": "Plazo desde ",
    //Preaprobado
    "ValorEntero": "Permitido solo valores enteros",
    "CreditoError": "NO SE HA PODIDO GENERAR EL CRÉDITO",
    "NMaximo": "Número Máximo:",
    "NMinimo": "Número Mínimo:",
    "MMinimo": "Monto Mínimo:",
    "MensajeAH": "SU REQUERIMIENTO DE AHORRO PROGRAMADO, NO PUEDE SER CONCLUIDO POR FALTA DE ACTUALIZACIÓN DE SUS DATOS, FAVOR ACÉRQUESE A LA AGENCIA MAS CERCANA",
    "MensajeCP": "SU REQUERIMIENTO DE CRÉDITO EN LÍNEA, NO PUEDE SER CONCLUIDO POR FALTA DE ACTUALIZACIÓN DE SUS DATOS, FAVOR ACÉRQUESE A LA AGENCIA MAS CERCANA",
    "MensajeCPG": "SE PRESENTARÁ LA INFORMACIÓN DEL CRÉDITO EL SIGUIENTE DÍA.",
    "MensajeIL": "SU REQUERIMIENTO DE INVERSIÓN EN LÍNEA NO PUEDE SER CONCLUIDO POR FALTA DE ACTUALIZACIÓN DE SUS DATOS, FAVOR ACÉRQUESE A LA AGENCIA MAS CERCANA",
    "FijoPago": "Primera Fecha de Pago",
    "PrestamoPreaprobadoT": "Crédito en Línea",
    "PrestamoPreaprobadoTMenu": "Préstamos Preaprobados",
    "PrestamoPreaprobadoTMob": "Crédito Línea",
    "FinicioPagos": "Fecha de Inicio de Pagos",
    "EstadoCuenta": "Estado de Cuenta",
    "CorteCuenta": "Corte de Cuenta",
    "NOperacion": "N° Cuenta",
    "VerCertificado": "Ver Certificado",
    "TransferenciasAOtrosExt": "Transferencias al Exterior",
    "FValor": "Fecha Valor",
    "CSwift": "Código SWIFT",
    "DirBanco": "Dirección Banco",
    "TPago": "Tipo Pago",
    "DBeneficiario": "Dirección Beneficiario",
    "CargosAFavor": "Cargos a favor de",
    "CTransferenciasExt": "Consultas al Exterior",
    "PorBeneficiario": "Porcentaje Beneficiario",
    "BCorresInter": "BANCO CORRESPONSAL O INTERMEDIARIO",
    "BBeneficiario": "BANCO BENEFICIARIO",
    "DireccionB1": "Dirección del Banco 1",
    "DireccionB2": "Dirección del Banco 2",
    "DireccionB3": "Dirección del Banco 3",
    "CodigoABA": "Código ABA",
    "CuentaBInter": "No. Cuenta Banco Intermediario",
    "BenefFinal": "BENEFICIARIO FINAL",
    "FDesdeHasta": "LA FECHA HASTA DEBE SER MAYOR A LA FECHA DESDE",
    "OTPUnlockBCPMessage": "INGRESE OTP DE SEGURIDAD ENVIADO PARA CONTINUAR CON DESBLOQUEO POR VALIDACIÓN",
    "MensajeTranferenciasSPI": "Estimados clientes, debido a las próximas festividades de Fin de año les informamos que todas las transferencias a otras instituciones financieras que se efectúen a través de nuestro aplicativo web hasta las 16H00 del jueves 30 de Diciembre se procesarán el mismo día, las transacciones realizadas después de la hora señalada se enviaran el Lunes 3 de enero de 2022.",
    "RealizarTransferencia": "Realizar Transferencia",
    "RealizarPagoTarjeta": "Realizar Pago Tarjeta",
    'CredioPreaprobado': 'Crédito Preaprobado',
    'CreditoPreaprobado': 'Preaprobados',
    'CreditoPreaprobadoMob': 'Preaprobado',
    "Transparencia": "Transparencia",
    "CertificadoBancario": "Certificado Bancario",
    "Descuentos": "Acepto Descuentos de Ley",
    "FondoReserva": "Fondo Irrepartible de Reserva",
    "TesoroNacional": "Tesoro Nacional",
    "ImpuestoSolca": "Impuesto SOLCA",
    "SeguroDesgravamen": "Seguro Desgravamen",
    "AhorroProgramadoT": "Ahorro Programado valor inicial",
    "MensajeNoPreaprobado": "Estimad@ cliente usted no tiene un crédito aprobado, acérquese a cualquier oficina de la Cooperativa más cercana y solicite mayor información.",
    "MontomaxPre": "Monto Máximo",
    "Tinteres": "Tasa de Interés",
    "CuentaAhorro": "Cuenta de Ahorros",
    "TexoAcepta": "Estimado cliente, una vez que usted acepte las condiciones pactadas del crédito en línea no podrá reversarlo. ¿Está seguro de continuar con la transacción?",
    "TexoNoAcepta": "Estimado cliente, una vez rechazado el crédito en línea no podrá reversarlo. ¿Está seguro de continuar con la transacción?",
    "AutorizacionAhorroP": "Autorización Ahorro Programado",
    "AutorizaGAhorroP": " establecidas por la Cooperativa; declaro que he recibido toda la información de este producto.",
    "AutorizoAhorroP": "Autorizo, expresamente a la Cooperativa de Ahorro y Crédito Julian Lorente, la creación de un ahorro programado, bajo ",
    "AutorizaGCredPer": "Información Crediticia y Personal",
    "AutorizoCredPer": "Faculto y autorizo de manera voluntaria, expresa e irrevocable a la Cooperativa de Ahorro y Crédito Julian Lorente, para que proceda a consultar la información concerniente a mis activos, pasivos y datos personales, así como para obtener datos referentes a mi comportamiento crediticio, manejo de mi(s) cuenta(s) de cualquier clase y en general sobre el cumplimiento de mis obligaciones financieras y comerciales; de igual manara autorizo de manera expresa que, en caso de la eventual concesión de la o las operaciones crediticias, la entidad financiera reporte la información referente a mi comportamiento de pago y, por ende, cumplimiento de las obligaciones crediticias que de manera libre y voluntaria he aceptado.",
    "MontoMensual": "Monto Mensual",
    "AutorizoBloqueoCuenta": "Autorizo el bloqueo de mi cuenta",
    "ErrorCuentaVista": "ERROR AL OBTENER INFORMACIÓN DE LA CUENTA",
    "Fvigencia": "Fecha de vigencia",
    "FvigenciaC": "F. Vigencia",
    "PagCreditoLinea": "Crédito en Línea",
    "SolTarjeta": "Solicitud Tarjeta de Débito",
    "SolTarjetaTitulo": "SOLICITAR TARJETA DE DÉBITO ADICIONAL",
    "NombreTrajeta": "Nombre que desea impreso en su tarjeta",
    "ColorTarjeta": "Escoja el diseño de su tarjeta de crédito adicional",
    "OficinaEntrega": "Oficina de Retiro",
    "SolEnviada": "ESTIMADO CLIENTE, YA SE ENVIO UNA SOLICITUD DE TARJETA DE DÉBITO EL:",
    "SolRegistrada": "Estimado cliente, su solicitud para la emisión de una Tarjeta de Débito ha sido registrada con éxito; por favor, acercarse a la oficina de retiro que eligió en 5 días laborables, para poder retirar su tarjeta.",
    "PlazoFijoR": "Inversión en Línea",
    "LicitudFondosR": "Licitud de Fondos",
    "And": " y ",
    "CreditoApro": "Contrato",
    'IMPUESTOS': 'Impuestos',
    'Sucursal': 'Sucursal',
    "Certificado": "Certficados",
    "BancaPersonas": "Ingreso",
    "BancaEmpresas": "Empresas",
    'MensajeEstadoCuenta': 'LA FECHA SELECCIONADA NO PUEDE SUPERAR LOS ULTIMOS 12 MESES',
    "MasIva": "+ IVA",
    "Frecuencias": [
      /*{"value": 0, "viewValue": "AL VENCIMIENTO", "nDays": 1},
      {"value": 1, "viewValue": "DIARIO", "nDays": 1},
      {"value": 2, "viewValue": "SEMANAL", "nDays": 5},
      {"value": 4, "viewValue": "QUINCENAL", "nDays": 15},*/
      { "value": 5, "viewValue": "MENSUAL", "nDays": 30 },
      /*{"value": 6, "viewValue": "TRIMESTRAL", "nDays": 90},
      {"value": 7, "viewValue": "SEMESTRAL", "nDays": 180},
      {"value": 8, "viewValue": "ANUAL", "nDays": 360},
      {"value": 12, "viewValue": "SEPTEMESTRAL", "nDays": 210},
      {"value": 13, "viewValue": "CUATRIMESTRAL", "nDays": 120},
      {"value": 14, "viewValue": "BIANUAL", "nDays": 720}*/
    ],
    "VDiferimiento": "Valor Diferimiento",
    "IDiferimiento": "Interés Diferimiento",
    "MisCuentas": "Mis Cuentas",
    "Perfil": "Perfil",
    "PServicios": "Servicios",
    "Internas": "CUENTAS PJL.",
    "Otros": "Externos",
    "NombreE": "Nombre Empresa",
    "NombreD": "Nombre Deudor",
    "NoTienePagos": "No Tiene Pagos Pendientes",
    "NumeroOrden": "Número de Orden",
    'NEstudios': 'Nivel de Estudios',
    'FVivienda': 'Fecha Vivienda',
    'FITrabajo': 'Fecha Ingreso Trabajo',
    'FIngresos': 'Fuente Ingresos',
    'TIngreso': 'Tipo Ingreso',
    'MIngreso': 'Monto Ingreso',
    "GenerarPDF": "Formato PDF",
    "GenerarXLS": "Formato XLS",
    "DatosPersonales": "Datos Personales",
    "DireccionesTelefonos": "Direcciones y Teléfonos",
    "FuenteIngresos": "Fuente de Ingresos",
    'PagosMenu': 'Préstamos',
    'PagoPrestamos': 'Pago Prestamos',
    "Normal": "Normal",
    "Estudiantil": "Estudiantil",
    "Diferenciada": "Diferenciada",
    "TarifayPasajeros": "Tarifas y Pasajeros",
    "EscogeTarifaMsg": "Escoge tu tartifa correcta y evita ser multado",
    "FechaHora": "Fecha y Hora del Ticket",
    "ResumenCompra": "Resumen Compra",
    "NumeroPasajeros": "Numero Pasajeros",
    "AlertaTicketsExcedidos": "Ha excedido el numero máximo de tickets para su compra",
    "AlertaHorarioExcedidos": "Solo puede realizar la compra en el horario siguiente",
    "TranviaCuenca": "Tranvía de Cuenca",
    "Pasajero": "Pasajeros",
    "VigenciaDesde": "Válido Desde",
    "VigenciaHasta": "Válido Hasta",
    "ValidoHasta": "Valido Hasta",
    "TotalTicket": "Total Tickets",
    "ComprarTickets": "Comprar Tickets",
    "ServicioNoDisponible": "Servicio no disponible intente mas tarde.",
    'SolicitudCredito': 'Solicitud Crédito',
    'TipoCredito': 'Destino del crédito',
    'TituloSolicitudCredito': 'Solicitud de Crédito en Línea',
    'DatosSolicitados': 'Datos del crédito solicitado:',
    'MontoSolicitado': 'Monto Solicitado',
    'CuotaMensual': 'Cuota Mensual',
    'PlazoMeses': 'Plazo en Meses',
    'CuotaAjustada': 'Cuota Ajustada',
    'GarPropuesta': 'Garantía propuesta ',
    'Descripicion': 'Descripición',
    'Avaluo': 'Valor Comercial',
    'Garantes': 'ó Garantes ',
    'CTipo': 'Tipo de Cuota',
    'Cuotamin': 'El valor permitido para la cuota es entre ',
    'tipoGarantia': 'Tipo Garantía',
    'tipoBien': 'Tipo de Bien',
    'destinoGarantia': 'Destino de Crédito como Garantía',
    'valorGrantizado': 'EL VALOR GARANTIZADO ES MENOR AL VALOR DEL PRODUCTO',
    'CargarDocumento': 'Subir Documento',
    'DescripcionDocumento': 'Descripción del Documento',
    'Requisitos': 'Requisitos',
    'Garantia': 'Garantía',
    'Archivo': 'Archivo',
    'CargaPendiente': 'Pendiente',
    'ArchivoGargado': 'Cargado',
    'DestinoCredito': 'Para obtener la lista de documentos debe completar la información del destino de crédito',
    'valorGarantia': 'EL SALDO DISPONIBLE NO GARANTIZA EL MONTO SOLICITADO',
    "TipoCertificado": "Tipo Certificado",
    "tipoCertificado": [
      { "value": "AC", "viewValue": "Cuenta Activa" },
      { "value": "AB", "viewValue": "Saldo Promedio" },
      { "value": "C", "viewValue": "Crediticio" }
    ],
    "CuentaActiva": "Cuenta Activa",
    "SaldoPromedio": "Saldo Promedio",
    "Crediticio": "Crediticio",
    "Nota": "Nota",
    "Guardar": "Guardar",
    "GuardarBene": "Guardar Beneficiario",
    "GenerarSolicitud": "Generar Solicitud",
    'Acuerdo': 'Por mis propios derechos y los que represento, autorizo a la Entidad para que pueda obtener en todo momento y cuantas veces sean necesarias de cualquier fuente de información, incluida la central de riesgos y los burós de información crediticia, la información personal sobre el comportamiento de mi(s) crédito(s). De igual manera autorizo a transferir o entregar la información referente a todo comportamiento de mi(s) crédito(s) a autoridades competentes, organismos de control, burós de información crediticia y a todos aquellos que legalmente los soliciten, además autorizo conocer tal comportamiento crediticio para fines de evaluación de crédito eximiendo de toda responsabilidad a la Entidad, sometiéndome a las disposiciones de las normativas de crédito.',
    'ApellidoNombre': 'APELLIDOS Y NOMBRES',
    "Identificacion2": "IDENTIFICACIÓN",
    "Fecha2": "FECHA",
    "porcentajeF": "Porcentaje a financiar",
    "Profesion": "Profesión",
    "ActualizacionDatos": "Actualizacion de Datos",
    "InformacionBasica": "Información Básica",
    "DirecionTelefonos": "Dirección y Teléfonos",
    "ActividadEconomica": "ACTIVIDAD ECONÓMICA (SI SU ACTIVIDAD PRINCIPAL ES DIFERENTE, ACTUALICE DE FORMA PRESENCIAL)",
    "ActividadEconomicaIngAct": "Información Ecónomica (Ingresos y Activos)",
    "ActividadEconomicaEgPas": "Información Ecónomica (Egresos y Pasivos)",
    "Referencias": "Referencias",
    "PersonaExpuestaPEP": "Persona Expuesta Políticamente (PEP / RESIDENCIA FISCAL)",
    "DeclaracionAutorizacion": "Declaración y Autorización",
    "MsgDeclaracionAutorizacionTit": "PROMOCIONES Y PUBLICIDAD",
    "MsgDeclaracionAutorizacionTitD": "ALMACENAMIENTO DE DATOS",
    "MsgDeclaracionAutorizacion": "Conforme lo dispuesto en el Art. 2 de la Ley de Comercio Electrónico, Firmas Electrónicas y Mensaje de Datos, declaro, que la información que suministro es auténtica y veraz, por tanto, eximo a la Cooperativa de toda responsabilidad inclusive respecto de terceros, si esta declaración fuere falsa o errónea; y, autorizo además a la Cooperativa de Ahorro y Crédito, la verificación de la misma, sometiéndome a las leyes pertinentes en caso de falsear a la verdad y estar presto en reconocer la información ante la autoridad competente en caso de ser necesaria.",
    "MsgDeclaracionAutorizacionA": "Autorizo de forma expresa y voluntaria a la Cooperativa de Ahorro y Crédito, para que valide mis medios de notificación y contactos proporcionados a la entidad; y me remita y/o contacte a mi correo electrónico, números celulares, números convencionales: avisos, comunicaciones, claves temporales, notificaciones de acceso a canales electrónicos, notificaciones de vencimiento de operaciones de crédito, recordatorios de fechas de pago, notificaciones de realización de transferencias electrónicas, códigos de seguridad, campañas de marketing digital por cualquier medio electrónico, llamadas informativas y/o comerciales, gestiones de cobranza, comprobantes de venta electrónicos, información de productos y servicios ofrecidos por la Cooperativa de Ahorro y Crédito, y demás notificaciones o información que la Cooperativa de Ahorro y Crédito considere importante hacerme conocer. Además, autorizo a realizar los procedimientos de control, gestión y seguimiento de los riesgos legalmente establecidos en la ley.    ",
    "MsgDeclaracionAutorizacionD": "Autorizo expresa y voluntariamente a la Cooperativa de Ahorro y Crédito, para que registre y almacene en sus bases de datos, y aplique los mecanismos de tratamiento y protección que garanticen la confidencialidad y seguridad de los datos que han sido ingresados y actualizados. Los datos serán conservados de acuerdo con: 1.- Durante el tiempo que dure mi relación comercial con la Cooperativa de Ahorro y Crédito; 2.- Hasta que solicite la revocación de este consentimiento, siempre y cuando dicha revocatoria sea aplicable en el marco de la ley y/o 3.- hasta que la Cooperativa de Ahorro y Crédito considere necesario almacenar mis datos.",
    "MsgPersonaExpuestaPEP": "*** Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control respectivo.<br> Los familiares de las Personas Expuestas Políticamente (PEP), sean estos los conyugues o personas unidas bajo el régimen de unión de hecho, familiares hasta el segundo grado de consanguineidad o primero de afinidad; y, el o los colaborador (es) directo (s) esto es la (s) persona (s) natural (es) con las cuales una Persona Expuesta Políticamente (PEP), se encuentre asociada o vinculada societariamente, o mantenga otro tipo de relaciones empresariales, comerciales o laborales estrechas, seránconsideradas como PEP, según lo dispuesto en los Art. 42, 43 y 44 del Reglamento a la Ley de Prevención de Lavado de Activos y del Financiamiento de Delitos.",
    "MsgPersonaExpuestaPEPAcp": "¿ ES USTED UNA PERSONA EXPUESTA POLÍTICAMENTE O TIENE VINCULO CON PERSONAS EXPUESTAS POLÍTICAMENTE ?",
    "Si": "Si",
    "No": "No",
    "tipoIdentificacion": "Tipo de Identificación",
    "identificacion": "Identificación",
    "apellidos": "Apellidos",
    "nombres": "Nombres",
    "nacionalidad": "Nacionalidad",
    "fechaNacimiento": "Fecha de Nacimiento",
    "genero": "Género",
    "correoElectronico": "Correo Electrónico",
    'NProfesion': 'Profesion/Titulo',
    'miembrosFamilia': 'N° Miembros Familiares',
    'cargasFamiliares': 'N° Cargas Familiares',
    'primerNombre': 'Primer Nombre',
    'primerApellido': 'Primer Apellido',
    'segundoNombre': 'Segundo Nombre',
    'segundoApellido': 'Segundo Apellido',
    'InformacionConyuge': 'INFORMACIÓN CÓNYUGUE O CONVIVIENTE',
    'tiemposVivienda': 'Años en la Vivienda',
    'tipoTelefono': 'Tipo Teléfono',
    'operadora': 'Operadora',
    'area': 'Área',
    'pais': 'País',
    'provincia': 'Provincia',
    'ciudad': 'Ciudad',
    'parroquia': 'Parroquia',
    'canton': 'Cantón',
    'actividadPrincipal': 'Actividad Principal',
    'origenIngresos': 'Origen de Ingresos',
    'lugarTrabajo': 'Lugar de Trabajo',
    'cargo': 'Cargo',
    'direccionTrabajo': 'Dirección Trabajo',
    'telefonoTrabajo': 'Teléfono Trabajo',
    'celularTrabajo': 'Celular Trabajo',
    'relacion': 'Relación',
    'mensajeActualizacionRevision': 'POR FAVOR, CONTINÚE CON LA ACTUALIZACIÓN DE LA INFORMACIÓN FALTANTE',
    "Ingresos": "INGRESOS (VALORES MENSUALES)",
    "Activos": "ACTIVOS",
    "Arriendos": "ARRIENDOS",
    "Comercio": "COMERCIO",
    "InteresInversiones": "INTERES INVERSIONES",
    "OtrosIngresos": "OTROS INGRESOS",
    "PensionJubilacion": "PENSIÓN JUBILACION",
    "PensionesAlimenticias": "PENSIONES ALIMENTICIAS",
    "Remesas": "REMESAS",
    "SueldoConyugue": "SUELDO CONYUGUE",
    "SueldoMensual": "SUELDO MENSUAL",
    "Bancos": "BANCOS",
    "BienesMuebles": "BIENES MUEBLES",
    "Caja": "CAJA",
    "CuentasPorCobrar": "CUENTAS POR COBRAR",
    "Inversiones": "INVERSIONES",
    "Maquinaria": "MAQUINARIA",
    "Mercaderias": "MERCADERIAS",
    "Terrenos": "TERRENOS",
    "Vehiculos": "VEHÍCULOS",
    "Vivienda": "VIVIENDA",
    "Egresos": "EGRESOS (VALORES MENSUALES)",
    "Pasivos": "PASIVOS",
    "Alimentacion": "ALIMENTACIÓN",
    "Arriendo": "ARRIENDO",
    "CuotaCasasComerciales": "CUOTA CASAS COMERCIALES",
    "CuotaPrestamo": "CUOTA PRESTAMOS",
    "CuotaTrajetaCredito": "CUOTA TARJETAS CRÉDITO",
    "Educacion": "EDUCACIÓN",
    "OtrosGastos": "OTROS GASTOS",
    "Salud": "SALUD",
    "ServiciosBasicos": "SERVICIOS BÁSICOS",
    "Transporte": "TRANSPORTE",
    "Vestuario": "VESTUARIO",
    "CasasComerciales": "CASAS COMERCIALES",
    "CuentasPorPagar": "CUENTAS POR PAGAR",
    "TrajetaCredito": "TARJETAS DE CRÉDITO",
    "MensajeActualizacionDatos": "ESTIMADO SOCI@ PARA ACCEDER AL INCENTIVO, POR FAVOR, ACTUALICE SUS DATOS.",
    "PrimerPaso": "Info. Bas.",
    "SegundoPaso": "Dir. Telf.",
    "TercerPaso": "Act. Eco.",
    "CuartoPaso": "Act. Eco. Ing y Act",
    "QuintoPaso": "Act. Eco. Eg y Pas",
    "SextoPaso": "Ref.",
    "SeptimoPaso": "PEP",
    "OctavoPaso": "Decl. Aut.",
    "NoTienePasosPrevios": "ESTIMADO USUARIO, POR FAVOR REALIZAR LOS PASOS PREVIOS PARA ACTIVAR EL ACTUAL.",
    "Minimo": "Mínimo",
    "Digitos": "digitos",
    "Referencia1": "Referencia 1",
    "Referencia2": "Referencia 2",
    "DatosCompletos": "SUS DATOS SE ENCUENTRAN ACTUALIZADOS CORRECTAMENTE.",
    "Etnia": "Etnia",
    'Registro': 'Cliente no registrado en el sistema',
    'PorcentajeA22': 'La información ingresada será sujeta a verificación por parte de la institución.',
    'PorcentajeA13': 'La información ingresada será sujeta a verificación por parte de la institución.',
    'AutorizacionBuro': 'AUTORIZACIÓN DE CONSULTA EN EL BURÓ DE CRÉDITO',
    'Atentamente': 'Atentamente',
    "IdentificacionG": "Estimado Socio/a no debe ingresar su información sino la identificación del garante.",
    "MensajeA22": "DE ACUERDO A SU GARANTÍA, EL MONTO MÁXIMO A SOLICITAR ES DE ",
    "MensajeA13": "MONTO A OTORGARSE DE ACUERDO AL AVALÚO DEL BIEN",
    "numeroCuotaNovalido": "El número de cuotas para el monto solicitado no es válido",
    "InternasBene": "Internos",
    "OtrosBene": "Externos",
    "CampoNoModificable": "Campo no Modificable",
    "MsgNCargasFamiliares": "Considere el número de personas que dependen económicamente de usted",
    "Coperadora": [
      { "value": "1", "viewValue": "CNT" },
      { "value": "2", "viewValue": "MOVISTAR" },
      { "value": "3", "viewValue": "CLARO" },
      { "value": "4", "viewValue": "TWENTI" }
    ],
    "TCuota": "Tipo Cuota",
    "TipoCuotas": [
      { "value": "AMG", "viewValue": "Francesa" },
      { "value": "CAP", "viewValue": "Alemana" }
    ],
    "SCuota": "N. Cuotas",
    "VCuota": "Valor Cuota",
    "TScoring": "Total Valor Scoring",
    "DeudorFinanciero": "Deudor En El Sist. Financiero",
    "TInstitucion": "Títulos Fuera De La Institución",
    "TPermanencia": "Tiempo De Permanencia",
    "SCredito": "Solicitud de Crédito",
    "PrestamoS": "Préstamo Solicitado",
    "UnosDatos": "Unos pocos datos más: ",
    "ModalidadTrabajo": "Modalidad de Trabajo",
    "AhorrosVistaFirst": "Ahorro",
    "AhorrosVistaSecond": "Vista",
    "AhorrosProgramadoFirst": "Ahorro",
    "AhorrosProgramadoSecond": "Programado",
    "CertificadosAportacionFirst": "Certificado",
    "CertificadosAportacionSecond": "Aportacion",
    "DeseaCredito": "NO SOY SOCIO AUN PERO QUISIERA UN PRÉSTAMO (click AQUI)",
    "RegGiros": "Registro Giro",
    "ConGiros": "Consulta Giros",
    "Giros": "Giros",
    "MontoDebitar": "Monto a Debitar",
    "CuentaDebitar": "Cuenta a Debitar",
    "AcNumeroTel": "Número de teléfono actual",
    "AcDireccion": "Dirección actual",
    "EsperaToken": "Tiempo vigencia OTP",
    "Instalar": "Instalar",
    "App": "App",
    "TituloInstalarApp": "Instalar app en su Dispositivo",
    "InstalariOS": "O para instalar la aplicación, toque el botón compartir",
    "InstalariOSTienda": "Descargar la aplicación desde Apple Store.",
    "InstalariOSTiendaSafari": "Descargar la aplicación desde Apple Store o mediante el navegador Safari.",
    "InstalariOSSec": "Luego, sube para buscar y toca 'Agregar a la pantalla de inicio'.",
    "InstalariOSThird": "Y al estar instalado la aplicación en el dispositivo, podra proceder a utilizarlo seleccionandolo desde el  el menú de inicio.",
    "InstalarAndroid": "O descargarlo directamente desde el siguiente botón.",
    "InstalarAndroidTienda": "Descargar la aplicación desde Google Play Store ",
    "InstalarAndroidTiendaCrhome": "Descargar la aplicación desde Google Play Store o mediante el navegador Chrome.",
    "InstaladoAndroid": "La aplicación móvil fue instalada correctamente, puede proceder a utilizarla, desde su dispositivo móvil seleccionandolo desde el menú de inicio.",
    "TienePreApro": "Tiene un préstamo preaprobado de",
    "TienePreAproAntiTit": "Anticipo de Sueldo",
    "TienePreAproAnti": "Tiene un anticipo de sueldo disponible por",
    "TienePreAproAntiD": ". Click aquí para más información",
    "TransferirMobMen": "Transferir",
    "RegresarBene": "Elige el Beneficiario",
    "RegresarServ": "Elige el Servicio",
    "RegresarTarj": "Elegir Tarjeta de Crédito",
    "RegresarIni": "Regresar al Inicio",
    "DesvincularDispositivo": "Desvincular",
    "DesvincularDispositivoTot": "Desvincular dispositivo",
    "DesvincularDispositivoTotMens": "Usted ya posee un dispositivo vinculado, para desvincularlo, por favor ingresar la siguiente información",
    "Dispositivo": "Dispositivo",
    "VincularDispositivo": "FitToken",
    "LeerQR": "Leer Codigo QR:",
    "IngresarPin": "Ingresar PIN / Huella / Face ID",
    "IngresarPinLog": "Ingresar PIN",
    "DispAVincular": "Dispositivo a Vincular",
    "DispVinculado": "Dispositivo Vinculado",
    "NombreDispositivo": "Nombre Dispositivo",
    "FechaRegistro": "Fecha Registro",
    "Vincular": "Vincular",
    "MsgDispositivoVinculado": "Estimado/a cliente, para utilizar el dispositivo FitToken primero debe iniciar sesión en su dispositivo móvil y aceptar los permisos de recepción de notificaciones push.",
    "SolicitudEnviada": "Solicitud Enviada a su dispositivo",
    "SolicitudNoVigAutorizada": "Solicitud No vigente o Autorizada",
    "MsgNotificacionesPush": "Estimado cliente, si no llega la notificación de autorización a su dispositivo móvil puede autorizarla abriendo la aplicación",
    "DispositivoDesvinculado": "Dispositivo Desvinculado",
    "ValidandoPin": "Validando PIN",
    "ValidandoBiometric": "Validando Credencial Biométrico",
    "AutorizacionLogin": "Autorizar Login",
    "AutorizacionTransferencia": "Autorizar Transferencia",
    "AutorizacionBeneficiario": "Autorizar Gestión Beneficiario",
    "AutorizacionBloqueoCuenta": "Autorizar Bloqueo de Cuenta",
    "AutorizacionMontosMaximos": "Autorizar Gestión Montos",
    "SolicitudAutorizada": "Solicitud Autorizada",
    "InversionMobMen": "Inversiones",
    "PagoTarjetaMobMen": "Pagar Tarjeta",
    "ServicioMobMen": "Servicios",
    "PrestamoMobMen": "Préstamo",
    "DatosContacto": "Datos de contacto",
    "SolicitarAutorizacion": "Solicitar Autorización",
    "Autorizacion": "Autorización",
    "RegSoftToken": "Registrar fit-token",
    "DesSoftToken": "Desvincular fit-token",
    "EliminarPin": "Eliminar Pin",
    "FechaVinculacion": "Fecha Vinculación",
    "CodigoQR": "Código QR",
    "MisContactos": "Mis Contactos",
    "AddFavorites": "Añadir a Favoritos",
    "IngresarReferencia": "Por favor, ingrese una referencia",
    "NoConexGuradar": "NO HAY CONEXION PARA GUARDAR",
    "Ejecutar": "Ejecutar",
    "TituloAutorizacion": "Autorización para el tratamiento de sus datos personales.",
    "MsgAutorizacion": "En cumplimiento de lo estipulado por la Ley 81 de 26 de marzo de 2019, el Decreto Ejecutivo No.285 de 28 de mayo de 2021 y el Acuerdo 001-2022 de 24 de febrero de 2022, todos relativos a la Protección de Datos Personales, Coop Virtual PJL, en su condición de responsable del tratamiento de los datos personales , reconocemos la importancia de la privacidad, seguridad, sensibilidad y confidencialidad de los datos de nuestros clientes, colaboradores, accionistas, proveedores y otros; por lo que en materia de protección de datos personales, solicitamos su consentimiento para tratamiento de datos personales en la forma que se indica dentro de nuestra política.",
    "MsgAutorizar": "Usted autoriza el tratamiento de sus datos:",
    "ScanearCodigoQR": "Escanear código QR:",
    "MsgScanearCodigo": "Estimado/a Cliente, descargar la aplicación Coop Virtual PJL en su aplicativo móvil desde Google Play o Apple Store y escanear el siguiente código QR:",
    "MsgScanearCodigoTran": "Estimado usuario, enseñe su código QR para recibir un pago o transferencia.",
    "MsgCerrarCodigo": "Una vez realizada la vinculación con su celular puede cerrar esta ventana.",
    "Copiar": "Copiar al portapapeles",
    "CopiarMsg": "Cuenta copiada al portapapeles",
    "CodigoArea": "Código Area",
    "FiltrarResultadosPaises": "Buscar código de area",
    'NombresCuenta': [
      { 'descripcion': 'CERTIFICADOS DE APORTACION', 'viewValue': 'CERTIFICADOS DE APORTACION' },
      { 'descripcion': 'CUENTA BASICA', 'viewValue': 'CUENTA BASICA' },
      { 'descripcion': 'AHORRO PROGRAMADO ESCOLAR', 'viewValue': 'AHORRO PROGRAMADO ESCOLAR' },
      { 'descripcion': 'AHORRO PROGRAMADO NAVIDENO', 'viewValue': 'AHORRO PROGRAMADO NAVIDENO' },
      { 'descripcion': 'AHORRO CONVENIO', 'viewValue': 'AHORRO CONVENIO' },
      { 'descripcion': 'AHORRO CLIENTES', 'viewValue': 'AHORRO CLIENTES' },
      { 'descripcion': 'AHORRO SOCIO', 'viewValue': 'AHORRO SOCIO' },
      { 'descripcion': 'AHORRO ENCAJE', 'viewValue': 'AHORRO ENCAJE' },
      { 'descripcion': 'AHORRO PROGRAMADO INFANTIL', 'viewValue': 'AHORRO PROGRAMADO INFANTIL' },
      { 'descripcion': 'AHORRO PLUS', 'viewValue': 'AHORRO PLUS' },
      { 'descripcion': 'AHORRO PROGRAMADO', 'viewValue': 'AHORRO PROGRAMADO' },
      { 'descripcion': 'AHORRO PROGRAMADO CLIENTES', 'viewValue': 'AHORRO PROGRAMADO CLIENTES' },
      { 'descripcion': 'AHORRO OBLIGATORIO', 'viewValue': 'AHORRO OBLIGATORIO' },
      { 'descripcion': 'AHORRO PROGRAMADO GOLD', 'viewValue': 'AHORRO PROGRAMADO GOLD' },
      { 'descripcion': 'AHORRO PROGRAMADO FUTURO', 'viewValue': 'AHORRO PROGRAMADO FUTURO' },
      { 'descripcion': 'AHORRO PROGRAMADO EJECUTIVO', 'viewValue': 'AHORRO PROGRAMADO EJECUTIVO' },
      { 'descripcion': 'CUENTAS CORRESPONSALIA', 'viewValue': 'CUENTAS CORRESPONSALIA' },
      { 'descripcion': 'CUENTAS CORRIENTES', 'viewValue': 'CUENTAS CORRIENTES' },
      { 'descripcion': 'CUENTAS AHORROS', 'viewValue': 'CUENTAS AHORROS' },
      { 'descripcion': 'CUENTAS POR PAGAR', 'viewValue': 'CUENTAS POR PAGAR' },
      { 'descripcion': 'DEPOSITOS A PLAZO FIJO', 'viewValue': 'DEPOSITOS A PLAZO FIJO' },
      { 'descripcion': 'CERT.AHORRO ACTIVOS', 'viewValue': 'CERT.AHORRO ACTIVOS' },
      { 'descripcion': 'AHORRO COOP VIRTUAL', 'viewValue': 'AHORRO COOP VIRTUAL' },
      { 'descripcion': 'MICROCREDITO CONAFIPS ZONA A', 'viewValue': 'MICROCREDITO CONAFIPS ZONA A' },
      { 'descripcion': 'MICROCREDITO CONAFIPS ZONA B', 'viewValue': 'MICROCREDITO CONAFIPS ZONA B' },
      { 'descripcion': 'PRESTAMO CREDI AHORRO MICRO', 'viewValue': 'PRESTAMO CREDI AHORRO MICRO' },
      { 'descripcion': 'PRESTAMO VIVIENDA CFN', 'viewValue': 'PRESTAMO VIVIENDA CFN' },
      { 'descripcion': 'PRESTAMO MICROCREDITO', 'viewValue': 'PRESTAMO MICROCREDITO' },
      { 'descripcion': 'PRESTAMO EMERGENTE MICROCREDIT', 'viewValue': 'PRESTAMO EMERGENTE MICROCREDIT' },
      { 'descripcion': 'PRESTAMO CONSUMO CREDI AGIL', 'viewValue': 'PRESTAMO CONSUMO CREDI AGIL' },
      { 'descripcion': 'PRESTAMO CONSUMO', 'viewValue': 'PRESTAMO CONSUMO' },
      { 'descripcion': 'PRESTAMO CONSUMO CONVENIO', 'viewValue': 'PRESTAMO CONSUMO CONVENIO' },
      { 'descripcion': 'PRESTAMO MICRO GARA-FOGEPS VIP', 'viewValue': 'PRESTAMO MICRO GARA-FOGEPS VIP' },
      { 'descripcion': 'PRESTAMO MICROACUM AMPLIAD-VIP', 'viewValue': 'PRESTAMO MICROACUM AMPLIAD-VIP' },
      { 'descripcion': 'PRESTAMO MICRO EMPRENDE MUJER', 'viewValue': 'PRESTAMO MICRO EMPRENDE MUJER' },
      { 'descripcion': 'PRESTAMO CONSUMO ORD-PRIOR-B2B', 'viewValue': 'PRESTAMO CONSUMO ORD-PRIOR-B2B' },
      { 'descripcion': 'MICROCREDITO CONAFIPS ZONA C', 'viewValue': 'MICROCREDITO CONAFIPS ZONA C' },
      { 'descripcion': 'PRESTAMO PRODUCTIVO SECTORIAL', 'viewValue': 'PRESTAMO PRODUCTIVO SECTORIAL' },
      { 'descripcion': 'PRESTAMO VIVIENDA', 'viewValue': 'PRESTAMO VIVIENDA' },
      { 'descripcion': 'PRESTAMO MICROACUM SIMPLE MVIP', 'viewValue': 'PRESTAMO MICROACUM SIMPLE MVIP' },
      { 'descripcion': 'PRESTAMO CONSUMO ORDINARIO', 'viewValue': 'PRESTAMO CONSUMO ORDINARIO' },
      { 'descripcion': 'PRESTAMO PRODUCTIVO', 'viewValue': 'PRESTAMO PRODUCTIVO' },
      { 'descripcion': 'PRESTAMO VIVIENDA CONAFIPS', 'viewValue': 'PRESTAMO VIVIENDA CONAFIPS' },
      { 'descripcion': 'PRESTAMO MICRO ECOLOGICO', 'viewValue': 'PRESTAMO MICRO ECOLOGICO' },
      { 'descripcion': 'PRESTAMO CREDI AHORRO CONSUMO', 'viewValue': 'PRESTAMO CREDI AHORRO CONSUMO' },
      { 'descripcion': 'PRESTAMO MICRO EMPRENDEDOR', 'viewValue': 'PRESTAMO MICRO EMPRENDEDOR' },
      { 'descripcion': 'PRESTAMO CONSUMO ORD-PRIOR-VIP', 'viewValue': 'PRESTAMO CONSUMO ORD-PRIOR-VIP' },
      { 'descripcion': 'CONSUMO INIAP', 'viewValue': 'CONSUMO INIAP' },
      { 'descripcion': 'PRESTAMO PRODUCTIVO TITULARIZADO', 'viewValue': 'PRESTAMO PRODUCTIVO TITULARIZADO' },
      { 'descripcion': 'PRESTAMO CONSUMO TITULARIZADO', 'viewValue': 'PRESTAMO CONSUMO TITULARIZADO' },
      { 'descripcion': 'INVERSIONES EN OTRAS INSTITUCIONES', 'viewValue': 'INVERSIONES EN OTRAS INSTITUCIONES' },
      { 'descripcion': 'PLAZO FIJO BANCOS', 'viewValue': 'PLAZO FIJO BANCOS' },
      { 'descripcion': 'DPF VENCIDO', 'viewValue': 'DPF VENCIDO' },
      { 'descripcion': 'FUSIÓN MINORISTA', 'viewValue': 'FUSIÓN MINORISTA' },
      { 'descripcion': 'FUSIÓN ACUMULACIÓN SIMPLE', 'viewValue': 'FUSIÓN ACUMULACIÓN SIMPLE' },
      { 'descripcion': 'FUSIÓN ACUMULACIÓN AMPLIADA', 'viewValue': 'FUSIÓN ACUMULACIÓN AMPLIADA' },
      { 'descripcion': 'DPF INTELIGENTE', 'viewValue': 'DPF INTELIGENTE' },
      { 'descripcion': 'COMPRA DE CARTERA', 'viewValue': 'COMPRA DE CARTERA' },
      { 'descripcion': 'AHORRO PROGRAMADO EN LINEA', 'viewValue': 'AHORRO PROGRAMADO EN LINEA' },
      { 'descripcion': 'DPF EN LINEA', 'viewValue': 'DPF EN LINEA' },
      { 'descripcion': 'MICROCRÉDITO LC1 MIN', 'viewValue': 'MICROCRÉDITO LC1 MIN' },
      { 'descripcion': 'PRESTAMOS VENDIDOS CONSUMO', 'viewValue': 'PRESTAMOS VENDIDOS CONSUMO' },
      { 'descripcion': 'PRESTAMOS VENDIDOS MICROCREDITO', 'viewValue': 'PRESTAMOS VENDIDOS MICROCREDITO' },
      { 'descripcion': 'PRESTAMOS VENDIDOS PRODUCTIVO', 'viewValue': 'PRESTAMOS VENDIDOS PRODUCTIVO' },
      { 'descripcion': 'PRESTAMOS VENDIDOS INMOBILIARIO', 'viewValue': 'PRESTAMOS VENDIDOS INMOBILIARIO' },
      { 'descripcion': 'CREDIINVERSIÓN', 'viewValue': 'CREDIINVERSIÓN' }
    ],
    "Desinstalar": "Desinstalar",
    "DesintalarAplicacion": "Si usted ya desinstalo la aplicación pero presenta inconvenientes puede forzar la eliminacion de archivos temporales con el siguiente botón:",

    //FaceLiveness
    "timeoutHeaderText": "Tiempo de espera excedido",
    "timeoutMessageText": "Rostro no se ubicó en el óvalo dentro del tiempo límite. Intente de nuevo, centrando su rostro en el óvalo antes de los 7 segundos máximos",
    "faceDistanceHeaderText": "Validación falló durante el conteo regresivo",
    "faceDistanceMessageText": "Evite acercarse o alejarse durante el conteo regresivo y asegúrese que haya sólo un rostro frente a la cámara",
    "clientHeaderText": "Error con el cliente",
    "clientMessageText": "Validación  fallida debido a un problema con el cliente",
    "serverHeaderText": "Problema con el servidor",
    "serverMessageText": "No se pudo completar la validación debido a un problema con el servidor",
    "landscapeHeaderText": "Orientación horizontal no soportada",
    "landscapeMessageText": "Rote su dispositivo a orientación  vertial",
    "portraitMessageText": "Asegúrese que su dispositivo se mantenga en orientación vertial durante la duración de la verificación",
    "tryAgainText": "Intente nuevamente",
    "instructionsHeaderHeadingText": "Validación biométrica",
    "instructionsHeaderBodyText": "Usted pasará a través de un proceso de identificación facial, para validar que es una persona real.",
    "instructionsBeginCheckText": "Inicio de validación",
    "photosensitivyWarningHeadingText": "Advertencia",
    "photosensitivityWarningHeadingText": "Advertencia",
    "photosensitivyWarningBodyText": "Para una correcta verificación debe quedarse completamente quieto luego de encajar su rostro en el óvalo y no debe mover el dispositivo móvil",
    "photosensitivityWarningBodyText": "Para una correcta verificación debe quedarse completamente quieto luego de encajar su rostro en el óvalo y no debe mover el dispositivo móvil",
    "photosensitivyWarningInfoText": "Un pequeño porcentaje de individuos podrían experimentar ataques epilépticos cuando son expuestos a luces de colores variados. Tenga mucho cuidado si usted o alguien de su familia posee condiciones epilépticas",
    "instructionListHeadingText": "Siga las instrucciones para completar la validación:",
    "goodFitCaptionText": "Encaje correcto",
    "goodFitAltText": "Ilustración del rostro de una persona, encajando perfectamente dentro del óvalo",
    "tooFarCaptionText": "Muy lejos",
    "tooFarAltText": "Ilustración del rostro de una persona, dentro del óvalo; Hay un espacio vacío entre el perímetro del rostro y los límites del óvalo",
    "instructionListStepOneText": "Cuando aparezca el óvalo, ubique su rostro en el mismo durante 7 segundos",
    "instructionListStepTwoText": "Maximice el brillo de su pantalla",
    "instructionListStepThreeText": "Asegúrece que su rostro no esté cubierto con gafas, lentes, o máscaras",
    "instructionListStepFourText": "Ubíquese en un lugar bien iluminado pero que no tenga la luz solar directo en su rostro",
    "cameraMinSpecificationsHeadingText": "Su cámara no cumple con las especificaciones mínimas",
    "cameraMinSpecificationsMessageText": "Su cámara debe soportar una resolución de almenos 320*240 con 15 frames por segundo",
    "cameraNotFoundHeadingText": "Su cámara no está disponible",
    "cameraNotFoundMessageText": "Asegúrese que su cámara está conectada y que los permisos de acceso a cámara están habilitados en la configuración antes  de reintentar",
    "retryCameraPermissionsText": "Reintente",
    "cancelLivenessCheckText": "Cancelar validación de prueba de vida",
    "recordingIndicatorText": "Rec",
    "hintMoveFaceFrontOfCameraText": "Ubique su rostro frente a la cámara",
    "hintTooManyFacesText": "Asegúrese que sólo exista un rostro frente a la cámara",
    "hintFaceDetectedText": "Rostro detectado",
    "hintCanNotIdentifyText": "Ubique su rostro frente a la cámara",
    "hintTooCloseText": "Retroceda un poco",
    "hintTooFarText": "Acérquese un poco",
    "hintHoldFacePositionCountdownText": "Mantenga fija la posición del rostro durante la cuenta regresiva",
    "hintConnectingText": "Conectando...",
    "hintVerifyingText": "Verificando...",
    "hintIlluminationTooBrightText": "Ubíquese en un área más oscura",
    "hintIlluminationTooDarkText": "Ubíquese en un área más clara",
    "hintIlluminationNormalText": "Condiciones normales de iluminación",
    "hintHoldFaceForFreshnessText": "No se mueva",
    "ErrorLiveness": "Error durante la detección de vida",
    "ValidacionLiveness": "La prueba de vida no fue exitosa",
    "MensajeSoftOtp": "Estimado/a cliente no tiene una transacción pendiente de autorización",
    "GenerarOTP": "Generar OTP",
    "ClaveDigital": "Clave digital",
    "MsgClaveDigitalWEB": "La clave digital ha sido enviada a su correo electrónico.",
    "MsgClaveDigitalMOB": "Para obtener la clave digital debe abrir la aplicación de COOP VIRTUAL PJL y presionar sobre el botón \"Clave digital\" de su dispositivo móvil.",
    "hintCenterFaceText": "Centre su rostro y presione el botón",
    "startScreenBeginCheckText": "Iniciar verificación de video",
    "waitingCameraPermissionText": "Esperando que se de el permiso de la cámara.",
    "TieneSolicitud": "Usted tiene una solicitud quiere revisarla, completarla o anularla",
    "CertificadoCuentaActiva": "Certificado de Cuenta Activa",
    "CertificadoConsolidado": "Certificado Consolidado",
    "Certificados": "Certificados",
    "PrevisualizarCertificado": "Previsualizar",
    "GenerarCertificado": "Generar",
    "CertificarioBancario": "CERTIFICADO BANCARIO",
    "ValidacionCertificados": "Validación de Certificados",
    "descripcionValidacionCertificados": "Los certificados en formato electrónico que emite la Cooperativa de Ahorro y Crédito Padre Julián Lorente, deben ser validados por la institución o persona que lo solicita. Ingrese el Nro. de Certificado ubicado en la parte inferior del código de barras del documento.",
    "Importante": "IMPORTANTE: ",
    "descripcionImportante": "La única información válida es la generada en la pantalla; el certificado visualizado puede ser descargado si así lo requiere el trámite.",
    "codigoDeImagen": "Por favor digite el código de la imagen *",
    "CreditoPrea": "CRE. PRE",
    "numeroDocumento": "NÚMERO DE DOCUMENTO",
    "ejemploNumeroDocumento": "WEB-11-19990101-000001 ó PC-22-19990101-000001",
    "mensajeInformativoCertificadoBancario": "PUEDES ENCONTRAR EL NÚMERO DE CERTIFICADO DEBAJO DEL CÓDIGO DE BARRAS DEL DOCUMENTO.",
    "FechaEmision": "Fecha de Emisión",
    "validarCertificado": "Validar Certificado",
    "labelValidacionCertificado": "Valida tu Certificado PJL",
    "MensajeFooter": "ESTE ES UN MENSAJE AUTOMÁTICO POR FAVOR NO RESPONDER.",
    "DialogoParaEnviarReporteAlCorreo": "Se envió el certificado a su email. Revise por favor.",
    "DialogoParaEnviarNotificacionAlCorreo": "Cuenta Bloqueada.",
    "EnviarSPI": "Desea enviar la transferencia mediante el SPI?",
    "NumCuotasA": "Plazo Ahorro",
    "AgenciasC": "Selecciona tu agencia más cercana",
    "MensBene": "NINGUN CRITERIO COINCIDE CON SU BUSQUEDA"
  }

  idiomas: any;
  idioma: string;
  getIdioma() {
    //Lee Configuracion de Idioma - locale
    this.idioma = localStorage.getItem("locale");
    if (this.idioma == null) {  //Primer Ingreso
      //this.idioma = navigator.language.includes("en") ? "en" : "es"
      this.idioma = "es";
    }
    this.idiomas = (this.idioma === "en") ? this.ingles : this.espanol
    localStorage.setItem("locale", this.idioma)
    localStorage.setItem("idioma", JSON.stringify(this.idiomas));
  }

  toEspanol() {
    return this.espanol
  }
  toEnglish() {
    return this.ingles
  }
}